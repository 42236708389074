export const dateToStringFormat = (dateObject: any) => {
  const day = dateObject.getDate();
  let month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  month = getMonthName(month)

  return `${day} de ${month}, ${year}`;
}

const getMonthName = (monthNumber: number) => {
  const months = [
    { num: 1, name: 'Enero' },
    { num: 2, name: 'Febrero' },
    { num: 3, name: 'Marzo' },
    { num: 4, name: 'Abril' },
    { num: 5, name: 'Mayo' },
    { num: 6, name: 'Junio' },
    { num: 7, name: 'Julio' },
    { num: 8, name: 'Agosto' },
    { num: 9, name: 'Septiembre' },
    { num: 10, name: 'Octubre' },
    { num: 11, name: 'Noviembre' },
    { num: 12, name: 'Diciembre' },
  ]

  const monthName = months.find(({num}) => num === monthNumber)?.name
  return monthName || 'Mes inválido'
}

// From YYYY/MM/DD to DD/MM/YYYY
export const parseToStandardFormat = (YMDdate: string) => { 
  let splittedDate = YMDdate.trim().split('-');
  if (splittedDate.length !== 3) return YMDdate
  
  splittedDate = splittedDate.reverse();
  const fomrattedDate = splittedDate.join('/');

  return fomrattedDate
}

export const getMinutesDifference = (date1: Date, date2: Date) => {
  const diff = Math.abs(date1.getTime() - date2.getTime());
  return Math.round(diff / 60000)
}