import React, { Fragment, useState } from 'react'
import useCheckAplicatedCoupon from '../../../../hooks/carts/useCheckAplicatedCoupon'
import useGetAvailableCoupons from '../../../../hooks/carts/useGetAvailableCoupons'
import useGetCouponsActivatedByProvider from '../../../../hooks/carts/useGetCouponsActivatedByProvider'
import { ProviderCartData } from '../../../../interfaces/carts'
import CouponsActivatedModal from '../coupons_activated_model'
import './index.scss'

interface Props {
  provider: ProviderCartData
}

function CouponsSection({ provider }: Props) {
  const { coupons } = useGetCouponsActivatedByProvider(provider._id)
  const { availabledCoupons } = useGetAvailableCoupons(coupons, provider._id)
  const { aplicatedCoupon } = useCheckAplicatedCoupon(coupons, provider._id)

  const [openCouponsModal, setOpenCouponsModal] = useState<boolean>(false)

  if (coupons.length === 0) return <Fragment></Fragment>

  return (
    <Fragment>
      <div className={`coupons-section coupons-section--${aplicatedCoupon ? 'aplicated' : 'no-aplicated'}`}>
        <h6>
          {aplicatedCoupon
            ? '¡Descuento aplicado!'
            : `Tienes ${availabledCoupons} ${availabledCoupons ? 'cupón' : 'cupones'} disponible`}
        </h6>
        <span onClick={() => setOpenCouponsModal(true)}>Ver Todos</span>
      </div>

      <CouponsActivatedModal
        openModal={openCouponsModal}
        setOpenModal={setOpenCouponsModal}
        couponsActivated={coupons}
        provider={provider}
      />
    </Fragment>
  )
}

export default CouponsSection
