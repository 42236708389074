import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AppContext from '../../context/AppContext'
import { ProductSuggestedData, SuggestedData } from '../../interfaces/suggested'
import { getMultiSuggested } from '../../services/bff_mobile/v1/products/getMultiSuggested'
import { getHomeData } from '../../services/bff_mobile/v2/home/getHomeData'

function useGetSuggestedData(paymentMethod: string) {
  const { cartData } = useContext(AppContext)
  const [suggestedData, setSuggestedData] = useState<SuggestedData[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const history = useHistory()

  useEffect(() => {
    const action = async () => {
      if (cartData._id === '') {
        history.push('/cart2')
        return
      }

      setOpenModal(true)
      setLoading(true)
      const providers = cartData.data
        .find((data) => data.paymentMethod === paymentMethod)
        ?.providers.map((provider) => provider._id)

      const { vendors } = await getHomeData()
      const response = await getMultiSuggested(cartData._id, providers?.join(',')!)

      const data: SuggestedData[] = []
      for (const providerId of Object.keys(response)) {
        const providerName = vendors.find((vendor) => vendor._id === providerId)?.name!

        const products: ProductSuggestedData[] = response[providerId].map((suggested: any) => {
          return {
            productId: suggested['_id'],
            providerName: suggested['provider'],
            productName: suggested['name'],
            price: suggested['showPrice']['price'],
            offerPrice: suggested['showPrice']['offerPrice'],
            quantityMin: suggested['quantityMin'],
            quantityMax: suggested['quantityMax'],
            stock: suggested['stock'],
            promotionalDynamicType: suggested['promotionalDynamicType'],
            promotionalDynamicDetail:
              suggested['promotionalDynamicDetail'].length > 0
                ? suggested['promotionalDynamicDetail'][0]['stock']
                  ? suggested['promotionalDynamicDetail'][0]['stock']
                  : null
                : null,
            imageFullPath: suggested['imageFullPath'],
            uom: suggested['UOM'],
            units: suggested['units'],
            package: suggested['package'],
            outOfStock: suggested['outOfStock'],
          }
        })

        data.push({
          providerId,
          providerName,
          products,
        })
      }

      setSuggestedData(data)

      setLoading(false)
    }

    action()
  }, [paymentMethod]) // eslint-disable-line react-hooks/exhaustive-deps

  return { suggestedData, loading, openModal }
}

export default useGetSuggestedData
