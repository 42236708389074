import { IonSlide, IonSlides } from '@ionic/react';
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router';
import { registerHomeBrandSliderItemEvent } from '../../pages/firebase/firebaseTags';
import { getHomeBrands, THomeBrand } from '../../clients/brands';
import BrandIcon from '../brand-icon/BrandIcon';
import { VisibilityTracker } from '../../hoc';
import { useGetSessionId } from '../../hooks/useGetSessionId';
import './HomeBrandsSliderStyle.scss'

interface Brand {
  _id: string,
  name: string,
  urlLogo: string
}

const brandsSlidesOptions: any = {autoHeigth: true, slidesPerView: 'auto'}

type THomeBrandsSliderProps = RouteComponentProps;

const HomeBrandsSlider: React.FC<THomeBrandsSliderProps> = (props) => {
  const [homeBrands, setHomeBrands] = useState<THomeBrand[]>([]);
  const {history} = props;
  const sessionId = useGetSessionId()

  useEffect(() => {
    async function loadHomeBrands() {
      const homeBrandsResult = await getHomeBrands()
      setHomeBrands(homeBrandsResult)
    }

    loadHomeBrands()
  }, [])

  if (!homeBrands.length) return null

  const handleWhenBrandIsVisible = (brand: Brand) => {
    const now = Date.now();
    const nowDate: Date = new Date(now);
    const nowISO = nowDate.toISOString();    
    const userStringified = localStorage.getItem('@user')
    const user = JSON.parse(userStringified)
    let userId

    if(user && user.roleAlias === "guest"){
      userId = 'user_guest'
    } else {
      userId = user.id
    }

    const placement = 'home'

    registerHomeBrandSliderItemEvent({
      eventName: 'home_carousel_brand_view',
      brandId: brand._id,
      brandName: brand.name,
      userId: userId,
      userSessionCode: sessionId,
      placementName: placement,
      timestamp: now,
      datetimeISO: nowISO
    })


  }

  const handleWhenBrandIsClicked = (brand: Brand) => {
    const now = Date.now();
    const nowDate: Date = new Date(now);
    const nowISO = nowDate.toISOString();    
    const userStringified = localStorage.getItem('@user')
    const user = JSON.parse(userStringified)
    let userId

    if(user && user.roleAlias === "guest"){
      userId = 'user_guest'
    } else {
      userId = user.id
    }

    const placement = 'home'

    registerHomeBrandSliderItemEvent({
      eventName: 'home_carousel_brand_click',
      brandId: brand._id,
      brandName: brand.name,
      userId: userId,
      userSessionCode: sessionId,
      placementName: placement,
      timestamp: now,
      datetimeISO: nowISO
    })

    history.push(`/home-brands-detail/${brand._id}`, {name: brand.name})
  }

  return (
    <>
      <div className="container-distributo">
        <h1 className="providers-section-title">Marcas</h1>
      </div>
      <div className='container-distributo home-brands-container'>
        <IonSlides pager={false} scrollbar={false} options={brandsSlidesOptions} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {homeBrands.map((brand: Brand) => {
            return (
              <IonSlide key={brand._id} className='slide-home-brand' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <VisibilityTracker onVisible={() => handleWhenBrandIsVisible(brand)} threshold={0.5}>
                  <BrandIcon
                    urlLogo={brand.urlLogo}
                    handleAction={() => handleWhenBrandIsClicked(brand)}
                  />
                </VisibilityTracker>
              </IonSlide>
            )
          })}
        </IonSlides>
      </div>
    </>
  )
}

export default withRouter(React.memo(HomeBrandsSlider))
