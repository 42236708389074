import React from 'react'
import { Subscribe } from 'unstated-typescript'
import Pages from './pages'

// models
import User from './models/User'
import Office from './models/Office'
import Order from './models/Order'
import { Coupon } from './models/Coupon'
import Delivery from './models/Delivery'
import Category from './models/Category'
import Settings from './models/Settings'
import { CouponsActivatedModel } from './models/CouponsActivatedModel'
import { ProductModel } from './models/ProductModel'
import { CartModel } from './models/CartModel'
import { HashtagModel } from './models/HashtagModel'
import { ProviderModel } from './models/ProviderModel'
import { PromotionModel } from './models/PromotionModel'
import faqsModel from './models/Faqs'
import termsConditionModel from './models/TermsConditionModel'
import contactFormModel from './models/ContactForm'
import Notifications from './models/Notifications'
import SurveyModel from './models/Survey'
import useGetPlatformApp from './hooks/useGetPlatformApp'
import useGetCountryInfo from './hooks/useGetCountryInfo'

function MainApp() {
  useGetPlatformApp()
  useGetCountryInfo()

  return (
    <Subscribe
      to={[
        User,
        Settings,
        Office,
        Category,
        Order,
        Delivery,
        ProductModel,
        CouponsActivatedModel,
        PromotionModel,
        CartModel,
        ProviderModel,
        Coupon,
        faqsModel,
        termsConditionModel,
        contactFormModel,
        Notifications,
        SurveyModel,
        HashtagModel,
      ]}
    >
      {(
        user: User,
        settings: Settings,
        offices: Office,
        categories: Category,
        orders: Order,
        deliveries: Delivery,
        productModel: ProductModel,
        CouponsActivatedModel: CouponsActivatedModel,
        promotion: PromotionModel,
        cartModel: CartModel,
        providerModel: ProviderModel,
        coupons: Coupon,
        showFaqs: faqsModel,
        showTermsConditions: termsConditionModel,
        showContactDetails: contactFormModel,
        notification: Notifications,
        surveyModel: SurveyModel,
        hashtagModel: HashtagModel,
      ) => {
        if (user.isAuthenticated()) {
          return (
            <Pages.MenuView
              user={user}
              settings={settings}
              offices={offices}
              orders={orders}
              deliveries={deliveries}
              categories={categories}
              promotion={promotion}
              productModel={productModel}
              cartModel={cartModel}
              couponsActivatedModel={CouponsActivatedModel}
              providerModel={providerModel}
              coupons={coupons}
              showFaqs={showFaqs}
              showTermsConditions={showTermsConditions}
              showContactDetails={showContactDetails}
              notification={notification}
              surveyModel={surveyModel}
              hashtagModel={hashtagModel}
            />
          )
        } else {
          return (
            <Pages.AccessView
              contactFormModel={showContactDetails}
              user={user}
              settings={settings}
              cartModel={cartModel}
              offices={offices}
              showTermsConditions={showTermsConditions}
            />
          )
        }
      }}
    </Subscribe>
  )
}

export default MainApp
