import Axios from 'axios'

export const getContactDetails = async (countryCode: string) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/contacts/help`, {
      headers: {
        countryCode: countryCode,
      },
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}