import { IonIcon, IonItem, IonList, IonRange } from '@ionic/react'
import React, { Fragment, useEffect, useState } from 'react'
import iconSad from '../../assets/icons/icn_sad.svg'
import iconHappy from '../../assets/icons/icn_happy.svg'
import './SurveyNps.scss'

interface Props {
  info:any
  onChange: Function
}

const SliderGrade: React.FC<Props> = props => {
  const { info, onChange } = props
  const { title, subtitle, options, _id } = info
  const [values, setValues] = useState<any>({min:'', max:''})

  useEffect(() => {
    getMaxAndMinValue()
  }, [!values]) // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeRate = (valueSelected:any) => {
    let tempAnwserObj = {
      questionId: _id,
      options: [
        {
          'label': valueSelected,
          'value': valueSelected
        }
      ]
    }

    onChange(tempAnwserObj)
  }

  const reformattedArray = (options:any[]) => {
    let arrayValues = []
    for (let i = 0; i < options.length; i++) {
      arrayValues.push(options[i].value)
    }
    return arrayValues
  }

  const getMaxAndMinValue = () => {
    const values = reformattedArray(options)
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    setValues({min: minValue, max: maxValue})
  }
  
  return (
    <Fragment>
      <div className="container-surveys">
        <h3>{title}</h3>
        {subtitle && <h4>{subtitle}</h4>}
        <IonList className="buy-experience" lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonItem placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonRange 
              min={values.min}
              max={values.max}
              step={1}
              snaps={true}
              ticks={false}
              pin={true}
              debounce={1000}
              onIonChange={e => onChangeRate(e.detail.value)}
              color="secondary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            />
            <IonIcon slot="start" icon={iconSad} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            <IonIcon slot="end" icon={iconHappy} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </IonItem>
        </IonList>
      </div>
      <div className="divider-section empty-container"></div>
    </Fragment>
  )
}

export default SliderGrade
