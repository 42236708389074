import React, { useState, useEffect } from 'react'
import {
  IonPage,
  IonContent,
  IonSlides,
  IonSlide,
  IonAlert,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonRow,
  IonCol,
  IonGrid,
  IonBadge,
} from '@ionic/react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Redirect, StaticContext } from 'react-router'
import { AppLauncher } from '@capacitor/app-launcher';

import { first } from 'lodash'
import parse from 'html-react-parser';

import User from '../../models/User'
import Settings from '../../models/Settings'
import { ProductModel } from '../../models/ProductModel'
import Office from '../../models/Office'
import { CartModel } from '../../models/CartModel'
import { ProviderModel } from '../../models/ProviderModel'
import Category, { CategoryWithProducts } from '../../models/Category'
import { PromotionModel } from '../../models/PromotionModel'

import GlobalComponents from '../../components'
import ToolBar from '../../components/tool-bar/ToolBar'
import CategoriesProviders from '../categories-provider/CategoriesProviders'
import BannerProvider from '../banners-provider/BannersProvider'
import ModalsController from '../modals-controller/ModalsController'

import cartToolbarIcon from '../../assets/icons/nav_cart_blue.svg'
import searchIcon from '../../assets/icons/act_search.svg'

import { validateLocationState } from '../../utils/locationStateValidations'

import { formatCurrency } from '../../utils/intl'

// Update APP
import { brandProvidersSlider, categoryTreeOfVendor } from '../../clients/category'
import { ifExistAndMayorToZero } from '../../utils/arrays'

// Eventos
import { sendMetric, sendMetricWithSource, viewBrandEvent, viewProviderCategoriesEvent} from '../firebase/firebaseTags'

import LoadingCover from '../../components/loading-cover/LoadingCover'
import FadeBackground from '../../components/fade-background/FadeBackground'
import ProviderRating from '../../components/provider-rating/ProviderRating'
import LoadingComponent from '../../components/loading-component/LoadingComponent';
import { getCartLength } from '../../utils/cart';
import { EventEmitter } from '../../utils/events';
import { countryPe } from '../../utils/countriesTexts';
import { isOfficeSupported } from '../../utils/validateCurrentOffice';
import FeedbackNoScope from '../../components/feedback-no-scope/FeedbackNoScope';
import feedbackNoScopeImage from '../../assets/feedback-no-scope.png'
import blueBell from '../../assets/icons/nav_notification_blue.svg'
import { getLocalStorageObject } from '../../utils/localstorageData';
import { refreshNotifications } from '../reusableFunctions/Notifications';
import TextVariations from '../../models/TextVariations';
import { PromotionModal } from '../promotion-modal';

import './HomeProviders.scss'
import { isModalPromotionVisualized } from '../../utils/promotionModalFunctions';
import { navigateToCart } from '../../utils/navigation';
import { getProviderById } from '../../clients/provider';
import { callPromise } from '../../utils/promises';
import PerformanceTimer from '../../models/PerformanceTimer';

interface IPathParams {}

interface RouteState {
  singleProviderId?: any
  nameProvider?: any
  minumun?: any
  banners?: any
  aditionalInfo?: string
  categoryId?: any
  id?: any
  title?: any
  singleProvider?: any
  vendorId?: any
  oneVendor?: boolean
  recoveryPassword?: boolean
  categoryTree?: any[]
  vendorName?: string
  brandId?: string
  from?:string
}

type Props = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  offices: Office
  categories: Category
  promotion: PromotionModel
  productModel: ProductModel
  cartModel: CartModel
  providerModel: ProviderModel
  singleProviderIdUser?: string
  nameProvider?: any
  minumun?: any
  banners?: any
  aditionalInfo?: string
  oneVendor?: boolean
}

const varsToCheck = ['minumun', 'nameProvider', 'singleProviderId']
const brandsSlidesOptions: any = {autoHeigth: true, slidesPerView: 'auto'}

const HomeProviders: React.FC<Props> = props => {
  const fromBrandData = localStorage.getItem('fromBrandData')
  const [ , setLoading] = useState<any>(true)
  const [categories, ] = useState<CategoryWithProducts[]>([])
  const [ , setOffice] = useState<any>({})
  const [ , setOffices] = useState<any>([])
  const [latestUpdate, setLatestUpdate] = useState<Date>(new Date(2000, 1, 1))
  const [appVersion, ] = useState<any>({})
  const [nextUpdate, setNextUpdate] = useState<any>({})
  const [brandProviders, setBrandProviders] = useState<any[]>([])
  const [offers, setOffers] = useState<any[]>([])
  const [isLoadingOffers, setIsLoadingOffers] = useState<boolean>(true)
  const [filterByBrandsCategories, setFilterByBrandsCategories] = useState<string>('')
  const [filterBrand, ] = useState<boolean>(false)
  const [categoriesResult, setCategoriesResult] = useState<any>('')
  const [isFetching, setFetching] = useState<boolean>(true)
  const [showtype, setShowtype] = useState<any>('featured')
  const [providerId, setProviderId] = useState<any>('')
  const [promotions, setpromotions] = useState<any[]>([])
  const [promotionModals, setPromotionModals] = useState<any[]>([])
  const [isPromotionModalOpen, setPromotionModalOpen] = useState<boolean>(false)
  const [carouselTop, setCarouselTop] = useState<any>({})
  const [predefinedBrands, setPredefinedBrands] = useState<any[]>([])
  const [loadingCover, setLoadingCover] = useState<boolean>(false)
  //const [shouldRender] = useState<boolean>(validateLocationState(props.history, varsToCheck))
  const [shouldRender] = useState<boolean>(true)
  const [hasReadTerms, setHasReadTerms] = useState<boolean>(false)
  const [isCheckedTabOne, setIsCheckedTabOne] = useState<boolean>(fromBrandData && fromBrandData === 'info-view' ? false : true )
  const [isCheckedTabTwo, setIsCheckedTabTwo] = useState<boolean>(false)
  const [isCheckedTabThree, setIsCheckedTabThree] = useState<boolean>(fromBrandData && fromBrandData === 'info-view' ? true : false)
  const [cartLengthData, setCartLengthData] = useState<any>({ cartLength: 0, prevLength: 0 })
  const [animateAtc, setAnimateAtc] = useState<string>('')
  const [favorites, setFavorites] = useState<any[]>([])
  const [isLoadingFavs, setIsLoadingFavs] = useState<boolean>(true)
  const [average, setAverage] = useState<number>(0)
  const [isOfficeSupportedValue, setIsOfficeSupportedValue] = useState<boolean>(true)
  const [notificationsQty, setNotificationsQty] = useState<number>(0)
  const [textVariations] = useState<TextVariations>(() => new TextVariations('homeProvider'))

  const performanceTimer = PerformanceTimer.getInstance();

  useEffect(() => {
    if (shouldRender && isFetching === true) { 
      const countryCode = JSON.parse(localStorage.getItem('countryName') || '')?.countryCode

      if(fromBrandData) setShowtype(fromBrandData === 'info-view' ? 'brands' : 'featured')

      if(countryCode === countryPe) setLoadingCover(true) 
      get()
      getDataAync()
      showCategoriesOrProduct()
    }
  }, [categories, filterByBrandsCategories]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoadingFavs(false)
  }, [favorites])

  useEffect(() => {
    if (shouldRender) {

      const userData = getLocalStorageObject('@user');
      const userId = userData?.id;
      
      const promises = [
        { 
          req: brandProvidersSlider,
          params: [validateSingleProviderId()],
          callback: (brands: any) => {
            if (brands) setPredefinedBrands(brands);
          },
        },
        {
          req: props.user.getUserMe,
          params: [],
          callback: (user: any) => {
            if (user) checkTermsAndConditions(user);
          },
        }
      ]

      if (userId) promises.push({
        req: refreshNotifications,
        params: [userId],
        callback: (updatedNotifications: any) => {
          if (updatedNotifications) setNotificationsQty(updatedNotifications || 0)
        },
      });
      

      const requests = promises.map(({ req, params, callback }) => callPromise(req, params, callback))
      Promise.all(requests).then(() => {
        if (locationState?.oneVendor) performanceTimer.endPerformanceTimer('home-provider');
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    getCart()

    EventEmitter.subscribe('UpdateCartCount', ({ cartUpdateAction }: any) => {
      setCartLengthData((prevState: any) => {
        let newCartLength = prevState.cartLength
        if(cartUpdateAction === 'add') newCartLength = newCartLength + 1
        if(cartUpdateAction === 'subtract') newCartLength = newCartLength - 1
        const didAdd = newCartLength > prevState.cartLength
        if (didAdd) {
          setAnimateAtc('badge-anim-in')
          setTimeout(() => { setAnimateAtc('badge-anim-out') }, 1);
        }
        return { 
          cartLength: newCartLength,
          prevLength: prevState.cartLength 
        }
      })
    })

    return () => {
      EventEmitter.unsubscribe('UpdateCartCount')
    }
  }, [])

  const checkOfficeSupport = () => {
    const isOfficeSupportedResult: boolean = isOfficeSupported()
    setIsOfficeSupportedValue(isOfficeSupportedResult)
  }
  
  const getCart = async () => {
    const cartLength: any = await getCartLength()
    setCartLengthData({ cartLength, prevLength: cartLength })
  }

  const checkTermsAndConditions = async (user: any) => {
    setTimeout(() => setHasReadTerms(true), 7000)
    const termsandconditions = user?.termsandconditions

    setHasReadTerms(Boolean(termsandconditions))

    const userInfoLocal: any = localStorage.getItem('@user') || null
    const userInfo = userInfoLocal ? JSON.parse(userInfoLocal) : null

    if(userInfo) {
      const updatedUser = Object.assign(userInfo, { termsandconditions })
      localStorage.setItem('@user', JSON.stringify(updatedUser))
    }
  }

  const get = async () => {
    checkOfficeSupport()
    const {
      user,
      promotion,
      categories,
      location,
    } = props

    getAverage()
   
    setProviderId(validateSingleProviderId())
    const data = await categories.providersBrand(validateSingleProviderId())
    const promotions = (await promotion.get("PromotionVendor", validateSingleProviderId()))
    const carouselTop: any = await categories.providerTopCarousel(validateSingleProviderId())

    setCarouselTop(carouselTop)
    setpromotions(promotions)
    setBrandProviders(data)
    setFilterByBrandsCategories('blonde')
    offersPoviders()

    if (location?.state?.oneVendor || checkOneVendor()) checkPromotionModals()

    if (getQueryVariable('pushId')) {
      user.pushUpdate(getQueryVariable('pushId'))
    }
    const offices = await props.offices.get()
    const office = first(offices)
    const gottenFavorites = await props.productModel.getFavorites(validateSingleProviderId() || '')

    props.offices.setOffice(office)
    
    setOffices(offices)
    setOffice(office)
    setFavorites(gottenFavorites)
    setFetching(false)
    setLoading(false)
    setLoadingCover(false)
  }

  const checkOneVendor = () => {
    const vendors = getLocalStorageObject('@providers') || null;
    const vendorsLength = Object.values(vendors).filter((item: any) => item.isActiveInComune !== false && item.sameBussinestype)?.length;
    return vendorsLength === 1;
  }

  const checkPromotionModals = async () => {
    const promotionModals = await props.promotion.get("PromotionModal");
    if (promotionModals?.length && !isModalPromotionVisualized(promotionModals[0]._id)) {
      setPromotionModals(promotionModals);
      setPromotionModalOpen(true)
    }
  }

  const getAverage = async () => { 
    const singleProviderId:any = props.location.state ? props.location.state.singleProviderId : ""
    const currentProvider: any = await getProviderById(singleProviderId)
  
    const average = Math.round(currentProvider?.qualification?.average * 10 ) / 10

    setAverage(average)
  }

  const getDataAync = async () => {
    const now = new Date()
    if (now.getTime() - latestUpdate.getTime() > 60000) {
      setLatestUpdate(new Date())
    }
  }

  const getQueryVariable = (variable: string) => {
    var query = window.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1])
      }
    }
    return null
  }

  const goStore = async () => {
    if (appVersion && appVersion.update && appVersion.url) {
      AppLauncher.openUrl({ url: String(appVersion.url) })
    }
  }

  const skipUpdate = async () => {
    var myDate = new Date()
    myDate.setHours(myDate.getHours() + 24)
    localStorage.setItem('@update', JSON.stringify({ nextUpdate: myDate.getTime() }))
    setNextUpdate(myDate)
  }

  const onMoreInfoClick = (info: { id: string }) => {
    const singleProviderId = props.location.state ? props.location.state.singleProviderId : undefined
    props.history.push(`/plp/${info.id}`, { ...info, singleProviderId })
  }

  const goToCart = () => {
    sendMetricWithSource('view_cart', 'vendor')
    navigateToCart(props.history, props.location.state || props.history.location.state);
  }
  const openSearch = () => { 
    const singleProviderId = locationState?.singleProviderId ? locationState.singleProviderId : undefined

    props.history.push(`/vendor/${singleProviderId}/search`)
  }

  const goBack = () => props.history.replace('/home')

  const renderToolbar = () => {
    const { providerModel, location: { state: locationState } } = props
    const resProps = props.nameProvider ? props.nameProvider : ''
    const nameProvider = locationState?.nameProvider ? locationState.nameProvider : resProps
    const isSingleProvider = providerModel.isSingleProvider()
    const { cartLength } = cartLengthData
    
    const startTime = localStorage.getItem("confirmShoppingCartClickProviderTimeStart")
    if (cartLength > 0 && startTime === null) {
      localStorage.setItem("confirmShoppingCartClickProviderTimeStart", new Date().toString())
    }

    const toolbarButtons = [
      {
        icon: searchIcon,
        key: 'search', 
        onClick: openSearch,
        notOffice: !isOfficeSupportedValue
      },
      {
        icon: cartToolbarIcon,
        key: 'cart',
        onClick: goToCart,
        badge: cartLength,
        animateAtc,
        disabled: !isOfficeSupportedValue
      },
    ]

    if (locationState?.oneVendor) {
      const currentOffice: any = getLocalStorageObject('currentOffice');
      const noOffice: boolean = Boolean(!currentOffice || currentOffice === 'undefined')

      const notificationsBadge = notificationsQty > 0 ? ' ' : 0
 
      const notificationsBtn: any = {
        icon: blueBell,
        key: 'notifications',
        onClick: () => { goToNotifications(noOffice) },
        notOffice: noOffice,
        badge: notificationsBadge,
      }
      toolbarButtons.splice(1, 0, notificationsBtn)
    }

    return (
      nameProvider && (
        <div className='relative-header-wrapper'>
          {cartLength > 0 && <IonBadge className={`cart-badge-btn ${animateAtc}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{cartLength}</IonBadge> }
          <ToolBar
            title={nameProvider}
            secondaryButtons={[{ type: 'back', onClick: goBack, disabled: isSingleProvider }]}
            secondaryHeader={true}
            boxShadow={true}
            oneVendor={locationState?.oneVendor}
            primaryButtons={toolbarButtons}
          />
        </div>
      )
    )
  }

  const goToNotifications = (noOffice: boolean) => {
    sendMetric('view_notifications')
    if(!noOffice) props.history.push('/notifications')
  }

  const onBrandClick = async (brand:any, from:string) => {
    const { _id: brandId, name } = brand
    const { singleProviderId } = props.location.state
    
    const resProps = props.nameProvider || ''
    const vendorName = locationState?.nameProvider || resProps 

    if (!singleProviderId) return;

    viewBrandEvent(brandId, name, singleProviderId, vendorName)
    const categoryTree = await categoryTreeOfVendor(brandId, singleProviderId);
    goToVendorBrand(categoryTree, vendorName, singleProviderId, brandId, from)
  }

  const goToVendorBrand = (categoryTree:any[], vendorName:string, vendorId:string, brandId:string, from:string) => {
    const { history } = props

    history.push(`/vendor/${singleProviderId}/brand/${brandId}`, {
      categoryTree,
      vendorName,
      vendorId,
      brandId,
      from,
    })
  }

  const renderBrandsForMainView = () => {
      return (
        <IonSlides pager={false} options={brandsSlidesOptions} className="slides-brands-home-provider" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {
            predefinedBrands.map(({brand}, index:number) => {
              return (
                <IonSlide key={index.toString() + brand._id} className="ion-slide-brand" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <div
                    className="brand-picture"
                    onClick={() => onBrandClick(brand, 'main-view')}
                  >
                    <img 
                      className="img-brand"
                      src={`${process.env.REACT_APP_BFF_IMAGE}brands/${brand.logo}`}
                      alt='Imagen de la marca'
                    />
                  </div>
                </IonSlide>
              )
            })
          } 
        </IonSlides>
      )
  }

  const renderProducts = () => {
    const {
      location: { state: locationState },
      minumun
    } = props
    const showAditionalInfo = props.providerModel.getById(providerId)

    const restProps = minumun || ''
    const minimunProvider = locationState?.minumun || restProps
    const orderedPromotions = promotions?.sort((p1, p2) => p1.weight - p2.weight)
    const resProps = props.nameProvider || ''
    const nameProvider = locationState?.nameProvider || resProps 

    return (
      <div className="container-reder-products">
        <div className="minimum-for-delivery">
          Mínimo {minimunProvider && formatCurrency(minimunProvider)}
          <div className="delivery-days">Entrega estimada {showAditionalInfo && showAditionalInfo.deliveryInfo && showAditionalInfo.deliveryInfo.days} días</div>
        </div>
        <div className="banners-grid-container">
          <IonGrid placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {ifExistAndMayorToZero(orderedPromotions) && nameProvider &&
              <IonRow placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonCol col-auto placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{renderPromotions(orderedPromotions, nameProvider)}</IonCol>
              </IonRow>
            }
            {ifExistAndMayorToZero(predefinedBrands) &&
              <IonRow placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {renderBrandsForMainView()}
              </IonRow>
            }
          </IonGrid>
        </div>
        <div>{showFavs()}</div>
        <div>{showOffers()}</div>
        <div>{ifExistAndMayorToZero(carouselTop) && carouselTopProduct()}</div>
      </div>
    )
  }

  const showFavs = () => {
    const favoritesTitle = textVariations.getVariationValue('favorites.title') || 'Tus favoritos'

    return (
      <div className="products-slider">
        {isLoadingFavs && (
          <div className="loading-offers">
            <p className="loading">Cargando favoritos...</p>
            <IonSpinner className="spinner-mid" name="crescent" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </div>
        )}
        {ifExistAndMayorToZero(favorites) &&
          <GlobalComponents.ProductSlider
            key={`FavoritesSlider`}
            products={favorites}
            placementName={`home-provider-favorite-product-slider`}
            info={{
              title: favoritesTitle,
              id: `FavoritesSlider`,
              brandId: '',
              hide_more: false,
              vendorName: locationState?.nameProvider,
              vendorId: providerId
            }}
            favorites={true}
            onGoToCart={goToCart}
            allMainCategories={categoriesResult}
            fromSlider={false}
            {...props}
          ></GlobalComponents.ProductSlider>
        }
      </div>
    )
  }

  const showOffers = () => {
    const offersTitle = textVariations.getVariationValue('offers.title') || 'Ofertas'
    const data = [
      {
        name: offersTitle,
        products: offers,
      },
    ]

    return (
      <div className="products-slider">
        {isLoadingOffers && (
          <div className="loading-offers">
            <p className="loading">Cargando ofertas</p>
            <IonSpinner className="spinner-mid" name="crescent" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </div>
        )}
        {ifExistAndMayorToZero(offers) &&
          data.map((offers: any) => (
              <GlobalComponents.ProductSlider
                key={offers._id}
                products={offers.products}
                placementName={`home-provider-promo-product-slider`}
                info={{
                  title: offers.name,
                  id: offers._id,
                  brandId: '',
                  hide_more: false,
                  vendorName: locationState?.nameProvider,
                  vendorId: providerId
                }}
                offers={true}
                onMoreInfoClick={onMoreInfoClick}
                onGoToCart={goToCart}
                allMainCategories={categoriesResult}
                fromSlider={false}
                {...props}
              ></GlobalComponents.ProductSlider>
          ))}
      </div>
    )
  }

  const carouselTopProduct = () => {
    const outstandingTitle = textVariations.getVariationValue('topOutstanding') || 'Top ventas'
    const data = [
      {
        name: outstandingTitle,
        products: carouselTop,
      },
    ]

    return (
      <div className="products-slider">
        {ifExistAndMayorToZero(carouselTop) &&
          data.map((top: any ) => { 
            return(
            <GlobalComponents.ProductSlider
              key={top._id}
              products={top.products}
              placementName={`home-provider-carousel-top-product-slider`}
              info={{
                title: top.name,
                id: top._id,
                brandId: '',
                hide_more: false,
                vendorName: locationState?.nameProvider,
                vendorId: providerId
              }}
              topProduct={true}
              onMoreInfoClick={onMoreInfoClick}
              onGoToCart={goToCart}
              allMainCategories={categoriesResult}
              fromSlider={false}
              {...props}
            ></GlobalComponents.ProductSlider>
          )})}
      </div>
    )
  }

  const showCategoriesOrProduct = async () => {
    const { location: { state: locationState } } = props

    const categoryId = locationState ? locationState.categoryId : null
   
    const result = await props.categories.categoriesPorvider(validateSingleProviderId(), 'primary')
    if (result) {
      if (categoryId) {
        const categoryMatch = result.response.find((category: any) => category._id === categoryId)
        goToCategoryOfProvider(categoryMatch, validateSingleProviderId())
      }
      setCategoriesResult(result.response)
    } 
  }

  const goToCategoryOfProvider = (category: any, singleProvider: any) => {
    const { history } = props
    
    history.push(`/provider/${singleProvider}/category/${category._id}`, {
      id: category._id,
      title: category.name,
      singleProvider: singleProvider,
      categoryId: category._id
    })
  }

  const offersPoviders = async () => {
    const {
      categories,
    } = props


    const offers = await categories.offersProviders(validateSingleProviderId())
    setOffers(offers)
    offers && setIsLoadingOffers(false)
  }

  const categoriesPrimary = () => {
    const { nameProvider } = props
    const resProps = nameProvider ? nameProvider  : ''
    const providerName = locationState?.nameProvider ? locationState.nameProvider : resProps

    return !categoriesResult ? (
      <LoadingComponent/>
    ) : (
      categoriesResult &&
      categoriesResult.map((category: any, index: number) => {
        return (
          <GlobalComponents.ProductSliderAsync
            placementName={`home-provider-category-product-slider-${category.name}`}
            filterBrand={filterBrand}
            singleProviderId={singleProviderId}
            category={category}
            hideMore={false}
            from='home-provider'
            {...props}
            categoryPosition={index + 1}
            nameProvider={providerName}
            fromSlider={true}
          ></GlobalComponents.ProductSliderAsync>
        )
      })
    )
  }

  const validateSingleProviderId = () => {
    const {
      location: { state: locationState },
      singleProviderIdUser
    } = props

    const restProps = singleProviderIdUser ? singleProviderIdUser : undefined

    return locationState?.singleProviderId ? locationState.singleProviderId : restProps
  }

  const showButton = async (event: any) => {
    switch (event) {
      case 'featured':
        setShowtype('featured')
        setIsCheckedTabOne(true)
        setIsCheckedTabTwo(false)
        setIsCheckedTabThree(false)
        break
      case 'categories':
        const { nameProvider, location } = props
        const resProps = nameProvider ? nameProvider  : ''
        const providername = locationState?.nameProvider ? locationState.nameProvider : resProps
        const providerId = location.state ? location.state.singleProviderId : undefined

        viewProviderCategoriesEvent(providerId, providername)
        setShowtype('categories')
        setIsCheckedTabOne(false)
        setIsCheckedTabTwo(true)
        setIsCheckedTabThree(false)
        showCategoriesOrProduct()
        break
      case 'brands':
        sendMetric('view_vendor_info')
        setShowtype('brands')
        setIsCheckedTabOne(false)
        setIsCheckedTabTwo(false)
        setIsCheckedTabThree(true)
        break
    }
  }

  const namePayTitle = () => {
    try {
      const showAditionalInfo = props.providerModel.getById(providerId)
      if(!showAditionalInfo) return ''
      const { paymentMethods } =  showAditionalInfo
  
      const providerMethodsNames: any = []
      paymentMethods.forEach(({type}: any) => !providerMethodsNames.includes(type) && providerMethodsNames.push(type))
      
      const paymentNames = [
        { key: "pre-pago", name: "Anticipado" },
        { key: "post-pago", name: "Contraentrega" },
        { key: "credito", name: "Crédito proveedor" },
      ]

      return (
        <div className="payments-list">
          {providerMethodsNames.map((name: any) => (<div>{paymentNames.find(({key}: any) => key === name)?.name || ""}</div>))}
        </div>
      )    
    } catch (err) {
      console.error(err)
      return ""
    }
  }


  const brandProvider = () => {
    const showAditionalInfo = props.providerModel.getById(providerId)

    return (
      <div className="information-brand">
        {renderProviderRating()}
        <div className="separator"></div>
        <div className="title-aditional-info">Despacho</div>
        <div className="aditional-info-vendors">
          <div>Entrega {showAditionalInfo && showAditionalInfo.deliveryInfo && showAditionalInfo.deliveryInfo.days} días</div>
          <div>Mínimo de compra {showAditionalInfo?.minimun && formatCurrency(showAditionalInfo.minimun)}</div><br />
          <div>Pedidos recibidos hasta {showAditionalInfo?.deliveryInfo?.maxHours} del día anterior al despacho.</div><br />
        </div>
        <div className="separator"></div>
        <div className="title-aditional-info">Forma de pago</div>
        <div className="aditional-info-text">
          {namePayTitle()}
        </div>

        <div className="separator"></div>

        {showAditionalInfo && showAditionalInfo.aditionalInfo &&
          <div>
            <div className="title-aditional-info">Información adicional</div>
            <div className="aditional-info-vendors aditionalInfo">{showAditionalInfo && showAditionalInfo.aditionalInfo && parse(showAditionalInfo.aditionalInfo)}</div>
            <div className="separator"></div>
          </div>
        }
        
        {validateShowAditionalInfo() && parseAdditionalInfo() &&
          <div>
            <div className="title-aditional-info">Información adicional</div>
            <div className="aditional-info-vendors aditionalInfo">{validateShowAditionalInfo() && parseAdditionalInfo()}</div>
            <div className="separator"></div>
          </div>
        }

        <div className="tittle-brand">Marcas</div>
        <div className="container-logo">
          {brandProviders?.length > 0 ? (
            brandProviders.map((brand: any) => {
              return (
                <div className="picture" onClick={() => onBrandClick(brand, 'info-view')}>
                  <img src={`${process.env.REACT_APP_BFF_IMAGE}brands/${brand.logo}`} alt='logo-marca' />
                </div>
              )
            })
          ) : (
            <div className="loading-products">
              <p className="loading">Cargando marcas</p>
              <IonSpinner className="spinner-mid" name="crescent" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderProviderRating = () => {
    return (
      average > 0 && 
        <div>      
          <div className="title-aditional-info">Calificación</div>
          <div className="aditional-info-text">{<ProviderRating average={average}/>}</div>
        </div>
    )
  }

  const validateShowAditionalInfo = () => {
    const showAditionalInfo = props.providerModel.getById(providerId)
    return showAditionalInfo?.deliveryInfo?.additionalInfo
  }

  const parseAdditionalInfo = () => {
    const showAditionalInfo = props.providerModel.getById(providerId)
    return parse(showAditionalInfo.deliveryInfo.additionalInfo)
  }

  const renderPromotions = (orderedPromotions:any, nameProvider:any) => {
    return (
      <div className="banners-grid-container">
        <IonGrid placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonRow className="one-promo" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCol align-self-center placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <div className="promotions-section">
                  <BannerProvider
                    nameProvider={nameProvider}
                    banners={orderedPromotions} 
                    history={props.history}  
                  />
                </div>
              </IonCol>
            </IonRow>
        </IonGrid>
      </div>
    )
  }

  const renderFadeBackground = (loadingCover: any, hasReadTerms: boolean) => {
    if(!loadingCover && !hasReadTerms) {
      return <FadeBackground visible={true}/>
    }
    return ''
  }

  if(!shouldRender) return <Redirect to="/home"/>

  const {
    location: { state: locationState },
    singleProviderIdUser
  } = props

  const restProps = singleProviderIdUser ? singleProviderIdUser : undefined
  const singleProviderId = locationState?.singleProviderId ? locationState.singleProviderId : restProps
  const resProps = props.nameProvider || ''
  const vendorName = locationState?.nameProvider || resProps 

  return (
    <IonPage className="home-page" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      {renderFadeBackground(loadingCover, hasReadTerms)}
      <LoadingCover isLoading={loadingCover} />
      {renderToolbar()}
      {average > 0 && <div className="provider-rating">{<ProviderRating average={average}/>}</div>}
      <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {isPromotionModalOpen && 
            <PromotionModal
              placementName='home-provider'
              isOpenPromotion={isPromotionModalOpen}
              promotionModal={promotionModals[0]}
              closePromotionModal={() => setPromotionModalOpen(false)}
              {...props}
            />
          }

        {appVersion.update &&
          appVersion.url &&
          (appVersion.required || !nextUpdate || nextUpdate.nextUpdate <= new Date().getTime()) && (
            <IonSlides pager={false} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonSlide placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonAlert
                  isOpen={appVersion.update}
                  onDidDismiss={(): void => {}}
                  header={appVersion.header}
                  message={appVersion.required ? appVersion.messageRequired : appVersion.message}
                  buttons={
                    appVersion.required
                      ? [
                        {
                          text: appVersion.buttonUpdate,
                          handler: goStore,
                        },
                      ]
                      : [
                        {
                          text: appVersion.buttonUpdate,
                          handler: goStore,
                        },
                        {
                          text: appVersion.buttonSkip,
                          handler: skipUpdate,
                        },
                      ]
                  }
                />
              </IonSlide>
            </IonSlides>
          )}
          {!isOfficeSupportedValue ?
            <FeedbackNoScope 
              image={feedbackNoScopeImage} 
              text="Lamentamos informar que actualmente no contamos con servicios en tu zona." 
              callToAction="¡Pronto volveremos con novedades para ti y tu negocio!" 
            />
            :
            <>
              <IonSegment mode="md" value={showtype} onIonChange={e => showButton(e.detail.value)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonSegmentButton value="featured" style={{ '--color-checked': '#1E3799' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel className={isCheckedTabOne ? 'isChecked' : ''} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Destacados</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="categories" style={{ '--color-checked': '#1E3799' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel className={isCheckedTabTwo ? 'isChecked' : ''} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Categorías</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="brands" style={{ '--color-checked': '#1E3799' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel className={isCheckedTabThree ? 'isChecked' : ''} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Información</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              {locationState?.oneVendor &&
                <ModalsController
                  recoveryPassword={locationState?.recoveryPassword}
                  showModals={true}
                  {...props} 
                />
              }
              {showtype === 'featured' && renderProducts()}
              {showtype === 'featured' && categoriesPrimary()}
              {showtype === 'categories' && (
                <CategoriesProviders
                  singleProvider={(locationState?.singleProviderId || singleProviderIdUser)}
                  categories={categoriesResult}
                  history={props.history}
                  vendorName={vendorName}
                />
              )}
              {showtype === 'brands' && brandProvider()}
            </>
          }
      </IonContent>
    </IonPage>
  )
}

export default withRouter(HomeProviders)
