import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

// Utils
import { isRfcValid } from '../../utils/rut'

// Components
import TextInput from '../input-form/TextInput'


interface IPathParams {}

type Props = RouteComponentProps<IPathParams> & {
  validateUser: any
}

interface State {
  valueInputText: any
  validationInput: boolean
  validationText: string | number
}
export default class FieldRfcMex extends React.PureComponent<Props, State> {
  state: State = {
    valueInputText: "",
    validationInput: false,
    validationText: ""
  }

  onChangeInput = (event:any) => {
    const inputVal = event.target.value
    const validation = isRfcValid(inputVal) 
    this.setState({
      valueInputText: inputVal,
      validationInput: !!validation,
      validationText: inputVal
    })

    const localUser = { username: inputVal || event.target.value }
    this.props.validateUser(localUser, Boolean(validation))
  }
  
  render() {
      const { validationInput, valueInputText, validationText } = this.state

    return (
      <div className='form-container'>
        <TextInput 
          iconDefault="icn_id" 
          iconInvalid="icn_id-red"
          iconValid="icn_id-blue"
          onChange={this.onChangeInput} 
          placeholder="Ej: VECJ880326XXX"
          label="CURP/RFC"
          isValid={validationInput}
          error={"Escribe tu CURP/RFC sin guión"}
          value={valueInputText}
          toValidate={validationText}
          type="text"
          maxLength={18}
          autoComplete="off"
          informativeText="Esta información es opcional"
        />
      </div>
    )
  }
}


