import React, { Fragment } from 'react'
import Rate from 'rc-rate';
import 'rc-rate/assets/index.css';
import { StarFilled } from '@ant-design/icons';
import 'antd/dist/antd.css';
import './SurveyNps.scss'

interface Props {
  info:any
  onChange: Function
}

const StarRating: React.FC<Props> = props => {
  const { info, onChange} = props
  const { title, subtitle, _id } = info

  const styleStarRating = {
    fontSize: '50px',
  }

  const onChangeRate = (valueSelected:number) => {
    let tempAnwserObj = {
      questionId: _id,
      options: [
        {
          'label': '',
          'value': valueSelected
        }
      ]
    }

    onChange(tempAnwserObj)
  }

  return (
    <Fragment>
      <div className="container-surveys">
        <h3>{title}</h3>
        {subtitle && <h4>{subtitle}</h4>}
        <Rate 
          character={<StarFilled/>}
          style={styleStarRating}
          onChange={onChangeRate}
        />
      </div>
      <div className="divider-section empty-container"></div>
    </Fragment>
  )
}

export default StarRating
