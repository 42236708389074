
const optionsToUOM = {
  'Litros':'litro',
  'litros':'litro',
  'Paquetes':'paquete',
  'paquetes':'paquete',
  'Packs':'pack',
  'packs':'pack',
  'Unidades':'unidad',
  'unidades':'unidad',
  'Displays':'display',
  'displays':'display',
  'Kilos':'kilo',
  'kilos':'kilo',
  'Mangas':'manga',
  'mangas':'manga',
  'Piezas':'pieza',
  'piezas':'pieza',
}

const optionsToPackage = {
  'caja': 'Cajas',
  'Caja': 'Cajas',
  'paquete': 'Paquetes', 
  'Paquete': 'Paquetes', 
  'paquetes': 'Paquetes', 
  'Paquetes': 'Paquetes', 
  'pack': 'Packs', 
  'Pack': 'Packs', 
  'packs': 'Packs',
  'Packs': 'Packs',
  'manga': 'Mangas', 
  'Manga': 'Mangas', 
  'display': 'Displays', 
  'Display': 'Displays', 
  'unidad': 'Unidades',
  'Unidad': 'Unidades'
}

const acronymsToPackage = {
  'caja': 'cajas',
  'Caja': 'cajas',
  'paquete': 'pkg', 
  'Paquete': 'pkg', 
  'paquetes': 'pkg', 
  'Paquetes': 'pkg', 
  'pack': 'packs', 
  'Pack': 'packs.', 
  'packs': 'packs',
  'Packs': 'packs',
  'manga': 'mangas', 
  'Manga': 'mangas', 
  'display': 'disp', 
  'Display': 'disp', 
  'unidad': 'un',
  'Unidad': 'un'
}


export const returnOptionsToUOM = () => optionsToUOM

export const returnOptionsToPackage = () => acronymsToPackage