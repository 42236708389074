import React from 'react';
import helperGuru from '../../assets/icons/almacenera-guru.svg'
import './GuruWhiteScreen.scss';

interface GuruWhiteScreenProps {
  text: string
}

const GuruWhiteScreen: React.FC<GuruWhiteScreenProps> = ({text}) => (
  <div className="container-guru-white-screen">
    <div className="guru-white-screen">
      <div className="container-text-guru-white-screen">
        {text}
      </div>
      <div className="container-img-guru-white-screen">
        <img src={helperGuru} alt="Almacenera Gurú"/>
      </div>
    </div>
  </div>
);

export default GuruWhiteScreen;
