import Axios from 'axios'
import { ProductHashtag, VendorHashtagProduct } from '../models/HashtagModel'

export const getHashtags = async (): Promise<any> => {
  try {
    const result = await Axios.get<ProductHashtag[]>(`${process.env.REACT_APP_BFF_NODE}/v1/hashtags`)

    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    return []
  }
}

export const getHashtag = async (hashtagId: string): Promise<any> => {
  try {
    if (!hashtagId) throw new Error('No se ha indicado un hashtag válido para obtener datos.')
    const result = await Axios.get<ProductHashtag>(`${process.env.REACT_APP_BFF_NODE}/v1/hashtags/${hashtagId}`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    return null
  }
}

export const getVendorsFromHashtag = async (hashtagId: string): Promise<any> => {
  try {
    if (!hashtagId) throw new Error('No se ha indicado un hashtag válido para obtener datos.')
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v2/products/hashtag/${hashtagId}`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    return null
  }
}

export const getFullProductsFromVendorHashtag = async (hashtagId: string, vendorId: string): Promise<Partial<VendorHashtagProduct[]>|null> => {
  try {
    if (!hashtagId) throw new Error('No se ha indicado un hashtag válido para obtener datos.')
    if (!vendorId) throw new Error('No se ha indicado un proveedor válido para obtener datos.')
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v2/products/hashtag/${hashtagId}/provider/${vendorId}`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    return null
  }
}