import { Container } from 'unstated-typescript'
import { produce } from 'immer'
import { find, count } from '../clients/delivery'
import { getFromCache } from '../utils/cache'
interface DeliveryState {
  deliveries: any[]
  count: Number
}

const storageDeliverysName = '@deliveries'

export default class Delivery extends Container<DeliveryState> {
  state: DeliveryState = {
    deliveries: [],
    count: 0,
  }

  constructor() {
    super()
    const deliveries = getFromCache<any>(storageDeliverysName)
    if (deliveries) {
      this.setState(
        produce(this.state, draft => {
          draft.deliveries = deliveries
        }),
      )
    }
  }

  async get(page: any, limit: any) {
    const deliveries = await find(limit * page, limit)
    await this.setState(
      produce(this.state, draft => {
        draft.deliveries = deliveries as any[]
      }),
    )
    localStorage.setItem(storageDeliverysName, JSON.stringify(deliveries))
    return deliveries as any[]
  }

  async count() {
    const countResult = await count()
    await this.setState(
      produce(this.state, draft => {
        draft.count = countResult as Number
      }),
    )
    return countResult
  }
}
