import React from 'react';

interface ButtonProps {
  className: string
  disabled?: boolean
  onClick: (event:any) => void
  text: string
}

const ButtonComponent: React.FC<ButtonProps> = ({className, disabled = false, onClick, text}) => (
  <button 
    className={className} 
    disabled={disabled}
    onClick={onClick}
    type="button"
  >
    {text}
  </button>
);

export default ButtonComponent;