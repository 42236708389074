import React from 'react';
import iconStar from './../../assets/icons/icn_star_yellow.svg'
import './ProviderRating.scss';

interface ProviderRatingProps{
  average: number,
}

const ProviderRating: React.FC<ProviderRatingProps> = ({average}) => (
  <div className="container-provider-rating">
    <div><img src={iconStar} alt="Icono boton"/></div>
    <div className="average"> {average}</div>
  </div>
);

export default ProviderRating;