import Axios from 'axios'


export const productExtraRequest= async () => {
  try {
  	const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/extraRequestCards`)
    return result.data
	} 
	catch (error) {
  	console.error('Error: =>>>>>>>', error)
    throw error
  }
}

export const requestSent= async (data:any,productModal:any, userId:any) => {
  try {
  	const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_NODE}/v1/extraRequests`,{
      "userId": userId,
      "cardId": productModal._id,
      "answers": [
        {
        "formId": data.DirecciónId,
        "answer": data.Dirección
        },
        {
          "formId": data.NombreId,
          "answer": data.Nombre
        }
        ]
    })
    return result.data
	} 
	catch (error) {
  	console.error('Error: =>>>>>>>', error)
    throw error
  }
}

export const createdRequests= async () => {
  try {
  	const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/extraRequests`)
    return result.data
	} 
	catch (error) {
  	console.error('Error: =>>>>>>>', error)
    throw error
  }
}

export const requestComune = async (name:string, email:string, comune:string) => {
  try {
    const result = await Axios.post(`${process.env.REACT_APP_BFF_PUBLIC}/v1/comunes/notify`, {name, email, comune});
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const getComunes = async (countryCode:string, comune?:string ) => {
  try {
    const validateComune = comune && comune !== "" ? `?name=${comune}` : ''
    const result = await Axios.get(`${process.env.REACT_APP_BFF_PUBLIC}/v1/comunes/guestInfo${validateComune}`,
    {
      headers: {
        countryCode,
      },
    }
    );
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const countVendorsByComune = async (comuneId:string) => {
  try {
    const result = await Axios.get(`${process.env.REACT_APP_BFF_PUBLIC}/v1/comunes/${comuneId}/vendors/count`);
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const validateIsUserInvited = async () => {
  try {
    const result = await Axios.get(`${process.env.REACT_APP_BFF_NODE}/v1/users`);
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}
