import { Container } from 'unstated-typescript'
import { getTermsAndConditions, getTermsAndConditionsLogin } from '../clients/termsConditions'

interface TermsConditionState {
  termsConditions:any
}

export default class termsConditionModel extends Container<TermsConditionState>{
  async getTermsConditions(){
    const showTermsConditions =  getTermsAndConditions()
    return showTermsConditions
  }

  async getTermsConditionsLogin(){
    const showTermsConditions =  getTermsAndConditionsLogin()
    return showTermsConditions
  }
}