import React, { useRef, useState, useEffect } from 'react'
import { IonSlides, IonSlide } from '@ionic/react'
import Settings from '../../models/Settings'
import { CartModel } from '../../models/CartModel'
import { Product } from '../../models/ProductModel'
import ProductCard from './../product-card/ProductCard'
import { VisibilityTracker } from '../../hoc'
import { useGetSessionId } from '../../hooks/useGetSessionId'
import { 
  viewProviderFavoriteProducts, 
  viewVendorEvent,
  registerProductSliderItemEventClick,
  registerProductSliderItemEventView,
  registerProductSliderPromoItemEventClick,
  registerProductSliderPromoItemEventView
} from '../../pages/firebase/firebaseTags'
import actRight from './../../assets/icons/act_right.svg'
import actStar from './../../assets/icons/act_star.svg'
import './ProductSlider.scss'

// Types
interface ProductSliderProps {
  settings: Settings
  products: Product[]
  info: {
    title: string
    id: string
    hide_more?: boolean
    brandId: string
    vendorName?: string
    vendorId?: string
    from?: string
    showInBlueColor?: boolean
    totalProducts?: number
  }
  hidePrice?: boolean
  onMoreInfoClick?: Function
  cartModel: CartModel
  onGoToCart?: () => void
  nextPage?: Function
  categoryPrimary?: any
  filterBrand?: boolean
  history: any
  colorText?: string
  colorBackground?: string
  allMainCategories?: any[]
  categoryPosition?: number
  filterCategoryName?: any
  fromSlider: boolean | undefined
  topProduct?: boolean
  offers?: boolean
  favorites?: boolean
  offersHome?: boolean
  placementName?: string
}

// Atoms
const SeeMoreButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <div className="button-more" onClick={onClick}>
    <div className="see-all-products">Todos</div>
    <img src={actRight} alt="" />
  </div>
)

const SliderHeader: React.FC<{
  title: string
  totalProducts?: number
  isAvailableToShowInBlue: string
  headerClass: string
  favorites?: boolean
  fromIsEqualOfferList: boolean
  onMoreButtonClick: () => void
  hideMore?: boolean
}> = ({
  title,
  totalProducts,
  isAvailableToShowInBlue,
  headerClass,
  favorites,
  fromIsEqualOfferList,
  onMoreButtonClick,
  hideMore,
}) => (
  <div className={`header ${headerClass}`}>
    <span className={`container-title ${isAvailableToShowInBlue} ${headerClass}`}>
      {favorites && <img src={actStar} style={{ marginRight: 8 }} width={24} height={24} alt="" />}
      {title.length < 26 ? title : `${title.slice(0, 25)}...`}
    </span>
    {fromIsEqualOfferList && (
      <span className="counter-products">{`${totalProducts} ${totalProducts === 1 ? 'oferta' : 'ofertas'}`}</span>
    )}
    {!hideMore && (
      <div className={`seeMore ${isAvailableToShowInBlue} ${!fromIsEqualOfferList && 'see-button'}`}>
        <SeeMoreButton onClick={onMoreButtonClick} />
      </div>
    )}
  </div>
)

// Main component
export const ProductSlider: React.FC<ProductSliderProps> = ({
  settings,
  products: initialProducts,
  info,
  hidePrice,
  onMoreInfoClick,
  cartModel,
  onGoToCart,
  nextPage,
  filterBrand,
  history,
  categoryPosition,
  filterCategoryName,
  fromSlider,
  topProduct,
  offers,
  favorites,
  offersHome,
  placementName
}) => {
  const [products, setProducts] = useState<any[]>([])
  const slidesRef = useRef<any>(null)
  const slideId = `slider-${Math.floor(Math.random() * 1000) + Date.now()}`
  const sessionId = useGetSessionId();

  useEffect(() => {
    if (favorites) {
      setProducts(initialProducts.map((product: any) => product.productId))
    } else {
      setProducts(initialProducts)
    }
  }, [initialProducts, favorites])

  useEffect(() => {
    const slides = document.getElementById(slideId) as any
    if (slides) {
      slides.options = {
        slidesPerView: 3.5,
        slidesOffsetBefore: 24,
        direction: 'horizontal',
        spaceBetween: 12,
        breakpoints: {
          260: { slidesPerView: 1.7 },
          300: { slidesPerView: 1.8 },
          320: { slidesPerView: 1.9 },
          340: { slidesPerView: 2.4 },
          360: { slidesPerView: 2.4 },
          380: { slidesPerView: 2.4 },
          400: { slidesPerView: 2.4 },
          500: { slidesPerView: 2.5 },
          600: { slidesPerView: 2.5 },
          700: { slidesPerView: 2.6 },
          800: { slidesPerView: 2.6 },
          900: { slidesPerView: 4.6 },
          1000: { slidesPerView: 5.6 },
        },
        autoplay: {
          delay: 10000,
          disableOnInteraction: true,
        },
      }
    }
  }, [products])

  const mapProductFromSuggested = (product: any) => ({
    ...product,
    provider: product.providerId,
    filename: product.imageFullPath ?? product.filename,
    vendorActiveInComune: product.active,
  })

  const validateIfItHasPromotion = (product: any) => product && product?.showPrice?.offerPrice > 0

  const onClickProduct = (product: any) => {
    const now = Date.now();
    const nowDate: Date = new Date(now);
    const nowISO = nowDate.toISOString();
    const userStringified = localStorage.getItem('@user')
    const user = JSON.parse(userStringified)
    let userId

    if(user && user.roleAlias === "guest"){
      userId = 'user_guest'
    } else {
      userId = user.id
    }

    const placement = placementName ?? "not-defined"

    if (validateIfItHasPromotion(product)) {
      registerProductSliderPromoItemEventClick({
        productId: product.id,
        productName: product.name,
        productBrand: product.brand,
        productCategory: product.category,
        productSku: product.sku,
        providerId: product.providerId,
        providerName: product.providerName,
        placementName: placement,
        userId: userId,
        userSessionCode: sessionId,
        timestamp: now,
        datetimeISO: nowISO
      })
    } else {
      registerProductSliderItemEventClick({
        productId: product.id,
        productName: product.name,
        productBrand: product.brand,
        productCategory: product.category,
        productSku: product.sku,
        providerId: product.providerId,
        providerName: product.providerName,
        placementName: placement,
        userId: userId,
        userSessionCode: sessionId,
        timestamp: now,
        datetimeISO: nowISO
      })
    }
  }

  const getEventSuffix = (prefix: string) => {
    if (offers) return `${prefix}_offers`
    if (topProduct) return `${prefix}_top_products`
    if (favorites) return `${prefix}_favorite_products`
    return ''
  }

  const onMoreButtonClick = () => {
    const { id, title, vendorId, vendorName } = info
    const fromIsEqualOfferList = info.from === 'list-offers'
    const nameToShow = fromIsEqualOfferList ? title : vendorName

    if (favorites) viewProviderFavoriteProducts()
    viewVendorEvent('search', id, title, categoryPosition, getEventSuffix(`view_provider`))

    if (offersHome) {
      history.push(`/vendors-products-offers`, { vendorName: nameToShow })
    } else if (topProduct) {
      history.push(`/vendors/${vendorId}/top-products`, { vendorName: nameToShow })
    } else if (offers || fromIsEqualOfferList) {
      history.push(`/vendors/${fromIsEqualOfferList ? id : vendorId}/offers`, { vendorName: nameToShow })
    } else if (filterBrand) {
      history.push(`/home-brand/${id}`, { singleBrandId: id })
    } else if (!offers && !filterBrand && !topProduct && onMoreInfoClick) {
      onMoreInfoClick(info)
    } else if (favorites) {
      history.push(`/vendors/${vendorId}/favorites`, { vendorName: nameToShow })
    }
  }

  const handleWhenProductIsVisible = (product: any) => {
    // TODO: Queremos marcar ambos eventos o solo queremos marcar 1 por caso?
    const now = Date.now();
    const nowDate: Date = new Date(now);
    const nowISO = nowDate.toISOString();    
    const userStringified = localStorage.getItem('@user')
    const user = JSON.parse(userStringified)
    let userId

    if(user && user.roleAlias === "guest"){
      userId = 'user_guest'
    } else {
      userId = user.id
    }

    const placement = placementName ?? "not-defined"

    if (validateIfItHasPromotion(product)) {
      registerProductSliderPromoItemEventView({
        productId: product.id,
        productName: product.name,
        productBrand: product.brand,
        productCategory: product.category,
        productSku: product.sku,
        providerId: product.providerId,
        providerName: product.providerName,
        placementName: placement,
        userId: userId,
        userSessionCode: sessionId,
        timestamp: now,
        datetimeISO: nowISO
      })
    } else {
      registerProductSliderItemEventView({
        productId: product.id,
        productName: product.name,
        productBrand: product.brand,
        productCategory: product.category,
        productSku: product.sku,
        providerId: product.providerId,
        providerName: product.providerName,
        placementName: placement,
        userId: userId,
        userSessionCode: sessionId,
        timestamp: now,
        datetimeISO: nowISO
      })
    }
    
  }

  const { showInBlueColor, from, totalProducts, title, id } = info
  const fromIsEqualOfferList = from === 'list-offers'
  const headerClass = `${!fromIsEqualOfferList && offers ? 'offersHeader' : ''}`
  const isAvailableToShowInBlue = !showInBlueColor ? '' : 'show-in-blue'

  products.sort((a: any, b: any) => (a.outOfStock === b.outOfStock ? 0 : a.outOfStock ? 1 : -1))

  return (
    <div className="product-slider" id={id}>
      {products && products.length > 0 && (
        <SliderHeader
          title={title}
          totalProducts={totalProducts}
          isAvailableToShowInBlue={isAvailableToShowInBlue}
          headerClass={headerClass ?? ''}
          favorites={favorites}
          fromIsEqualOfferList={fromIsEqualOfferList}
          onMoreButtonClick={onMoreButtonClick}
          hideMore={info.hide_more}
        />
      )}
      <div className="body">
        <IonSlides ref={slidesRef} key={slideId} id={slideId} pager={false} {...{} as any}>
          {products.map((product: any) => {
            return (
              <IonSlide key={`slide-${product.id}`} onClick={() => onClickProduct(product)} {...{} as any}>
                <VisibilityTracker onVisible={() => handleWhenProductIsVisible(product)} threshold={0.5}>
                  <ProductCard
                    key={`product-card-${product.id}`}
                    product={mapProductFromSuggested(product)}
                    settings={settings}
                    cartModel={cartModel}
                    onGoToCart={onGoToCart}
                    hidePrice={hidePrice}
                    history={history}
                    categoryPosition={categoryPosition}
                    filterCategoryName={filterCategoryName}
                    metrics={{
                      addToCartSource: getEventSuffix('provider'),
                      deleteProductCartSource: getEventSuffix('provider'),
                    }}
                  />
                </VisibilityTracker>
              </IonSlide>
            )
          })}
        </IonSlides>
      </div>
    </div>
  )
}
