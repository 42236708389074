import React, { useEffect, useState } from 'react'
import {IonIcon, IonImg, IonModal } from '@ionic/react'

// Camara
import  camera  from '../../assets/icons/act_camera.svg'
import  viewPhoto  from '../../assets/icons/act_view.svg'
import { Camera} from '@capacitor/camera';
import { CameraResultType } from '@capacitor/camera';
import { CameraSource } from '@capacitor/camera';
import CloseBlue from '../../assets/icons/nav_close_white.svg'
import trashIcon from './../../assets/icons/act_delete_white.svg'

import './PhotosUser.scss'

interface Photos {
  arrPhoto: any
  officeData?: any
  deleteImagenSelected?: any
}

const PhotosUser: React.FC<Photos> = props => {
  const { arrPhoto,officeData, deleteImagenSelected } = props
  const [photosEdit, setPhotosEdit] = useState<any>([])
  const [openLightbox, setOpenLightbox] = useState<boolean>(false)
  const [photoSelected, setPhotoSelected] = useState<any>('')
  const [officeExit, setOfficeExit] = useState<any>([])


  useEffect(() => {
    getOfficeData()
  }, [officeData])


  const getOfficeData = () => officeData && setOfficeExit(officeData)
  
  const onUploadPhoto = async  (edit?:boolean) => {
   let image:any = ''
    await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Prompt,
      width:600,
      height:600,
    }).then((imageData) => {
      image = imageData;
      if (image !== ''){
        handlePhotosEditState(image, edit) 
      }
     }, (err) => {
      console.error(err)
     });

  }

  const  handlePhotosEditState = async (image:any, edit?:boolean) => {
    Object.keys(officeData).length  === 0 && setPhotosEdit((prevArr:any) => {
      const newPhotosArray = [...prevArr, image.base64String]
      arrPhoto(newPhotosArray, image)
      
      return newPhotosArray
    })
    
    Object.keys(officeData).length > 0 && arrPhoto(photosEdit, image)
    if(edit) closeLightBoxPhoto()
  }


  const expandImag = (photo:any , index: number, value?: boolean) => {
    setPhotoSelected({"photo":photo , "index": index , officeEdit: value })
    setOpenLightbox(true)
  }

  const lightBoxPhoto = () => {
    const image = photoSelected.officeEdit ? `${process.env.REACT_APP_BFF_IMAGE}${officeExit._id}/${photoSelected.photo}`  : `data:image/png;base64,${photoSelected.photo}`
    return (
      <IonModal cssClass="modal-lightbox" isOpen={openLightbox} backdropDismiss={false}>
        <div className="container-lightbox">
          <div className="container-close" onClick={closeLightBoxPhoto}><IonIcon className='icon-camera' icon={CloseBlue} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></div>
          <div className="conianter-img-lightbox"><img className='img-camera' id="img01" src={image} /></div>
          <div className="container-btn">
            <div className="icon-footer" onClick={editPhotoSelected}>
                <div className="container-icon"><IonIcon className='icon-camera' icon={camera} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></div>
                <div>Cambiar</div>
            </div>
            <div className="icon-footer" onClick={() => deletePhotoSelected()}>
                <div className="container-icon"><IonIcon className='icon-camera' icon={trashIcon} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></div>
                <div>Eliminar</div>
            </div>
          </div>
        </div>
      </IonModal>
    )
  }


  const deletePhotoSelected = (edit?: boolean) => {
    const newArrPhotos = photosEdit.filter((photo:any, index:number) => index !== photoSelected.index)
    setPhotosEdit(newArrPhotos)
    !edit && closeLightBoxPhoto()

    deleteImagenSelected(photoSelected)
  }

  const editPhotoSelected = async () =>  {
   onUploadPhoto(true)
   deletePhotoSelected(true)
  }

  const closeLightBoxPhoto = () => setOpenLightbox(false)

  return (
    <div className="container-photo-user ds-content-page">
 		  <div className='container-image-photo-user'>
        {photosEdit?.length > 0  && 
          photosEdit.map((photo:any, index:any) =>
            <div className='image' onClick={() => expandImag(photo, index)}  >
              <IonImg  className='img-camera' src={`data:image/png;base64,${photo}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ></IonImg>
              <div className="icon-view" >
                <IonIcon className='icon-camera' icon={viewPhoto} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </div>
            </div> 
            )
         }
      
        {officeExit?.images  &&
          officeExit.images.map((photo:any, index:any) =>
            <div className='image' onClick={() => expandImag(photo, index, true)}  >
              <IonImg  className='img-camera' src={`${process.env.REACT_APP_BFF_IMAGE}${officeExit._id}/${photo}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ></IonImg>
              <div className="icon-view" >
                <IonIcon className='icon-camera' icon={viewPhoto} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </div>
            </div> 
            )
          }
 	      <div className='not-image' onClick={() => onUploadPhoto()} style={{ backgroundImage: `url(${camera})` }}></div>
      {openLightbox && lightBoxPhoto()}
			</div>
		</div>
  )
}



export default PhotosUser