import { IonToggle } from '@ionic/react'
import React, { useContext } from 'react'
import { REACT_APP_BFF_IMAGE } from '../../../../config'
import AppContext from '../../../../context/AppContext'
import useHandleInitialValuesCouponActivated from '../../../../hooks/carts/useHandleInitialValuesCouponActivated'
import { ProviderCartData } from '../../../../interfaces/carts'
import { CouponActivated } from '../../../../interfaces/coupons'
import { removeCouponInCart } from '../../../../services/bff_mobile/v2/carts/removeCouponInCart'
import { updateCouponInCart } from '../../../../services/bff_mobile/v2/carts/updateCouponInCart'
import { formatCurrency } from '../../../../utils/intl'
import './index.scss'

interface Props {
  couponActivated: CouponActivated
  provider: ProviderCartData
}

function CouponItem({ couponActivated, provider }: Props) {
  const { cartData, handlerCartData } = useContext(AppContext)
  const { checkedToggle, disabledToggle } = useHandleInitialValuesCouponActivated(couponActivated, provider)

  const updateCoupon = async (): Promise<void> => {
    await updateCouponInCart(cartData._id, couponActivated.couponId, provider._id)

    handlerCartData({
      ...cartData,
      discounts: [
        ...cartData.discounts,
        {
          couponId: couponActivated.couponId,
          providerId: provider._id,
          amount: couponActivated.amount,
        },
      ],
    })
  }

  const removeCoupon = async (): Promise<void> => {
    await removeCouponInCart(cartData._id, couponActivated.couponId)

    handlerCartData({
      ...cartData,
      discounts: cartData.discounts.filter((discount) => discount.couponId !== couponActivated.couponId),
    })
  }

  return (
    <div className="coupon-section">
      <img src={`${REACT_APP_BFF_IMAGE}/${couponActivated.image}`} alt="Cupón" />
      <div>
        <h4>{formatCurrency(couponActivated.amount)} de descuento</h4>
        <span>Compras sobre {formatCurrency(couponActivated.minimumAmount)}</span>
      </div>
      <IonToggle
        disabled={disabledToggle}
        checked={checkedToggle}
        onClick={(e) => (checkedToggle ? removeCoupon() : updateCoupon())} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      ></IonToggle>
    </div>
  )
}

export default CouponItem
