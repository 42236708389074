import { useContext, useEffect } from 'react'
import AppContext from '../context/AppContext'
import { Device } from '@capacitor/device'

function useGetPlatformApp() {
  const { handlerPlatform } = useContext(AppContext)

  useEffect(() => {
    const getPlatform = async () => {
      try {
        handlerPlatform((await Device.getInfo()).platform)
      } catch (error) {
        handlerPlatform('ios')
      }
    }

    getPlatform()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useGetPlatformApp
