import { IonIcon, IonModal } from '@ionic/react'
import { arrowBackOutline, callOutline, chevronForwardOutline, locationOutline, mailOutline } from 'ionicons/icons'
import React from 'react'
import { useHistory } from 'react-router-dom'
import './index.scss'

function Checkout() {
  const history = useHistory()

  return (
    <IonModal isOpen={true} id="checkout-modal">
      <div className="wrapper">
        <div>
          <div className="header">
            <IonIcon icon={arrowBackOutline} onClick={() => history.push('/cart2')} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
            <span>Confirmar Pedido</span>
            <span></span>
          </div>

          {/* {loading ? (
            <LoadingComponent height="60vh" />
          ) : (
            <div className="providers-content">
              {suggestedData.map((data) => (
                <ProviderSection key={data.providerId} data={data} paymentMethod={paymentMethod} />
              ))}
            </div>
          )} */}
          <div className="checkout-content">
            <div className="recibe-en">
              <span>
                <IonIcon icon={locationOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon> Recibe en:
              </span>
              <p>Gral José San Martín 3275, dpto 224 - Maipú</p>
            </div>

            <div className="datos-usuario">
              <div>
                <label htmlFor="telefono">
                  <IonIcon icon={callOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon> Teléfono
                </label>
                <input type="text" name="" id="telefono" value="964612192" />
              </div>

              <div>
                <label htmlFor="email">
                  <IonIcon icon={mailOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon> Email
                </label>
                <input type="email" name="" id="email" value="alberto@almacenguru.com" />
              </div>
            </div>

            <div className="comentario">
              <p>Agregar comentario</p>
              <textarea name="" id="" rows={3} placeholder="Preferiblemente llegar en horas de la mañana"></textarea>
            </div>

            <div className="resumen">
              <div className="padding">
                <h3>Resumen</h3>
              </div>

              <div className="hr"></div>

              <div className="padding">
                <h5 className="tipo-pago">Pago anticipado</h5>

                <div className="precios">
                  <div>
                    <p>Virginia</p>
                    <p>$22.920</p>
                  </div>

                  <p>Entrega 3 - 5 días</p>
                </div>

                <h3>Descuentos</h3>
              </div>

              <div className="hr"></div>

              <div className="padding">
                <div className="precios">
                  <div>
                    <p>--</p>
                    <p>$0</p>
                  </div>
                </div>

                <div className="total-price">
                  <p>Total</p>
                  <p>$45.720</p>
                </div>
              </div>
            </div>

            <div className="metodo-pago">
              <h3>Método de pago</h3>
              <div>
                <span>Elegir</span>
                <IonIcon icon={chevronForwardOutline} onClick={() => history.push('/payment-method')} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
              </div>
            </div>
          </div>
        </div>

        <div className="button">
          <button>Pagar Total $22.920</button>
        </div>
      </div>
    </IonModal>
  )
}

export default Checkout
