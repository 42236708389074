import Axios from 'axios'

export const getSurveys = async (countryCode?:string, key?:string, providerId?:string, group?:string) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/surveys`, {
      params: {
        countryCode, 
        key,
        providerId, 
        group
      }
    });
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const sendSurvey = async (surveyId:string, dataSurvey:Object) => {
  try {
    const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_NODE}/v1/surveys/${surveyId}/answers`, dataSurvey);
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const additionalInfoSurvey = async (surveyId:string) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/surveys/${surveyId}/additional`);
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const getSurveysKeys = async () => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/surveys/keys`);
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}
