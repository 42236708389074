import React, { useState, useEffect, useCallback, useRef } from 'react'
import { IonSlides, IonSlide } from '@ionic/react'
import { ProductModel } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'
import PromotionCard from '../promotion-card'
import { registerPromotionBannerSliderItemEvent} from '../../pages/firebase/firebaseTags'
import { counterEvent } from '../../clients/eventCounter'
import { manageBannerClick } from '../../utils/deeplinks'
import { Promotion } from '../../models/PromotionModel'
import { useGetSessionId } from '../../hooks/useGetSessionId'
import { PromotionModalClass } from '../../interfaces/promotionModal'
import './PromotionSlider.scss'

interface PromotionSliderProps {
  promotions: Promotion[]
  productModel: ProductModel
  cartModel: CartModel
  history: any
  providers?: any
  slidesPerView?: number
  timePerSlide?: number | null
  typeBanners: string
  validateDeeplinks?: () => void
  providerId?: string
  placement: string
}

export const PromotionSlider: React.FC<PromotionSliderProps> = ({
  promotions,
  productModel,
  cartModel,
  history,
  slidesPerView,
  timePerSlide,
  typeBanners,
  validateDeeplinks,
  providerId,
  placement
}) => {
  const [isOnePromo, setIsOnePromo] = useState(true)
  const [promotionsWithData, setPromotionsWithData] = useState<Promotion[]>([])
  const sessionId = useGetSessionId()

  const isMounted = useRef(true);

  useEffect(() => {
    // Establecer isMounted a true cuando el componente se monta
    isMounted.current = true;

    console.log({promotions})

    // Solo actualizar el estado si el componente sigue montado
    if (isMounted.current) {
      setPromotionsWithData(promotions.map((item: any) => PromotionModalClass.fromJson(item)));
    }

    // Función de limpieza
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    loadPromotions()
  }, [typeBanners, providerId])

  const loadPromotions = async () => {
    if (!promotions || !(promotions?.length > 0)) {
      return
    }
    setIsOnePromo(promotions.length === 1)
    const updatedPromotions = await Promise.all(
      promotions.map(async (promotion: Promotion) => {
        const product = await productModel.getByProviderAndSku(promotion.providerId, promotion.sku)
        const providerName = getProviderName(promotion.providerId)
        const promo: Promotion = {
          ...promotion,
          providerName,
          product,
        }
        return promo
      }),
    )
    setPromotionsWithData(updatedPromotions)
  }

  const getProviderName = (providerId: string) => {
    const providersLocal = localStorage.getItem('@providers')
    if (providersLocal) {
      const providers = JSON.parse(providersLocal)
      const providersValues: any = Object.values(providers)
      return providersValues.find(({ id }: any) => id === providerId)?.name || ''
    }
    return ''
  }

  const handleImpression = useCallback(
    (bannerPromotion: Promotion, index: number) => {
      const now = Date.now();
      const nowDate: Date = new Date(now);
      const nowISO = nowDate.toISOString();    
      const userStringified = localStorage.getItem('@user')
      const user = JSON.parse(userStringified)
      let userId

      if(user && user.roleAlias === "guest"){
        userId = 'user_guest'
      } else {
        userId = user.id
      }

      registerPromotionBannerSliderItemEvent({
        eventName: `${placement}_carousel_ad_banner_view`,
        bannerId: bannerPromotion._id,
        bannerType: bannerPromotion.type,
        bannerName: bannerPromotion.name,
        vendorId: bannerPromotion.providerId,
        vendorName: bannerPromotion.providerName,
        placementName: placement,
        userId: userId,
        userSessionCode: sessionId,
        timestamp: now,
        datetimeISO: nowISO
      })
    },
    [typeBanners],
  )

  const handlePromotionClick = useCallback(
    async (bannerPromotion: Promotion) => {
      
      const now = Date.now();
      const nowDate: Date = new Date(now);
      const nowISO = nowDate.toISOString();    
      const userStringified = localStorage.getItem('@user')
      const user = JSON.parse(userStringified)
      let userId

      if(user && user.roleAlias === "guest"){
        userId = 'user_guest'
      } else {
        userId = user.id
      }

      registerPromotionBannerSliderItemEvent({
        eventName: `${placement}_carousel_ad_banner_click`,
        bannerId: bannerPromotion._id,
        bannerType: bannerPromotion.type,
        bannerName: bannerPromotion.name,
        vendorId: bannerPromotion.providerId,
        vendorName: bannerPromotion.providerName,
        placementName: placement,
        userId: userId,
        userSessionCode: sessionId,
        timestamp: now,
        datetimeISO: nowISO
      })

      await counterEvent('panel-principal', bannerPromotion.type || '', userId, bannerPromotion._id, 'click')

      if (bannerPromotion?.deeplink) {
        registerDeeplinkEvent(bannerPromotion.deeplink)
      }

      manageBannerClick(bannerPromotion, history, validateDeeplinks)
    },
    [history, typeBanners, validateDeeplinks],
  )

  const registerDeeplinkEvent = (deeplink: string) => {
    const deeplinkRoutes = deeplink.split('/')
    // if (deeplinkRoutes.includes('home-providers')) sendMetricWithSource('view_vendor', 'banners')
    // if (deeplinkRoutes.includes('provider') && deeplinkRoutes.includes('category'))
      // sendMetricWithSource('view_categories', 'banners')
    // if (deeplinkRoutes.includes('vendor') && deeplinkRoutes.includes('product'))
      // TODO: comentado por mientras sendMetricWithSource('view_product', 'banners')
  }

  const options = {
    allowTouchMove: !isOnePromo,
    slidesPerView: isOnePromo ? 1 : slidesPerView || 1.2,
    spaceBetween: 20,
    preloadImages: true,
    autoplay: isOnePromo
      ? false
      : {
          delay: timePerSlide || 5000 + Math.random() * 2000,
          disableOnInteraction: false,
        },
  }

  return (
    <div className="product-slider-promotion">
      <IonSlides options={options} {...{} as any}>
        {promotionsWithData.map((promotion, index) => (
          <IonSlide key={promotion._id} className={`slide-${index}`} {...{} as any}>
            <PromotionCard
              promotion={promotion}
              cartModel={cartModel}
              index={index}
              typeBanners={typeBanners}
              onImpression={() => handleImpression(promotion, index)}
              onPromotionClick={() => handlePromotionClick(promotion)}
            />
          </IonSlide>
        ))}
      </IonSlides>
    </div>
  )
}
