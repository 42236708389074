import React, { useState, useEffect } from 'react'
import {
  IonModal,
  IonContent,
  IonPage,
} from '@ionic/react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import parse from 'html-react-parser';

// Models
import UserModel from './../../models/User'
import SettingsModel from './../../models/Settings'
import Office from '../../models/Office'
import termsConditionModel from '../../models/TermsConditionModel'

// Components
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import HeaderWithoutToolbar from '../../components/header-without-toolbar/HeaderWithoutToolbar'

// Icons
import arrowBack from './../../assets/icons/arrow-back-blue.svg'

import './TermsConditionsModal.scss'

interface IPathParams {
}

type RouteState = {}

type Props = RouteComponentProps<IPathParams, RouteState> & {
  isOpen: boolean
  user: UserModel
  settings: SettingsModel
  offices: Office
  showTermsConditions: termsConditionModel
  closeTermsModal: (val: boolean) => void
}

const Card: React.FC<Props> = (props) => {
  const { isOpen, history } = props
  const [termsConditions, setTermsConditions] = useState<any>({})
  const [currentTerms, setCurrentTerms] = useState<number>(0)

  useEffect(() => {
    getLegalDocs()
  }, [])

  const getLegalDocs = async () => {
    const termsConditions = await props.showTermsConditions.getTermsConditionsLogin();
    setTermsConditions(termsConditions)
  }

  const shouldRenderDoc = (index: number, currentTerms: number, history: any) => {
    return index === currentTerms 
  }

  const buttonTextByTerm = (currentIndex: any) => {
    let termsTexts = [
      { 
        index: 0, 
        text: 'Leer Términos y Condiciones'
      },
      {
        index: 1, 
        text: 'Leer Política de Privacidad'
      },
      {
        index: 2, 
        text: 'Leer Uso de Datos'
      },
    ]

    termsTexts = termsTexts.slice(0, termsConditions?.content?.length)

    return termsTexts.find(({index}: any) => index === currentIndex + 1)?.text || termsTexts[0]?.text
  }

  const termsParagraph = (paragraph: any) => {
    if(paragraph.includes("[link]")) {
      let parsedParagraph = paragraph.replace("[link]:", '')
      parsedParagraph = parsedParagraph.split(':')
      const url = `${parsedParagraph[1]}:${parsedParagraph[2]}`
      parsedParagraph = `${parsedParagraph[0]} <a href=${url}>${url}</a>`
      return parse(parsedParagraph)
    }

    return paragraph
  }

  const readNext = (index: number, termsLength: any) => {
    let nextDocIndex = index + 1
    if(index === termsLength - 1) nextDocIndex = 0
    setCurrentTerms(nextDocIndex)
  }

  const termsLength: any = termsConditions?.content?.length
  
  return (
    <IonModal isOpen={isOpen}>
      <IonPage className="terms-conditions-read-page ds-content-page" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className="terms-header-wrapper">
          <HeaderWithoutToolbar 
            text=""
            icon={arrowBack}
            onClick={() => props.closeTermsModal(false)}
          />
        </div>
        {termsLength > 0 && termsConditions?.content?.map((terms : any, index: number) => {
          const shouldRenderSection: any = shouldRenderDoc(index, currentTerms, history)
          const buttonText = buttonTextByTerm(currentTerms)
          
          if (shouldRenderSection) return (
            <div key={index} className="terms-page">
              <div className="terms-wrapper">
                <h3 className='title'>{terms.title}</h3>
                <div className='paragraph'>{terms.subtitle}</div>
                <div className='general-text'>{terms.paragraph}</div>
                <div>
                  {terms.content && terms.content.length > 0 && terms.content.map((internalTerms:any, index: any) => {
                    return (
                      <div key={index}>
                        <div className='points'>{internalTerms.title}</div>
                        <div className='general-text'>{internalTerms.paragraph}</div>
                        {internalTerms.content && internalTerms.content.length > 0 && internalTerms.content.map((moreInternalTerms:any, index: number) => {
                        const paragraph = termsParagraph(moreInternalTerms.paragraph)
                          return (
                            <div key={index} className='general-text'><span>{moreInternalTerms.title}</span> {paragraph} </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
                <div className="accept-terms-container">
                  <div className="terms-button-wrapper">
                    <ButtonComponent 
                      onClick={()=> readNext(index, termsLength)}
                      text={buttonText}
                      className={'btn-primary'}
                    />
                  </div>
                </div>
              </div>    
            </div>       
          )
          return ''
        })}
        <style>
          {`
            .footer-menu-home {
             display: none !important; 
            }
          `}
        </style>
      </IonPage>
    </IonModal>
  )
}

export default withRouter(Card)

