import { useState } from 'react'
import { CartData } from '../../interfaces/carts'

function CartProvider() {
  const [cartData, setCartData] = useState<CartData>({
    _id: '',
    data: [],
    discounts: [],
  })

  const handlerCartData = (cartData: CartData) => setCartData(cartData)

  return { cartData, handlerCartData }
}

export default CartProvider
