import Axios from 'axios'
import axiosRetry from 'axios-retry';
import { getLocalStorageObject } from '../utils/localstorageData';


export const registerUser = async (form: any, countryCode:any, lastToken?:any) => {
  let headers: any = { countryCode }

  if (lastToken) {
    const separator = `"`
    const cleanedToken = lastToken.split(separator)
    headers = {
      ...headers,
      'Authorization': `Bearer ${cleanedToken[1]}`
    }
  }

  try {
    axiosRetry(Axios, { 
      retries: 5, 
      retryDelay: () => 6000, 
      retryCondition: (e) => { return axiosRetry.isNetworkOrIdempotentRequestError(e) || e.message === 'Network Error'} 
    })
    
    const marketingParams = getLocalStorageObject('marketingRegisterParams');
    localStorage.removeItem("marketingRegisterParams")
    let body = { ...form }
    if (marketingParams) body = { ...body, ...marketingParams }
    
    const result = await Axios.post<object>(`${process.env.REACT_APP_BFF_PUBLIC}/v3/users/register`, body, { headers })
    return result?.data
    
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const validateSms = async (sms: string) => {
  try {
    const result = await Axios.post<object>(`${process.env.REACT_APP_BFF_NODE}/v1/users/validateSms`, {
      smsCode: sms
    })
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw error
  }
}

export const typeOfBussiness = async () => {
  try {
    const result = await Axios.get<object>(`${process.env.REACT_APP_BFF_NODE}/v1/typeOfBussiness`)
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const sendOptionalRegistration = async (send:any) => {
  try {
      const result = await Axios.put<any>(`${process.env.REACT_APP_BFF_NODE}/v1/users/update`,
        send
        )
      return result.data
    } catch (error) {
      console.log('Error: =>>>>>>>', error)
  }
}

export const getDepartmentsByCountryCode = async (countryCode:string) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_PUBLIC}/v2/comunes/departments?countryCode=${countryCode}`)
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const getProvincesByDepartmentsAndCountryCode = async (deparment:string,countryCode:string) => {
  try {
    
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_PUBLIC}/v1/provinces?department=${deparment}&countryCode=${countryCode}`)
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const getComunesByProvincesAndCountryCode = async (province:string,countryCode:string) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_PUBLIC}/v3/comunes?province=${province}&countryCode=${countryCode}`)
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const getComunesByDepartmentAndCountryCode = async (department:string, countryCode:string) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_PUBLIC}/v2/comunes?department=${department}&countryCode=${countryCode}`)
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const registerUserInvited = async (comune:any, countryCode:any, lastToken?:any) => {
  let headers: any = {
    countryCode
  }
  if (lastToken) {
    const separator = `"`
    const cleanedToken = lastToken.split(separator)

    headers = {
      ...headers,
      'Authorization': `Bearer ${cleanedToken[1]}`
    }
  }

  try { 
    const result = await Axios.post<object>(`${process.env.REACT_APP_BFF_PUBLIC}/v1/users/registerGuest`, 
      {
        comune
      },
      {
        headers
      }
    )
    return result?.data
  } catch (error) {
    console.error('Error: =>>>>>>>', error)
  }
}

export const checkRegistered = async (username:any, countryCode:string) =>{
  let headers: any = {
    countryCode
  }

  try {
    const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_PUBLIC}/v1/users/checkRegistered`,{
      username
    },
    {
      headers
    }
    );
    return result.data
  } catch (error) {
    console.error('Error: =>>>>>>>', error)
  }
}

export const getBusinessTypes = async (countryCode:string) =>{
  let headers: any = {
    countryCode
  }

  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_PUBLIC}/v1/bussinesType/country`,
     { headers }
    );
    return result.data
  } catch (error) {
    console.error('Error: =>>>>>>>', error)
  }
}