import React from 'react'
import './index.scss'

function InfoFulfillment() {
  return (
    <div className="info-fulfillment">
      <div>
        <span>Mínimo de compra</span>
        <span>$39.990</span>
      </div>
      <div>
        <span>Forma de Pago</span>
        <span>Anticipado</span>
      </div>
    </div>
  )
}

export default InfoFulfillment
