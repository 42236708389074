import { Container } from 'unstated-typescript'
import { fetchDiscounts } from '../clients/coupon'

export interface Product {
  id: string
  state: string
  vendors: any []
  couponId: string
  code: string
  date_expires: Date
  discount_type: string
  amount: number
}

interface ProductsFetched {
  products: Product[]
  updatedAt: number
}

interface CategoryWithProducts {
  [key: string]: ProductsFetched
}

interface State {
  categoryMap: CategoryWithProducts
}

export class CouponsActivatedModel extends Container<State> {
  getDiscounts = async (cartId: string): Promise<Product | undefined> => {
    const discounts = await fetchDiscounts(cartId)
    if (!discounts) {
      return undefined
    }
    return discounts
  }
}
