import React, { useState,useEffect  } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router'

import { CartModel, CartProduct } from '../../models/CartModel'
import { ProviderModel } from '../../models/ProviderModel'
import { CouponsActivatedModel } from '../../models/CouponsActivatedModel'

import { getSuggestedProductsForCart } from '../../clients/product'

import  CardCart from '../cart-card/CardCart'

import { formatCurrency } from '../../utils/intl'

import { IonButton } from '@ionic/react'
import { eventForCart, eventWithName, eventBeginCheckout, fbInitiateCheckoutEvent, checkoutEvent } from '../firebase/firebaseTags'

import OrderConfirmation from '../order-confirmation/OrderConfirmation'

import User from '../../models/User'
import Settings from '../../models/Settings'
import Office from '../../models/Office'
import { ProductModel } from '../../models/ProductModel'
import SuggestedCheckout from '../../components/suggested-checkout-modal/SuggestedCheckout'
import { validateIfIsInvitedUser } from '../../utils/invitedUser'
import './CartController.scss'
import ModalInvitedUser from '../../components/modals/modal-invited-user/ModalInvitedUser'
import { userInfo } from 'os'

interface IPathParams {}

interface PastRouteinfo {
  route?: string
  paymentMethod?: string
  cartId?: string
}
interface RouteState {
}

type Props = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  cartModel: CartModel
  providerModel: ProviderModel
  couponsActivatedModel: CouponsActivatedModel
  TitlePaymentMethod:any
  getCarProducts:any
  products:any
  providerReferal:any
  paymentForm:string
  productsGrouped:any
  onProductMethodFormChangeHandler:any
  metodoPago:any
  totalCart:any
  cuponsActiveCart:any
  couponReferal:any
  couponCount:number
  handleActivateCouponReferal:any
  handleDesactivate:any
  getDiscounts:any
  user: User
  settings: Settings
  offices: Office
  productModel: ProductModel
  length: number
  deviceInfo: any
  pastRouteInfo?: PastRouteinfo
}

const CartController: React.FC<Props> = props => {
  const [totalToPayCard, setTotalToPayCard] = useState<number>(0)
  const [modalOpen, setModalOpen] = useState<any>({ providerId: '', sku: '' })
  const [isOpenModalSuggested, setIsOpenModalSuggested] = useState<any>(false)
  const [modalOrderConfirmation, setModalOrderConfirmation] = useState<boolean>(false)
  const [arrMethod, sertArrMethod] = useState<any>([])
  const [productsGroupedController, setProductsGrouped] = useState<any>([])
  const [ , setProductsPerProvider] = useState<any>([])
  const [showAlertIsUserInvited, setShowAlertIsUserInvited] = useState<boolean>(false)

  useEffect(() => {
    const removeProduct =  localStorage.getItem("@areRemovedProducts")
    const  areRemovedProducts = removeProduct ? JSON.parse(removeProduct) : false

    const goBack = localStorage.getItem("@goBack")
    const goBackOrderConfirmation = goBack ? JSON.parse(goBack) : false

    const iniTialSuggestedVal: boolean = (
      props.pastRouteInfo?.cartId === props.cartModel.getId() &&
      props.pastRouteInfo?.paymentMethod === props.paymentForm && !areRemovedProducts && !goBackOrderConfirmation
    ) || false
    setTimeout(() => setIsOpenModalSuggested(iniTialSuggestedVal), 500)
  }, [
    props.cartModel,
    props.pastRouteInfo,
    props.paymentForm
  ])

  const totalToPay = (total:number,method:string) => {
    setTotalToPayCard(total)
  }
  
  const showModal = (providerId: string, sku: string, product:any) => {
    setModalOpen({  providerId, sku  })
    eventForCart("edit_product_cart", product.name, product.id, product.brand, product.category )
  }

  const renderModal = (cartProduct: CartProduct) => {
    const { sku, providerId, quantity } = cartProduct
    const showModal = modalOpen != null && modalOpen.providerId === providerId && modalOpen.sku === sku
    const product = products[providerId][sku]

    if (typeof product === 'string') {
      return undefined
    }

    props.history.push(`/vendor/${product.providerId}/product/${product._id}`,{
      isOpen:showModal,
      initialQuantity:quantity,
      product:product,
      useInitialQuantity:true,
      fromSuggested: true
    })
  }

  const removeProducts = () => {
    localStorage.setItem("@areRemovedProducts", JSON.stringify(true))
    props.getCarProducts()
    setIsOpenModalSuggested(false)
  }

  const onOpenChangeWayToPay = (event:string, providerId:any) => props.onProductMethodFormChangeHandler(event, providerId)
  
  const payTheTotal = (method:string, total:number)=>{
    const { metodoPago, productsGrouped, products, cuponsActiveCart, cartModel } = props
    eventWithName("request_cart")
    const arrMethod = metodoPago.filter((item:any) => {return item.type === method })
    const couponCode = cuponsActiveCart.length > 0 ? cuponsActiveCart[0].code : ""
    const productsQuantity = cartModel.state.products.reduce((acumulator:number, currentProduct:any) => {
      return acumulator + currentProduct.quantity
    }, 0)
    
    const skus:any[] = []
    const productsData:any[] = []

    for (const providersId in productsGrouped) {
    
      for (const skuOfProducts in products[providersId]) {

        const product = products[providersId][skuOfProducts]
          const partialProduct = {
            id: product.sku,
            name: product.name,
            price: product.price,
            quantity: product.quantity
          }
        skus.push(product.sku)
        productsData.push(partialProduct)
      }
    }

    fbInitiateCheckoutEvent('product', productsQuantity, total)
    eventBeginCheckout(couponCode, total, productsQuantity)

    if(arrMethod && arrMethod.length> 0 && productsGrouped && Object.keys(productsGrouped).length >0 ){
      setModalOrderConfirmation(true)
      sertArrMethod(arrMethod)
      setProductsGrouped(productsGrouped)
    }
  }
  

  const handleSuggestedCallback = (proceedWithPayment: boolean = false, total: number) => {    
    setIsOpenModalSuggested(false)
    if(proceedWithPayment) {
      const { cuponsActiveCart, productsGrouped, paymentForm } = props
      checkoutEvent('view_checkout', cuponsActiveCart, total, productsGrouped, paymentForm)
      const paymentAmount = totalToPayCard > 0 ? (total - totalToPayCard ): total
      payTheTotal(props.paymentForm, paymentAmount)
    }

  }

  const proceedWithCart = async (providersInCart: any, total:number) => {
    const { cuponsActiveCart, productsGrouped, paymentForm } = props
    const invitedUser = await validateIfIsInvitedUser()
    setShowAlertIsUserInvited(invitedUser)

    if(!invitedUser) {
      checkoutEvent('begin_checkout', cuponsActiveCart, total, productsGrouped, paymentForm)
  
      const productsPerProvider = await getSuggestedProductsForCart(providersInCart, props.cartModel.getId())
      if(Object.keys(productsPerProvider).length === 0 ) {
        const paymentAmount = totalToPayCard > 0 ? (total - totalToPayCard ): total
        payTheTotal(props.paymentForm, paymentAmount)
      }else {
        setProductsPerProvider(productsPerProvider)
        setIsOpenModalSuggested(true)
      }
    }
  }

  const goToLogout = async () => { 
    const { user, history } = props
    const lastToken = localStorage.getItem('@access_token')

    await user.signOut()
    if (lastToken) localStorage.setItem('@last_token_access', lastToken)

    history.push('/')
    window.location.reload()
  }
  

  const couponsController = (cartId:string) => props.getDiscounts(cartId)

  const activeCouponReferal = (providerId: string, couponReferal: any) => props.handleActivateCouponReferal(providerId , couponReferal)
  
  const handleDesactivateReferal = (providerId: string) => props.handleDesactivate(providerId)

  const closeModalOrder = () => setModalOrderConfirmation(false)

  const {
    providerModel,
    products,
    couponReferal,
    providerReferal,
    productsGrouped,
    cuponsActiveCart,
    couponCount,
    length,
    history
  } = props

  let atLeastOneValid = false
  let total = 0
  let providerPaymentfulFilled:any[] =  []
  let totalToPayWithouthFullfilled = 0
  let invalidCartWithSomeMinimum:boolean = false
  let paymentToTake = 0
  let providersInCart: string[] = []
  

    return (
      <div className='container-card-controller'>
        <span>{props.TitlePaymentMethod}</span> 
        {Object.keys(productsGrouped).map(providerId => {
          const provider = providerModel.getById(providerId)
      
          if (!provider.deliveryDays) {
            provider.deliveryDays = provider.days
          }
          const subtotal = productsGrouped[providerId]
          .map((item: any) => {
            return item.infoPrice
          })
          .reduce((sum:any,  totalPrice:any ) =>  sum + totalPrice.totalPrice, 0)

          const isMinimumFulfilled = !provider.minimun || subtotal >= provider.minimun //&& !isPaymentForm
          const difference = provider.minimun ? provider.minimun - subtotal : 0
          atLeastOneValid = atLeastOneValid || isMinimumFulfilled
          total += isMinimumFulfilled ? subtotal : 0  
          providerPaymentfulFilled.push(isMinimumFulfilled)
          totalToPayWithouthFullfilled += subtotal
          if(!invalidCartWithSomeMinimum && props.paymentForm === "pre-pago") {
            invalidCartWithSomeMinimum = difference > 0
          }
          if(providersInCart.indexOf(providerId) === -1 && isMinimumFulfilled) {
            providersInCart.push(providerId)
          }
          paymentToTake = totalToPayCard > 0 ? totalToPayWithouthFullfilled - totalToPayCard : totalToPayWithouthFullfilled
            return (
              <CardCart  
              key = {providerId}
              providerId = {providerId}
              provider = {provider}
              isMinimumFulfilled = {isMinimumFulfilled}
              difference = {difference}
              productsGroupedCard = {productsGrouped}
              productsCard = {products}
              cuponsActiveCard = {cuponsActiveCart}
              couponReferalCard = {couponReferal}
              providerReferalCard = {providerReferal}
              couponCountCard = {couponCount}
              subtotal = {subtotal}
              totalToPay = {totalToPay}
              showModal = {showModal}
              renderModal = {renderModal}
              removeProducts = { removeProducts}
              onOpenChangeWayToPay = {onOpenChangeWayToPay}
              couponsController = {couponsController}
              activeCouponReferal = {activeCouponReferal}
              handleDesactivateReferal = {handleDesactivateReferal}
              paymentMethod = {props.TitlePaymentMethod}
              cartLength={length}
              {...props}/>
              
            )
          })
        }    
        {
          props.totalCart(paymentToTake, props.paymentForm )
        }
        <div className="btn-cart-controller">
          <IonButton
            disabled={!atLeastOneValid || invalidCartWithSomeMinimum}
            onClick={() => proceedWithCart(providersInCart, total)}
            expand="block" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {props.paymentForm === "pre-pago" ? "PAGAR TOTAL" : "PEDIR TOTAL"}: {totalToPayCard > 0 ? formatCurrency(total - totalToPayCard) : formatCurrency(total)}
          </IonButton>
        </div>
        {
          modalOrderConfirmation &&
          <OrderConfirmation
            appliedShippingCost={null}
            isOpenModal={modalOrderConfirmation}
            metodoPagoUser={arrMethod}
            productsGroupedMethod={productsGroupedController}
            paymentMethod={props.paymentForm}
            closeModalOrder = {closeModalOrder}
            totalOrder={total}
            cuponsActiveCard={cuponsActiveCart}
            {...props}
            totalShippingCost={0}
            vendorsShippingCost={null}
          />
        }
        {/* {
          isOpenModalSuggested &&
          <SuggestedCheckout
            isOpenModal={isOpenModalSuggested}
            closeModalSuggested={(proceedWithPayment?: boolean) => handleSuggestedCallback(proceedWithPayment, total)}
            providersToCheckout={providersInCart}
            paymentFormMethod={props.paymentForm}
            {...props}
          />
        } */}

        <ModalInvitedUser 
          isOpen={showAlertIsUserInvited} 
          history={history} 
          user={props.user}
          closeModal={() => history.push('/home')}
        />
      </div>
    )
}

export default withRouter(CartController)
