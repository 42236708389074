import React, { useState, useEffect } from 'react'
import { IonBadge, IonContent, IonPage } from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { StaticContext } from 'react-router'
import track from 'react-tracking'

//COMPONENTS
import ToolBar from '../../components/tool-bar/ToolBar'
import WordsSlider from '../../components/words-slider/WordsSlider'
import GlobalComponents from '../../components'

//MODELS
import User from '../../models/User'
import Settings from '../../models/Settings'
import Category from '../../models/Category'
import { ProductModel } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'
import { PromotionModel } from '../../models/PromotionModel'
import Office from '../../models/Office'
import { ProviderModel } from '../../models/ProviderModel'
//ICONS

import { categoriesOfProductsByVendor, getOutstandingProductsByVendor } from '../../clients/provider'
import { ifExistAndMayorToZero } from '../../utils/arrays'
import { onPageScroll } from '../../utils/scroll-spy'
import actClose from './../../assets/icons/act_less.svg'
import actOpen from './../../assets/icons/act_more.svg'
import ProductCard from '../../components/product-card/ProductCard'
import LoadingComponent from '../../components/loading-component/LoadingComponent'
import cartToolbarIcon from '../../assets/icons/nav_cart_blue.svg'
import searchIcon from '../../assets/icons/act_search.svg'
import { EventEmitter } from '../../utils/events'
import { getCartLength } from '../../utils/cart'
import './VendorTopProducts.scss'
import { navigateToCart } from '../../utils/navigation'


type IPathParams = {}

interface RouteState {
  vendorName: string
}

type IProps = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  categories: Category
  productModel: ProductModel
  tracking?: any
  cartModel: CartModel
  promotion: PromotionModel
  offices: Office
  providerModel: ProviderModel
}

const VendorTopProducts: React.FC<IProps> = props => {
  const { history, location, settings, cartModel} = props
  const { vendorName } = location.state
  const [vendorNameToToolbar, setVendorNameToToolbar] = useState<string>(vendorName)
  const [outstandingProducts, setOutstandingProducts] = useState<any>([])
  const [allMergeCategories, setAllMergeCategories] = useState<any>([])
  const [cart, setCart] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [page, setPage] = useState<any>(1)
  const [cartLengthData, setCartLengthData] = useState<any>({ cartLength: 0, prevLength: 0 })
  const [animateAtc, setAnimateAtc] = useState<string>('')
  const [categoriesInfo, setCategoriesInfo] = useState<any>({})

  useEffect(() => {
   setVendorName(findVendor().alias)
  }, [ vendorNameToToolbar === '' || !vendorNameToToolbar ]) // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    getOutstandingProducts()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAllMergeCategories(outstandingProducts)
  }, [outstandingProducts])

  useEffect(() => {
    const cartLocalStorage = localStorage.getItem('@cart')

    if (cartLocalStorage) setCart(JSON.parse(cartLocalStorage).products)
  }, [])

  useEffect(() => {
    const cartLocal = localStorage.getItem('@cart')
    const cart = cartLocal ? JSON.parse(cartLocal) : null
    if (cart) {
      const updatedCartLength: any = cart.products?.length || null
      setCartLengthData({ cartLength: updatedCartLength, prevLength: updatedCartLength })
    }

    getCart()

    EventEmitter.subscribe('UpdateCartCount', ({ cartUpdateAction }: any) => {
      setCartLengthData((prevState: any) => {
        let newCartLength = prevState.cartLength
        if(cartUpdateAction === 'add') newCartLength = newCartLength + 1
        if(cartUpdateAction === 'subtract') newCartLength = newCartLength - 1
        const didAdd = newCartLength > prevState.cartLength
        if (didAdd) {
          setAnimateAtc('badge-anim-in')
          setTimeout(() => { setAnimateAtc('badge-anim-out') }, 1);
        }
        return { 
          cartLength: newCartLength,
          prevLength: prevState.cartLength 
        }
      })
    })

    return () => {
      EventEmitter.unsubscribe('UpdateCartCount')
    }
  }, [])

  const getCart = async () => {
    const cartLength: any = await getCartLength()
    setCartLengthData({ cartLength, prevLength: cartLength })
  }

  const getOutstandingProducts = async () => {
    const categories: any = await categoriesOfProductsByVendor(splitRouteToGetVendorId(location.pathname), 'outstanding')
    let topProductsData:any= []
    let objTempWithCategoryAndProducts:any = {}
    let categoriesInfoByCategoryId = {}

    for(let i=0; i < categories?.data?.length; i++ ) {
      const productsByCategory: any = await getOutstandingProductsByVendor(splitRouteToGetVendorId(location.pathname), categories.data[i].name, page)

      categoriesInfoByCategoryId = {
        ...categoriesInfoByCategoryId,
        [categories.data[i]._id]: productsByCategory?.data
      }

      const tempObj = {products: productsByCategory?.data?.products}
      objTempWithCategoryAndProducts = {...categories?.data[i], ...tempObj}
      topProductsData.push(objTempWithCategoryAndProducts)
    }
      
    setCategoriesInfo(categoriesInfoByCategoryId)
    setOutstandingProducts(topProductsData)
    setIsLoading(false)
  }

  const fetchVendors = () => {
    let allProviders: any[] = []
    const providers = localStorage.getItem('@providers')
    if(providers) { 
      allProviders = JSON.parse(providers)
      allProviders = Object.values(allProviders)
    }
    
    return allProviders
  }
  
  const splitRouteToGetVendorId = (route:string) => {
    const splittedRoute = route.split('/')
    return splittedRoute[2]
  }

  const findVendor = () => {
    let allVendors = fetchVendors();
    const vendorId = splitRouteToGetVendorId(location.pathname)
    const matchVendor = allVendors.find(({id}) => (id === vendorId)) 

    return matchVendor
  }

  const mapProductFromSuggested = (product: any) => ({
    ...product,
    provider: product.providerId,
    filename: product.imageFullPath ?? product.filename,
    vendorActiveInComune: product.active
  })

  const showPartialValue = (value:string) => {
    return value
  }

  const getMoreProductsByCategory = async (category: any) => {
    if (category.productsQty > category.products.length) {
      setPage(async () => {
        categoriesInfo[category._id].page = categoriesInfo[category._id].page + 1
        let totalSum = categoriesInfo[category._id].page
        const productsByCategory: any = await getOutstandingProductsByVendor(splitRouteToGetVendorId(location.pathname), category.name, totalSum)
        const tempObj = [...outstandingProducts]
        const matchOutstandingCategory = tempObj.find((outstandingCategory: any) => outstandingCategory._id === category._id);

        matchOutstandingCategory.products = matchOutstandingCategory.products.concat(productsByCategory.data.products)

        setOutstandingProducts(tempObj)
        
        return totalSum
      })
    }
  }

  const seeLessProducts = async (category: any) => {
    if (category.productsQty === category.products.length) {
      const tempObj = [...outstandingProducts]
      const matchOutstandingCategory = tempObj.find((outstandingCategory: any) => outstandingCategory._id === category._id);
      matchOutstandingCategory.products = matchOutstandingCategory.products.slice(0,9)
      categoriesInfo[category._id].page = 1
      setPage(categoriesInfo[category._id].page)
      setOutstandingProducts(tempObj)
    }
  }

  const outstandingProductsMap = () => {
    return (
      outstandingProducts.map((category:any, index: number) => {
        category.products.sort((a:any,b:any) => a.outOfStock === b.outOfStock ? 0 : a.outOfStock ? 1 : -1)

        return (
        <div className="container-all-category">
          <div className="header-category" id={`header-${category._id}`}>
            <span className={`container-title`}>{showPartialValue(category.name)}</span>
          </div>
          <div className="container-product-card-offers">
            {category.products.map((product:any) => 
              (
                <ProductCard
                  key={`product-card-${product._id}`}
                  product={mapProductFromSuggested(product)}
                  settings={settings}
                  cartModel={cartModel}
                  onGoToCart={goToCart}
                  hidePrice={false}
                  history={history}
                  categoryPosition={index + 1}
                  needPadding={true}
                  metrics={{
                    addToCartSource: 'provider_top_products',
                    deleteProductCartSource: 'provider_top_products'
                  }}
                />
              )
            )}
          </div>
          {category.productsQty > 9 && category.productsQty > category.products.length &&
            <div className="provider-summary-order">
              <div onClick={() => getMoreProductsByCategory(category)} className={`provider-header-order`}>
                <div className="container-provider-header">
                <div className="provider-title">
                  Ver más
                </div>
                  <div className="icon-provider">
                    <img src={actOpen}/>
                  </div>
                </div>
              </div>
            </div>
          }
          {category.productsQty > 9 && category.productsQty === category.products.length &&
            <div className="provider-summary-order">
              <div onClick={() => seeLessProducts(category)} className={`provider-header-order`}>
                <div className="container-provider-header">
                <div className="provider-title">
                  Ver menos
                </div>
                  <div className="icon-provider">
                    <img src={actClose}/>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>

      )})
    )
  }

  const validateIfExistsOutstandingProducts = () => {
    if(!isLoading) return outstandingProductsMap()

    return (
      <LoadingComponent/>
    )
  }

  const openSearch = () => { 
    const singleProviderId = splitRouteToGetVendorId(location.pathname)

    props.history.push(`/vendor/${singleProviderId}/search`)
  }

  const setVendorName = (vendorName:string) => setVendorNameToToolbar(vendorName)

  const goToCart = () => navigateToCart(history, props.location.state || props.history.location.state);

  return (
      <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className='relative-header-wrapper'>
          {cartLengthData.cartLength > 0 && <IonBadge className={`cart-badge-btn ${animateAtc}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{cartLengthData.cartLength}</IonBadge> }
          <ToolBar
            title={vendorNameToToolbar}
            secondaryButtons={[{ type: 'back', onClick: history.goBack }]}
            secondaryHeader={true}
            boxShadow={true}
            primaryButtons={[
              {
                icon: searchIcon,
                key: 'search',
                onClick: openSearch,
              },
              {
                icon: cartToolbarIcon,
                key: 'cart',
                onClick: goToCart,
                badge: cart.length,
                animateAtc,
              },
            ]}
          />
        </div>
        {ifExistAndMayorToZero(outstandingProducts) && ifExistAndMayorToZero(allMergeCategories) && <WordsSlider fireEvent='click_category_providers_top_products' words={allMergeCategories}/>}
        <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          // scrollEvents={true} onIonScroll={(e) => onPageScroll(e, 'outstandingProducts')}
        >
          {validateIfExistsOutstandingProducts()}
          <div className="container-height"></div>
        </IonContent>
      </IonPage>
    )
}

export default track({ page: 'VendorTopProducts' })(withRouter(VendorTopProducts))
