import Axios from "axios";

export type UserContactData = {
  name: string
  phone: string
  email: string
}

export const sendContactData = async (userContactData: UserContactData) => {
  try {
    const lastToken = localStorage.getItem('@access_token')
    const url = `${process.env.REACT_APP_BFF_NODE}/v1/users/guest`

    const result = await Axios.patch(
      url, 
      userContactData,
      {
        headers: {
          Authorization: `Bearer ${lastToken}`
        }
      }
    )
    return result.data
  } catch(error) {
    console.error('Error: ', error);
    throw error
  }
}
