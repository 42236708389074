import { IonIcon } from '@ionic/react'
import React, { Dispatch, Fragment, SetStateAction, useContext, useState } from 'react'
import { addOutline, removeOutline, trashOutline } from 'ionicons/icons'
import './index.scss'
import { ProductCartData } from '../../../../interfaces/carts'
import { shortString } from '../../../../utils/functions/shortString'
import { formatCurrency } from '../../../../utils/intl'
import { updateProductPriceInCart } from '../../../../services/bff_mobile/v2/carts/updateProductPriceInCart'
import AppContext from '../../../../context/AppContext'
import useHandleDisabledQuantityButton from '../../../../hooks/carts/useHandleDisabledQuantityButton'
import DeleteModal from '../delete_modal'
import { deleteProductInCart } from '../../../../services/bff_mobile/v2/carts/deleteProductInCart'
import useCheckQuantity from '../../../../hooks/carts/useCheckQuantity'

interface Props {
  product: ProductCartData
  paymentMethod: string
  setLoadingProvider: Dispatch<SetStateAction<boolean>>
  providerId: string
}

function ProductItem({ product, paymentMethod, setLoadingProvider, providerId }: Props) {
  const { cartData, handlerCartData } = useContext(AppContext)
  useCheckQuantity(product, paymentMethod)
  const { disabledDecreaseButton, disabledIncreaseButton, setDisabledDecreaseButton, setDisabledIncreaseButton } =
    useHandleDisabledQuantityButton(product)

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [loadingDeleteButton, setLoadingDeleteButton] = useState<boolean>(false)

  const handlerQuantityProduct = async (type: string) => {
    setLoadingProvider(true)

    let newQuantity: number = product.quantity

    if (type === 'increase') {
      setDisabledIncreaseButton(true)
      newQuantity++
    }
    if (type === 'decrease') {
      setDisabledDecreaseButton(true)
      newQuantity--
    }

    const result = await updateProductPriceInCart(cartData._id, product._id, newQuantity)

    const { providerId, _id, quantity, totalPrice } = result

    const updatedCartData = cartData.data.map((data) => {
      if (data.paymentMethod !== paymentMethod) return data

      const updatedProviders = data.providers.map((provider) => {
        if (provider._id !== providerId) return provider

        const updatedProducts = provider.products.map((product) => {
          if (product._id !== _id) return product

          return {
            ...product,
            quantity,
            totalPrice,
          }
        })

        return {
          ...provider,
          products: updatedProducts,
        }
      })

      return {
        ...data,
        providers: updatedProviders,
      }
    })

    handlerCartData({
      ...cartData,
      data: updatedCartData,
    })

    if (type === 'increase') setDisabledIncreaseButton(false)
    if (type === 'decrease') setDisabledDecreaseButton(false)
    setLoadingProvider(false)
  }

  const deleteProduct = async (): Promise<void> => {
    setLoadingDeleteButton(true)

    await deleteProductInCart(cartData._id, product._id)

    const updatedCartData = cartData.data.map((data) => {
      if (data.paymentMethod !== paymentMethod) return data

      const updatedProviders = data.providers.map((provider) => {
        if (provider._id !== providerId) return provider

        const filteredProducts = provider.products.filter((p) => p._id !== product._id)

        return {
          ...provider,
          products: filteredProducts,
        }
      })

      return {
        ...data,
        providers: updatedProviders.filter((provider) => provider.products.length > 0),
      }
    })

    handlerCartData({
      ...cartData,
      data: updatedCartData.filter((data) => data.providers.length > 0),
    })

    setLoadingDeleteButton(false)
    setOpenDeleteModal(false)
  }

  return (
    <Fragment>
      <div>
        <div className="cart-product-section">
          <span>{product.quantity}</span>
          <img src={product.image} alt={product.name} />

          <div className="cart-product-section__info">
            <h5>{shortString(product.name, 22)}</h5>
            <h5>
              {product.units} {product.uom} por {product.packageType}
            </h5>
            <h3>{formatCurrency(product.totalPrice)}</h3>
          </div>

          <div className="cart-product-section__buttons">
            <button
              className={`cart-product-section__buttons__button cart-product-section__buttons__button--${
                disabledIncreaseButton ? 'disabled' : 'active'
              }`}
              onClick={() => handlerQuantityProduct('increase')}
              disabled={disabledIncreaseButton}
            >
              <IonIcon icon={addOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
            </button>
            {product.quantity === 1 ? (
              <button
                className={`cart-product-section__buttons__button--trash`}
                onClick={() => setOpenDeleteModal(true)}
              >
                <IonIcon icon={trashOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
              </button>
            ) : (
              <button
                className={`cart-product-section__buttons__button cart-product-section__buttons__button--${
                  disabledDecreaseButton ? 'disabled' : 'active'
                }`}
                onClick={() => handlerQuantityProduct('decrease')}
                disabled={disabledDecreaseButton}
              >
                <IonIcon icon={removeOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
              </button>
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        title="Quitar de la lista"
        message="El producto ya no estará en el carrito"
        buttonActionName="Quitar"
        action={() => deleteProduct()}
        open={openDeleteModal}
        closeModal={() => setOpenDeleteModal(false)}
        loadingButton={loadingDeleteButton}
      />
    </Fragment>
  )
}

export default ProductItem
