import { IonContent, IonHeader, IonIcon, IonPage, IonSpinner } from "@ionic/react";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import { getBrandProviderProducts, getBrandProviders, TProvider } from "../../clients/brands";
import ToolBar from "../../components/tool-bar/ToolBar";
import cartToolbarIcon from '../../assets/icons/nav_cart_blue.svg'
import { ifExistAndMayorToZero } from "../../utils/arrays";
import User from "../../models/User";
import Settings from "../../models/Settings";
import Office from "../../models/Office";
import Category from "../../models/Category";
import { PromotionModel } from "../../models/PromotionModel";
import { ProductModel } from "../../models/ProductModel";
import { CartModel } from "../../models/CartModel";
import { ProviderModel } from "../../models/ProviderModel";
import { sendMetricWithSource } from "../firebase/firebaseTags";
import { useSubscribeToCart } from "../../hooks/useSubscribeToCart";
import { BrandVendors } from "../../components/home-brand-detail/BrandVendors";
import { categoryTreeOfVendor, getProducts } from "../../clients/category";
import CategorySecondary from '../provider-category-sencondary/CategorySecondary'
import './HomeBrandDetail.scss'
import { mapProductFromSuggested } from "../../components/product-slider/ProductSlider";
import WordsSlider from "../../components/words-slider/WordsSlider";
import backSmallArrow from '../../assets/icons/nav_left.svg'

type TRouteState = {
  name: string;
}

type IPathParams = {
  id: string
}

export type TRouteProps = RouteComponentProps<IPathParams, StaticContext, TRouteState> & {
  user: User
  settings: Settings
  offices: Office
  categories: Category
  promotion: PromotionModel
  productModel: ProductModel
  cartModel: CartModel
  providerModel: ProviderModel
  singleProviderIdUser?: string
};

export type TGroupedProducts = {
  _id: string;
  name: string;
  products: any[];
}

type TScreenView = 'vendors' | 'categories' | 'subcategories'

const HomeBrandDetail: React.FC<TRouteProps> = (props) => {
  const [groupedProducts, setGroupedProducts] = useState<TGroupedProducts[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedVendor, setSelectedVendor] = useState<any>({});
  const [selectedCategory, setSelectedCategory] = useState<any>({})
  const [screenView, setScreenView] = useState<TScreenView>('vendors')
  const [mainScreenView, setMainScreenView] = useState<TScreenView>('vendors')
  const [prevScreen, setPrevScreen] = useState<TScreenView>('vendors');

  const {cartLengthData, animateAtc} = useSubscribeToCart();

  const {match, location, history} = props
  const {id: brandId} = match.params
  const {name} = location.state

  async function loadBrandProviders() {
    const providers = await getBrandProviders(brandId)
    let initialData = []
    if (providers.length > 1) {
      initialData = await fetchAllProviders(providers)
    }
    if (providers.length === 1) {
      setSelectedVendor({id: providers[0]._id, name: providers[0].name})
      const {categoriesData, screen} = await fetchProviderCategoryTree(providers[0]._id)
      initialData = categoriesData
      setScreenView(screen)
      setMainScreenView(screen)
    }
    setGroupedProducts(initialData)
    setIsLoading(false)
  }

  useEffect(() => {
    loadBrandProviders()
  }, [])

  const fetchAllProviders = async (loadedProviders: TProvider[]) => {
    const providersData = await Promise.all(
        loadedProviders.map(async ({_id, name}) => {
          const categoryTree = await categoryTreeOfVendor(brandId, _id);
          const products = await getBrandProviderProducts({brandId, providerId: _id, mainCategory: false, limit: 12})
          return {_id, name, providerId: _id, categoryTree, products}
        })
      )
    return providersData
  }

  const fetchProviderCategoryTree = async (providerId: string) => {
    const categories: any = await categoryTreeOfVendor(brandId, providerId)
    let categoriesData: any = [];
    let screen: TScreenView = 'categories'
    if (categories.length > 1) {
      categoriesData = await fetchCategoriesAndProducts(providerId, categories)
    }
    if (categories.length === 1) {
      categoriesData = await fetchProductsSubcategory(providerId, categories[0].name)
      setSelectedCategory({id: providerId, name: categories[0].name})
      screen = 'subcategories'
    }
    
    return {screen, categoriesData}
  }

  const fetchCategoriesAndProducts = async (providerId: string, categories: string[]) => {
    const productsByCategory: any = await getBrandProviderProducts({brandId, providerId, mainCategory: true, limit: 12})
    const categoriesData = categories.map(({_id, name}: any) => {
      return {_id, name, providerId, products: productsByCategory[name]}
    })

    return categoriesData;
  }

  const fetchProductsSubcategory = async (providerId: string, categoryId: string) => {
    const productsBySubcategory: any = await getBrandProviderProducts({brandId, providerId, mainCategory: true, category: categoryId})
    const subcategoriesMapped = []
    for (const sub in productsBySubcategory) {
      const productsCategory = productsBySubcategory[sub];
      const products = productsCategory.map((item: any) => mapProductFromSuggested(item))
      subcategoriesMapped.push({_id: productsCategory[0].categoryTree[0].name, name: productsCategory[0].categoryTree[0].name, providerId, products})
    }

    return subcategoriesMapped;
  }

  const handleClickSeeAllProviderCategories = async (providerId: string, categoryId: string, name: string) => {
    setIsLoading(true)
    setPrevScreen(screenView);

    let products = []
    let goToScreen: TScreenView = 'subcategories'
    
    if (screenView === 'vendors') {
      setSelectedVendor({id: providerId, name})
      const {categoriesData, screen}  = await fetchProviderCategoryTree(providerId)
      products = categoriesData
      goToScreen = screen
    } else if (screenView === 'categories') {  
      setSelectedCategory({id: categoryId, name})
      products = await fetchProductsSubcategory(providerId, name)
    }
    setScreenView(goToScreen)
    setGroupedProducts(products)
    setIsLoading(false)
  }

  const mapWordsSlider = (collection: any[]) => {
    return [...collection].map(({_id, name}) => {
      return {_id, name}
    })
  }

  const getWordsSlider = useMemo(() => {
    let wordsList: any[] = []

    if (screenView === 'subcategories') {
      wordsList = mapWordsSlider(groupedProducts)
      if (mainScreenView === 'vendors') {
        wordsList.unshift({_id: selectedVendor.name, name: selectedVendor.name})
      } else {
        wordsList.unshift({_id: selectedCategory.name, name: selectedCategory.name})
      }
    }

    if (screenView === 'categories') {
      wordsList = mapWordsSlider(groupedProducts)
      if (mainScreenView === 'vendors') {
        wordsList.unshift({_id: selectedVendor.name, name: selectedVendor.name})
      }
    }

    if (screenView === 'vendors') {
      wordsList = mapWordsSlider(groupedProducts)
    }

    return {wordsList, isSecondaryScreen: mainScreenView !== screenView};

  }, [groupedProducts, screenView])

  const goToCart = () => {
    history.push('/cart')
    sendMetricWithSource('view_cart', 'brands_home')
  }

  const handlePressSwordSliderBack = async () => {
    setIsLoading(true)
    if (
      prevScreen === 'vendors'
      || screenView === 'categories'
    ) {
      setScreenView('vendors')
      await loadBrandProviders()
    } else if (prevScreen === 'categories') {
      const {categoriesData} = await fetchProviderCategoryTree(selectedVendor.id)
      setScreenView('categories')
      setGroupedProducts(categoriesData)
      setIsLoading(false)
    }
  }

  return (
    <IonPage className="home-brand-detail-page" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <ToolBar
        title={name}
        secondaryButtons={[{ type: 'back', onClick: history.goBack }]}
        secondaryHeader={true}
        boxShadow={true}
        primaryButtons={[
          {
            icon: cartToolbarIcon,
            key: 'cart',
            onClick: () => history.push('/cart'),
            badge: cartLengthData.cartLength,
            animateAtc,
          },
        ]}
      />
      {
        !isLoading && mainScreenView !== 'subcategories' ? 
          <div className="container-words-slider">
            {Boolean(getWordsSlider.isSecondaryScreen) &&
              <div className="words-slider-arrow">
                <IonIcon slot="icon-only" src={backSmallArrow} onClick={handlePressSwordSliderBack} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </div>
            }
            <WordsSlider words={getWordsSlider.wordsList} />
          </div>
          : null
      }
      <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        {isLoading &&
          <div className="loading-offers">
            <IonSpinner className="spinner-home-brand-detail" name="crescent" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            <p className="loading">Cargando Productos...</p>
          </div>
        }
        {(screenView === 'vendors' || screenView === 'categories') &&
          <>
            {(ifExistAndMayorToZero(groupedProducts) && !isLoading) &&
              <>
                <div className="container-category-provider">
                  <BrandVendors
                    brandId={brandId}
                    providers={groupedProducts}
                    goToCart={goToCart}
                    pressSeeAll={handleClickSeeAllProviderCategories}
                    {...props} />
                  <div className="container-height" />
                </div>
              </>
            }
          </>
        }
        {screenView === 'subcategories' &&
          <>
            {(groupedProducts.length > 0 && !isLoading) ?
              <>
                <div className="container-category-provider">
                  <CategorySecondary
                    subcategories={groupedProducts}
                    singleProvider={false}
                    loadMoreProductsForCategory={() => {}}
                    seeLessProductsForCategory={() => {}}
                    productsInfo={[]}
                    nameProvider={''}
                    arrowCategory={false}
                    {...props}
                  />
                  <div className="container-height" />
                </div>
              </>
              : null
            }
          </>
        }
      </IonContent>
    </IonPage>
  )
}

export default withRouter(React.memo(HomeBrandDetail));

