import React from 'react';
import checkMark  from '../../assets/icons/icon-check.svg'
import './Stepper.scss'


interface StepperProps {
  steps: {
    text?: string;
    stepNumber: number;
    currentStep: number;
  }[];
  currentStep: number
}

const validateStep = (stepNumber: number, currentStep: number) => {
  if (stepNumber < currentStep) return 'completed-step'
  if (stepNumber === currentStep) return 'active-step'
  return 'not-completed-step'
}

const validateCheckmark = (stepNumber: number, currentStep: number) => {
  if(stepNumber < currentStep) return <img src={checkMark} alt="check"/>
  return stepNumber
}

const Stepper: React.FC<StepperProps> = ({steps, currentStep}) => (
  <>
    {
      steps.map(({stepNumber}, index) => (
        <div className="container-step-line" key={stepNumber}>
          <div className={`container-index ${validateStep(stepNumber, currentStep)}`} >
            {validateCheckmark(stepNumber, currentStep)} 
          </div>
          {index !== steps.length -1 &&
            <hr 
              className={`trace-line ${(stepNumber === currentStep || stepNumber < currentStep) ? "active-line" : ''}`}
            />
          }
        </div>
      ))
    }
  </>
);

export default Stepper;