import { cartInitialState, CartInitialState } from './cart'
import { countryInfoInitialState, CountryInfoInitialState } from './countryInfo'
import { CouponActivatedInitialState, couponsActivatedInitialState } from './couponsActivated'
import { platformInitialState, PlatformInitialState } from './platform'

export type InitialStateContext = PlatformInitialState &
  CartInitialState &
  CouponActivatedInitialState &
  CountryInfoInitialState

export const initialStateContext: InitialStateContext = {
  ...platformInitialState,
  ...cartInitialState,
  ...couponsActivatedInitialState,
  ...countryInfoInitialState,
}
