import { useState } from 'react'

function PlatformProvider() {
  const [platform, setPlatform] = useState<string>('')

  const handlerPlatform = async (platform: string) => setPlatform(platform)

  return { platform, handlerPlatform }
}

export default PlatformProvider
