import React, { useEffect, useState } from 'react';
import './Checkbox.scss';

interface CheckboxProps {
  label: string
  onChange?: (checked: boolean) => any
  checked?: boolean
}

const Checkbox: React.FC<CheckboxProps> = ({ label, onChange, checked }) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    if (checked !== undefined) setValue(checked)
  }, [])

  const usedValue = checked !== undefined ? checked : value;

  return (
    <div className="checkbox-component">
      <div 
        className={`checkbox-component__checkbox ${usedValue ? 'checkbox-component__checked' : ''}`}
        onClick={() => {
          setValue(!usedValue)
          if (onChange) onChange(!usedValue)
        }}
      >
      </div>
      <div>
        <span className="checkbox-component__label">{label}</span>
      </div>
    </div>
  )
};
export default Checkbox;