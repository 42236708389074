import React, { useEffect, useState } from 'react';
import { Plugins } from '@capacitor/core'
import './HeaderWithoutToolbar.scss'

const { Device } = Plugins
interface HeaderProps {
  onClick: (event:any) => void
  text: string
  icon?: string
  iconSize?: string
}

const HeaderWithoutToolbar: React.FC<HeaderProps> = ({ onClick, text, icon, iconSize }) => {
  const [deviceInfo, setDeviceInfo] = useState('')

  useEffect(() => {
    getDeviceInfo()
  }, [deviceInfo])

  const getDeviceInfo = async () => {
    const platform = (await Device.getInfo()).platform
    setDeviceInfo(platform)
  }

  return (
    <div className={`container-header-help ${(deviceInfo === 'ios') ? 'platform-ios' : ''}`}>
      <div className={"header-icon-container"}>
        <img 
          style={iconSize ? { width: iconSize, height: iconSize } : {}}
          className="arrow-back"
          src={icon} 
          alt="Icono volver atras" 
          onClick={onClick}
        />
      </div>
      <h1 className="label-header-help">{text}</h1>
    </div>
  )
};

export default HeaderWithoutToolbar;