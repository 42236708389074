import { getCartDataById } from '../../services/bff_mobile/v2/carts/getCartDataById'
import { getProductDataBySkuAndProviderId } from '../../services/bff_mobile/v2/products/getProductDataBySkuAndProviderId'
import { CartData, PaymentMethodCartData, ProductCartData, ProviderCartData } from '../../interfaces/carts'
import { getCartId } from '../../services/bff_mobile/v2/carts/getCartId'
import { getHomeData } from '../../services/bff_mobile/v2/home/getHomeData'

export const getCartData = async (): Promise<CartData> => {
  const cartId = await getCartId()

  const { products, discounts } = await getCartDataById(cartId)

  const { vendors } = await getHomeData()

  const cartData: PaymentMethodCartData[] = []

  // Filtramos los métodos de pago únicos que se encuentran en el carrito
  const uniquePaymentMethods: string[] = []
  for (const product of products) {
    if (!uniquePaymentMethods.includes(product.paymentForm)) uniquePaymentMethods.push(product.paymentForm)
  }

  for (const paymentMethod of uniquePaymentMethods) {
    // Filtramos los proveedores únicos por método de pago
    const uniqueProvidersByPaymentMethod: ProviderCartData[] = []
    for (const product of products) {
      if (
        product.paymentForm === paymentMethod &&
        !uniqueProvidersByPaymentMethod.find((provider) => provider._id === product.providerId)
      ) {
        const providerFound = vendors.find((vendor) => vendor._id === product.providerId)

        if (providerFound)
          uniqueProvidersByPaymentMethod.push({
            _id: product.providerId,
            name: providerFound?.name,
            minimum: Number(providerFound?.minimun),
            alias: providerFound?.alias,
            products: [],
          })
      }
    }

    // Filtramos los productos por proveedor
    for (const provider of uniqueProvidersByPaymentMethod) {
      const productsByProvider: ProductCartData[] = []
      for (const product of products) {
        if (product.paymentForm === paymentMethod && product.providerId === provider._id) {
          const { imageFullPath, quantityMax, quantityMin, stock, promotionalDynamicDetail, promotionalDynamicType } =
            await getProductDataBySkuAndProviderId(product.sku, product.providerId)

          const foundProduct = products.find(
            (productCart: any) =>
              productCart._id === product._id &&
              productCart.providerId === provider._id &&
              productCart.paymentForm === paymentMethod,
          )

          productsByProvider.push({
            _id: product._id,
            name: product.name,
            image: imageFullPath,
            quantity: foundProduct?.quantity!,
            units: foundProduct?.units!,
            packageType: foundProduct?.packageType!,
            totalPrice: foundProduct?.infoPrice.totalPrice!,
            uom: foundProduct?.infoPrice.uom!,
            quantityMin,
            quantityMax,
            stock,
            promotionalDynamicDetail,
            promotionalDynamicType,
          })
        }
      }

      provider.products = productsByProvider
    }

    cartData.push({
      paymentMethod,
      providers: uniqueProvidersByPaymentMethod,
    })
  }

  return {
    _id: cartId,
    data: cartData,
    discounts,
  }
}
