import Axios from 'axios'

export interface CartProduct {
  providerId: string
  sku: string
  price: number
  quantity: number
}

export interface CartDiscounts {
  code: string,
  amount: string,
  discountType: string,
  couponId: string,
  couponActivateId: string,
  provider: string,
  realDiscount: string,
  providerId: string
}

export interface Cart {
  id: string,
  state: string,
  products: CartProduct[],
  discounts: CartDiscounts[],
  _id?: string
}

export const postCart = async (cart: Cart): Promise<any> => {
  try {
    if(cart._id) delete cart._id 
    const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_NODE}/v1/carts`, cart)
    return result
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const getCartClient = async (id: string): Promise<any> => {
 const time = ( new Date()).getTime()
 try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/carts/${id}?${time}`)
    return result
  } catch (error) {
    if (error instanceof Error)  console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const updateCartClient = async (cart: Cart): Promise<any> => {
  try {
    const result = await Axios.put<any>(`${process.env.REACT_APP_BFF_NODE}/v1/carts/${cart.id}`, cart)
    return result
  } catch (error) {  
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const verifyProviderReferal = async (couponType:string): Promise<any> => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/coupons/findBlackList/${couponType}`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  
  }
}


  export const whitelistByPaymentMethod = async (listIdProviders:any): Promise<any> => {
    try {
      const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/paymentmethods/forms?vendorIds=${listIdProviders}`)
      return result.data
    } catch (error) {
      if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
      throw error
    }
  }



