import React from 'react'
import './LoadingComponent.scss'

type Props = {
  height?: string
}

const LoadingComponent: React.FC<Props> = ({ height = '100%' }) => (
  <div className="spinner-container" style={{ height }}>
    <div className="spinner"></div>
  </div>
);

export default LoadingComponent;
