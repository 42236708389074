import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { validarRuc } from '../../utils/rut'

import TextInput from '../../components/input-form/TextInput'


interface IPathParams {}

type Props = RouteComponentProps<IPathParams> & {
  validateUser: any
  informativeText?: string
}
interface State {
  isFormValid: boolean
  user: any
  valueInputText: any
  validationInput: boolean
  validationText: string | number
}
export default  class FieldRucPeru extends React.PureComponent<Props, State> {
  state: State = {
    isFormValid: false,
    user: '',
    valueInputText: "",
    validationInput: false,
    validationText: ""
  }

  onChangeInput = (event:any) => {
    const inputVal = event.target.value
    const validation = validarRuc(inputVal) 
    this.setState({
      valueInputText: validation || inputVal,
      validationInput: !!validation,
      validationText: event.target.value
    })

    const localUser = { username: inputVal }
    this.props.validateUser(localUser, Boolean(validation))
  }
  
  render() {
      const {
        validationInput,
        valueInputText,
        validationText
      } = this.state

      const {informativeText} = this.props

    return (
      <div className='form-container'>
        <TextInput 
          iconDefault="icn_id" 
          iconInvalid="icn_id-red"
          iconValid="icn_id-blue"
          onChange={this.onChangeInput} 
          placeholder="Ej: 201234567"
          label="RUC o DNI"
          isValid={validationInput}
          error={"Escribe tu RUC sin guión"}
          value={valueInputText}
          toValidate={validationText}
          type="text"
          maxLength={11}
          autoComplete="off"
          informativeText={informativeText || ''}
        />
      </div>
    )
  }
}


