import React from 'react'
import { IonPage, IonContent, IonBadge } from '@ionic/react'
import User from '../../models/User'
import Category from '../../models/Category'
import Settings from '../../models/Settings'
import GlobalComponents from '../../components'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { ProductModel } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'

import cartToolbarIcon from '../../assets/icons/nav_cart_blue.svg'

import ToolBar  from '../../components/tool-bar/ToolBar'
import { termSearched, viewSearchResultsEvent } from '../firebase/firebaseTags'
import { EventEmitter } from '../../utils/events'
import { getCartLength } from '../../utils/cart'
import GuruWhiteScreen from '../../components/guru-white-screen/GuruWhiteScreen'
import LoadingComponent from '../../components/loading-component/LoadingComponent'

import './Result.scss'
import { navigateToCart } from '../../utils/navigation'


type IPathParams = {
  id: string
}

type IProps = RouteComponentProps<IPathParams> & {
  user: User
  settings: Settings
  categories: Category
  productModel: ProductModel
  cartModel: CartModel
  hidePrice?: boolean
  shouldHideModal?: boolean
  routeHome?: boolean
}

interface State {
  searchText: string
  loading: boolean
  productsCategory: any
  cartLengthData: any
  animateAtc: any
}

class Result extends React.PureComponent<IProps, State> {
  state: State = {
    searchText: '',
    loading: true,
    productsCategory: [],
    cartLengthData: { cartLength: 0, prevLength: 0 },
    animateAtc: '',
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    EventEmitter.unsubscribe('UpdateCartCount')
    this.setState = (state,callback)=>{
        return;
    };
  }

  goToCart = () => {
    navigateToCart(this.props.history, this.props.location.state || this.props.history.location.state);
  }

  goToDeliveries = () => {
    this.props.history.push('/deliveries')
  }

  goToOrders = () => {
    this.props.history.push('/orders')
  }

  onMoreInfoClick = (providerId:any, nameCategory:any, arrowSearch?:boolean, searchText?:any,
      allProductsOfProviderWithTextSearch?:any) => {
    this.props.history.push(`/search-products-providers`, {
      title:nameCategory,
      idProvider: providerId,
      textSearch: searchText,
      productsFound: allProductsOfProviderWithTextSearch
    })
  }

  async UNSAFE_componentWillReceiveProps(props: any) {
    const { searchText } = props.location.state
    if (this.state.searchText !== searchText) {
      this.doSearch(searchText)
    }
  }

  async componentDidMount() {
    const { searchText }: any = this.props.location.state
    if (this.state.searchText !== searchText) {
      this.doSearch(searchText)
    }

    const cartLocal = localStorage.getItem('@cart')
    const cart = cartLocal ? JSON.parse(cartLocal) : null
    if (cart) {
      const updatedCartLength: any = cart.products?.length || null
      this.setState({ cartLengthData: { cartLength: updatedCartLength, prevLength: updatedCartLength } })
    }
    
    this.getCart()

    EventEmitter.subscribe('UpdateCartCount', ({ cartUpdateAction }: any) => {
      this.setState((prevState: any) => {
        let newCartLength = prevState.cartLengthData.cartLength
        if(cartUpdateAction === 'add') newCartLength = newCartLength + 1
        if(cartUpdateAction === 'subtract') newCartLength = newCartLength - 1
        
        const didAdd = newCartLength > prevState.cartLengthData.cartLength
        if (didAdd) {
          this.setState({ animateAtc: 'badge-anim-in' })
          setTimeout(() => { this.setState({ animateAtc: 'badge-anim-out'}) }, 1);
        }
        return {
          cartLengthData: {
            cartLength: newCartLength,
            prevLength: prevState.cartLengthData.cartLength,
          }
        }
      })
    })
  }

  renderProducts = (products: any) => {
    return products && products.length
      ? products.map((product: any) => (
        <GlobalComponents.ProductCard
          key={`product-slider-${product._id}`}
          settings={this.props.settings}
          product={product}
          cartModel={this.props.cartModel}
          onGoToCart={this.goToCart}
          history={this.props.history}
        />
      ))
      : null
  }

  onSearch = (query: any) => {
    this.doSearch(query)
  }

  doSearch = async (query: any) => {
    const { categoryId }: any = this.props.location.state
    const { categories }: any = this.props

    this.setState({
      searchText: query,
      loading: true,
    })

    termSearched(query, 'home')
    
    const productsCategory = await categories.searchProduct(query, categoryId)

    viewSearchResultsEvent(query, productsCategory, 'home')

    this.setState({
      productsCategory,
      loading: false,
    })
    this.props.history.push('/results/'+query, {
      searchText: query,
      categoryId: categoryId,
      time: Date.now()
    })
  }

  getCart = async () => {
    const cartLength: any = await getCartLength()
    this.setState({ cartLengthData: { cartLength, prevLength: cartLength } })
  }

  notProduct = () => (
    <GuruWhiteScreen 
      text="¡Ups! Parece que no hay productos relacionados a tu búsqueda"
    />
  )

  goBack = (history: any) => history.push('/home')

  render() {
    const { history } = this.props
    const { loading, productsCategory, animateAtc, cartLengthData } = this.state
    
    return (
      <IonPage className="plp-page" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className='relative-header-wrapper'>
          {cartLengthData.cartLength > 0 && <IonBadge className={`cart-badge-btn-search ${animateAtc}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{cartLengthData.cartLength}</IonBadge>}
          <ToolBar
            title={`Resultados de "${this.state.searchText}"`}
            showSearchBar={true}
            searchBar={(
            <GlobalComponents.SearchBar 
            isBackgroundTransparent={true} 
            searchText={this.state.searchText} 
            onSearch={this.onSearch} 
            routeHome={true}
            {...this.props} 
            />)}
            secondaryHeader={true}
            primaryButtons={[
              {
                icon: cartToolbarIcon,
                key: 'cart',
                onClick: this.goToCart,
                badge: cartLengthData.cartLength,
                animateAtc,
              },
            ]}
            secondaryButtons={[{ type: 'back', onClick: () => this.goBack(history) }]}
          />
        </div>
        <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {!loading &&
            productsCategory.length > 0 &&
            productsCategory.map((category: any, index: number) => {
              return (
                <GlobalComponents.ProductSlider
                key={`category-slider-${category._id}`}
                products={category.products}
                placementName={`result-category-product-slider-${category.name}`}
                info={{
                  title: category.name,
                  id: category._id,
                  brandId: '',
                }}
                hidePrice= {false}
                onMoreInfoClick={() => this.onMoreInfoClick(category._id, category.name, true,
                    this.state.searchText, category)}
                onGoToCart={this.goToCart}
                {...this.props}
                categoryPosition={index + 1}
                fromSlider={false}
              ></GlobalComponents.ProductSlider>
              )
            })}
          {productsCategory.length <= 0 && !loading && this.notProduct()}
          {loading && <LoadingComponent/>}
        </IonContent>
      </IonPage>
    )
  }
}

export default withRouter(Result)
