import { Container } from 'unstated-typescript'
import { getNotifications, notificationRead, deleteNotification, unreadNotification } from '../clients/notifications'


export default class Notifications extends Container<Notification>{
  async getNotifications(userId:any){
    const showNotifications = getNotifications(userId)
    return showNotifications
  }

  async notificationRead(notificationId:any){
    const resultNotificationRead = notificationRead(notificationId)
    return resultNotificationRead
  }

  async deleteNotification(notificationId:any){
    const resulDeletetNotification = deleteNotification(notificationId)
    return resulDeletetNotification
  }

  async unreadNotification(userId:any){
    const resulUnreadNotification = unreadNotification(userId)
    return resulUnreadNotification 
  }
}