export const capitalizeFirstChar = (text: string) => {
  return text?.charAt(0)?.toUpperCase() + text?.slice(1);
}

export const createUniqueString = (prefix?: string) => {
  return `${prefix}_${Math.floor(100000 + Math.random() * 900000)}`
}

export const cleanText = (text: string) => { // Remove every accentuated character and set to lowercase
  return text.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
}

export const formatAddress = (...args: string[]) => {
  const strings = args.filter((text: string) => Boolean(text));
  return strings.join(", ");
}

export const limitText = (text: string, maxLength: number, limitCharacters: string) => {
  if (text.length < maxLength) return text
  return `${text.slice(0, maxLength + 1)}${limitCharacters}`
}