import moment from 'moment'

export const getDeliveryDay = (days: number[], cutHour: number, deliveryNotice: number): number => {
  const today = new Date().getDay()
  const expectedDay = (today + deliveryNotice + (new Date().getHours() > cutHour ? 1 : 0)) % 7
  const closestDayIndex = days.findIndex(d => expectedDay <= d)
  if (closestDayIndex !== -1) {
    return days[closestDayIndex]
  }
  return days[0]
}

export const getDayOfMoment = (m: moment.Moment): number => (m.weekday() + 1) & 7

export const getDeliveryDate = (date: Date | string, days: Set<number>, cutHour: number, daysOfNotice: number) => {
  const momentDate = moment(date)
  const orderEffectiveDate = momentDate.hour() >= cutHour ? momentDate.clone().add(1, 'day') : momentDate.clone()
  const expectedDay = orderEffectiveDate.clone().add(daysOfNotice, 'day')
  var d
  for (d = expectedDay; !days.has(getDayOfMoment(d)); d.add(1, 'day'));
  return d
}
