import React from 'react';
import helperGuru from '../../assets/icons/almacenera-guru.svg'
import './GuruHelper.scss';

interface GuruHelperProps {
  label?: string
  text: string
  marginTop?: string
  marginBottom?: string
}

const GuruHelper: React.FC<GuruHelperProps> = ({ label, text, marginTop, marginBottom }) => (
  <div className="container-guru-helper ds-content-page" style={{ marginTop, marginBottom }}>
    {label && <h3 className="label-helper-guru">{label}</h3>}
    <div className="helper-guru">
      <div className="container-img-helper-guru">
        <img src={helperGuru} alt="Almacenera Gurú"/>
      </div>
      <div className="container-text-helper-guru">
        {text}
      </div>
    </div>
  </div>
);

export default GuruHelper;