import { sendPerfomance } from "../clients/performance";

export interface PerformanceData {
  loadingTime: number, // en ms
  from: string, 
  speed?: number, // en kb/s 
  pageBefore? : string,
  requestType?: string,
}

export default class PerformanceTimer {
  private static instance: PerformanceTimer;

  private timer: number

  private constructor() {
    this.timer = 0;
  }

  public static getInstance = (): PerformanceTimer => {
    if (!PerformanceTimer.instance) {
      PerformanceTimer.instance = new PerformanceTimer();
    }
    return PerformanceTimer.instance;  
  }
  
  startPerformanceTimer = (): void => {
    this.timer = window.performance.now()
  }
  
  endPerformanceTimer = (from: string): void => {
    try {
      if (this.timer === 0) return;
  
      const end = window.performance.now()
      const result =  Math.floor(end - this.timer)
      this.restartTimer()

      const performanceData: PerformanceData = {
        loadingTime: result,
        from,
      }
      
      sendPerfomance(performanceData);

    } catch (error) {
      console.log(error)
    }
  }

  private restartTimer = () => {
    this.timer = 0
  }
}