import React from 'react'
import ErrorList from './ErrorList'
import GuruIcon from 'guru-react-icons'
import { eyeOutline, eyeOffOutline } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'
import './TextInput.scss'

interface TextInputProps {
  iconValid?: string
  iconInvalid?: string
  iconDefault?: string
  isValid?: boolean
  placeholder?: string
  label?: string
  error?: string
  onChange: any
  value: string
  toValidate?: string | number | undefined
  maxLength?: number
  type: string
  autoComplete?: string
  required?: boolean
  isEmptyValidStyle?: boolean
  informativeText?: string
  showEyeIcon?: boolean
  showPassword?: boolean
  handleShowPassword?: () => void
}

const inputValidation = (
  isValid: boolean | undefined,
  value: string | number | undefined,
  toValidate: string | number | undefined,
  isEmptyValidStyle?: boolean | undefined,
) => {
  const styleValidate = isEmptyValidStyle !== undefined ? isEmptyValidStyle : true
  const inputIsValid = value && isValid
  const inputIsEmpty = toValidate === ''

  if (!styleValidate && inputIsEmpty) return 'invalid'
  if (inputIsEmpty || inputIsValid) return 'valid'
  return 'invalid'
}

const inputIconValidation = (
  isValid: boolean | undefined,
  value: string | number | undefined,
  toValidate: string | number | undefined,
) => {
  if (toValidate === '') return ''
  if (value && isValid) return 'icn_check-blue'
  return 'icn_alert'
}

const validationsGenerator = (iconDefault: string, iconValid: string, iconInvalid: string) => {
  return [
    {
      isValid: (value: string | number | undefined, isValid: boolean) => value && !isValid,
      value: iconInvalid,
    },
    {
      isValid: (value: string | number | undefined, isValid: boolean) => value && isValid,
      value: iconValid,
    },
    {
      isValid: (value: string | number | undefined, isValid: boolean) => !value && !isValid,
      value: iconValid,
    },
    {
      isValid: (value: string | number | undefined, isValid: boolean) => !value && isValid,
      value: iconValid,
    },
  ]
}

const getIconValue = (
  toValidate: string | number | undefined,
  isValid: boolean | undefined,
  iconDefault: string | undefined,
  iconValid: string | undefined,
  iconInvalid: string | undefined,
) => {
  const validations = validationsGenerator(iconDefault || '', iconValid || '', iconInvalid || '')

  for (let i = 0; i < validations.length; i++) {
    if (validations[i].isValid(toValidate, isValid || false)) {
      return validations[i].value
    }
  }
}
const TextInput: React.FC<TextInputProps> = ({
  isValid,
  placeholder,
  label,
  error,
  onChange,
  iconValid,
  iconDefault,
  iconInvalid,
  value,
  toValidate,
  maxLength,
  type,
  autoComplete,
  required,
  isEmptyValidStyle,
  informativeText,
  showEyeIcon,
  handleShowPassword,
  showPassword,
}) => {
  const renderInfoText = () => {
    if (!informativeText) return ''

    return (
      <div className={`informative-text-container`}>
        <p>{informativeText}</p>
      </div>
    )
  }

  return (
    <div className="container-input">
      <div className="container-label-icon">
        {(iconDefault || iconInvalid || iconValid) && (
          <div className="icon-label">
            <GuruIcon nameIcon={getIconValue(toValidate, isValid, iconDefault, iconValid, iconInvalid)} />
          </div>
        )}
        <label className={`${inputValidation(isValid, value, toValidate, isEmptyValidStyle)}`}>{label}</label>
      </div>
      <div className="container-input-text">
        <input
          onChange={onChange}
          type={type}
          maxLength={maxLength || 100}
          value={value}
          className={`form-input-${inputValidation(isValid, value, toValidate, isEmptyValidStyle)}`}
          placeholder={placeholder}
          autoComplete={autoComplete || 'on'}
          required={required || false}
        />
        <div className="container-alert-icon">
          {showEyeIcon ? (
            <IonIcon onClick={handleShowPassword} icon={showPassword ? eyeOutline : eyeOffOutline} className="eye-icon" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
          ) : (
            <GuruIcon nameIcon={`${inputIconValidation(isValid, value, toValidate)}`} />
          )}
        </div>
      </div>
      {renderInfoText()}
      {inputValidation(isValid, value, toValidate, isEmptyValidStyle) === 'invalid' && error && (
        <ErrorList rule={error} />
      )}
    </div>
  )
}

export default TextInput
