import React, { useContext, useEffect } from 'react'
import AppContext from '../context/AppContext'

function useGetCountryInfo() {
  const { handlerCountryInfo } = useContext(AppContext)

  const defaultCountryInfo = {
    city: undefined,
    countryCode: 'CL',
    countryName: 'Chile'
  }

  useEffect(() => {
    if (!localStorage.getItem('countryName')) handlerCountryInfo({ city: '', countryCode: '', countryName: '' })

    let countryInfo = JSON.parse(localStorage.getItem('countryName')!)

    if(!countryInfo) {
      countryInfo = defaultCountryInfo
    }

    handlerCountryInfo({
      city: countryInfo?.city,
      countryCode: countryInfo?.countryCode,
      countryName: countryInfo?.countryName,
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <div>useGetCountryName</div>
}

export default useGetCountryInfo
