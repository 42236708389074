import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IonIcon } from '@ionic/react'
import { caretUpOutline, caretDownOutline } from 'ionicons/icons'
import './index.scss'
import { PaymentMethodCartData, ProviderCartData } from '../../../../interfaces/carts'
import LoadingSvg from '../../../../svg/LoadingSvg'
import PaymentMethodSection from '../payment_method_section'
// import IconoRayo from '../../../../assets/icons/rayo_icono.png'
import { formatCurrency } from '../../../../utils/intl'
import ProductItem from '../product_item'
import CouponsSection from '../coupons_section'
import useCalculateProviderCardTotalPrice from '../../../../hooks/carts/useCalculateProviderCardTotalPrice'
import useFindShippingCost from '../../../../hooks/carts/useFindShippingCost'
interface Props {
  provider: ProviderCartData
  paymentMethodData: PaymentMethodCartData
}

function ProviderCard({ provider, paymentMethodData }: Props) {
  const { paymentMethod } = paymentMethodData
  const { totalPrice } = useCalculateProviderCardTotalPrice(provider)
  const { shippingCost } = useFindShippingCost(provider._id, totalPrice)

  const [openCard, setOpenCard] = useState<boolean>(false)
  const [loadingProvider, setLoadingProvider] = useState<boolean>(false)

  const history = useHistory()

  const redirectToHomeProvider = () => {
    history.push(`/home-providers/${provider._id}`, {
      singleProviderId: provider._id,
      nameProvider: provider.alias,
      minumun: provider.minimum,
    })
  }

  return (
    <div className="provider-card-content">
      {/* Accordion */}
      <li
        className={`provider-card-item ${
          provider.minimum && totalPrice < provider.minimum && 'provider-card-item--error'
        }`}
      >
        <label>
          <span>
            {/* Icono del rayo (Fulfillment) */}
            {/* <i className="rayo-icon">
              <img src={IconoRayo} alt="Rayo" />
            </i> */}
            {provider.name}{' '}
            {loadingProvider && (
              <i className="loading-icon">
                <LoadingSvg color="#1E3799" width={21} height={21} background="#fff" />
              </i>
            )}
          </span>
          <span onClick={() => setOpenCard(!openCard)}>
            <IonIcon icon={openCard ? caretUpOutline : caretDownOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
          </span>
        </label>

        <div className={`provider-card-item__accordion provider-card-item__accordion--${openCard ? 'up' : 'down'}`}>
          {/* Despacho gratis (Fulfillment) */}
          {/* <div className="provider-card-item__accordion__despacho-gratis">
            <p>
              <span>Despacho gratis</span> por compras sobre $39.990
            </p>
          </div> */}

          {/* Renderizan los productos */}
          {provider.products.map((product) => (
            <ProductItem
              key={product._id}
              product={product}
              paymentMethod={paymentMethod}
              setLoadingProvider={setLoadingProvider}
              providerId={provider._id}
            />
          ))}

          {/* Sección de los cupones */}
          <CouponsSection provider={provider} />

          {/* Sección para cambiar el método de pago */}
          <PaymentMethodSection providerPaymentMethod={paymentMethod} provider={provider} />
        </div>
      </li>

      {/* Mínimo de entrega */}
      {provider.minimum !== null && provider.minimum !== undefined && totalPrice < provider.minimum && (
        <div
          className={`provider-card-content__minimo-entrega ${
            provider.minimum && totalPrice < provider.minimum && 'provider-card-content__minimo-entrega--error'
          }`}
        >
          <p>Mínimo para entrega {formatCurrency(provider.minimum)}</p>
          <p onClick={redirectToHomeProvider}>Completar</p>
        </div>
      )}

      {/* Costo de despacho */}
      {shippingCost !== null && shippingCost !== undefined && (
        <div
          className={`provider-card-content__costo-despacho ${
            provider.minimum && totalPrice < provider.minimum && 'provider-card-content__costo-despacho--error'
          }`}
        >
          <p>Costo de despacho</p>
          <p>{shippingCost === 0 ? '¡Gratis!' : formatCurrency(shippingCost)}</p>
        </div>
      )}

      {/* Precio total de la card */}
      <div
        className={`provider-card-content__total ${
          provider.minimum && totalPrice < provider.minimum && 'provider-card-content__total--error'
        }`}
      >
        <span>Total</span>
        <span>{formatCurrency(totalPrice)}</span>
      </div>
    </div>
  )
}

export default ProviderCard
