import React from 'react'
import GuruIcon from 'guru-react-icons'

interface ErrorListProps {
  rule?: string;
}

const ErrorList: React.FC<ErrorListProps> = ({rule}) => {
  return (
    <div className='error-message-container'>
      <div className="icon-error">
        <GuruIcon nameIcon="act_info-red"/>
      </div>
      <p>{rule}</p>
    </div>
  )
}

export default ErrorList
