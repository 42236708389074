import React from 'react'
import './LoadingCover.scss'
import loadingGif from '../../assets/gifs/loading.gif'

interface IProps {
  isLoading: boolean
}

export default class LoadingCover extends React.PureComponent<IProps> {

  renderLoading = () => {

    const { isLoading } = this.props

    if (isLoading) {
      return (
        <div className="loading-container">
          <img src={loadingGif} alt='loading-gif' className='loading-gif' />

          <style>
            {`
              .footer-menu-home {
                display: none !important;
              }
            `}
          </style>
        </div>
      )
    } else {
      return (
        <></>
      )
    }
  }

  render() {
    return this.renderLoading()
  }
}