import { IonIcon, IonModal } from '@ionic/react'
import { arrowBackOutline } from 'ionicons/icons'
import React from 'react'
import { useHistory } from 'react-router-dom'
import './index.scss'

function PaymentMethod() {
  const history = useHistory()

  return (
    <IonModal isOpen={true} id="payment-method-modal">
      <div className="wrapper">
        <div>
          <div className="header">
            <IonIcon icon={arrowBackOutline} onClick={() => history.push('/checkout')} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
            <span>Método de pago</span>
            <span></span>
          </div>

          {/* {loading ? (
            <LoadingComponent height="60vh" />
          ) : (
            <div className="providers-content">
              {suggestedData.map((data) => (
                <ProviderSection key={data.providerId} data={data} paymentMethod={paymentMethod} />
              ))}
            </div>
          )} */}
          <div className="payment-method-modal-content">
            <div className="title">
              <span>Elige tu método de pago</span>
              <span>$22.920</span>
            </div>

            <div className="metodo-pago">
              <span>Webpack</span>
              <input type="checkbox" name="" id="" />
            </div>

            <div className="metodo-pago">
              <span>Agrega tu tarjeta con OneClick</span>
              <input type="checkbox" name="" id="" />
            </div>

            <div className="metodo-pago">
              <span>Transferencia Bancaria</span>
              <input type="checkbox" name="" id="checkbox" />
            </div>
          </div>
        </div>

        <div className="button">
          <button>Confirmar</button>
        </div>
      </div>
    </IonModal>
  )
}

export default PaymentMethod
