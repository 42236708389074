import React, { Fragment } from 'react'
import SliderGrade from './SliderGrade'
import StarRating from './StarRating'
import Textarea from './Textarea'
import './SurveyNps.scss'

interface Props {
  questions:any[]
  onChange: Function
  isComplete: any
}

const FindSurveyComponents: React.FC<Props> = props => {
  const { questions, onChange, isComplete } = props
  const components: any = {
    'radio-star-rating': StarRating,
    'radio-slider-grade': SliderGrade,
    'textarea-default': Textarea,
  }

  const mappingComponents = () => {
    return (
      questions.map((question:any, index:number) => {
        if(index === questions.length -1)  {
          isComplete(true) 
        }
        const SurveyComponent = components[`${question.type}-${question.style}`]
        if (SurveyComponent) return <SurveyComponent key={index} info={question} onChange={onChange}/>
        return ''
      })
    )
  }

  return (
    <Fragment>
      {questions && mappingComponents()}
    </Fragment>
  )
}

export default FindSurveyComponents
