import React, { useState, useEffect } from 'react'
import { IonPage} from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { StaticContext } from 'react-router'
import track from 'react-tracking'
import BottomSheet from 'bottom-sheet-react'
import './ShippingGuru.scss'
import moment from 'moment'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
//COMPONENTS


interface IPathParams {}

interface PastRouteinfo {}

interface RouteState {}

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  showGuruShipping: boolean
  closeGuruShipping: any
  arrProvidersShipping: any
  getArrShippingGuru: any
  arSelectedProviderDate: any
  providersWithShippingGuru: number
}

const ShippingGuru: React.FC<IProps> = props => {
  const { showGuruShipping, closeGuruShipping, arrProvidersShipping, getArrShippingGuru, arSelectedProviderDate, providersWithShippingGuru } = props

  const [arrProviderSelected, setArrProviderSelected] = useState<any>([])

  useEffect(() => {
    arSelectedProviderDate.length && setArrProviderSelected(arSelectedProviderDate) 
  }, [] )

  const clickDay = (id:any, date: any, day:any) =>{
    setArrProviderSelected((prevArr:any) => {
      let newPhotosArray = []
      const existProvider = arrProviderSelected.some((provider:any) => provider._id === id && provider.date === date )
      
      if(!existProvider) {
        const providerIdDistinct = prevArr.filter((provider: any) => provider._id !== id)
        newPhotosArray.push(...providerIdDistinct, {'_id':id ,'date':date, "day":day })
      } 
      else {
        const providerIdDistinct = prevArr.filter((provider: any) => provider._id !== id)
        const sameProviderDistinctDate = prevArr.filter((disti: any) => disti._id === id && disti.date !== date)
        const arr = providerIdDistinct.concat(sameProviderDistinctDate)
        newPhotosArray = arr
      }
        return newPhotosArray
      })
  }

  return (
    <IonPage className="" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <BottomSheet
            isExpandable={showGuruShipping}
            customHeight={200}
            onClose={() => closeGuruShipping()}
            >
            <div className="bottom-sheet">
              <div className="btn-close" onClick={() => {
                  closeGuruShipping()
                 }}></div>
              <div className="title-text">Elige el día</div>
              <div className="sheet-text paragraph-2">
               ¿Cuándo te gustaría recibir tu pedido?
              </div>
              <div className='time-Shipping'>
                <div className='time-title'>Horario de despacho</div>
                <div  className='time'>10:00 a 18:00</div>
              </div>
              <div className='container-provider'>
                {arrProvidersShipping.length &&
                  arrProvidersShipping.map((prodiver:any) => {

                    return(
                      <div className='provider' key={prodiver.name}>
                          <div className='title-provider' >{prodiver.name}</div>
                          <div className='container-day'>
                            {prodiver?.nextDays.slice(0,5).map((day:any) => {
                              const opacity = day.active ? '' : '0.5'
                              const existProviderAndDate = arrProviderSelected.some((exist:any) => exist._id === prodiver.providerId && exist.date === day.date)
                              const colorClick = existProviderAndDate && '#1E3799' 
                              const colorDay = existProviderAndDate && '#ffff' 
                           
                              return(
                                <div
                                key={day.date}
                                onClick={() => day.active && clickDay(prodiver.providerId, day.date, day.day)}
                                className="day-provider" 
                                style={{
                                  opacity: opacity, 
                                  backgroundColor: colorClick, 
                                  color: colorDay
                                  }}>
                                  <div>{day.day.slice(0,3)}</div>
                                  <div>{moment(day.date).format('DD')}</div>
                                </div>
                              )
                            })}
                          </div>
                      </div>
                    )}
                  )
                }
              </div>
              <div className='container-button'>
                <ButtonComponent 
                className={`btn-primary`}
                onClick={() => {
                  getArrShippingGuru(arrProviderSelected)
                  closeGuruShipping()
                  }
                }
                text= {'Confirmar'}
                disabled={arrProviderSelected.length != providersWithShippingGuru}
                 />
              </div>
              <div className='info-footer'>Las fechas podrían ser modificadas según la necesidad del operador logístico, pero tranquilo <strong>SIEMPRE</strong> te informaremos antes.</div>
            </div>
          </BottomSheet>
    </IonPage>
  )
}


export default track({ page: 'ShippingGuru' })(withRouter(ShippingGuru))

