import Axios from 'axios'
import { HELPQUESTIONS } from '../mocks/help-questions/help-questions'
import { Incident } from '../models/Incident'
import { currentApp } from '../utils/currentCountry'
export interface OrderProduct {
  sku: string
  price: number
  quantity: number
  units: number
  packageType: string
  infoPrice: any
}

export interface Order {
  _id?: string
  providerId: string
  officeId: string
  products: OrderProduct[]
  contact: any
  orderNumber?: number
  totalAmount?: number
  cartId?: string
  paymentMethodsType?: string
}

export const oneClick = async (): Promise<any> => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/oneclick`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const paymentOrder = async (payment: any): Promise<any> => {
  try {
    const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_NODE}/v2/payments`, payment)
    return result
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const addCard = async (callback:string): Promise<any> => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/oneclick/init`,{
      params: {
        callback:callback
      },
    })
    return result
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const enrolamiento = async (): Promise<any> => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/bancoEstado/iniciarEnrolamientoCliente`)
    return result
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const reversarBancoEstado = async (buyOrder: any): Promise<any> => {
  try {
    const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_NODE}/v1/bancoEstado/reversarPago`, buyOrder)
    return result
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const getEnrolamiento = async (): Promise<any> => {
  try {
    const result = await Axios.get<any>(
      `${process.env.REACT_APP_BFF_NODE}/v1/bancoEstado/obtenerEstadoEnrolamientoCliente`,
    )
    return result
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const getEnrolado = async (): Promise<any> => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/bancoEstado`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const postOrder = async (order: Order): Promise<any> => {
  try {
    const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_NODE}/v1/orders`, order)
    return result
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const find = async (skip: any, limit: any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/orders`, {
      params: {
        filter: JSON.stringify({
          skip,
          limit,
        }),
      },
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const findByStatus = async (page: any, limit: any, states: string) => {
  const params: any = { states }
  if (page) params.page = page
  if (limit) params.limit = limit

  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v3/orders`, {
      params,
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const findOne = async (id: any, withImages: boolean = false) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/orders/${id}`, { params: { withImages }})
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const count = async () => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/orders/count`)
    return result.data.count
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const orderDelete = async (idOrder:string,value?:string, question?:any) =>{
  try {
    const result = await Axios.put<any>(`${process.env.REACT_APP_BFF_NODE}/v1/orders/anulateOrder/${idOrder}`, {
      comment: value,
      questions: question,
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}



export const deletePaymentCard = async (id:string,type:string) => {
  try {
    const result = await Axios.delete<any>(`${process.env.REACT_APP_BFF_NODE}/v1/paymentcards/remove/${id}`,{
      params:{
        type:type
      }
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const questions = async () => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/questions/findAnulateQuestions`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const paymentmethods = async (totalCard?:number, ipromos?:boolean, vendorIds?:any ) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/paymentmethods`,
    {
      params: {
        ipromos:ipromos,
        price:totalCard,
        vendorIds: vendorIds
      },
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const repeatProduct = async (orderNumber:string) =>{
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/orders/${orderNumber}/products`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const getOrderConfirmUser = async () => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/orders/deliverable`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const orderConfirmUser = async (idOrder: string) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/orders/${idOrder}/confirm`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const orderNotConfirmUser = async (idOrder: string) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/orders/${idOrder}/undelivered`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const orderUpdateNotConfirmUser = async (idOrder: string) => {
  try {
    const result = await Axios.put<any>(`${process.env.REACT_APP_BFF_NODE}/v1/orders/${idOrder}`,{
      isHomeDeliverableDone: true
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const checkConfirmedOrders = async () => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/orders/aredeliverable`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}


export const sendEmailNoConfirm = async (user:any, order:any, emailInternal:any) => {
  const countryName = localStorage.getItem('countryName') 
	const country = countryName ? JSON.parse(countryName) :  ''
  
  const prefixNumber:any =   currentApp().find(route => country.countryCode === route.country) 

  const body = 
      `<table  style="text-align: left">
        <tr><td>Nombre: ${user.name}</td>
        <tr><td>Rut: ${user.username}</td>
        <tr><td>Teléfono: ${prefixNumber.prefixNumber} ${user.phone}</td>
        <tr><td>Email: ${user.email}</td>
        <tr><td>Número de orden: ${order.orderNumber}</td>
        <tr><td>Proveedor: ${order?.provider?.alias || order?.providerId?.alias}</td>
      </table>` 
  try {
    const result = await Axios.post<object>(`${process.env.REACT_APP_BFF_NODE}/v1/email/sendFormEmail`, {
      "to":  [`${emailInternal}`],
      "subject":"[Orden no entregada] - Pedido no entregado",
      "body": body
    })
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const repeatOrder = async (idOrder:any) => {
  try {
    const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_NODE}/v1/orders/${idOrder}/repeat`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const findHelpOptions = async (orderStatus: string) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/incidents`, { params: { state: orderStatus } })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

  export const createIncident = async (incidentData: Incident) => {
    try {
      const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_NODE}/v1/incidents/users`, incidentData)
      return result.data
    } catch (error) {
      if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    }
  }
  

  export const guethShippingGuruProvider = async (providers: string) => {
    try {
      const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/dispatchtimes/cuttime?idsProviders=${providers}`)
      return result.data
    } catch (error) {
      if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    }
  }




