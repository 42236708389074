import React, { useState, useEffect, useRef } from 'react'
import {
  IonSlides,
  IonSlide,
  IonButton
} from '@ionic/react'
import { Plugins } from '@capacitor/core'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { sendMetric } from '../../pages/firebase/firebaseTags'
import ButtonComponent from '../basic-button/ButtonComponent'

import './Onboarding.scss'

const { Device } = Plugins

interface IPathParams {}

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  loginShowCases?:any
  onboarding: any
  nextPagePath: string
  onclickNextPagePath: (type:any) => void
  onClickInOnboarding?: any
  from: string
}

const slideOpts = {
  initialSlide: 0,
  speed: 400,
}

const Onboarding: React.FC<IProps> = props => {
  const [sliderIndex, setSliderIndex] = useState(0)
  const [deviceInfo, setDeviceInfo] = useState('')
  const [sliderOnboarding, setSliderOnboarding] = useState<any>([])
  const [loginShowCases, ] = useState<any>(props.loginShowCases?.sectionsVisibility)
  const mySlides = useRef<any>(null);

  useEffect(() => {
    get()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const get = async () => {
    sendMetric('view_onboarding')
    props.onboarding && setSliderOnboarding(props.onboarding)
  }

  useEffect(() => {
    getDeviceInfo()
  }, [deviceInfo])

  const getDeviceInfo = async () => {
    const platform = (await Device.getInfo()).platform
    setDeviceInfo(platform)
  }


  const onSliderChange = async () => {
    const swiper = await mySlides?.current?.getSwiper();
    setSliderIndex(swiper.activeIndex)
  }

  const onIconClick = async () => {
    const swiper = await mySlides?.current?.getSwiper();
    swiper.slideNext();
  }

  const onIconClickSkip = () => {
    const { nextPagePath, onclickNextPagePath } = props
    sendMetric('close_onboarding')

    if(nextPagePath === 'login' && onclickNextPagePath) onclickNextPagePath(false)
    if(nextPagePath === 'profile') props.history.push('/home')
  }

  const mapOnboardingImages = (onboarding:any) => {
    return (
      onboarding.map((item:any) =>{
        return(
          <IonSlide className="git-slide" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <div>
              <img className="img-slide-onboarding" src={item.url} alt="Imagen del producto"/> 
              <div className="text-info">{item.text}</div>
            </div>
          </IonSlide>
        )
      }))
  }
  
  const skipOnboarding = () => {
    sendMetric('close_onboarding')
    props.onclickNextPagePath && props.onclickNextPagePath(false)
  }
  
  const nameBtn = () => sliderIndex !== sliderOnboarding.length - 1 ? "Continuar" : "Comenzar" 

  const functionBtnClick = () => sliderIndex !== sliderOnboarding.length - 1 ? onIconClick() : onIconClickSkip()

  const classNameBtn = () => sliderIndex !== sliderOnboarding.length - 1 ? "btn-next" : "btn-skip" 

  return (
    <div className="page-omboarding">
      <div className={`container-skip-page ${(deviceInfo === 'ios') ? 'this-is-ios' : ''}`}>
        <ButtonComponent 
          className="btn-tertiary btn-skip-page" 
          onClick={skipOnboarding}
          text="Saltar"
        />
      </div>
      <div className="container-slides">
        <IonSlides 
          className="slides-git"
          pager={true}
          options={slideOpts}
          onIonSlideTransitionStart={() => onSliderChange()}
          ref={mySlides} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
            {props.onboarding && mapOnboardingImages(props.onboarding)}
          </IonSlides>
      </div>
      <div className="container-btn">
          { props.from === 'profile' &&
            <IonButton  
            expand="block"
            className={classNameBtn()}
            onClick={functionBtnClick} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
              {nameBtn()}
            </IonButton>
          }
      </div>
      <style>
        {`
          .footer-menu-home {
            display: none !important;
          }
        `}
      </style>
    </div>
  )
}

export default withRouter(Onboarding)
