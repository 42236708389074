import { Container } from 'unstated-typescript'
import { getFaqs } from '../clients/faqs'

interface FaqsState {
  faq:any
}
export default class faqsModel extends Container<FaqsState>{
  async getFaqs(){
    const showFaqs =  getFaqs()
    return showFaqs
  }
}