import { IonModal } from '@ionic/react';
import React from 'react';
import guruAccept from '../../assets/icons/almacenera-bienvenidos.svg'
import ButtonComponent from '../basic-button/ButtonComponent';

import './ModalActionAccept.scss';

interface ModalActionAccept {
  label: string
  textButton: string
  onClickButton: () => void
  className: string
  disabled: boolean
  isOpen: boolean
  secondLabel?: string
  imgToShow?:string
}

const GuruAcceptModal: React.FC<ModalActionAccept> = ({label, textButton, className, disabled, onClickButton, isOpen, secondLabel, imgToShow }) => (
  <IonModal cssClass="modal-action-accept" isOpen={isOpen}>
    <div className="modal-content-wrapper">
      <div className="container-modal-action-accept">
        <div className="container-img-action-accept">
          <img src={imgToShow || guruAccept} className={`img-action-accept ${imgToShow ? 'img-props-action-accept' : ''}`} alt="Almacenera Gurú"/>
        </div>
        <div className="container-label-action-accept">
          <h4 className="label-accept-guru">{label}</h4>
          {secondLabel && <h4 className="label-accept-guru">{secondLabel}</h4>}
        </div>
        <div className={`container-btn-modal-action-accept ${imgToShow ? 'btn-action-accept' : ''}`}>
          <ButtonComponent
            className={className}
            text={textButton}
            onClick={onClickButton}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  </IonModal>
)

export default GuruAcceptModal;