import { ProviderCartData } from '../../../../interfaces/carts'
import { apiBffMobileV2Carts } from './api'

export interface UpdateCartPaymentMethodParameters {
  cartId: string
  providerId: string
  paymentForm: string
  provider: ProviderCartData
  providerPaymentMethod: string
}

export const updateCartPaymentMethod = async (
  cartId: string,
  providerId: string,
  paymentForm: string,
): Promise<void> => {
  const body = { paymentForm }
  await apiBffMobileV2Carts.put(`/${cartId}/providers/${providerId}`, body)
}
