import { getMinutesDifference } from "./dates";
import { getLocalStorageObject, setLocalStorageObject } from "./localstorageData"

type ITimers = {
  [timerkey: string]: Date,
}

export const callWithTimer = (timerKey: string, timeLimitMins: number, callback: Function) => {
  try {
    const timers: ITimers = getLocalStorageObject('timers') || {};
    const calledTimerDate = timers[timerKey] && new Date(timers[timerKey]);
    const now = new Date()

    if (!calledTimerDate || (getMinutesDifference(now, calledTimerDate) >= timeLimitMins)) {
      timers[timerKey] = now;
      callback();
    }
    setLocalStorageObject('timers', timers)

  } catch (error) {
    console.error(error)
    callback();
  }  
}

export const deleteTimer = (timerKey: string) => {
  const timers: ITimers = getLocalStorageObject('timers') || {};
  delete timers[timerKey]
  setLocalStorageObject('timers', timers)
}