import React from 'react';
import './TitleWithText.scss';

interface TitleWithTextProps {
  title: string
  text: string
}

const TitleWithText: React.FC<TitleWithTextProps> = ({title, text}) => (
  <div>
    <h5 className="profile-subtitle">{title}</h5>
    <p className="paragraph-2 profile-info-text">{text}</p>
  </div>
);

export default TitleWithText;