import { Container } from 'unstated-typescript'
import { produce } from 'immer'
import {
  find,
  count,
  search,
  getById,
  withProducts,
  ProductIn,
  CategoryWithProductsIn,
  brand,
  providersBrand,
  offersProviders,
  filterByBrandsCategories,
  categoriesProvider,
  categoriesProductProvider,
  brandProvider,
  pagesProviderCategory,
  providerOffers,
  tagsCatregory,
  findCategoriesAllProviders,
  providerTopCarousel,
  brandProvidersSlider,
  categoryTreeOfVendor,
  getProducts,
  vendorsWithOffers,
  categoriesWithOffers
} from '../clients/category'
import { getFromCache } from '../utils/cache'
import { Product, PackageType } from './ProductModel'
import placeholderImage from '../assets/icons/placeholder-image.svg'
import { ifExistAndMayorToZero } from '../utils/arrays'

export interface CategoryWithProducts {
  _id: string
  active: boolean
  main: boolean
  name: string
  order: number
  tags: string []
  products?: Product[]
}

interface CategoryState {
  categories: any[]
  products: any[]
  count: number
  brand: any[]
}

const storageCategoriesName = '@categories'
const storageCategoriesProductName = '@categories/products'

const mapPackageType = (packageType: string): PackageType => {
  if (
    packageType === 'box' ||
    packageType === 'pack' ||
    packageType === 'piece' ||
    packageType === 'bag' ||
    packageType === 'sbox' ||
    packageType === 'xbox'
  ) {
    return packageType
  }
  return 'box'
}

export const mapProductToModel = (productIn: ProductIn): Product => ({
  ...productIn,
  id: productIn._id,
  brand: productIn.brand,
  packageType: mapPackageType(productIn.package),
  uom: productIn.UOM.includes('unit') ? 'unit' : 'kg',
  showPrice: productIn.showPrice,
  filename: (productIn.imageFullPath) || placeholderImage,
  vendorActiveInComune: productIn.vendorActiveInComune,
  infoPrice: productIn.infoPrice,
  quantityMin: productIn.quantityMin,
  quantityMax: productIn.quantityMax,
  categoryId: productIn.categoryId,
  categoryTree: productIn.categoryTree,
})

const mapCategoryToModel = ({ products, ...categoryProps }: CategoryWithProductsIn): CategoryWithProducts => ({
  ...categoryProps,
  products:
    products == null
      ? undefined
      : products.map(mapProductToModel).sort((p1, p2) => {
          if (p1.discount != null && p2.discount == null) return -1
          if (p1.discount == null && p2.discount != null) return 1
          return 0
        }),
})
export default class Category extends Container<CategoryState> {
  state: CategoryState = {
    categories: [],
    products: [],
    count: 0,
    brand: [],
  }

  constructor() {
    super()
    const categories = getFromCache<any>(storageCategoriesName)
    const products = getFromCache<any>(storageCategoriesProductName)
    if (categories) {
      this.setState(
        produce(this.state, draft => {
          draft.categories = categories
          draft.products = products
          draft.brand = categories
        }),
      )
    }
  }

  async get(page: any, limit: any) {
    let categories = getFromCache<any>(storageCategoriesName)
    if (!categories || categories.length <= 0) {
      categories = await this.updateCategory(page, limit)
    } else {
      this.updateCategory(page, limit)
    }
    return categories as any[]
  }

  async updateCategory(page: any, limit: any) {
    let categoriesUpdate = await find(limit * page, limit)
    await this.setState(
      produce(this.state, draft => {
        draft.categories = categoriesUpdate as any[]
      }),
    )
    localStorage.setItem(storageCategoriesName, JSON.stringify(categoriesUpdate))
    return categoriesUpdate
  }

  async products(limit: any, filter: any, providerId: any = null, brandId: any = null) {
    const products = (await withProducts(limit, filter, providerId, brandId)).map(mapCategoryToModel)
    await this.setState(
      produce(this.state, draft => {
        draft.products = products as any[]
      }),
    )
    localStorage.setItem(storageCategoriesProductName, JSON.stringify(products))
    return products
  }

  async getProductsById(id: any, brandId: any = null) {
    const products = (await getById(id, brandId)).map(mapCategoryToModel)
    return products
  }

  async searchProduct(query: any, categoryId?: any) {
    const products = (await search(query, categoryId)).map(mapCategoryToModel)
    return products
  }

  async count() {
    const countResult = await count()
    await this.setState(
      produce(this.state, draft => {
        draft.count = countResult as number
      }),
    )
    return countResult
  }

  async brand() {
    const resultBrand = await brand()
    return resultBrand
  }

  async providersBrand(idProviders: any) {
    const resultprovidersBrand = await providersBrand(idProviders)
    return resultprovidersBrand
  }

  async offersProviders(idProviders: any) {
    const resultOffersProviders = await offersProviders(idProviders)
    const data = resultOffersProviders?.products
      .map((r: any) => {
        return {
          ...r,
          filename: (r.imageFullPath) || placeholderImage,
          packageType: mapPackageType(r.package),
          uom: r.UOM.includes('unit') ? 'unit' : 'kg',
          id: r._id,
        }
      })
      .filter((r: any) => r.discount > 0)
    return data
  }

  async providerTopCarousel  (providerId: any, page?:number) {
  
    const product = await providerTopCarousel(providerId, page)
    const data = ifExistAndMayorToZero(product.products) &&
    product.products.map((r: any) => {
      return {
        ...r,
        filename: (r.imageFullPath) || placeholderImage,
        packageType: mapPackageType(r.package),
        uom: r.UOM.includes('unit') ? 'unit' : 'kg',
        id: r._id,
      }
    })
    return data
  }

  async filterByBrandsCategories(idProviders: any) {
    const result = await filterByBrandsCategories(idProviders)
    const data = result.map((r: any) => {
      return {
        ...r,
        products: r.products.map((products: any) => {
          return {
            ...products,
            filename:
              (products.filename && `${process.env.REACT_APP_BFF_IMAGE}${products.filename}`) || placeholderImage,
          }
        }),
      }
    })
    return data
  }

  async categoriesPorvider(idProviders: any, typeCategory: any) {
    const resultprovidersCategories = await categoriesProvider(idProviders, typeCategory)
    return resultprovidersCategories
  }

  async productsByCategories(idCategories: string, providerId: any, page: number) {
    const resultProduct = await categoriesProductProvider(idCategories, providerId, page)
    const data = resultProduct?.products?.map((r: any) => {
      return {
       ...r,
        filename: (r.imageFullPath ) || placeholderImage,
        packageType: mapPackageType(r.package),
        uom: r.UOM.includes('unit') ? 'unit' : 'kg',
        id: r._id,
      }
    })
    const arr = { ...resultProduct, products: data }
    return arr
  }

  async brandProvider(idProviders: any) {
    const result = await brandProvider(idProviders)
    return result
  }

  async pagesProviderCategory(idProviders: any) {
    const result = await pagesProviderCategory(idProviders)
    return result
  }

  async providerOffers(categoryId: string, singleProviderId: string, userName: string) {
    const result = await providerOffers(categoryId, singleProviderId, userName)
    const data = result?.products?.map((r: any) => {
      return {
        ...r,
        filename: (r.imageFullPath) || placeholderImage,
        packageType: mapPackageType(r.package),
        uom: r.UOM.includes('unit') ? 'unit' : 'kg',
        id: r._id,
      }
    })
    return data
  }

  async tagsCatregory(nameTags: string, page: number, providerId: string) {
    const result = await tagsCatregory(nameTags, page, providerId)
    const data = result?.products?.map((r: any) => {
      return {
        ...r,
        filename: (r.imageFullPath) || placeholderImage,
        packageType: mapPackageType(r.package),
        uom: r.UOM.includes('unit') ? 'unit' : 'kg',
        id: r._id,
      }
    })
    const arr = { ...result, products: data }

    return arr
  }

  findCategoriesAllProviders = async (idCategory: string) => await findCategoriesAllProviders(idCategory)

  brandProvidersSlider = async (vendorId: any) => await brandProvidersSlider(vendorId) 

  categoryTreeOfVendor = async (brandId:string, vendorId:string) => await categoryTreeOfVendor(brandId,vendorId)

  getProducts = async (page:number, vendorId?:string, brandId?:string, categoryId?:string, onlyOffers:boolean = false) => await getProducts(page, vendorId, brandId, categoryId, onlyOffers)

  vendorsWithOffers = async (categoryId?:string) => await vendorsWithOffers(categoryId || '')

  categoriesWithOffers = async () => await categoriesWithOffers()
}
