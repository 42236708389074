type Grouping<T> = {
  [key: string]: T[]
}

const emptyGroup = <T extends {}>(): Grouping<T> => ({})

export const groupBy = <T extends {}>(arr: T[], selector: (item: T) => string | number): Grouping<T> =>
  arr.reduce((grouping, item) => {
    const key = selector(item)
    const newGroup = grouping[key] ? [...grouping[key], item] : [item]
    return {
      ...grouping,
      [key]: newGroup,
    }
  }, emptyGroup<T>())


export const ifExistAndMayorToZero = (array: any) => array && array.length > 0 