import { newValidatePhone } from "./basetypes"
import { getLocalStorageObject } from "./localstorageData"

export type territoryType = "region" | "province" | "commune";
export type supportedCountry = "CL" | "PE" | "MX" | null

export const countryCl = 'CL'
export const countryPe = 'PE'
export const countryMx = 'MX'
export const defaultDni = 'DNI'

export const supportedCountries = [countryCl, countryPe, countryMx]
export const countriesWithProvinces = [countryPe, countryMx];
export const countriesWithPostalCode = [countryMx];

export const territorialNames = {
  [countryCl]: { region: "Región", province: "", commune: "Comuna" },
  [countryPe]: { region: "Departamento", province: "Provincia", commune: "Distrito" },
  [countryMx]: { region: "Estado", province: "Delegación", commune: "Colonia" },
}

export const territorialSelectTexts = {
  [countryCl]: { region: "Elige la Región", province: "", commune: "Elige la Comuna" },
  [countryPe]: { region: "Elige el Departamento", province: "Elige la Provincia", commune: "Elige el Distrito" },
  [countryMx]: { region: "Elige el Estado", province: "Elige la Delegación", commune: "Elige la Colonia" },
}

export const territorialSelectEmptyTexts = {
  [countryCl]: { region: "Elige la Región", province: "", commune: "Selecciona la región primero" },
  [countryPe]: { region: "Elige el Departamento", province: "Selecciona el departamento primero", commune: "Selecciona la provincia primero" },
  [countryMx]: { region: "Elige el Estado", province: "Selecciona el estado primero", commune: "Selecciona la delegación primero" },
}

export const phoneAsUsernameCountries = [countryMx]

export const countriesWithHunters = [countryCl, countryPe, countryMx]

export const dniByCountry = () => {
  const countryData = getLocalStorageObject('countryName')

  if (!countryData) return defaultDni
  
  const supportedDnis: any = {
    [countryCl]: "RUT",
    [countryPe]: "RUC",
    [countryMx]: "teléfono",
  }
  
  return supportedDnis[countryData?.countryCode] || defaultDni
}

export const phonePlaceholderByCountry = () => {
  const countryData = getLocalStorageObject('countryName')


  const placeholders: any = {
    [countryCl]: "9 1234 5678",
    [countryPe]: "912 345 678",
    [countryMx]: "55 74608989",
  }

  return placeholders[countryData?.countryCode] || ""
}

export const phoneLengthByCountry = () => {
  const countryData = getLocalStorageObject('countryName')

  const lengthsByCountry: any = {
    [countryCl]: 9,
    [countryPe]: 9,
    [countryMx]: 10,
  }

  return lengthsByCountry[countryData?.countryCode] || 9
}

export const isPhoneValidByCountry = (phone: string) => {

  const countryPhoneLength = phoneLengthByCountry()
  return !!newValidatePhone(phone, countryPhoneLength)
}

export const getCountryCode = () => {
  const country = getLocalStorageObject('countryName') || ''
  return country?.countryCode
}

export const commentPlaceholderByCountry = () => {
  const countryCode = getCountryCode();
  const placeholders: any = {
    [countryCl]: "Preferiblemente llegar en horas de la mañana",
    [countryPe]: "Quisiera boleta o factura. La referencia de mi dirección es...",
    [countryMx]: "Mi tienda abre a las 9 am, y está frente a la casa azul",
  }

  return placeholders[countryCode] || "Quisiera boleta o factura. La referencia de mi dirección es...";
}

export const commercialNameByCountry = () => {
  const countryCode = getCountryCode();
  const names: any = {
    [countryCl]: "Nombre comercial",
    [countryPe]: "Nombre comercial",
    [countryMx]: "Nombre del negocio",
  }

  return names[countryCode] || "Nombre comercial";
}

export const countryTerritoryName = (territoryType: territoryType, countryCode: supportedCountry) => {
  if (!countryCode) return ""
  const countryTerritorialNames = territorialNames[countryCode]
  return countryTerritorialNames[territoryType]
}

export const countryTerritorySelectText = (territoryType: territoryType, countryCode: supportedCountry) => {
  if (!countryCode) return ""
  const countryTerritorialSelectText = territorialSelectTexts[countryCode]
  return countryTerritorialSelectText[territoryType]
}

export const getUserCountryCode = (): string => getLocalStorageObject("countryName")?.countryCode;
