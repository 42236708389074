import { Container } from 'unstated-typescript'
import { getFromCache } from '../utils/cache'

import { getFullProductsFromVendorHashtag, getHashtags, getHashtag, getVendorsFromHashtag } from '../clients/hashtag'
import { Product } from './ProductModel'

export interface VendorHashtagProduct {
  UOM: string
  active: boolean
  brand: string
  brandId: string
  category: string
  categoryId: string
  categoryTree: any[]
  createdAt: string
  description: string
  discount: number
  externalId: string
  filename: string
  hashtag: string[]
  id: string
  imageFullPath: string
  isPromotion: boolean
  isThirdPartyImageUrl: boolean
  ladder: any[]
  maximumQuantity: number
  medias: any[]
  minimumQuantity: number
  name: string
  offerPrice: number
  order: number
  outOfStock: boolean
  outstanding: boolean
  outstandingSort: number
  package: string
  price: number
  priceCalculatorMethod: string
  pricing: any[]
  productActive: string
  productLadder: string
  productPhoto: string
  promotionalDynamicDetail: any[]
  promotionalDynamicType: string
  providerId: string
  providerName: string
  quantityMax: number
  quantityMin: number
  showPrice: {
    price: number
    offerPrice: number 
  }
  sku: string
  subcategory: string
  tags: string[]
  type: string
  units: number
  updatedAt: string
  walmartSteps: any[]
  __v?: number
  _id?: string
}

export interface VendorHashtagPaginate<T> {
  docs: T[]
  hasNextPage: boolean
  hasPrevPage: boolean
  limit: number
  nextPage: any
  offset: number
  page: number
  pagingCounter: number
  prevPage: any
  totalDocs: number
  totalPages: number
}

export interface ProductHashtag {
  active: boolean
  countryId: string
  createdAt: string
  endDate: string
  endDateStr: string
  name: string
  startDate: string
  startDateStr: string
  type: string
  updatedAt: string
  __v?: any
  _id?: string
  image?: string
  tag?: string
  weight?: number
}

export interface Hashtag {
  id: string
  hashtags: ProductHashtag[]
}

const storageHashtagName = '@hashtags'

export class HashtagModel extends Container<Hashtag> {
  state: Hashtag = {
    id: '',
    hashtags: [],
  }

  constructor() {
    super()

    const hashtagCache = getFromCache<Hashtag>(storageHashtagName)
    if (hashtagCache) {
      this.setState(hashtagCache)
    }
  }

  getId = () => this.state.id

  getHashtags = () => this.state.hashtags

  getHashtagsClientApi = async () => {
    let _hashtags = (await getHashtags()).filter((hashtag: any) => hashtag.active)

    _hashtags = _hashtags.map((_r: any) => {
      _r.tag = this.makeTag(_r.name)
      _r.weight = 1
      return _r
    })

    this.setState({
      hashtags: _hashtags
    })
    localStorage.setItem(storageHashtagName, JSON.stringify(this.state))
    return _hashtags
  }

  getHashtag = async (hashtagId: string) => {
    const hashtag = await getHashtag(hashtagId)
    if(!hashtag){
      return {
        tag: '#',
        weight: 1
      }
    }
    hashtag.tag = this.makeTag(hashtag.name)
    hashtag.weight = 1

    return hashtag
  }

  getVendors = async (hashtagId: string) => await getVendorsFromHashtag(hashtagId)

  getProductsByVendors = async (hashtagId: string, providerId: string): Promise<any> => await getFullProductsFromVendorHashtag(hashtagId, providerId)

  makeTag = (name: string) => {
    // TODO: Temporary patch to fix undefined names...
    if(!name){
      return '#'
    }
    const words = name?.split(' ')
    const tag = []
    for (const word of words) {
      tag.push(`${word.charAt(0).toUpperCase()}${word.substring(1)}`)
    }
    return `#${tag.join('')}`
  }
}
