import React from 'react'
import track from 'react-tracking'

import ladder_icon from './../../assets/icons/promo_scale.svg'
import desc_monto from './../../assets/icons/promo_cost.svg'
import desc_percent from './../../assets/icons/promo_percent.svg'
import PromotionImage from '../product-promotion-image/PromotionImage'

import './PromotionIcon.scss'
interface PastRouteinfo {
}

interface IProps {
  product: any
  selectedQuantity?: any
  iconHeight: string
  textSize?:string
}

interface IState {
}

class PromotionIcon extends React.Component<IProps, IState> {
  state: IState = {
  }

  renderIcon = () => {
    const { product, iconHeight } = this.props
    const { promotionalDynamicType, promotionalDynamicDetail, price, offerPrice, ladder } = product
    const style = { height: iconHeight}

    if((!promotionalDynamicType || !promotionalDynamicDetail) && ladder?.length) {
      return <PromotionImage icon={ladder_icon} style={style} />
    }

    const typesImages: any = {
      ladder: ladder_icon,
      discountRate: desc_percent,
      directOfferPrice: desc_monto,
    }

    switch (promotionalDynamicType) {
      case 'ladder':
      case 'discountRate':
      case 'directOfferPrice':
        if(price !== offerPrice) return <PromotionImage icon={typesImages[promotionalDynamicType]} style={style} />
        return ''
      case 'xByI':
        return this.getXbYIPromotionIcon(promotionalDynamicDetail)
      default:
        return ''
    }
  }

  getXbYIPromotionIcon = (promotionalDynamicDetail: any) => {
    const { textSize } = this.props
    const xByIRules = promotionalDynamicDetail.find(({name}:any) => name === "xByI")?.rules
    if(!xByIRules || xByIRules.length === 0) return ''

    const selectedQuantity = this.props.selectedQuantity || 0
    const sortedRules = xByIRules?.sort((a:any, b:any) => b.quantity - a.quantity)
    const actualRule = sortedRules?.find((rule: any) => rule.quantity <= selectedQuantity) || sortedRules ? sortedRules[sortedRules.length - 1] : null
    const style = { fontSize: textSize }

    if(actualRule) {
      return (
        <div className="promotion-icon-box">
          <span className="promotion-info-text" style={style}>
            {actualRule.quantity}x{actualRule.quantity - actualRule.discount}
          </span>
        </div>
      ) 
    }
    return ''
  }

  render() {
    return this.renderIcon()
  }
}

export default track({})(PromotionIcon)
