import React from 'react'
import { countryCl, countryPe, countryMx } from '../../utils/countriesTexts'
import FieldRutChile from './FieldRutChile'
import FieldRucPeru from './FieldRucPeru'
import FieldRfcMex from './FieldRfcMex'

interface IPathParams {}

interface Props {
  countryCode: string
  properties: any
}

interface State {}

export default class DniField extends React.PureComponent<Props, State> {
  state: State = {}

  renderDniField = () => {
    const { countryCode, properties } = this.props

    const fieldsByCountry: any = {
      [countryCl]: <FieldRutChile {...properties} />,
      [countryPe]: <FieldRucPeru  {...properties} />,
      [countryMx]: <FieldRfcMex {...properties} />,
    }

    return fieldsByCountry[countryCode] || ""
  }
  
  render() {
    return this.renderDniField()
  }
}


