type BannerMapping = {
  [key: string]: string | undefined
}

export const bannerMapping: BannerMapping = {
  'Cervecería Chile / ABInBev': 'ABInBev.png',
  "Watt's": "Watt's.png",
  Colun: 'colun.png',
  Ballerina: 'BALLERINA.png',
  'Viña Santa Rita': 'Santa_rita.png',
  Algramo: 'ALGRAMO.png',
  'San Pablo Alimentos': 'San_pablo.png',
  'Trendy Helados': undefined,
  BioV: 'BioV.png',
  'Distribuidora DAOS': 'DAOS.png',
  'Trendy Snack & Confites': 'Trendy.png',
  'Alma Brands': 'Alma_brands.png',
  Agrosuper: 'AgrosuperNew.png',
  'Quesos Don Jaime': 'Don_Jaime.png',
  BUKA: 'BUKA.png',
  Mizos: undefined,
  'Las Mellizas': undefined,
  'Don Carlos Guru': undefined,
  'The NotCo': undefined,
  Pulmahue: undefined,
  'Pulmahue - La Selecta': undefined,
}
