import { getNotifications } from '../../clients/notifications'

export const refreshNotifications = async (userId: any) => {
  try {
    let notifications = await getNotifications(userId)
    if(!notifications) return 0
    notifications = notifications.docs

    const unreadNotifications = notifications.filter((notif: any) => !notif?.isOpened)
    const unreadNotifLength = unreadNotifications.length
    const currentNotifications = localStorage.getItem('currentNotifications')
    const hasOpenedNotifications = localStorage.getItem('hasOpenedNotifications')
    const lastNotificationStore = localStorage.getItem('lastNotification')
    const hasOpenedNotifValue = hasOpenedNotifications === 'true' ? true : false
    const lastUnreadNotif = unreadNotifications[0]?._id

    let stateNotifications = unreadNotifLength

    let currentNotifNew = String(unreadNotifLength)
    let hasOpenedNotifNew = 'false'
    let lastNotificationId = String(lastUnreadNotif)

    const numStoredNotifications = Number(currentNotifications)
    const isFirstTime = !currentNotifications || !hasOpenedNotifications
    const isNewNotification = numStoredNotifications < unreadNotifLength
    const isSameLastNotification = lastNotificationId === lastNotificationStore

    if(!isFirstTime && !isNewNotification && isSameLastNotification && hasOpenedNotifValue) {
      stateNotifications = 0
      hasOpenedNotifNew = 'true'
    }

    localStorage.setItem('currentNotifications', currentNotifNew)
    localStorage.setItem('hasOpenedNotifications', hasOpenedNotifNew)
    localStorage.setItem('lastNotification', lastNotificationId)
    
    return stateNotifications
  } catch (err) {
    console.error(err)
  }
}