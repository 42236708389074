export function isValidUrl(url: string) {
  let testedUrl;
  
  try {
    testedUrl = new URL(url);
  } catch (err) {
    return false;
  }
  
  return testedUrl.protocol === "http:" || testedUrl.protocol === "https:";
}