import React, { useState, useEffect } from 'react';

import './DropdownSelect.scss'

interface DropdownProps {
  onSelect?: any
  options?: any[]
  label?: string
  firstOption?: string
  isDisabled: boolean
  defaultSelected?: string
}

const DropdownSelect: React.FC<DropdownProps> = ({onSelect, options, label, firstOption, isDisabled, defaultSelected}) => {
  const [selectedVal, setSelectedVal] = useState<string>("")
  const [isDefaultReady, setIsDefaultReady] = useState<boolean>(false)

  useEffect(() => {
    if (defaultSelected && !isDefaultReady) {
      setSelectedVal(defaultSelected)
      setIsDefaultReady(true)
      onSelect(defaultSelected)
    }
  }, [defaultSelected])

  const handleSelect = (value: string) => {
    setSelectedVal(value)
    onSelect(value)
  }

  const validateIsOneOption = () => {
    return options?.length === 0 || (options?.length === 1 && Boolean(defaultSelected))
  }

  const isOneOption = validateIsOneOption()

  return (
    <div className="container-dropdown-select">
      <div className={`container-label ${isDisabled ? 'disabled' : ''}`}>
        <label>{label}</label>
      </div>
      <div className="select-container">
        <select
          style={{ pointerEvents: isOneOption ? 'none' : 'auto' }}
          name="select" 
          disabled={isDisabled} 
          onChange={(e) => handleSelect(e.target.value) }
          value={selectedVal}
          className={isOneOption ? '': 'select-arrow'}
        >
          <option value="">{firstOption}</option>

          {options?.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default DropdownSelect;