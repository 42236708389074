import Axios from 'axios'


export const counterEvent = async (group: string, tag: string, idUser: any, bannerId: any, action: any) => {
  try {

    const result = await Axios.put<object>(`${process.env.REACT_APP_BFF_NODE}/v1/events/${idUser}`, {
      group: group,
      tag: tag,
      action,
      bannerId: bannerId
    })

    return result.data

  } catch (error) {
    console.error(error)
    throw error
  }
}