import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import track from 'react-tracking'
import { StaticContext, Redirect } from 'react-router'

import { IonPage, IonBadge, IonIcon, IonContent } from '@ionic/react'

import User from '../../models/User'
import Category from '../../models/Category'
import Settings from '../../models/Settings'
import { ProductModel, Product } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'

import GlobalComponents from '../../components'
import ToolBar from '../../components/tool-bar/ToolBar'
import CategorySecondary from '../provider-category-sencondary/CategorySecondary'
import cartToolbarIcon from '../../assets/icons/nav_cart_blue.svg'
import searchIcon from '../../assets/icons/act_search.svg'
import { PromotionModel } from '../../models/PromotionModel'
import Office from '../../models/Office'
import { ProviderModel } from '../../models/ProviderModel'
import { ifExistAndMayorToZero } from '../../utils/arrays'
import { validateLocationState } from '../../utils/locationStateValidations'
import WordsSlider from '../../components/words-slider/WordsSlider'
import LoadingComponent from '../../components/loading-component/LoadingComponent'
import { getCartLength, getLengthOfCart } from '../../utils/cart'

import { EventEmitter } from '../../utils/events'
import backSmallArrow from '../../assets/icons/nav_left.svg'
import './ProviderCategory.scss'
import { slice } from 'lodash'
import { navigateToCart } from '../../utils/navigation'
interface IPathParams {
  id: string
  brandId: string
}

interface RouteState {
  subcategoryId?: string;
  singleProvider?: string
  title?: string
  goBack?: any
  categoryId?: any
  tree?: any[]
  category?: any
  from?: string
  nameProvider:string
  arrow?: boolean
  singleProviderId?: string
  minumun?: number
  banners?: any
}

type IProps = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  categories: Category
  productModel: ProductModel
  tracking?: any
  cartModel: CartModel
  promotion: PromotionModel
  offices: Office
  providerModel: ProviderModel
}

interface State {
  category: any
  products: any
  page: number
  subcategoriesMapped: any[]
  isLoadingView: boolean
  productsInfo: any
  shouldRender?: any
  cartLengthData: any
  animateAtc: any
} 

const varsToCheck = ['categoryId', 'id', 'singleProvider', 'title']

class ProviderCategory extends React.PureComponent<IProps, State> {
  state: State = {
    category: [],
    products: [],
    page: 1,
    subcategoriesMapped: [],
    isLoadingView: false,
    productsInfo: {},
    shouldRender: validateLocationState(this.props.history, varsToCheck),
    cartLengthData: { cartLength: 0, prevLength: 0},
    animateAtc: '',
  }

  async componentDidMount() {
    const cartLocal = localStorage.getItem('@cart')
    const cart = cartLocal ? JSON.parse(cartLocal) : null
    if (cart) {
      const updatedCartLength: any = cart.products?.length || null
      this.setState({ cartLengthData: { cartLength: updatedCartLength, prevLength: updatedCartLength } })
    }
    
    this.getCart()

    EventEmitter.subscribe('UpdateCartCount', ({ cartUpdateAction }: any) => {
      this.setState((prevState: any) => {
        let newCartLength = prevState.cartLengthData.cartLength
        if(cartUpdateAction === 'add') newCartLength = newCartLength + 1
        if(cartUpdateAction === 'subtract') newCartLength = newCartLength - 1
        
        const didAdd = newCartLength > prevState.cartLengthData.cartLength
        if (didAdd) {
          this.setState({ animateAtc: 'badge-anim-in' })
          setTimeout(() => { this.setState({ animateAtc: 'badge-anim-out'}) }, 1);
        }
        return {
          cartLengthData: {
            cartLength: newCartLength,
            prevLength: prevState.cartLengthData.cartLength,
          }
        }
      })
    })

    if(this.state.shouldRender) await this.loadProviderCategory()
  }

  componentWillUnmount() {
    EventEmitter.unsubscribe('UpdateCartCount')
  }

  getCart = async () => {
    const cartLength: any = await getCartLength()
    this.setState({ cartLengthData: { cartLength, prevLength: cartLength } })
  }

  async componentDidUpdate(prevProps: any) {
    if (this.props.location.state !== prevProps.location.state) {
      await this.loadProviderCategory()
    }
  }

  async loadProviderCategory() {
    const { singleProvider, categoryId, category } = this.getPageValuesFromUrl()
    
    this.setState({
      isLoadingView: true,
      category: category || {},
      subcategoriesMapped: [],
      products: [],
      productsInfo: {}
    })
    const result = await this.props.categories.pagesProviderCategory(singleProvider) 
    let categoryFound = category || result.response.find((categoryFound:any) => categoryFound._id === categoryId)
    this.getInnerMostCategory((categoryFound || result.response[0]), categoryId)
  }

  getInnerMostCategory(category: any, categoryId: string) {
    if(categoryId !== category._id) {
      category.subcategories.forEach((subObj: any) => {
        if (subObj._id === categoryId) {
          this.setState({ category: subObj }, this.getProductsForCategories)
        }
        this.getInnerMostCategory(subObj, categoryId)
      })
    } else {
      this.setState({category}, this.getProductsForCategories)
    }
  }

  goToCart = () => {
    navigateToCart(this.props.history, this.props.location.state || this.props.history.location.state);
  }

  goToSubCategoryDetail = () => {
    this.props.history.push('/')
  }

  filterSelect = () => {  
    const { category } = this.state
    return (
      <WordsSlider words={category.subcategories} subcategoryId={this.props.location.state.subcategoryId}/>
    )
  }

  mapProductsToSubcategories = async () => {
    const { products, category } = this.state    
    let subcategoriesMapped: any = []
    if(category.hasOwnProperty('_id')) {
      const mainSubcategoryId = category._id
      subcategoriesMapped = category.subcategories.map((subcategory: any) => {
        
        let productsMapped: any = []
        let tree: any = []
        if(ifExistAndMayorToZero(products)) {
          productsMapped = products.filter((product:any ) => {
            const mainCategoryIndex = product.categoryTree.findIndex((treeElement: any) => mainSubcategoryId === treeElement.id) 
            return product.categoryTree[mainCategoryIndex-1].id === subcategory._id
          })
          tree = productsMapped[0]?.categoryTree
        }
        if(ifExistAndMayorToZero(productsMapped)) {

          const finalSubcategory = { ...subcategory, products: productsMapped, tree } 
          return finalSubcategory
        }

        return subcategory
      })
    }
    subcategoriesMapped = subcategoriesMapped?.filter((subcategory: any) => subcategory !== undefined)
    this.setState({ subcategoriesMapped, isLoadingView: false })
  }
  
  assignProductByCategoriesResponse = (productsResponse: any) => { 
    return (
      {
        limit: productsResponse.limit,
        page: productsResponse.page,
        totalPages: productsResponse.totalPages,
        totalProducts: productsResponse.totalProducts,
        isLoading: false
      }
    )
  }

  productCategory = async (subcategory: any) => {
    const { categoryId, singleProvider } = this.getPageValuesFromUrl()
    const { page } = this.state
    let productsResponse: any = {}
    const idCategory = subcategory?._id || categoryId
    productsResponse = await this.props.categories.productsByCategories(idCategory, singleProvider, page)
    this.setState(prevState => ({
      products: [...prevState.products, ...productsResponse.products],
      productsInfo: {
        ...prevState.productsInfo,
        [subcategory._id]: this.assignProductByCategoriesResponse(productsResponse)
      }
      
    }), this.mapProductsToSubcategories)
  }

  getProductsForCategories = async () => {
    const { category } = this.state    
    
    for (let index = 0; index < category.subcategories.length; index++) {
      const subcategory = category.subcategories[index]
      this.productCategory(subcategory)
    }
  }

  seeMoreProducts = async (subcategory: any) => {
    const { products, productsInfo } = this.state
    const { singleProvider } = this.getPageValuesFromUrl()

    let count: number = productsInfo[subcategory._id].page
    if (count < productsInfo[subcategory._id].totalPages) {
      count++
      this.setState(prevState => ({
        productsInfo: {
          [subcategory._id]: {
            isLoading: true,
            page: count,
            ...prevState.productsInfo[subcategory._id]
          },
          ...prevState.productsInfo
        },
      }))

      const productsResponse = await this.props.categories.productsByCategories(subcategory._id, singleProvider, count)
      const ids = new Set(products.map((product:any) => product._id))
      const nonRepeatingProducts = [...products, ...productsResponse.products.filter((product: any) => !ids.has(product._id))]

      this.setState(prevState => ({
          products: nonRepeatingProducts,
          productsInfo: {
            ...prevState.productsInfo,
            [subcategory._id]: this.assignProductByCategoriesResponse(productsResponse)
          }
        }),
        this.mapProductsToSubcategories
      )
    }
  }

  seeLessProducts = async (subcategory:any) => {
    const { products, productsInfo} = this.state

    let subcategoryCopy: any = {...productsInfo[subcategory._id], page: 1}
    let currentCategoryProducts = products
      .filter((product: any) => product.categoryTree
      .some((category:any) => category.id === subcategory._id))
    let slicedProductsIds = currentCategoryProducts
      .slice(0, subcategoryCopy.limit)
      .map((product:any) => product._id)
    let productsIdsToRemove = currentCategoryProducts
      .filter((subcategory:any) => !slicedProductsIds.includes(subcategory._id))
      .map((product:any) => product._id)
    let productsCopy = [...products
      .filter((product:any) =>  !productsIdsToRemove
      .includes(product._id))]

    this.setState(prevState => ({
      products: productsCopy,
      productsInfo: {
        ...prevState.productsInfo,
        [subcategory._id]: subcategoryCopy
      }
    }),
    this.mapProductsToSubcategories
  )}

  mapCategoriesView = (productsCard: any) => {
    const { category, subcategoriesMapped, productsInfo } = this.state
    const { location } = this.props
    const { singleProvider } = this.getPageValuesFromUrl()

    return (
      <React.Fragment key={category._id}>
        {
          ifExistAndMayorToZero(category.subcategories) &&
          <div key={category._id} >
            {
              subcategoriesMapped?.length > 0 &&
              <CategorySecondary 
                subcategories={subcategoriesMapped}
                singleProvider={singleProvider}
                loadMoreProductsForCategory={(subcategory) => this.seeMoreProducts(subcategory)}
                seeLessProductsForCategory={(subcategory:any) => this.seeLessProducts(subcategory)}
                productsInfo={productsInfo}
                nameProvider={location.state.nameProvider || ''}
                arrowCategory={location.state.arrow}
                {...this.props}
              />
            }
          </div>
        }
        {
          !ifExistAndMayorToZero(category.subcategories) &&
          <div key={category._id} className="prueba">
            <div className="product-list-1">
              {productsCard}
            </div>
          </div>
        }
      </React.Fragment>
    )
  }

  getPageValuesFromUrl = () => {
    const pathArray: any[] = this.props.match.url.split('/')     
    return this.props.location?.state || { singleProvider: pathArray[2], categoryId: pathArray[4], category: null }
  } 

  goBack = () => {
    const { history, location } = this.props

    location.state.arrow &&  history.push('/categories')

    if(location?.state?.from === 'deeplink'){
      this.goToHomeProvider()
    } else {
      !location.state.arrow && history.goBack()
    }
  }

  goToHomeProvider = () => {
    const { history, location } = this.props
    const providerId = location?.state?.singleProvider || '';
    const providers = this.props?.providerModel?.state?.providers || []
    const findProvider = providers[providerId]
    const activeVendors = Object.values(providers).filter((vendor: any) => vendor.isActiveInComune)
    
    if (activeVendors.length === 1) {
      history.replace('/home')
      return
    }

    if(findProvider) {
      history.push(`/home-providers/${findProvider.id}`, {
        singleProviderId: findProvider.id,
        nameProvider: findProvider.alias,
        minumun: findProvider.minimun,
        banners: findProvider.banners,
      })
      return
    }

    history.replace('/home')
  }

  goBackCategory = () => this.props.history.goBack()

  openSearch = () => { 
    const { singleProvider } = this.getPageValuesFromUrl()   

    this.props.history.push(`/vendor/${singleProvider}/search`)

  }

  getToolbarTitle = (): string => {
    const { location } = this.props
    const providerId = location?.state?.singleProvider || '';
    const findProvider = this.props?.providerModel?.state?.providers[providerId].name;
    return location.state.nameProvider || findProvider || ''
  }

  render() {
    const { location, cartModel, history, settings } = this.props
    const { products, isLoadingView, category, shouldRender, cartLengthData, animateAtc } = this.state
    if(!shouldRender) return <Redirect to="/home"/>
    // location is undefined when route is opened by deeplinks so whe have to validate these fields
    let productsCard: any = null
    products.sort((a:any,b:any) => a.outOfStock === b.outOfStock ? 0 : a.outOfStock ? 1 : -1)

    productsCard =
      ifExistAndMayorToZero(products)
      ?
        products.map((product: Product, index: number) => (
          <GlobalComponents.ProductCard
            key={`${product.sku}${index}`}
            settings={settings}
            product={product}
            cartModel={cartModel}
            onGoToCart={this.goToCart}
            history={history}
          />
        ))
      : null

    return (
      <IonPage className="page-category-provider" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className='relative-header-wrapper'>
          {cartLengthData.cartLength > 0 && <IonBadge className={`cart-badge-btn ${animateAtc}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{cartLengthData.cartLength}</IonBadge> }
          <ToolBar
            title={this.getToolbarTitle()}
            secondaryButtons={[{ type: 'back', onClick: this.goBack }]}
            secondaryHeader={true}
            boxShadow={true}
            primaryButtons={[
              {
                icon: searchIcon,
                key: 'search',
                onClick: this.openSearch,
              },
              {
                icon: cartToolbarIcon,
                key: 'cart',
                onClick: this.goToCart,
                badge: cartLengthData.cartLength,
                animateAtc,
              },
            ]}
          />
        </div>

        {!isLoadingView && ifExistAndMayorToZero(category.subcategories) && 
          <div className="container-words-slider">
            {location.state.arrow && 
              <div className="icon-arrow" onClick={() => {this.goBackCategory()}}>
                <IonIcon slot="icon-only" src={backSmallArrow} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </div>
            }
            <WordsSlider words={[{_id:'12345', name:category.name},...category.subcategories]} subcategoryId={this.props.location.state.subcategoryId}/>
          </div>
        }
        
        <div className="container-category-provider">
          { !isLoadingView && 
            <>
              {this.mapCategoriesView(productsCard)}
              <div className="container-height"></div>
            </>
          }
          { isLoadingView && 
            <LoadingComponent />
          }
        </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default track({ page: 'Categoría' })(withRouter(ProviderCategory))
