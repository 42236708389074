import axios from 'axios'
import { REACT_APP_BFF_NODE } from '../../../../config'
import { getUserToken } from '../../../../utils/functions/getUserToken'

export const apiBffMobileV2Carts = axios.create({
  baseURL: `${REACT_APP_BFF_NODE}/v2/carts`,
  headers: {
    Authorization: `Bearer ${getUserToken()}`,
  },
})
