import { Container } from 'unstated-typescript'
import { getContactDetails } from '../clients/contactForm'

interface ContactFormState {
  contact:any
}

export default class contactFormModel extends Container<ContactFormState>{
  async getContactDetails(countryCode: string){
    const showContactDetails = getContactDetails(countryCode)
    return showContactDetails
  }
}