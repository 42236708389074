import React from 'react'
import {
  IonContent,
  IonPage,
  IonButton,
  IonSpinner
} from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import User from '../../models/User'
import track from 'react-tracking'

import { recoveryPaswordSmsEmail } from '../../clients/validateSms'
import arrowBack from './../../assets/icons/arrow-back-blue.svg'

import HeaderWithoutToolbar from '../../components/header-without-toolbar/HeaderWithoutToolbar'
import GuruHelper from '../../components/guru-helper/GuruHelper'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import ContactSupport from '../../components/contact-support/ContactSupport'
import './RecoveryPassword.scss'


type IPathParams = {
}

type Props = RouteComponentProps<IPathParams> & {
  user: User
}

interface State{
  country: any
  infoUser: any
  spinnerEmail: boolean
  spinnerPhone: boolean
}

class RecoveryPassword extends React.PureComponent<Props, State> {
  state: State = {
    country:'',
    infoUser: '',
    spinnerPhone: false,
    spinnerEmail: false
  }

  async componentDidMount(){
    const countryName = localStorage.getItem('countryName') 
    const country = countryName ? JSON.parse(countryName) :  null 
    this.setState({country:country, infoUser:this.props.history.location.state})
  }

  helpForm = () => {
    const { history, location }: any = this.props
    const rut = location.state?.infoUser?.rut
    history.push('/help-form', {
      text: 'Rellena el siguiente formulario con tus datos',
      rut
    })
  }
  
  sendCode = async (type:string) =>{
    const { infoUser } = this.state
    const data ={
      'type':type,
      'username':infoUser.infoUser.username
    }

    this.setState({
      spinnerPhone: type === 'phone' && true,
      spinnerEmail : type === 'email' && true,
    })

    const result = await recoveryPaswordSmsEmail(data)

    if(result && result.data && result.data.hashedCode){
      this.setState({spinnerPhone:false, spinnerEmail:false})
      this.props.history.push(`/confirm-code-sms`,{
        hashedCode:result.data.hashedCode,
        username:result.data.username,
        type: type
    })
    }
  }

  goToHelp = () => {
    const { history, location }: any = this.props
    const rut = location.state?.infoUser?.rut
    history.push("/contact", { rut })
  }

  render() {
    const { infoUser, spinnerPhone , spinnerEmail } = this.state
    const { history } = this.props

    return (
      <IonPage className="recovery-password" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonContent className="content-recovery-password" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <HeaderWithoutToolbar 
            icon={arrowBack}
            text="Recupera tu acceso"
            onClick={history.goBack}
          />
          <GuruHelper 
            label="Recibe el código"
            text="Ahora, elige dónde quieres recibir el código de reinicio."
          />
          <div className="container-get-code-btn">
            {infoUser?.infoUser?.phone &&
              <ButtonComponent 
                onClick={() => this.sendCode('phone')}
                text={infoUser.infoUser.phone}
                className={`btn-primary btn-first ${spinnerPhone ? 'loading' : ''} `}
              />
            } 
            {infoUser?.infoUser?.email &&
              <ButtonComponent 
                onClick={() => this.sendCode('email')}
                text={infoUser.infoUser.email}
                className={`btn-primary ${spinnerEmail ? 'loading' : ''} `}
              />
            }
          </div>
            <div className="container-subtitle">
              <div className="subtitle-validate-user">Recibirás un código de 4 dígitos</div>
            </div>

            <div className="container-subtitle-form">
              <div className="subtitle-validate-user">Si los medios actuales no funcionan, deja
                tus datos y nuestro equipo ayudará</div>
            </div>
            <div className="container-btn-form">
              <ButtonComponent 
                className="btn-secondary"
                onClick={this.helpForm}
                text="Ir a formulario"
                disabled={false}
              />
            </div>
            
        </IonContent>
        <div className="container-help" >
          <ContactSupport />
        </div>
      </IonPage>
    )
  }
}

export default track({ page: 'RecoveryPassword' })(withRouter(RecoveryPassword))