import React from 'react'
import {
    IonIcon
  } from '@ionic/react'
import { contactForms } from '../firebase/firebaseTags'
import arrowDown from '../../assets/icons/act_open.svg';
import arrowUp from '../../assets/icons/act_close.svg';

import './Faq.scss'
import GuruIcon from 'guru-react-icons';

const ContactSections = (props:any) => {
    const { contactForm, selectedSection, toggleContactSection } = props

    const cleanPhoneNumber = (phoneNumber:String) => {
        return phoneNumber.replace('+','').replaceAll(' ', '')
    }

    return (
        <div className="questions"> 
            <div className='header-questions'>
                <h3>
                    Contacto
                </h3>
            </div>
            {contactForm.sections && contactForm.sections.map((contactForm:any, i:number) => {
                return (
                    <div className='question-card' key={i}>
                        <div className='question-card-header' onClick={() => toggleContactSection(contactForm.name)}>
                            <h6 className='no-margin'>{contactForm.title}</h6>
                            <IonIcon className="icon" slot="end" icon={selectedSection && selectedSection === contactForm.name ? arrowUp : arrowDown} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
                        </div>
                        { selectedSection && selectedSection === contactForm.name &&
                            <div className='question-card-body'>
                                <div 
                                    className="question-card-header" onClick= {() => contactForms("help_contact", `${contactForm.name}` , "Gmail" )}>
                                    <p className='paragraph-2'><a href={`mailto:${contactForm.email}`}>{contactForm.email}</a></p>
                                    <GuruIcon nameIcon='icn_email-blue'/>  
                                </div>
                                <div className="question-card-header" onClick= {() => contactForms( "help_contact", `${contactForm.name}`, "Whatsapp" )} >
                                    <p className='paragraph-2'><a href={`https://wa.me/${cleanPhoneNumber(contactForm.cellphone)}`}>{contactForm.cellphone}</a></p>
                                    <GuruIcon nameIcon='act_whatsapp'/>  
                                </div>
                                <div className="question-card-header" onClick= {() => contactForms( "help_contact", `${contactForm.name}`, "Llamada" )}>
                                    <p className='paragraph-2'><a href={`tel:${contactForm.phone.replaceAll(' ', '')}`}>{contactForm.phone}</a></p>
                                    <GuruIcon nameIcon='icn_phone-blue'/>  
                                </div>
                            </div>
                        }
                    </div>
                )
            })}
        </div>
    )
}

export default ContactSections
