import React, { useEffect, useState  } from 'react'
import { IonIcon, IonButton, IonPage, IonContent } from '@ionic/react'

//COMPONENTS
import PhotosUser from './../photosUser/PhotosUser'

//ICONS
import backSmallArrow from '../../assets/icons/nav_left.svg'
import GuruIcon from 'guru-react-icons'

import GoogleMap from '../google-map/GoogleMap'
import { 
  getCoordinates, 
  getCoordinatesOfCurrentOffice, 
  uploadImage,
  deleteImage,
} from '../../clients/offices'
import TitleWithText from '../title-with-text/TitleWithText'
import AlertModal from '../../components/modals/AlertModal'
import './Office.scss'
import { getBusinessTypes } from '../../clients/register'

interface OfficeProps {
  office?: any
  closeOfficeUser?: any
  officeExtraData: any
  user: any
  history: any,
}

const OfficeUser: React.FC<OfficeProps> = props => {
  const { closeOfficeUser, office, officeExtraData } = props
  const [coordinates, setCoordinates] = useState<any>({})
  const [officeData, setOfficeData] = useState<any>({})
  const [country, setCountry] = useState<any>({})
  const [businessTypes, setBusinessTypes] = useState<any>([])
  const [openModifyOffice, setOpenModifyOffice] = useState<boolean>(false)
  const [photos, setPhotos] = useState<any>('')
  const [alertFromTooltip, setAlertFromTooltip] = useState<boolean>(false)

  useEffect(() => {
    getCountryBusinessTypes()
    getOfficesUser()
  }, [])

  const getOfficesUser = async () => {
    let result:any = {}
    const countryData = localStorage.getItem('countryName')
     
    if (countryData) {
      const parsedCountry = JSON.parse(countryData)
      setCountry(parsedCountry)
      if(!office) { 
        result = await getCoordinates('Argomedo 350', 'Santiago', 'Chile') || {}
      } else {
        result = await getCoordinatesOfCurrentOffice(office._id) || {}
        setOfficeData(office)
      }
    }

    setCoordinates(result)
  }

  const getCountryBusinessTypes = async () => {
    const allBusinessTypes = await getBusinessTypes(country.country_code)
  
    let parsedBusinessTypes: any[] = allBusinessTypes?.map((businessType:any) => ({ 
      value: businessType.name, 
      label: businessType.name, 
      id: businessType._id 
    }))

    setBusinessTypes(parsedBusinessTypes)
  }

  const findBusinessTypeById = (businessTypeId: string) => { 
    return businessTypes?.find(({id}: any) => id ===  businessTypeId)?.value
  }

  const onAcceptRequestChanges = () => { 
    props.history.push('/office-localization', { officeAction: "office-edit", editedOffice: office });
  }

  const photoUser = async (arrPhoto: any, image:any) => {
    const arr = arrPhoto.map((item:any) => `data:image/${image.format};base64,${item}` )
    setPhotos(arr)
    
    if(officeExtraData.action === "editOffice" && image){
      const img = {"image":`data:image/${image.format};base64,${image.base64String}`}
       const result:any = await uploadImage(img, office._id)

       if(Object.keys(result).length > 0){
          const currentOffice = {...officeData, "images": result.images}
          setOfficeData(currentOffice)
       }
    }
  }

  const deleteImagenSelected  = async (photoSelected:any) => {
    if(Object.keys(officeData).length > 0) {
      const result:any =  await deleteImage(officeData._id, photoSelected.photo)
      const currentOffice = {...officeData, "images": result.office.images}
      setOfficeData(currentOffice)
    }

    officeExtraData.action === 'addOffice' && setPhotos((prevPhotos: any[]) => {
      return prevPhotos.filter((photo: any, index: number) => index !== photoSelected?.index)
      })
  }

  const openAlertFromTooltip = (value:boolean) => setAlertFromTooltip(value)

  const onRequestChanges = () => setOpenModifyOffice(true)

  const onDidDismissAlert = () => setOpenModifyOffice(false)

  const getCountryCode = (): string => {
    const countryData = localStorage.getItem('countryName')
    if (countryData) {
      const parsedCountry = JSON.parse(countryData)
      return parsedCountry.countryCode;
    }
    return ''
  }

  return (
    <IonPage className="container-office-user ds-content-page" {...{} as any}>
        <IonContent className="container-office" {...{} as any}>
        <div className="container-office-user ds-content-page" {...{} as any}>
          <div className="container-header">
            <div className='container-title-action'>
              <div className="back-small-arrow" >
                <IonButton onClick={() => closeOfficeUser()} {...{} as any}>
                  <IonIcon src={backSmallArrow} {...{} as any} />
                </IonButton>
              </div>
              <div className="container-title">
                <h1> Cuenta</h1>
              </div>
            </div>
            <div>
              {(officeExtraData.action === "editOffice") &&
                <IonButton onClick={() => onRequestChanges()} className="send" 	mode ='ios' {...{} as any}>
                  Editar
                </IonButton>
              }
            </div>
          </div>
          {Object.keys(coordinates).length > 0 &&  
            <div className="container-google-map">
              <GoogleMap coordinates={{lat: coordinates.latitude, lng: coordinates.longitude}} zoom={18} />
            </div>
          }
          {officeExtraData.action === "editOffice" && office && 
            <div className="info-adress">
              {office?.businessName ? <TitleWithText title="Nombre comercial" text={office?.businessName} /> : ''}
              <TitleWithText title="Dirección" text={office.location.address} />
              <TitleWithText title={getCountryCode() === 'PE' ? 'Distrito' : "Comuna"} text={office.location.city} />
              {getCountryCode() === 'PE' && <TitleWithText title='Provincia' text={office?.comuneId?.province} />}
              <TitleWithText title={getCountryCode() === 'PE' ? 'Departamento' : "Región"} text={office?.comuneId?.department} />
              { businessTypes?.length > 0 && <TitleWithText title="Tipo de negocio" text={findBusinessTypeById(office.bussinesTypeId)} />}
            </div>
          }
          <AlertModal 
            label={'¡Espera!'}
            text={'Los cambios pueden afectar el acceso a proveedores. ¿Quieres continuar?'}
            buttonText={'Sí'}
            onDismiss={onDidDismissAlert}
            isOpen={openModifyOffice}
            buttonAction={onAcceptRequestChanges}
          />
          <div className="container-patent">
            <div className="container-patent-tittle">
              <h5>Documentos</h5>
              <div className="icon" onClick={() => openAlertFromTooltip(true)}><GuruIcon nameIcon="act_info"/></div>
            </div>
            <PhotosUser 
              arrPhoto={photoUser}
              officeData={officeData}
              deleteImagenSelected={deleteImagenSelected}
            />
          </div>
          {alertFromTooltip && 
            <AlertModal 
              label={'Ten en cuenta que...'}
              text={'Los documentos deben ser en formato jpg, jpeg y png. No aceptamos archivos en pdf.'}
              buttonText={'Ok'}
              onDismiss={() => openAlertFromTooltip(false)}
              isOpen={alertFromTooltip}
              buttonAction={() => openAlertFromTooltip(false)}
            />}
          <style>
            {`
              .footer-menu-home {
              display: none !important; 
              }
            `}
          </style>
        </div> 
      </IonContent>
    </IonPage >
  )
}

export default OfficeUser