import Axios from 'axios'
import { MapLocationCoordinates } from '../models/MapLocationCoordinates'
import { Loader } from '@googlemaps/js-api-loader'
import { OfficeRegistration } from './officeRegistration'

export const find = async () => {
  try {
    const time = new Date().getTime()
    const result = await Axios.get<object>(`${process.env.REACT_APP_BFF_NODE}/v2/offices?time=${time}`)

    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const bussinesTypePeru = async () => {
  try {
    const result = await Axios.get<object>(`${process.env.REACT_APP_BFF_PUBLIC}/v1/bussinesType`)
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const createOffice = async (
  nameOffice: string,
  bussinesTypeId: string,
  commercialName: string,
  address: string,
  city: string,
  token: string,
  department: string,
  province: string,
  postalCode?: string,
) => {
  /*********** DEPRECATED: use 'registerOffice' instead ***********/
  try {
    const result = await Axios.post<object>(
      `${process.env.REACT_APP_BFF_NODE}/v1/users/office`,
      {
        casaMatriz: true,
        name: nameOffice,
        businessName: commercialName,
        bussinesTypeId: bussinesTypeId,
        location: {
          address: address,
          city: city,
          deparment: department,
          province: province,
          postalCode: postalCode,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const registerOffice = async (office: OfficeRegistration, token: string) => {
  try {
    const result = await Axios.post<object>(`${process.env.REACT_APP_BFF_NODE}/v1/users/office`, office, {
      headers: { Authorization: `Bearer ${token}` },
    })
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const getCoordinates = async (address: string, city: string, country: string) => {
  try {
    const result = await Axios.get<object>(
      `${process.env.REACT_APP_BFF_NODE}/v1/googleMaps/calculateLatLong?address=${address}&city=${city}&country=${country}`,
    )
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw Error
  }
}

export const getCoordinatesOfCurrentOffice = async (officeId: string) => {
  try {
    const result = await Axios.get<object>(`${process.env.REACT_APP_BFF_NODE}/v1/offices/${officeId}/coordinates`)
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const getAddressByCoordinates = async (
  coordinates: MapLocationCoordinates,
): Promise<google.maps.GeocoderResult | null> => {
  try {
    await loadScript()
    const geocoder = new google.maps.Geocoder()

    const { results } = await geocoder.geocode({ location: coordinates })
    if (results && results[0]) return results[0]
  } catch (error) {
    console.error('Error: =>>>>>>>', error)
  }
  return null
}

const loadScript = async (): Promise<void> => {
  if (process?.env?.REACT_APP_GOOGLE_MAP_KEY) {
    const loader = new Loader({
      apiKey: process?.env?.REACT_APP_GOOGLE_MAP_KEY,
      libraries: ['places'],
    })

    await loader.load()
  } else {
    throw new Error('No google maps api key!')
  }
}

export const getOfficesByUserId = async (userId: string) => {
  try {
    const result = await Axios.get<object>(`${process.env.REACT_APP_BFF_NODE}/v1/offices/users/${userId}`)
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const getPendingOfficesRequestByUserId = async (userId: string) => {
  try {
    const result = await Axios.get<object[]>(`${process.env.REACT_APP_BFF_NODE}/v1/officeRequests/pending/${userId}`)
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>m', error)
  }
}

export const getComuneById = async (comuneId: string) => {
  try {
    const result = await Axios.get<object>(`${process.env.REACT_APP_BFF_NODE}/v1/comunes/${comuneId}`)
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const getPhoto = async (idOffice: string, image: any) => {
  try {
    const result = await Axios.get<object>(`${process.env.REACT_APP_BFF_NODE}/v1/offices/${idOffice}/${image}`)
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const createRequests = async (data: any) => {
  try {
    const result = await Axios.post<object>(`${process.env.REACT_APP_BFF_NODE}/v1/officeRequests`, data)
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const uploadImage = async (data: any, idOffice: string) => {
  try {
    const result = await Axios.post<object>(`${process.env.REACT_APP_BFF_NODE}/v1/offices/${idOffice}/image`, data)
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}

export const deleteImage = async (idOffice: any, img: any) => {
  try {
    const result = await Axios.delete<object>(`${process.env.REACT_APP_BFF_NODE}/v1/offices/${idOffice}/image/${img}`)
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
  }
}
