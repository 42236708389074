import { useEffect, useState } from 'react'
import { ProductCartData } from '../../interfaces/carts'

function useHandleDisabledQuantityButton(product: ProductCartData) {
  const [disabledIncreaseButton, setDisabledIncreaseButton] = useState<boolean>(false)
  const [disabledDecreaseButton, setDisabledDecreaseButton] = useState<boolean>(false)

  useEffect(() => {
    if (product.promotionalDynamicType === 'no_promotion' || product.promotionalDynamicDetail === null) {
      if (product.stock) {
        if (product.stock === product.quantity) setDisabledIncreaseButton(true)
        else setDisabledIncreaseButton(false)
      } else {
        if (product.quantity === product.quantityMax) setDisabledIncreaseButton(true)
        else setDisabledIncreaseButton(false)
      }
    } else {
      if (product.promotionalDynamicDetail === product.quantity) setDisabledIncreaseButton(true)
      else setDisabledIncreaseButton(false)
    }

    if (product.quantity === product.quantityMin) {
      setDisabledDecreaseButton(true)
    } else {
      setDisabledDecreaseButton(false)
    }
  }, [product]) // eslint-disable-next-line react-hooks/exhaustive-deps

  return { disabledIncreaseButton, disabledDecreaseButton, setDisabledIncreaseButton, setDisabledDecreaseButton }
}

export default useHandleDisabledQuantityButton
