
import React from 'react'
import { IonPage, IonContent, IonSpinner, IonButton } from '@ionic/react'
import User from '../../models/User'
import Category from '../../models/Category'
import Settings from '../../models/Settings'
import GlobalComponents from '../../components'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import {  Product } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'
import './TagsCategory.scss'
import cartToolbarIcon from '../../assets/icons/icon_carrito_grey.svg'

import ToolBar  from '../../components/tool-bar/ToolBar'
import { StaticContext } from 'react-router'
import { getLengthOfCart } from '../../utils/cart'
import { navigateToCart } from '../../utils/navigation'

interface IPathParams {

}

interface RouteState {
  title:string
  categoryId:any
}

type IProps = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  categories: Category
  cartModel: CartModel
}

interface State {
  loading:boolean
  productsTags:any
  isLoadingProduct:boolean
  page:number
}

class TagsCategory extends React.PureComponent<IProps, State> {
  state: State = {
    loading: true,
    productsTags:[],
    isLoadingProduct:false,
    page:1,
  }

  async componentDidMount(){
    const { categories } =this.props

    const result = await categories.tagsCatregory(this.props.location.state.title,1, this.props.location.state.categoryId)
      if(result && Object.keys(result).length !== 0){
      this.setState(
        {loading:false,
          productsTags:result,
        })
    }
  }
  
  goToCart = () => {
    navigateToCart(this.props.history, this.props.location.state || this.props.history.location.state);
  }
 
  seeMoreProducts = async () => {
    const { productsTags, page } = this.state
    const { categories, location } = this.props
  
    this.setState({
      isLoadingProduct:true
    })
    let counter = page

    if(page < productsTags.totalPages){
      counter = page + 1
      this.setState({
        page:counter
      })
  
      const productsResult  =  await categories.tagsCatregory(location.state.title, counter, location.state.categoryId)
      const concatProduct = productsTags.products.concat(productsResult.products)
      this.setState({
        productsTags:{
          ...productsTags,
          products:concatProduct
        },
        isLoadingProduct:false
      })
    }
  }


  render() {
    const { history } = this.props
    const { loading, productsTags, isLoadingProduct, page } = this.state
   
    return (
      <IonPage className="containet-tags-category" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <ToolBar
            title={this.props.location.state.title}
            secondaryButtons={[{ type: 'back', onClick: history.goBack }]}
            secondaryHeader={true}
            boxShadow={true}
            primaryButtons={[
              {
                icon: cartToolbarIcon,
                key: 'cart',
                onClick: this.goToCart,
                badge: getLengthOfCart(),
              },
            ]}
          />
        
        <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {loading && 
            <div className="loading-tags">
              <p className='loading'>Cargando productos</p>
              <IonSpinner className="spinner-mid" name="crescent" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/> 
            </div>}
            <div className="card-tags">
              {productsTags.products &&  productsTags.products.length > 0 &&
                productsTags.products.map((product: Product) => {
                  return(
                    <div key={product.sku}>
                       <div className="product-list">
                        <GlobalComponents.ProductCard
                          key={product.sku}
                          settings={this.props.settings}
                          product={product}
                          cartModel={this.props.cartModel}
                          onGoToCart={this.goToCart}
                          history={this.props.history}
                        />
                      </div>
                    </div>
                    )
              })}
              {productsTags.products && productsTags.products.length === 0 && 
                <div className="loading-tags">
                  <p className='loading'>Producto no disponible</p>
                </div>
              }
                {productsTags.totalPages > 1 && page !== productsTags.totalPages && 
                  <IonButton 
                className="btn-seeMoreProducts-tags"
                onClick={this.seeMoreProducts} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                    >
                      Ver más productos
                      {isLoadingProduct && <IonSpinner name="crescent" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                  </IonButton>
                  }
            </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default (withRouter(TagsCategory))

