import React from 'react'
import { useVisibilityTracker } from '../../hooks/useVisibilityTracker'

interface VisibilityTrackerProps {
  /**
   * Function to call when the child element becomes visible.
   */
  onVisible: () => void

  /**
   * Percentage of the element's visibility required to trigger the onVisible callback (default is 0.5).
   */
  threshold?: number

  /**
   * Optional Classname for this VisibilityTracker.
   */
  className?: string

  /**
   * The child elements to be rendered inside the visibility tracker.
   */
  children: React.ReactNode
}

/**
 * VisibilityTracker is a wrapper component that uses Intersection Observer API
 * to track the visibility of its child element.
 *
 * @param onVisible - Function to call when the child element becomes visible.
 * @param threshold - Percentage of the element's visibility required to trigger the onVisible callback (default is 0.5).
 * @param children - The child elements to be rendered inside the visibility tracker.
 */
const VisibilityTracker: React.FC<VisibilityTrackerProps> = ({ className, onVisible, threshold = 0.5, children }) => {
  const ref = useVisibilityTracker(onVisible, threshold)

  return (
    <div className={`${className ?? ''}`} ref={ref}>
      {children}
    </div>
  )
}

export default VisibilityTracker
