import React, { useState, useEffect, Fragment } from 'react'
import {
  IonPage,
  IonContent,
  IonHeader,
  IonAlert,
  IonSpinner,
  IonIcon,
  IonActionSheet,
} from '@ionic/react'
import { first } from 'lodash'
import {
  oneClick,
  addCard,
  enrolamiento,
  getEnrolamiento,
  getEnrolado,
  deletePaymentCard,
  paymentmethods
} from '../../clients/order'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { refreshNotifications } from '../../pages/reusableFunctions/Notifications'
import { getLocalUserData } from '../../utils/localstorageData'

// COMPONENTS
import ToolBar from '../../components/tool-bar/ToolBar'
import GuruWhiteScreen from '../../components/guru-white-screen/GuruWhiteScreen'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import AlertModal from '../../components/modals/AlertModal'
import LoadingComponent from '../../components/loading-component/LoadingComponent'

// IMGS - ICONS
import cartToolbarIcon from '../../assets/icons/nav_cart.svg'
import bell from '../../assets/icons/nav_notification.svg'
import photoVisa from './../../assets/visa_x2.png'
import photoMasterCard from './../../assets/mastercard.png'
import photoBancoEstado from './../../assets/bancoestado_x3.png'
import { CartModel } from '../../models/CartModel'
import Office from '../../models/Office'
import Notifications from '../../models/Notifications'
import { trashOutline, informationCircleOutline, ellipsisVertical } from 'ionicons/icons'
import { InAppBrowser, InAppBrowserEvent, InAppBrowserOptions } from '@ionic-native/in-app-browser'
import menuIcon from './../../assets/icons/act_menu.svg'
import trashIcon from './../../assets/icons/act_delete.svg'

import { getCartId, getProductCart } from '../../clients/cartNew'

// EVENTS
import { sendMetric, sendMetricWithSource } from '../firebase/firebaseTags'
import User from '../../models/User'

import './Card.scss'
import GuruIcon from 'guru-react-icons'


import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { isOfficeSupported } from '../../utils/validateCurrentOffice'
import FeedbackNoScope from '../../components/feedback-no-scope/FeedbackNoScope'
import feedbackNoScopeImage from '../../assets/feedback-no-scope.png'
import { navigateToCart } from '../../utils/navigation'

interface IPathParams {
  statePay?: string
}

type RouteState = {}

type Props = RouteComponentProps<IPathParams, RouteState> & {
  cartModel: CartModel
  offices: Office
  notification: Notifications
  user: User
}

const Card: React.FC<Props> = props => {
  const [openSelect, setOpenSelect] = useState<boolean>(false)
  const [showAddCard, ] = useState<boolean>(false)
  const [getCardsAssociateWithOneClick, setGetCardsAssociateWithOneClick] = useState<any>([])
  const [getCardsAssociateWithBancoEstado, setGetCardsAssociateWithBancoEstado] = useState<any>([])
  const [statusOrder, setStatusOrder] = useState<any>({ open: false, paid: false, subHeader: '', header: '' })
  const [userInfo] = useState<any>(JSON.parse(localStorage.getItem('@user') || ''))
  const [showAlertForDelete, setShowAlertForDelete] = useState(false)
  const [showAlertForDeleteBnacoEstado, setShowAlertForDeleteBnacoEstado] = useState(false)
  const [isFetching, setFetching] = useState<boolean>(true)
  const [isWeb, setIsWeb] = useState<boolean>(false)
  const [office, setOffice] = useState<any>({})
  const [offices, setOffices] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [cardDelete, setCardDelete] = useState<any>({})
  const [paymentMethods, setPaymentMethods] = useState<any>([])
  const [cardDeleteBech, setCardDeleteBech] = useState<any>({})
  const [urlBech, setUrlBech] = useState<any>('')
  const [, setNameBtn] = useState<any>('')
  const [routeBtnModal, setRouteBtnModal] = useState<any>('')
  const [unreadNotificationsUser, setUnreadNotificationsUser] = useState<number>(0)
  const [cart, setCart] = useState<any>(0)
  const [deleteColor, setDeleteColor] = useState<boolean>(false)
  const [deleteColorBech, setDeleteColorBech] = useState<boolean>(false)
  const [deleteCardSelect, setDeleteCardSelect] = useState<any>('')
  const [deleteCardSelectBech, setDeleteCardSelectBech] = useState<any>('')
  const [isOfficeSupportedValue, setIsOfficeSupportedValue] = useState<boolean>(true)

  useEffect(() => {
    getTheCardsWithOneClickAndEnrollment()
  }, [getCardsAssociateWithOneClick, getCardsAssociateWithBancoEstado, statusOrder]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isFetching === true) {
      get()
      setFetching(true)
      getInfoPlatform()
    }
    return () => {
      setStatusOrder({ open: false, paid: false, subHeader: '', header: '' })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const checkOfficeSupport = () => {
    const isOfficeSupportedResult: boolean = isOfficeSupported()
    setIsOfficeSupportedValue(isOfficeSupportedResult)
  }

  const getInfoPlatform = async () => {
    const info = await Device.getInfo()
    if(info.platform === 'web') setIsWeb(true)
  }

  const getUserShortName = () => {
    const nameArray = userInfo.name.split(' ')
    return `${nameArray[0]} ${nameArray[1]}`
  }

  const get = async (changedOffice?: any) => {
    const info = await Device.getInfo()

    const idCart = await getCartId()
    let productCart = await getProductCart(idCart.data._id)
    const cart = productCart?.cart?.products.filter((item:any) => !item.paid)
    setCart(cart.length)

    if (info.platform === 'web') {
      if (props.match.params) {
        const statePay = props.match.params.statePay || undefined
        datailPay(statePay)
      }
    }

    const user = localStorage.getItem('@user') 
    const userId = user ? JSON.parse(user) :  null 

    if(userId) {
      const stateNotifications = await refreshNotifications(userId.id)
      setUnreadNotificationsUser(stateNotifications)
    }
    
    const offices = await props.offices.get()
    const currentOffice = localStorage.getItem('currentOffice')
    const office =  currentOffice ? JSON.parse(currentOffice) : first(offices)
    setOffice(changedOffice ? changedOffice : office)
    setOffices(offices)

    const paymentMethods = await paymentmethods()
    setPaymentMethods(paymentMethods)
  }

  const datailPay = async (statePay?: any) => {
    if (statePay) {
      if (statePay === '2') {
        setStatusOrder({ 
          open: true, 
          paid: false, 
          subHeader: 'Tu método de pago ha sido agregado con éxito.', 
          header: '¡Felicitaciones!',
        })
        setNameBtn('Ok')
      }
      if (statePay === '1') {
        setStatusOrder({ open: true, paid: true, subHeader: 'Realizado', header: '' })
      }
      if (statePay === '0') {
        setStatusOrder({
          open: true,
          paid: false,
          subHeader: 'Asegúrate que la cuenta/tarjeta no esté registrada. Si no es el caso, inténtalo de nuevo.',
          header: 'Algo salió mal',
          error: true
        })
        setNameBtn('Vamos de nuevo')
        setRouteBtnModal('/card')
      }
      if (statePay === '-1') {
        setStatusOrder({ 
          open: true, 
          paid: false, 
          subHeader: 'Asegúrate que la cuenta/tarjeta no esté registrada. Si no es el caso, inténtalo de nuevo.', 
          header: 'Algo salió mal',
          error: true
        })
        setNameBtn('Vamos de nuevo')
        setRouteBtnModal('/card')
      }
    }
  }

  const goToCart = () => {
    sendMetricWithSource('view_cart', 'payments')
    navigateToCart(props.history, props.location.state || props.history.location.state);
  }  

  const openOneClick = async () => {

    sendMetric('oneclick_payment_method')

    const info = await Device.getInfo()
    const urlRedirect = info.platform === 'web' ? `${process.env.REACT_APP_MOBILE}/card` : `/card`
   
    const res = await addCard(urlRedirect)
    if (info.platform === 'web') {
      Browser.open({ url: res.data.url, windowName: '_self' })
    } else {
      openWebviewNative(res.data.url)
    }
  }

  const openBancoEstado = async () => {
    const res = await enrolamiento()

    openWebviewNative(res.data.url)
  }

  const getTheCardsWithOneClickAndEnrollment = () => {
    oneClickData()
    enroladoData()
    checkOfficeSupport()
  }

  const oneClickData = async () => {
    if (getCardsAssociateWithOneClick.length !== 0) return

    let response = await oneClick()
    if (response.length) {
      response = response.map((card: any) => {
        card.isOptionsVisible = false
        return card
      })
      setGetCardsAssociateWithOneClick(response)
    }
    response && setLoading(false)
  }

  const enroladoData = async (shouldUpdate:boolean=false) => {
    if (getCardsAssociateWithBancoEstado.length !== 0 && !shouldUpdate) return

    let enrolado = await getEnrolado()
  
    if (enrolado.length) {
      enrolado = enrolado.map((card: any) => {
        card.isOptionsVisible = false
        return card
      })
      setGetCardsAssociateWithBancoEstado(enrolado)
    }
    enrolado && setLoading(false)
  }     

  const openWebviewNative = (websiteUrl: string) => {
    const options: InAppBrowserOptions = {
      hideurlbar: 'yes',
      hardwareback: 'yes',
      hidenavigationbuttons: 'yes',
      zoom: 'no',
    }


    const browser = InAppBrowser.create(websiteUrl, '_blank', options)
    browser.show()
    
    browser?.on('loadstart').subscribe((event: InAppBrowserEvent) => {
      setUrlBech(event.url)
      if (event.url.includes('/webPay/pay')) {
        browser?.close()
        setStatusOrder({ open: true, paid: true, subHeader: 'Tu método de pago ha sido agregado con éxito.', header: '¡Felicidades!' })
        setNameBtn('Ok')
        setRouteBtnModal('/card')
      }
      else if (event.url.includes('/webPay/rejection')) {
        browser?.close()
        setStatusOrder({ open: true, error: true,  paid: false, subHeader: 'Tu método de pago ha sido agregado con éxito.', header: 'Algo salió mal' })
        setNameBtn('Vamos de nuevo')
        setRouteBtnModal('/card')
      }
      else if (event.url.includes('/oneClick/inscribe')) {
        browser?.close()
        setStatusOrder({ open: true, 
          paid: false, 
          subHeader: 'Tu método de pago ha sido agregado con éxito.', 
          header: '¡Felicidades!'
        })
        setNameBtn('Ok')
        setRouteBtnModal('/card')
        oneClickData()
      }
      else if (event.url.includes('/oneClick/duplicate')) {
        browser?.close()
        setStatusOrder({
          open: true,
          paid: false,
          subHeader: 'Asegúrate que la cuenta/tarjeta no esté registrada. Si no es el caso, inténtalo de nuevo.',
          header: 'Algo salió mal',
          error: true
        })
        setNameBtn('Vamos de nuevo')
        setRouteBtnModal('/card')
      }
      else if (event.url.includes('/oneClick/rejected')) {
        browser?.close()
        setStatusOrder({ 
          open: true, 
          paid: false, 
          subHeader: 'Asegúrate que la cuenta/tarjeta no esté registrada. Si no es el caso, inténtalo de nuevo.', 
          header: 'Algo salió mal',
          error: true
        })
        setNameBtn('Vamos de nuevo')
        setRouteBtnModal('/card')
      }
      else if (event.url.includes('/fin_flujo1')) {
        browser?.close()
        setStatusOrder({ 
          open: true, 
          paid: false, 
          subHeader: 'Tu método de pago ha sido agregado con éxito.', 
          header: '¡Felicidades!'
        })
        getEnrolamiento()
        updateEnroladoDataBech()
        setNameBtn('Ok')
        setRouteBtnModal('/card')
      }
      else if (event.url.includes('/fin_flujo2')) {
        browser?.close()
        setStatusOrder({ 
          open: true, 
          paid: false, 
          subHeader: 'Tu método de pago ha sido agregado con éxito.', 
          header: '¡Felicidades!'
        })
        getEnrolamiento()
        updateEnroladoDataBech()
        setNameBtn('Ok')
        setRouteBtnModal('/card')
      }
      else if (event.url.includes('/fin_flujo3')) {
        browser?.close()
        setStatusOrder({ 
          open: true, 
          paid: false,  
          subHeader: 'Asegúrate que la cuenta/tarjeta no esté registrada. Si no es el caso, inténtalo de nuevo.', 
          header: 'Algo salió mal',
          error: true
        })
        setNameBtn('Vamos de nuevo')
        setRouteBtnModal('/card')
      }
    })
  }

  const updateEnroladoDataBech = () => {
    let tries = 0

    const resul = setInterval(() => {
      enroladoData(true)
      tries++
      if(tries >= 3) clearInterval(resul)
    }, 1500)

  }

  const deleteCard = (card: any, isOptionsVisible:any) => {
    setCardDelete(card)
    setDeleteCardSelect(card)
    setDeleteColor(true)
    setShowAlertForDelete(isOptionsVisible)

    if (!card.isOptionsVisible) {
      const newState = getCardsAssociateWithOneClick.map((mapCard: any) => {
        if (mapCard._id === card._id) {
          mapCard.isOptionsVisible = isOptionsVisible
        }
        return mapCard
      })
      setGetCardsAssociateWithOneClick(newState)
    }
  }

  const deleteCardOfBancoEstado = (card: any) => {
    setCardDeleteBech(card)
    setDeleteCardSelectBech(card)
    setDeleteColorBech(true)

    if (card.isOptionsVisible) {
      setShowAlertForDeleteBnacoEstado(true)
    } else {
      const newState = getCardsAssociateWithBancoEstado.map((mapCard: any) => {
        if (mapCard._id === card._id) {
          mapCard.isOptionsVisible = true
        }
        return mapCard
      })
      setGetCardsAssociateWithBancoEstado(newState)
    }
  }

  const executeDelete = async (id: any, type:string) => {
    const response = await deletePaymentCard(id,type)

    if(response){
      const deletePaymentCard = getCardsAssociateWithOneClick.filter((item:any) => item._id !== id)
      const deletePaymentCardBancoEstado = getCardsAssociateWithBancoEstado.filter((item:any) => item._id !== id)
      setGetCardsAssociateWithOneClick(deletePaymentCard)
      setGetCardsAssociateWithBancoEstado(deletePaymentCardBancoEstado)
      setDeleteCardSelect('')
    }
  }

  const onCloseAlertCards = () => {
    setCardDelete({})
    setShowAlertForDelete(false) 
    setDeleteColor(false)
    setDeleteColorBech(false)
    setDeleteCardSelect('')
  }

  const renderCardsList = (cards: any) => {
   
    return (
      <div className="list-orders">
        <div className="list">
          {cards.map((card: any) => {
            const { last4CardDigits, creditCardType, _id } = card
           
            return (
              <div>
                {card._id === cardDelete._id && 
                  <AlertModal 
                    label={'¡Espera!'}
                    text={'¿Seguro que quieres eliminar este método de pago?'}
                    buttonText={'Confirmar'}
                    onDismiss={onCloseAlertCards}
                    isOpen={showAlertForDelete}
                    buttonAction={() => executeDelete(_id, card.type)}
                  />
                }
                <Fragment>
                  <div className={`card-associate ${deleteColor && (deleteCardSelect._id === card._id) ? 'deleting' : ''}`}>
                    {creditCardType === 'Visa' && <img className="img-of-cards" src={photoVisa} alt="cupones" />}
                    {creditCardType === 'Mastercard' && (
                      <img className="img-of-cards" src={photoMasterCard} alt="cupones" />
                    )}

                    <div className="cards">
                      <div className="container-info-card">
                        <div className="last-four-digits">**** **** **** {last4CardDigits.replaceAll('X', '')}</div>
                        <div className="name-associate-with-card">{getUserShortName()}</div>
                      </div>
                      <div className="container-icon-trash" >
                        {deleteColor && (deleteCardSelect._id === card._id)  ? <IonIcon icon={trashIcon} onClick={() => deleteCard(card, true)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /> : <IonIcon icon={menuIcon} onClick={() => deleteCard(card, false)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                      </div>
                    </div>
                  </div>
                </Fragment>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const onCloseAlertBech = () => {
    setShowAlertForDeleteBnacoEstado(false) 
    setDeleteColorBech(false)
    setCardDeleteBech({})
  }

  const renderCardsWithBancoEstado = (cards: any) => {
   
    return (
      <div className="list-orders">
        <div className="list">
          {cards.map((card: any) => {
            const { type, _id } = card
         
            return (
              <div > 
              {card._id === cardDeleteBech._id && 
                <AlertModal 
                  label={'¡Espera!'}
                  text={'¿Seguro que quieres eliminar este método de pago?'}
                  buttonText={'Confirmar'}
                  onDismiss={onCloseAlertBech}
                  isOpen={showAlertForDeleteBnacoEstado}
                  buttonAction={() => executeDelete(_id, card.type)}
                />
              }
                <Fragment>
                  <div className={`card-associate ${deleteColorBech ? 'deleting' : ''}`}>
                    {type === 'enrolamiento' && <img className="img-of-cards" src={photoBancoEstado} alt="cupones" />}
                    <div className="cards">
                      <div className="container-info-card">
                        <div className="id-cuenta">**** **** **** {card.data.descStatusEnrolamiento.idCuenta}</div>
                        <div className="rut-account">{card.data.descStatusEnrolamiento.tipoCuenta}</div>
                      </div>
                      <div className="container-icon-trash" onClick={() => deleteCardOfBancoEstado(card)}>
                        {deleteColorBech ? <IonIcon icon={trashOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /> : <IonIcon icon={ellipsisVertical} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                      </div>
                    </div>
                  </div>
                </Fragment>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const onCancelAddPayment = () => {
    sendMetric('cancel_payment_method')
    setOpenSelect(false)
  }

  const showAddPaymentsMethods = () => {
    let showPaymenttypeCard = paymentMethods && paymentMethods.length > 0 ? 
    paymentMethods.map((item:any) => {return item.name}) : []
    return (
      <IonActionSheet
        isOpen={openSelect}
        onDidDismiss={onCancelAddPayment}
        cssClass="my-custom-class"
        buttons={[
          {
            text: showPaymenttypeCard.includes('Banco Estado') && 'BancoEstado',
            handler: () => {
              showPaymenttypeCard.includes('Banco Estado') && openBancoEstado()
            },
            cssClass: 'bank',
          },
          {
            text: showPaymenttypeCard.includes('OneClick') && 'OneClick',
            handler: () => {
              showPaymenttypeCard.includes('OneClick') && openOneClick()
            },
            cssClass: 'oneClick',
          },
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'delete',
          },
        ]}
      ></IonActionSheet>
    )
  }

  const showAddPaymentsMethodsInweb = () => {
    return (
      <IonActionSheet
        isOpen={openSelect}
        onDidDismiss={onCancelAddPayment}
        cssClass="my-custom-class"
        buttons={[
          {
            text: 'OneClick',
            handler: () => {
              openOneClick()
            },
            cssClass: 'oneClick',
          },
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'delete',
          },
        ]}
      ></IonActionSheet>
    )
  }

  const optionsAddPayments = () => {
    sendMetric('add_payment_method')
    setOpenSelect(true)
  }
  

  const openAlertFromTooltip = () => {
    setStatusOrder({ 
      open: true, 
      paid: false, 
      subHeader: 'Utiliza el menú -icon- para eliminar el método de pago que desees.', 
      header: 'Eliminar métodos de pago',
      icon: `${menuIcon}`
    })
    setNameBtn('Ok')
  }

  const goToCategory = () => {
    sendMetricWithSource('view_categories', 'payments')
    props.history.push('/categories')
  }
  

  const loadPageByComuneId = async (changedOffice: any) => {
    get(changedOffice)
    checkOfficeSupport()
  }

  const goToNotifications = () => {
    sendMetric('view_notifications')
    props.history.push('/notifications')
  }

  const renderToolbar = () => {
    const notificationsBadge = unreadNotificationsUser > 0 ? ' ' : 0

    const userData = getLocalUserData()
    let userName = userData?.name || userData?.role?.name
    const officesLocal = localStorage.getItem('@offices')
    const officesParsed = officesLocal ? JSON.parse(officesLocal) : []
    const officeLocal = localStorage.getItem('currentOffice')
    const officeParsed = officeLocal ? JSON.parse(officeLocal) : null

    return (
      <>
        <p className="paragraph-3 categories-toolbar-text">Categorías</p>
        <ToolBar
          title={userName}
          office={officeParsed}
          offices={officesParsed}
          primaryButtons={[
            {
              icon: bell,
              key: 'notifications',
              onClick: goToNotifications,
              badge: notificationsBadge,
            },
            {
              icon: cartToolbarIcon,
              key: 'cart',
              onClick: goToCart,
              badge: cart,
            },
          ]}
          secondaryButtons={[
            {
              key: 'category',
              onClick: goToCategory,
            },
          ]}
          loadOnChangeOffice={loadPageByComuneId}
          officeModel={props.offices}
          cartModel={props.cartModel}
        />
      </>
    )
  }

  const showModal = () =>{
    const { open, header, subHeader, nameBtn, icon, error} = statusOrder

    return (
      <AlertModal 
        label={header}
        text={subHeader}
        buttonText={!nameBtn ? `${error ? 'Vamos de nuevo' : 'Ok'}` : nameBtn}
        buttonAction={closeModal}
        isOpen={open}
        onDismiss={() => setStatusOrder({ open: false, paid: false })}
        icon={icon || null}
        hasError={error || false}
      />
    )
  }

  const closeModal = () =>{
    setStatusOrder ({ open: false, paid: false, subHeader: '', header: '' })
    if (routeBtnModal && routeBtnModal !== '') { 
      props.history.push(`${routeBtnModal}`)
    } else {
      props.history.push('/card')
    }
  }

  const goToLogout = async () => { 
    const { user, history } = props
    const lastToken = localStorage.getItem('@access_token')

    await user.signOut()
    if (lastToken) localStorage.setItem('@last_token_access', lastToken)

    history.push('/')
    window.location.reload()
  }

  const validateCardsAssociateBech = (cards:any, isWeb:boolean) => cards.length > 0  && isWeb === false

  const validateCardsAssociateOneClick = (cards:any ) => cards.length > 0 

  const validateBechAndOneClick = (cardsOneclick:any , cardsBech:any, loading:boolean ) => cardsOneclick.length <= 0 && (cardsBech <= 0 || cardsBech > 0 && isWeb) && !loading

  let showPaymenttypeCard = paymentMethods && paymentMethods.length > 0 ? 
  paymentMethods.map((item:any) => {return item.name}) : []

  return (
    <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{renderToolbar()}</IonHeader> 
      {!showAddCard && (
        <IonContent className="container-payment-view" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {!isOfficeSupportedValue ?
            <FeedbackNoScope 
              image={feedbackNoScopeImage} 
              text="Lamentamos informar que actualmente no contamos con servicios en tu zona." 
              callToAction="¡Pronto volveremos con novedades para ti y tu negocio!" 
            />
            :
            <>
              <div className="container-middle-text">
                <div className="container-icon-and-title">
                  <h1 className="title-payments-methods">Tus tarjetas</h1>
                  <div className="container-icon-tooltip" onClick={openAlertFromTooltip}>
                    <GuruIcon nameIcon="act_info"/>
                  </div>
                </div>
                {loading && <LoadingComponent/>}
                {validateBechAndOneClick(getCardsAssociateWithOneClick, getCardsAssociateWithBancoEstado , loading)  &&
                  <div className="card-guru-white-screen"><GuruWhiteScreen text="Paga tus pedidos más rápido agregando tus tarjetas acá"/></div>
                }
                {validateCardsAssociateOneClick(getCardsAssociateWithOneClick) && (
                  <div className="wrapper-orders">
                  { renderCardsList(getCardsAssociateWithOneClick)}
                  </div>
                )}
                {validateCardsAssociateBech(getCardsAssociateWithBancoEstado, isWeb) && (
                  <div className="wrapper-orders">
                    {renderCardsWithBancoEstado(getCardsAssociateWithBancoEstado)}
                  </div>
                )}
              </div>
              <div className="container-btn-add-payment">
                <ButtonComponent 
                  className="btn-primary"
                  disabled={!(showPaymenttypeCard.includes('OneClick') || showPaymenttypeCard.includes('Banco Estado'))}
                  onClick={optionsAddPayments}
                  text="Agregar"
                />
              </div>
                {openSelect && isWeb === false && showAddPaymentsMethods()} 
                {openSelect && isWeb && showPaymenttypeCard.includes('OneClick') && showAddPaymentsMethodsInweb()}
              <div>{process.env.REACT_SHOW_DEBUG === 'true' && urlBech}</div>
            </>
          }
        </IonContent>
      )}
      { statusOrder.open && showModal()}
    </IonPage>
  )
}

export default withRouter(Card)


