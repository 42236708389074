import axios from 'axios'
import { REACT_APP_BFF_NODE } from '../../../../config'
import { getUserToken } from '../../../../utils/functions/getUserToken'
import { getBussinesTypeId, getComuneId } from '../../../../utils/functions/parserCurrentOffice'

export const apiBffMobileV2Home = axios.create({
  baseURL: `${REACT_APP_BFF_NODE}/v2/home`,
  headers: {
    Authorization: `Bearer ${getUserToken()}`,
    bussinestypeid: getBussinesTypeId(),
    comuneid: getComuneId(),
  },
})
