import { apiBffMobileV2Home } from './api'

interface Response {
  vendors: {
    _id: string
    name: string
    alias: string
    minimun: string
  }[]
}

export const getHomeData = async (): Promise<Response> => {
  const response = await apiBffMobileV2Home.get('/')

  return {
    vendors: response.data['vendors'],
  }
}
