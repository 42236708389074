import React from 'react'
import Settings from '../../models/Settings'
import ProductCard from './../product-card/ProductCard'
import { IonSlides, IonSlide } from '@ionic/react'
import { CartModel } from '../../models/CartModel'
import { Product } from '../../models/ProductModel'
import { carouselItem, viewProviderFavoriteProducts } from '../../pages/firebase/firebaseTags'
import { viewVendorEvent } from '../../pages/firebase/firebaseTags'
import actRight from './../../assets/icons/act_right.svg'
import actStar from './../../assets/icons/act_star.svg'

import './ProductSlider.scss'

interface IProps {
  settings: Settings
  products: Product[]
  info: {
    title: string
    id: string
    hide_more?: boolean
    brandId: string
    vendorName?: string
    vendorId?: string
    from?: string
    showInBlueColor?: boolean
    totalProducts?: number
  }
  hidePrice?: boolean
  onMoreInfoClick?: Function
  cartModel: CartModel
  onGoToCart?: () => void
  nextPage?: Function
  categoryPrimary?: any
  filterBrand?: boolean
  history: any
  colorText?: string
  colorBackground?: string
  allMainCategories?: any[]
  categoryPosition?: number
  filterCategoryName?: any
  fromSlider: boolean | undefined
  topProduct?: boolean
  offers?: boolean
  favorites?: boolean
  offersHome?: boolean
}

interface IState {
  products: any
}

export const mapProductFromSuggested = (product: any) => ({
  ...product,
  provider: product.providerId,
  filename: product.imageFullPath ?? product.filename,
  vendorActiveInComune: product.active,
})
class ProductSliderLegacy extends React.Component<IProps, IState> {
  state: IState = {
    products: [],
  }
  slideId = `slider-${Math.floor(Math.random() * 1000) + Date.now()}`
  lastProductId = null
  private slidesRef = React.createRef<any>()

  componentDidMount() {
    this.slidesAction()
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return
    }
  }

  slidesAction = () => {
    const { products, favorites } = this.props
    const slides = document.getElementById(this.slideId) as any

    if (favorites) {
      this.setState({
        products: products.map((product: any) => product.productId),
      })
    } else {
      this.setState({
        products,
      })
    }
    slides.options = {
      slidesPerView: 3.5,
      slidesOffsetBefore: 24,
      direction: 'horizontal',
      spaceBetween: 12,
      breakpoints: {
        260: {
          slidesPerView: 1.7,
        },
        300: {
          slidesPerView: 1.8,
        },
        320: {
          slidesPerView: 1.9,
        },
        340: {
          slidesPerView: 2.4,
        },
        360: {
          slidesPerView: 2.4,
        },
        380: {
          slidesPerView: 2.4,
        },
        400: {
          slidesPerView: 2.4,
        },
        500: {
          slidesPerView: 2.5,
        },
        600: {
          slidesPerView: 2.5,
        },
        700: {
          slidesPerView: 2.6,
        },
        800: {
          slidesPerView: 2.6,
        },
        900: {
          slidesPerView: 4.6,
        },
        1000: {
          slidesPerView: 5.6,
        },
      },
      autoplay: {
        delay: 10000,
        disableOnInteraction: true,
      },
    }
  }

  componentDidUpdate(prevState: any, prevPros: any) {
    if (prevState.products.length !== this.props.products.length) {
      this.slidesAction()
    }
  }

  nextPage = (category: any) => {
    if (this.props.nextPage) {
      this.props.nextPage(category)
    }
  }

  validateIfItHasPromotion = (product: any) => product && product?.showPrice?.offerPrice > 0

  onClickProduct = (product: any) => {
    if (this.validateIfItHasPromotion(product))
      carouselItem(
        'click_product_promo',
        product.id,
        product.name,
        product.category,
        product.brand,
        product.sku,
        product.providerName,
      )
    carouselItem(
      'click_product',
      product.id,
      product.name,
      product.category,
      product.brand,
      product.sku,
      product.providerName,
    )
  }

  getEventSuffix = (prefix: string) => {
    const { offers, topProduct, favorites } = this.props

    let eventSuffix = null
    const validations = [
      { isValid: () => offers, action: () => (eventSuffix = 'offers') },
      { isValid: () => topProduct, action: () => (eventSuffix = 'top_products') },
      { isValid: () => favorites, action: () => (eventSuffix = 'favorite_products') },
    ]
    validations.forEach((validation) => {
      if (validation.isValid()) validation.action()
    })

    if (eventSuffix !== null) {
      eventSuffix = `${prefix}_${eventSuffix}`
    }
    return eventSuffix || ''
  }

  onMoreButtonClick = (offers: any, topProducts: any) => {
    const { info, categoryPosition, favorites } = this.props
    const { id, title } = info

    if (favorites) viewProviderFavoriteProducts()
    viewVendorEvent('search', id, title, categoryPosition, this.getEventSuffix(`view_provider`))

    this.validateOnSeeMoreClick(offers, topProducts)
  }

  validateOnSeeMoreClick = (offers: any, topProducts: any) => {
    const { filterBrand, onMoreInfoClick, info, history, favorites, offersHome } = this.props
    const { id, vendorId, vendorName, from, title } = info
    const fromIsEqualOfferList = from === 'list-offers'
    let nameToShow = fromIsEqualOfferList ? title : vendorName
    const validations = [
      {
        isValid: () => offersHome,
        action: () => {
          history.push(`/vendors-products-offers`, { vendorName: nameToShow })
        },
      },
      {
        isValid: () => topProducts,
        action: () => {
          history.push(`/vendors/${vendorId}/top-products`, { vendorName: nameToShow })
        },
      },
      {
        isValid: () => offers || fromIsEqualOfferList,
        action: () => {
          history.push(`/vendors/${fromIsEqualOfferList ? id : vendorId}/offers`, { vendorName: nameToShow })
        },
      },
      {
        isValid: () => filterBrand,
        action: () => {
          history.push(`/home-brand/${id}`, { singleBrandId: id })
        },
      },
      {
        isValid: () => !offers && !filterBrand && !topProducts && onMoreInfoClick,
        action: () => {
          onMoreInfoClick && onMoreInfoClick(info)
        },
      },
      {
        isValid: () => favorites,
        action: () => {
          history.push(`/vendors/${vendorId}/favorites`, { vendorName: nameToShow })
        },
      },
    ]

    validations.forEach((validation) => {
      if (validation.isValid()) validation.action()
    })
  }

  partialValue = (value: string) => {
    return <>{value.slice(0, 25)}...</>
  }

  showPartialValue = (value: string) => {
    if (value.length < 26) return value
    return this.partialValue(value)
  }

  render() {
    const { onGoToCart, info, offers, hidePrice, topProduct, categoryPosition, filterCategoryName, favorites } =
      this.props
    const { showInBlueColor, from, totalProducts, title, id } = info
    const { products } = this.state
    const fromIsEqualOfferList = from === 'list-offers'

    const headerClass = !fromIsEqualOfferList && (offers ? 'offersHeader' : '')
    const isAvailableToShowInBlue = !showInBlueColor ? '' : 'show-in-blue'
    const arrowRight = !fromIsEqualOfferList && offers ? 'see-button' : ''
    const textOffers = totalProducts === 1 ? 'oferta' : 'ofertas'
    products.sort((a: any, b: any) => (a.outOfStock === b.outOfStock ? 0 : a.outOfStock ? 1 : -1))

    return (
      <div className="product-slider" id={id}>
        {products && products.length > 0 && (
          <div className={`header ${headerClass}`} id={`header-${id}`}>
            <span className={`container-title ${isAvailableToShowInBlue} ${headerClass}`}>
              {favorites && <img src={actStar} style={{ marginRight: 8 }} width={24} height={24} alt="" />}
              {this.showPartialValue(title)}
            </span>
            {fromIsEqualOfferList && <span className="counter-products">{`${totalProducts} ${textOffers}`}</span>}

            {!info.hide_more && (
              <div className={`seeMore ${isAvailableToShowInBlue} ${arrowRight}`}>
                <div
                  className="button-more"
                  onClick={() => {
                    this.onMoreButtonClick(offers, topProduct)
                  }}
                >
                  <div className="see-all-products">Todos</div>
                  <img src={actRight} alt="" />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="body">
          <IonSlides ref={this.slidesRef} key={this.slideId} id={this.slideId} pager={false} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {products.map((product: any) => {
              if (this.validateIfItHasPromotion(product))
                carouselItem(
                  'product_impression_promo',
                  product.id,
                  product.name,
                  product.category,
                  product.brand,
                  product.sku,
                  product.providerName,
                ) // TODO: Eliminar esta linea de código (307-325)
              carouselItem(
                'product_impression',
                product.id,
                product.name,
                product.category,
                product.brand,
                product.sku,
                product.providerName,
              ) // TODO: Eliminar esta linea de código (307-325)

              return (
                <IonSlide
                  key={`slide-${product.id}`}
                  onClick={() => this.onClickProduct(product)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                >
                  <ProductCard
                    key={`product-card-${product.id}`}
                    product={mapProductFromSuggested(product)}
                    settings={this.props.settings}
                    cartModel={this.props.cartModel}
                    onGoToCart={onGoToCart}
                    hidePrice={hidePrice}
                    history={this.props.history}
                    categoryPosition={categoryPosition}
                    filterCategoryName={filterCategoryName}
                    metrics={{
                      addToCartSource: this.getEventSuffix('provider'),
                      deleteProductCartSource: this.getEventSuffix('provider'),
                    }}
                  ></ProductCard>
                </IonSlide>
              )
            })}
          </IonSlides>
        </div>
      </div>
    )
  }
}
