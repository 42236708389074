export const getLocalUserData = () => {
  const userLocal = localStorage.getItem("@user")
  return userLocal ? JSON.parse(userLocal) : ""
}

export const getLocalStorageObject = (key: string) => {  
  const localData = localStorage.getItem(key) || null
  
  try {
    const parsedData = localData ? JSON.parse(localData) : null
    return parsedData
  } catch (e) {
    return null
  }
}

export const setLocalStorageObject = (key: string, object: Object) => {
  try {
    localStorage.setItem(key, JSON.stringify(object));
  } catch (error) {
    console.error(error);
  }
}