import { Container } from 'unstated-typescript'
import { produce } from 'immer'
import { find, bussinesTypePeru, createOffice, registerOffice } from '../clients/offices'
import { getFromCache } from '../utils/cache'
import { OfficeRegistration } from '../clients/officeRegistration'
import { getLocalStorageObject } from '../utils/localstorageData'
import User from './User'
interface OfficeState {
  offices: any[]
  office: any
  count: Number
}

const storageOfficesName = '@offices'

export default class Office extends Container<OfficeState> {
  state: OfficeState = {
    offices: [],
    office: {},
    count: 0,
  }

  constructor() {
    super()
    const offices = getFromCache<any>(storageOfficesName)
    if (offices) {
      this.setState(
        produce(this.state, draft => {
          draft.offices = offices
        }),
      )
    }
  }

  async get() {
    const offices:any = await find() || []
    await this.setState(
      produce(this.state, draft => {
        draft.offices = offices as any[]
      }),
    )
    offices?.length && localStorage.setItem(storageOfficesName, JSON.stringify(offices))
    return offices as any[]
  }

  async setOffice(office: any) {
    await this.setState(
      produce(this.state, draft => {
        draft.office = office
      }),
    )
  }

  getOffice() {
    return this.state.office
  }

  bussinesTypePeru = async () => bussinesTypePeru()
 

  createOffice = async (nameOffice:string, bussinesTypeId:string, commercialName: string,  address:string, city:string, token:string, deparment:string, province:string, postalCode?: string) =>{
    const bussinesType = createOffice(nameOffice, bussinesTypeId, commercialName, address, city, token, deparment, province, postalCode)
    return bussinesType
  }

  createOfficeFromRegister = async (office: OfficeRegistration, userModel: User) =>{
    try {
      const authData = getLocalStorageObject("registerSuccesfull");
      if (!authData) return;
      office.name = authData?.user?.name || "Sin nombre";

      await registerOffice(office, authData.access_token)

      return { ...authData, welcomeGuru: true }
    } catch (error) {
      console.error(error)
      return null;
    }
  }

  createOfficeFromLoggedIn = async (office: OfficeRegistration) => {
    try {
      const accessToken = localStorage.getItem('@access_token');
      if (!accessToken) return null

      const userData = getLocalStorageObject('@user');
      office.name = userData?.name || "Sin nombre";
      await registerOffice(office, accessToken)
    } catch (error) {
      console.error(error)
      return null
    }
  }
}
