import React from 'react'
import { Link } from 'react-router-dom'
import useCalculatePaymentMethodCardTotalPrice from '../../../../hooks/carts/useCalculatePaymentMethodCardTotalPrice'
import useCheckProvidersMinimumPrice from '../../../../hooks/carts/useCheckProvidersMinimumPrice'
import { PaymentMethodCartData } from '../../../../interfaces/carts'
import { handlePaymentMethodName } from '../../../../utils/functions/handlePaymentMethodName'
import { formatCurrency } from '../../../../utils/intl'
import ProviderCard from '../provider_card'
import './index.scss'

interface Props {
  paymentMethodData: PaymentMethodCartData
}

function PaymentMethodCard({ paymentMethodData }: Props) {
  const { paymentMethod, providers } = paymentMethodData
  const { totalPrice } = useCalculatePaymentMethodCardTotalPrice(paymentMethodData)
  const { disabledButton } = useCheckProvidersMinimumPrice(paymentMethodData)

  return (
    <div className="payment-method-card">
      <h4>{handlePaymentMethodName(paymentMethod)}</h4>

      {/* Content para las tarjetas por proveedor */}
      <ul className="payment-method-card__providers">
        {providers.map((provider) => (
          <ProviderCard key={provider._id} provider={provider} paymentMethodData={paymentMethodData} />
        ))}
      </ul>

      <div
        className={`payment-method-card__button payment-method-card__button--${disabledButton ? 'disabled' : 'active'}`}
      >
        <Link
          to={disabledButton ? '#' : `/suggested/${paymentMethodData.paymentMethod}`}
          className={`payment-method-card__button__link payment-method-card__button payment-method-card__button__link--${
            disabledButton ? 'disabled' : 'active'
          }`}
        >
          {paymentMethod === 'pre-pago' ? 'Pagar' : 'Pedir'} Total: {formatCurrency(totalPrice)}
        </Link>
      </div>
    </div>
  )
}

export default PaymentMethodCard
