import { getLocalStorageObject } from "../utils/localstorageData";
import { getTextVariations } from '../clients/variantsTexts'

interface Variation {
  key: string
  value: string
}

export default class TextVariations {
  key: string
  variations?: Variation[] 

  constructor(variationKey: string, fetchVariations?: boolean) {
    this.key = variationKey;
    this.initKeyVariations(variationKey, fetchVariations)
  }
  
  private initKeyVariations = async (variationKey: string, fetchVariations?: boolean) => {
    let allVariations = getLocalStorageObject('variationsText')?.variations || null
     
    if (!allVariations || fetchVariations) {
      const res = await getTextVariations()

      if (res) {
        allVariations = res?.variations 
        localStorage.setItem('variationsText', JSON.stringify(res))
      } else {
        allVariations = []
      }
    }

    const keyVariations = allVariations.filter((variation: Variation) => variation?.key?.includes(variationKey))
    this.variations = keyVariations
  }

  public getVariationValue = (key: string): string => {
    const variation: Variation | undefined = this.variations?.find((variation: Variation) => variation?.key?.includes(key))
    return variation?.value || ""
  }
}