import axios from 'axios'
import { REACT_APP_BFF_NODE } from '../../../../config'
import { getUserToken } from '../../../../utils/functions/getUserToken'

export const apiBffMobileV1ShippingCost = axios.create({
  baseURL: `${REACT_APP_BFF_NODE}/v1/shippingcost`,
  headers: {
    Authorization: `Bearer ${getUserToken()}`,
  },
})
