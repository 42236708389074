import { IonContent, IonModal } from '@ionic/react';
import React, { useState } from 'react';
import { sendContactData, UserContactData } from '../../../clients/invitedUser';
import User from '../../../models/User';
import { isPhoneValidByCountry, phoneLengthByCountry, phonePlaceholderByCountry } from '../../../utils/countriesTexts';
import { newValidatePhone, validateEmail } from '../../../utils/basetypes';
import ButtonComponent from '../../basic-button/ButtonComponent';
import TextInput from '../../input-form/TextInput';
import './modal-invited-user.scss'
import ModalSendSuccess from './ModalSendSuccess';

interface ModalInvitedUserInterface {
  isOpen: boolean
  history: any
  user: User
  closeModal(): void
}

const ModalInvitedUser: React.FC<ModalInvitedUserInterface> = (props) => {
  const {isOpen, history, user, closeModal} = props

  const [name, setName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [modalSuccessIsOpen, setModalSuccessIsOpen] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  
  const goToLogout = async () => {
    const lastToken = localStorage.getItem('@access_token')

    await user.signOut()
    if (lastToken) localStorage.setItem('@last_token_access', lastToken)

    localStorage.setItem('requestRegistration', 'true');
    history.push('/login')
    window.location.reload()
  }

  const onHandleClickRegister = () => {
      goToLogout()
  }

  const onHandleSendUserData = async () => {
    try {
      setError('')
      const userData: UserContactData = {
        name,
        phone,
        email,
      }
      const result = await sendContactData(userData)
      if(result) {
        clearForm()
        setModalSuccessIsOpen(true)
      }
    } catch(error) {
      console.log(error);
      setError('No fue posible enviar los datos, vuelve a intentarlo')
    }
  }

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value)
  }

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  
  const disabledSendButton = (): boolean => {
    if (!!name.trim().length && isPhoneValidByCountry(phone) && (email === '' || validateEmail(email))) {
      return false
    }
    return true
  }

  const clearForm = (): void => {
    setName('')
    setPhone('')
    setEmail('')
  }
  
  if (modalSuccessIsOpen) {
    return <ModalSendSuccess 
      isOpen={modalSuccessIsOpen} 
      cerrarModal={() => setModalSuccessIsOpen(false)}
    />
  }

  return (
    <IonModal cssClass="modal-invited-user" isOpen={isOpen} onDidDismiss={closeModal} backdropDismiss={true}>
      <IonContent class='modal-body-content' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className='modal-content'>
          <div className='modal-form-container'>
            <div className='form-helper-container'>
              <span className='form-helper-text'>¿Tienes dudas? Deja tus datos de contacto para comunicarnos contigo</span>
            </div>
            <TextInput 
              iconDefault="nav_account" 
              iconValid="nav_account-blue"
              value={name} 
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeName(event)} 
              type='text'
              placeholder='Ej: María'
              label='Nombre'
              isValid={!!name.trim().length}
              error={"El nombre debe tener al menos un carácter"}
              toValidate={name}
              autoComplete="off"
            />
            <TextInput 
              iconDefault="icn_phone" 
              iconValid="icn_phone-blue"
              iconInvalid="icn_phone-red"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangePhone(event)} 
              placeholder={phonePlaceholderByCountry()}
              label="Teléfono"
              isValid={isPhoneValidByCountry(phone)}
              error={"Escribe tu número con el código de área"}
              value={phone}
              type="tel"
              toValidate={phone}
              maxLength={phoneLengthByCountry()}
              autoComplete="off"
            />
            <TextInput 
              iconDefault="icn_email" 
              iconValid="icn_email-blue"
              iconInvalid="icn_email-red"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeEmail(event)} 
              placeholder="Ej: maria@email.com"
              label="Email (opcional)"  
              isValid={!!validateEmail(email)}
              error={"Tu email debe ser válido para recibir mensajes"}
              value={email}
              type="email"
              toValidate={email}
              autoComplete="off"
              required={false}
            />
          </div>
          {error.length > 0 && <div className='error-container'><span className='error-text'>{error}</span></div>}
        </div>
      </IonContent>
      <div className='btn-actions-container'>
        <ButtonComponent 
          text={'Regístrate'}
          className="btn-primary"
          onClick={() => onHandleClickRegister()}
        />
        <ButtonComponent
          text={'Enviar'}
          className="btn-secondary"
          onClick={() => onHandleSendUserData()}
          disabled={disabledSendButton()}
        />
      </div>
    </IonModal>
  )
}

export default ModalInvitedUser