import { Container } from 'unstated-typescript'
import { produce } from 'immer'
import { find, findUsed, findExpired, count, activate, countReferal, couponReferalCart,activateCouponReferal, 
  desactivateCouponReferal, removeCouponReferal, getALLCoupons, cartAddCoupon, deactivateCoupon, availableVendorsForCoupon } from '../clients/coupon'
import { getFromCache } from '../utils/cache'

interface CouponState {
  coupons: any[]
  couponsUsed: any[]
  couponsExpired: any[]
  count: Number
}

const storageCouponsName = '@coupons'
const storageCouponsUsedName = '@couponsUsed'
const storageCouponsExpiredName = '@couponsExpired'
export class Coupon extends Container<CouponState> {
  state: CouponState = {
    coupons: [],
    couponsUsed: [],
    couponsExpired: [],
    count: 0,
  }

  constructor() {
    super()
    const coupons = getFromCache<any>(storageCouponsName)
    const couponsUsed = getFromCache<any>(storageCouponsUsedName)
    const couponsExpired = getFromCache<any>(storageCouponsExpiredName)
 
    if (coupons) {
      this.setState(
        produce(this.state, draft => {
          draft.coupons = coupons
        }),
      )
    }
    if (couponsUsed) {
      this.setState(
        produce(this.state, draft => {
          draft.couponsUsed = couponsUsed
        }),
      )
    }
    if (couponsExpired) {
      this.setState(
        produce(this.state, draft => {
          draft.couponsExpired = couponsExpired
        }),
      )
    }
  }

  async get(page: any, limit: any): Promise<any[] | null> {
    const coupons = await find(limit * page, limit)
    if (!coupons) return null
    
    await this.setState(
      produce(this.state, draft => {
        draft.coupons = coupons as any[]
      }),
    )
    localStorage.setItem(storageCouponsName, JSON.stringify(coupons))
    return coupons as any[]
  }

  async getUsed(page: any, limit: any): Promise<any[] | null> {
    const couponsUsed = await findUsed(limit * page, limit) 
    if (!couponsUsed) return null;

    await this.setState(
      produce(this.state, draft => {
        draft.couponsUsed = couponsUsed as any[]
      }),
    )
    localStorage.setItem(storageCouponsUsedName, JSON.stringify(couponsUsed))
    return couponsUsed as any[]
  }

  async getExpired(page: any, limit: any) {
    const couponsExpired = await findExpired(limit * page, limit)
    await this.setState(
      produce(this.state, draft => {
        draft.couponsExpired = couponsExpired as any[]
      }),
    )
    localStorage.setItem(storageCouponsExpiredName, JSON.stringify(couponsExpired))
    return couponsExpired as any[]
  }

  async activate(id: string) {
    const coupons = await activate(id)
    return coupons as any[]
  }

  async count() {
    const countResult = await count()
    await this.setState(
      produce(this.state, draft => {
        draft.count = countResult as Number
      }),
    )
    return countResult
  }

  async countReferal(codeCoupons:string, idUser:string) {
    const countResult = await countReferal(codeCoupons, idUser )
    return countResult
  }

  async couponReferalCart( idUser:string , typeCoupon:string) {
    const countResult = await couponReferalCart(idUser, typeCoupon )
    return countResult
  }

  async activateCouponReferal( idcoupon: string, idUser:string, idVendor:string) {
    const countResult = await activateCouponReferal(idcoupon, idUser, idVendor )
    return countResult
  }

  async  desactivateCouponReferal( idcoupon: string, idUser:string) {
    const countResult = await desactivateCouponReferal(idcoupon, idUser )
    return countResult
  }

  async  removeCouponReferal(code:any, userId:any ) {
    const countResult = await removeCouponReferal(code, userId)
    return countResult
  }

  async getALLCoupons() {
    const countResult = await getALLCoupons()
    return countResult
  }
  
  async cartAddCoupon(cartId:string, providerId:string, cuponId: string ) {
    const countResult = await cartAddCoupon(cartId, providerId, cuponId)
    return countResult
  }

  async deactivateCoupon(cartId:string,  cuponId: string ) {
    const countResult = await deactivateCoupon(cartId, cuponId)
    return countResult
  }

  availableVendorsForCoupon = async (cuponId: string ) => await availableVendorsForCoupon(cuponId)
}
 