import React from 'react'
import './FadeBackground.scss'

interface IProps {
  visible: boolean
}

export default class FadeBackground extends React.PureComponent<IProps> {

  renderLoading = () => {
    const { visible } = this.props

    if (visible) {
      return (
        <>
          <div className="fade-opacity-background"></div>
          <style>
            {
              `
                .footer-menu-home button {
                  pointer-events: none !important;
                }
              `
            }
          </style>
        </>
      )
    } else {
      return (
        <></>
      )
    }
  }

  render() {
    return this.renderLoading()
  }
}