import React, { Fragment } from 'react'
import {
  IonPage,
  IonContent,
  IonProgressBar,
  IonImg,
  withIonLifeCycle,
  IonAvatar,
  IonSlides,
  IonSlide,
  IonAlert,
  IonInput,
  IonHeader,
} from '@ionic/react'
import moment from 'moment'
import { formatCurrency } from '../../utils/intl'
import { getLocalUserData } from '../../utils/localstorageData'
import { groupBy , first} from 'lodash'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import User from '../../models/User'
import { activatedCouponEvent, applyCouponEvent, validateCoupon, viewCouponsPageEvent } from '../firebase/firebaseTags'
import { refreshNotifications } from '../../pages/reusableFunctions/Notifications'

// Icons
import cartToolbarIcon from '../../assets/icons/nav_cart.svg'
import withoutCouponsIcon from './../../assets/icons/sin_cupones.svg'
import bell from '../../assets/icons/nav_notification.svg'

// Components
import ToolBar from '../../components/tool-bar/ToolBar'
import GuruWhiteScreenProps from '../../components/guru-white-screen/GuruWhiteScreen'
import ButtonComponent from '../../components/basic-button/ButtonComponent';
import AlertModal from '../../components/modals/AlertModal'
import LoadingComponent from '../../components/loading-component/LoadingComponent'
import  BottomSheet  from 'bottom-sheet-react'
import ProvidersCardsRenderer from '../../components/providers-cards-renderer/ProvidersCardsRenderer'
import TermsConditionsCoupons from '../terms-conditions-coupons/TermsConditionsCoupons'

// Models
import { Coupon } from '../../models/Coupon'
import { ProviderModel } from '../../models/ProviderModel'
import { CartModel } from '../../models/CartModel'
import Office from '../../models/Office'
import  Notifications   from '../../models/Notifications'
import { getCartId, getProductCart } from '../../clients/cartNew'

// Events
import { sendMetric, sendMetricWithSource } from '../firebase/firebaseTags'

import './Coupons.scss'
import ModalInvitedUser from '../../components/modals/modal-invited-user/ModalInvitedUser'
import { validateIfIsInvitedUser } from '../../utils/invitedUser'
import FeedbackNoScope from '../../components/feedback-no-scope/FeedbackNoScope'
import feedbackNoScopeImage from '../../assets/feedback-no-scope.png'
import { isOfficeSupported } from '../../utils/validateCurrentOffice'
import { navigateToCart } from '../../utils/navigation'

type IPathParams = {}

type IProps = RouteComponentProps<IPathParams> & {
  coupons: Coupon
  providerModel: ProviderModel
  cartModel: CartModel
  user: User
  offices: Office
  notification: Notifications
}

interface State {
  msg: any
  coupons: any
  couponsUsed: any
  couponsExpired: any
  limit: any
  loading: boolean
  page: any
  views: any
  valueInput: string
  couponsActvive: boolean
  alert: boolean
  currentCouponStatus: any
  message: string
  alertDelete: boolean
  messageDelete: string
  segmentCoupons: any
  loadingCoupons: boolean
  office: any
  offices: any
  unreadNotificationsUser: number
  valueInputText: any
  validationInput: boolean
  validationText: string | number
  cart: any
  showAlertIsUserInvited: any
  bottomSheetShow: boolean
  vendors: any
  isOpenTermsConditionsCoupons: boolean
  isOfficeSupportedValue: boolean
}

class Coupons extends React.PureComponent<IProps, State> {
  private initialState: State = {
    msg: { msg: null, active: false },
    coupons: [],
    couponsUsed: [],
    couponsExpired: [],
    limit: 10,
    loading: true,
    page: 0,
    views: {
      valid: { name: 0, active: true },
      used: { name: 1, active: true },
      expired: { name: 2, active: false },
    },
    valueInput: '',
    couponsActvive: false,
    alert: false,
    currentCouponStatus: '',
    message: '',
    alertDelete: false,
    messageDelete: '',
    segmentCoupons: 0,
    loadingCoupons: true,
    office: {},
    offices: [],
    unreadNotificationsUser: 0,
    valueInputText: "",
    validationInput: false,
    validationText: "",
    cart: 0,
    showAlertIsUserInvited: false,
    bottomSheetShow: false,
    vendors: [], 
    isOpenTermsConditionsCoupons:false,
    isOfficeSupportedValue: true
  }

  state: State = this.initialState

  async componentDidMount() {
    const validation = await validateIfIsInvitedUser()
    this.setState({showAlertIsUserInvited: validation})

    this.loadCouponsPage()
  }

  checkOfficeSupport = () => {
    const isOfficeSupportedResult: boolean = isOfficeSupported()
    this.setState({isOfficeSupportedValue: isOfficeSupportedResult})
  }

  loadCouponsPage = async (changedOffice?: any) => {
    viewCouponsPageEvent()
    this.checkOfficeSupport()
    this.updateCoupons()
    const offices = await this.props.offices.get()
    const currentOffice = localStorage.getItem('currentOffice')
    const office =  currentOffice ? JSON.parse(currentOffice) : first(offices)
    this.props.offices.setOffice(office)
    await this.setState({
      offices,
      office: changedOffice ? changedOffice : office,
    })

    const idCart = await getCartId()
    let productCart = await getProductCart(idCart.data._id)
    const cart = productCart?.cart?.products.filter((item:any) => !item.paid)
    this.setState({cart: cart.length})

    const user = localStorage.getItem('@user') 
    const userId = user ? JSON.parse(user) :  null 

    if (userId.id) {
      const stateNotifications = await refreshNotifications(userId.id)
      this.setState({ unreadNotificationsUser: stateNotifications})
    }  
  }

  updateCoupons = async () => {
    const coupons = await this.props.coupons.get(this.state.page, this.state.limit)
    const couponsUsed = await this.props.coupons.getUsed(this.state.page, this.state.limit)
    const couponsExpired = await this.props.coupons.getExpired(this.state.page, this.state.limit)

    if (!coupons || !couponsUsed || !couponsExpired) return;
    
    this.setState({
      coupons: this.excludeChooseVendorTypeFromCoupons(coupons),
      couponsUsed: this.excludeChooseVendorTypeFromCoupons(couponsUsed),
      couponsExpired,
      loading: false,
      loadingCoupons: false,
    })
  }

  goToCart = () => {
    const { props } = this
    sendMetricWithSource('view_cart', 'coupons')
    navigateToCart(props.history, props.location.state || props.history.location.state);
  }
  
  goToHome = () => this.props.history.replace('/home')

  goToNotifications = () => {
    sendMetric('view_notifications')
    this.props.history.push('/notifications')
  }

  groupedCoupons = (coupons: any) => {
    return groupBy(coupons, (coupon: any) => {
      return coupon._id
    })
  }

  activateCoupon = async (id: string, code: any) => {
    activatedCouponEvent(code)
    const result = await this.props.coupons.activate(id)
    const msg = !result.length && Object(result)
    if (!result.length && msg) {
      msg.active = true
      msg.msg = Object(result).description
      if (Object(result).code) {
        msg.header = '¡Felicitaciones!'
      } else {
        msg.header = 'Ups'
      }
      this.setState({ msg })
    } else if (result.length) {
      msg.msg = 'Activación satisfactoria.'
      msg.active = true
      this.setState({ msg })
    }
    this.updateCoupons()
  }

  renderCouponList(key: any, coupons: any, active:boolean = false, hasBlueBorder:boolean = true) {
    const listedVendorsCouponsTypes = ['referal', 'referal-lote', 'full-coupon'];
    return (
      <div key={key} className="list-orders">
        <div className="list">
          {coupons.map((coupon: any) => {
            const { _id, amount, minimum_amount, date_expires, discount_type, code, image, couponType } = coupon
            const expire = moment(new Date(date_expires)).format('DD/MM/YYYY')
            return (
              <Fragment key={_id}>
                  <div className="container-coupons" >
                    <div className={`coupon ${hasBlueBorder ? 'blue-border' : ''}`}>
                      <IonAvatar className="no-radius" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <img
                          className="img_coupon"
                          src={image && `${process.env.REACT_APP_BFF_IMAGE}coupons/${image.url}`}
                          alt="cupones"
                        />
                      </IonAvatar>
                      <div className="provider">
                        {this.returnPorcentOrAmount(discount_type, amount )}
                        <div className="minimum_amount">Compras sobre {formatCurrency(minimum_amount)}</div>
                        <div className="code">Código: #{code} Vence {expire}</div>
                      </div>
                    </div>
                    {(listedVendorsCouponsTypes.includes(couponType)) && 
                      <div className="coupon-vendors" onClick={() => this.showBottomSheet(coupon._id)}>Conoce los proveedores asociados aquí</div>
                    }
                  </div>
              </Fragment>
            )
          })}
        </div>
      </div>
    )
  }

  returnPorcentOrAmount = (type:string , amount: any ) => (
    type === 'porcent' ? (
      <div className="amount">
        <strong>{amount}% de descuento</strong>
      </div>
    ) : (
      type === 'amount' && (
        <div className="amount">
          <strong>{formatCurrency(amount)} de descuento</strong>
        </div>
      )
    )
  )

  showBottomSheet = async (idCoupon?:string) => {
    const { bottomSheetShow } = this.state

    this.setState({bottomSheetShow: !bottomSheetShow})
    
    bottomSheetShow && this.setState({vendors: []})

    if(idCoupon) {
      const result = await this.props.coupons.availableVendorsForCoupon(idCoupon)
      this.setState({vendors: result.vendors})
    }

  }

  deleteCouponReferal = async (code: any, couponType:any) => {
    sendMetric('deactivate_coupon')
    const user: any = (await this.props.user.getUser()) || {}
    const result = await this.props.coupons.removeCouponReferal(code, user.id)
    if (result.status === 200 && couponType === 'referal') {
      this.setState({
        alertDelete: true,
        messageDelete: result.respuesta,
      })
    } else if (result.status === 200 && couponType === 'one-vendor') {
      this.setState({
        alertDelete: true,
        messageDelete: "Cupón "+ code + " anulado satisfactoriamente"
      })
    }
    this.updateCoupons()
  }

  closeAlert = () => {
    this.setState({
      alert: false,
    })
    this.updateCoupons()
  }
  
  onChangeValidate = (event: any) => {
    this.setState({
      valueInput: event,
    })
  }

  buttonValidate = async () => {
    const { valueInput } = this.state
    const user: any = (await this.props.user.getUser()) || {}

    const result = await this.props.coupons.countReferal(valueInput, user.id)
    if (result.status === 400) {
      sendMetric('validate_coupon_error')
      this.setState({
        currentCouponStatus: result.status,
        message: this.validateMessage(result)
      })
    } else if (result.status === 200) {
      validateCoupon(valueInput)
      applyCouponEvent(valueInput.toUpperCase())
      this.setState({
        currentCouponStatus: result.status,
        message: this.validateMessage(result),
      })
    } else if (result.status === 404) {
      sendMetric('validate_coupon_error')
      this.setState({
        currentCouponStatus: result.status,
        message: this.validateMessage(result)
      })
    } else if (result.status === 409) {
      sendMetric('validate_coupon_error')
      this.setState({
        currentCouponStatus: result.status,
        message: this.validateMessage(result)
      })
    }

    this.setState({
      valueInput: '',
      couponsActvive: Object.keys(result).length > 0 ? true : false,
      alert: true,
    })
  }

  validateMessage = (result:any) => {
    const placeholderMessage = result.status === 200 ? "Tu cupón fue validado correctamente" :  "Ha ocurrido un problema al validar tu cupón, intenta de nuevo."
    return result?.respuesta &&  Object.keys(result?.respuesta).length ? result?.respuesta : placeholderMessage
  }

  private goToCategory = () => {
    sendMetricWithSource('view_categories', 'coupons')
    this.props.history.push('/categories')
  }

  excludeChooseVendorTypeFromCoupons = (coupons:any[]) => {
    return coupons.filter(coupon => coupon.couponType !== 'choose-vendor');

  }

  loadCouponsByComuneId = async (changedOffice: any) => {
    this.checkOfficeSupport()
    this.setState(this.initialState, () => this.loadCouponsPage(changedOffice))
  }

  goToLogout = async () => { 
    const { user, history } = this.props
    const lastToken = localStorage.getItem('@access_token')

    await user.signOut()
    if (lastToken) localStorage.setItem('@last_token_access', lastToken)

    history.push('/')
    window.location.reload()
  }

  showTermsConditionsCoupons = () =>  this.setState({isOpenTermsConditionsCoupons: true})

  showListVendors = () => {
    const { vendors } = this.state

    return (
      <div className="container-vendors-bottoms-sheet">
        {vendors.length === 0 && <LoadingComponent/>}
        <ProvidersCardsRenderer 
          providers={vendors} 
          onProviderClick={this.goToHomeProvider} 
          source="home"
        />
      </div>
    )
  }
 
  goToHomeProvider = (item:any) => {
    this.props.history.push(`/home-providers/${item._id}`, {
      singleProviderId: item._id,
      nameProvider: item.alias,
      minumun: item.minimun,
      banners: item.banners,
    })

  }

  renderBottomSheet = () => {
    const { bottomSheetShow } = this.state

    return (
      <BottomSheet
      isExpandable={bottomSheetShow}
      customHeight={350}
      onClose={() => this.showBottomSheet()}>
      <div className="info-bottom-sheet">
        <div className="btn-close" onClick={() => this.showBottomSheet()}></div>
        <div className="title-text">Proveedores</div>
        <div className="sheet-text paragraph-2">
          Al aplicar cualquier cupón estás 
          <div className="underlined"  onClick={this.showTermsConditionsCoupons}>aceptando los términos y condiciones.</div>
        </div>
        {this.showListVendors()}
      </div>
    </BottomSheet>
    )
  }
  
  closeTermsConditionsCoupons = () => this.setState({isOpenTermsConditionsCoupons: false})
 
  couponModalLabel = () => {
    const { currentCouponStatus } = this.state

    const couponsStatus: any = {
        '200': '¡Felicitaciones!',
        '400': 'Ups' ,
        '404': 'Código Incorrecto',
        '409': 'Ups',
    }

    return couponsStatus[currentCouponStatus]
  }

  render() {
    const { cartModel } = this.props
    const {
      coupons,
      couponsExpired,
      couponsUsed,
      loading,
      views,
      msg,
      valueInput,
      alert,
      currentCouponStatus,
      message,
      alertDelete,
      messageDelete,
      segmentCoupons,
      loadingCoupons,
      cart,
      unreadNotificationsUser,
      showAlertIsUserInvited,
      bottomSheetShow,
      isOpenTermsConditionsCoupons
    } = this.state

    const length = coupons.length
    const lengthExpired = couponsExpired.length
    const lengthUsed = couponsUsed.length
    const grouped = this.groupedCoupons(coupons)
    const groupedExpired = this.groupedCoupons(couponsExpired)
    const groupedUsed = this.groupedCoupons(couponsUsed)

    const notificationsBadge = unreadNotificationsUser > 0 ? ' ' : 0

    const userData = getLocalUserData()
    let userName = userData?.name || userData?.role?.name
    const officesLocal = localStorage.getItem('@offices')
    const officesParsed = officesLocal ? JSON.parse(officesLocal) : []
    const officeLocal = localStorage.getItem('currentOffice')
    const officeParsed = officeLocal ? JSON.parse(officeLocal) : null

    return (
      <IonPage className="orders-page-cupons ds-content-page" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <>
            <p className="paragraph-3 categories-toolbar-text">Categorías</p>
            <ToolBar
              title={userName}
              office={officeParsed}
              offices={officesParsed}
              loadOnChangeOffice={this.loadCouponsByComuneId}
              primaryButtons={[
                {
                  icon: bell,
                  key: 'notifications',
                  onClick: this.goToNotifications,
                  badge: notificationsBadge,
                },
                {
                  icon: cartToolbarIcon,
                  key: 'cart',
                  onClick: this.goToCart,
                  badge: cart,
                },
              ]}
              secondaryButtons={[
                {
                  key: 'category',
                  onClick: this.goToCategory,
                },
              ]}
              cartModel={cartModel}
            />
          </>
        </IonHeader>
        {/* Without items */}
        <h1 className="title-coupon">Tus Cupones</h1>
        <IonContent className='body-content-coupons' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <ModalInvitedUser 
            isOpen={showAlertIsUserInvited} 
            history={this.props.history} 
            user={this.props.user}
            closeModal={() => this.setState({showAlertIsUserInvited: false})}
          />
          {msg.active && (
            <IonSlides pager={false} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonSlide placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonAlert
                  isOpen={msg.active}
                  onDidDismiss={() =>
                    this.setState({
                      msg: { active: false, msg: null },
                    })
                  }
                  header={msg.header}
                  message={msg.msg}
                  buttons={[
                    {
                      text: 'OK',
                      handler: () =>
                        this.setState({
                          msg: { active: false, msg: null },
                        }),
                    },
                  ]}
                />
              </IonSlide>
            </IonSlides>
          )}
          {alert && (
            <AlertModal 
              label={this.couponModalLabel()}
              text={message}
              buttonText="Ok"
              buttonAction={() => this.closeAlert()}
              isOpen={true}
              onDismiss={() => this.closeAlert()}
            />
          )}
          {loading && <LoadingComponent/>}
          {!loading && (
            <div>
              {!this.state.isOfficeSupportedValue ?
                <FeedbackNoScope 
                  image={feedbackNoScopeImage} 
                  text="Lamentamos informar que actualmente no contamos con servicios en tu zona." 
                  callToAction="¡Pronto volveremos con novedades para ti y tu negocio!" 
                />
                :
                <>
                  {loadingCoupons && <IonProgressBar type="indeterminate" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonProgressBar>}
                  {views.valid.active && !loadingCoupons && (
                    <div className="container-new-coupon">
                    <div className="new-coupons">
                      <div className="cotainer-coupons-referal">
                        <div className="title-new-coupons">Nuevo Cupón</div>
                        <div>
                          <div className="container-input" >
                            <IonInput
                                value={valueInput}
                                maxlength={13}
                                onIonChange={e => this.onChangeValidate(e.detail.value)}
                                placeholder="Ingresar Código" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                            ></IonInput>
                          </div>
                          <div className="container-btn-validate">
                              <ButtonComponent 
                                text={"Validar"}
                                className="btn-tertiary"
                                onClick={this.buttonValidate}
                                disabled={valueInput ? false : true}
                              />
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  )}


                  {(length > 0 || lengthUsed > 0) && segmentCoupons === 0 && !loadingCoupons ? (
                    <div>
                      <div className="wrapper-orders">
                        {Object.keys(grouped).map((key: any) => {
                          return this.renderCouponList(key, grouped[key] ,true, false )
                        })}
                      </div>
                      <div className="wrapper-orders">
                        {Object.keys(groupedUsed).map((key: any) => {
                          return this.renderCouponList(key, groupedUsed[key] )
                        })}
                      </div>
                    </div>
                  ) : (
                    views.valid.active &&
                    !loadingCoupons && (
                      <div className="without-products-card">
                        <GuruWhiteScreenProps
                          text={"Ingresa tu primer cupón utilizando el validador"}
                        />
                      </div>
                    )
                  )}
                  {lengthExpired > 0 && segmentCoupons === 2 && !loadingCoupons ? (
                    <div>
                      <div className="message-top-creator">Cupones</div>
                      <div className="wrapper-orders">
                        {Object.keys(groupedExpired).map((key: any) => {
                          return this.renderCouponList(key, groupedExpired[key], false, false )
                        })}
                      </div>
                    </div>
                  ) : (
                    views.expired.active &&
                    !loadingCoupons && (
                      <div className="without-products">
                        <IonImg src={withoutCouponsIcon} alt="cupones" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </div>
                    )
                  )}
                </>
              }
            </div>
          )}
          {alertDelete && (
            <IonAlert
              isOpen={true}
              message={messageDelete}
              buttons={[
                {
                  text: 'OK',
                  handler: () =>
                    this.setState({
                      alert: false,
                      alertDelete:false
                    }),
                },
              ]}
            />
          )}
        </IonContent>
        <div className="container-bottom-sheet-coupons">
          {bottomSheetShow && this.renderBottomSheet()}
        </div>
        {bottomSheetShow &&  
          <style>
          {`
            .footer-menu-home {
            display: none !important; 
            }
          `}
        </style>}
        {isOpenTermsConditionsCoupons && 
          <TermsConditionsCoupons 
          isOpen={isOpenTermsConditionsCoupons} 
          closeTermsModal={this.closeTermsConditionsCoupons}
          />
        }
      </IonPage>
    )
  }
}

export default withRouter(withIonLifeCycle(Coupons))