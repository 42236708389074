import React from 'react'
import { IonContent, IonButton } from '@ionic/react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import GlobalComponents from '../../components'

// Iconos
import guruProImg from './../../assets/turbant_pro.png'

import './GuruPro.scss'

interface IPathParams {}

type IProps = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  orderConfirmation?: Boolean
}

interface RouteState {}

interface Form {
  username: string
  email: string
  phone: string
  message: string
}

interface State<T> {
  showModal: boolean
}

class GuruPro extends React.PureComponent<any> {
  state: State<Form> = {
    showModal: false,
  }

  showModal = (dismissAgent?: boolean) => {
    const { showModal } = this.state
    this.setState(
      {
        showModal: !showModal,
      },
      () => {
        if (dismissAgent) {
          this.props.history.push('/orders')
        }
      },
    )
  }

  render() {
    const { showModal } = this.state

    return (
      <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className="modal-pay-guru-pro">
          <div className="content">
            <div className="header">
              <div>Gracias por realizar tu compra</div>
              <div>en Almacén Gurú.</div>
            </div>
            <div className="body-guru-pro">
              <div className="star" style={{ backgroundImage: `url(${guruProImg})` }}></div>
            </div>
            <div className="container-info-productModal">
              <div className="title-pay-guru-pro">Gurú Pro</div>
              <div className="info-modal">
                Te invitamos a ser un Gurú Pro completando tu información en el perfil. Así accederás a promociones
                especiales para ti e información sobre productos destacados y más vendidos.
              </div>
            </div>
            <div className="footer-btn">
              <IonButton className="profile" size="small" onClick={() => this.props.history.push('/profile')} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {'Ir a perfil'}
              </IonButton>
              <IonButton className="contact" size="small" onClick={() => this.showModal()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {'Agendar Contacto Agente'}
              </IonButton>
            </div>
          </div>
        </div>
        {showModal && (
          <GlobalComponents.Modals
            title={'Agenda contacto con un agente'}
            img={false}
            openModal={showModal}
            onDismiss={this.showModal}
          />
        )}
      </IonContent>
    )
  }
}

export default withRouter(GuruPro)
