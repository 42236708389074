import React from 'react';
import './ButtonSelect.scss';

interface ButtonSelectProps {
  onPressBtn: any,
  isOpen?: boolean
}
const actClose = require("../../assets/icons/act_less.svg")
const actOpen = require("../../assets/icons/act_more.svg")

const ButtonSelect: React.FC<ButtonSelectProps> = ({onPressBtn, isOpen}) => (
  <div className="container-btn-select">
    <button className="btn-tertiary btn-select" onClick={onPressBtn}>
      {isOpen ? 'Ver menos' : 'Ver más'}
      <img src={isOpen ? actClose : actOpen} alt="Icono abrir"/>
    </button>
  </div>
);

export default ButtonSelect;