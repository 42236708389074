import React from 'react'
import { IonModal, IonContent } from '@ionic/react'
import './LoadingFullPage.scss'

const LoadingFullPage = () => {
  return(
    <IonModal cssClass="spinner-container-full-page" isOpen={true}>
      <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className='spinner-container'>
          <div className="spinner" />
        </div>
      </IonContent>
    </IonModal>
  )
}

export {LoadingFullPage}
