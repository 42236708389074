import React from 'react'
import User from '../../models/User'
import Settings from '../../models/Settings'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { ProductModel } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'
import './Order.scss'
import { ProviderModel } from '../../models/ProviderModel'
import { formatCurrency } from '../../utils/intl'
import { uomTypeAsText } from '../../models/ProductModel'

import placeholderImage from '../../assets/icons/placeholder-image.svg'


type IPathParams = {
  id: string
}

type IProps = RouteComponentProps<IPathParams> & {
  readonly user: User
  readonly settings: Settings
  readonly productModel: ProductModel
  readonly cartModel: CartModel
  readonly providerModel: ProviderModel
  orderUser:any
}

interface State {
  order: any
}

class OrderProduct extends React.PureComponent<IProps, State> {
  state: State = {
    order: {},
  }

  componentDidMount() {
    this.setState({
      order: this.props.orderUser
    })
  }

  validateIfExistTotalPrice = (item:any) =>  item.infoPrice && item.infoPrice.totalPrice

  render() {
    const { order } = this.state

    return (
      <div className="card-order-product">
						{order && order.products && order.products.length > 0 &&
							order.products.map((item:any, index: number) => {
                const unitOfMeasure = item?.infoPrice.uom && item?.infoPrice.units > 1 && uomTypeAsText(item?.infoPrice.uom, item?.infoPrice.units > 1)

							  return (
									<div className='card-product' key={index}>
										<div className='quantity'>{item.quantity}</div>
										<div className='card-img' style={{ backgroundImage: `url(${item.imageFullPath !== '' ? item.imageFullPath  : placeholderImage})` }}></div>
										<div className='card-text'>
											<div className='card-name'>{item.name.slice(0, 19)}...</div>
                      {unitOfMeasure && item?.infoPrice.units && <p className='card-name'>{`${item.infoPrice.units} ${unitOfMeasure} por caja`}</p>}
											<div className='card-total'>{formatCurrency(this.validateIfExistTotalPrice(item))} </div>
										</div>
									</div>
								
								)
							})
						}
      </div>
    )
  }
}

export default withRouter(OrderProduct)
