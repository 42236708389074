/**
 * Valida si existen proveedores disponibles en la comuna activa en la app
 * @returns {boolean} Validacion si existen oficinas disponibles
 */
export const isOfficeSupported = (): boolean => {
  const validationModalGuestUser = localStorage.getItem('isUserInvited')
  const providers = localStorage.getItem('@providers')

  if (validationModalGuestUser) {
    return true
  }

  if (providers) {
    const providersList = JSON.parse(providers)
 
    const activeProvidersCount = Object.values(providersList).length
   
    if (activeProvidersCount > 0) {
      return true
    }
    return false
  }
  return false
}

/**
 * Valida si el proveedor asociado al carro o el pedido
 * esta activo en la comuna activa en la app
 * @param {string} idProvider Id del proveedor  
 * @returns {boolean} Validacion si el proveedor está disponible en la comuna activa
 */
export const isProviderActive = (idProvider: string): boolean => {
  const providers = localStorage.getItem('@providers')
  
  if (providers) {
    const providersList = JSON.parse(providers)
    const provider: any = Object.values(providersList).find((item: any) => {
      return item.id === idProvider
    })
    
    if (provider) {
      return provider.isActiveInComune && provider.sameBussinestype
    }
    return false
  }
  return false
}
