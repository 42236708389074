import SearchBar from './search-bar/SearchBar'
import ProductSlider from './product-slider'
import ProductPlp from './product-plp/ProductSlider'
import ProductCard from './product-card/ProductCard'
import ProductModal from './product-modal/ProductModal'
import Form from './../pages/form/Form'
import PhotosUser from './../pages/photosUser/PhotosUser'
import ProductSliderAsync from './product-slider-async/ProductSliderAsync'
import OrderConfirmedModal from './order-confirmed-modal/OderConfirmedModal'
import Modals from '../pages/modals/Modals'
import Onboarding from './onboarding/Onboarding'
import GuruWhiteScreen from './guru-white-screen/GuruWhiteScreen'
import LoadingComponent from './loading-component/LoadingComponent'
import ToolBar from './tool-bar/ToolBar'
import FeedbackNoScope from './feedback-no-scope/FeedbackNoScope'

export default {
  SearchBar,
  ProductSlider,
  ProductPlp,
  ProductCard,
  ProductModal,
  Form,
  PhotosUser,
  ProductSliderAsync,
  OrderConfirmedModal,
  Modals,
  Onboarding,
  GuruWhiteScreen,
  LoadingComponent,
  ToolBar,
  FeedbackNoScope,
}
