import React from 'react'
import {
  IonContent,
  IonPage,
  IonToast,
} from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import track from 'react-tracking'
import UserModel from '../../models/User'
import SettingsModel from '../../models/Settings'
import { produce } from 'immer'

import { validateCodeSms, recoveryPaswordSmsEmail } from '../../clients/validateSms'

//ICONS
import eraseVerify from './../../assets/icons/erase-verify.svg'
import arrowBack from './../../assets/icons/arrow-back-blue.svg'

import GuruHelper from '../../components/guru-helper/GuruHelper'
import HeaderWithoutToolbar from '../../components/header-without-toolbar/HeaderWithoutToolbar'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import ContactSupport from '../../components/contact-support/ContactSupport'

import './ConfirmCodeSms.scss'

type IPathParams = {}

type Props = RouteComponentProps<IPathParams> & {
  user: UserModel
  settings: SettingsModel
  tracking?: any
}

interface Form {
  code1: any
  code2: any
  code3: any
  code4: any
}

interface State<T> {
  data: any
  validation: { [name in keyof T]: boolean }
  isFormValid: boolean
  showToast: boolean
  codeData: any
}

class ConfirmCodeSms extends React.PureComponent<Props, State<Form>> {
  state: State<Form> = {
    data: {
      code1: null,
      code2: null,
      code3: null,
      code4: null,
    },
    validation: {
      code1: false,
      code2: false,
      code3: false,
      code4: false,
    },
    isFormValid: false,
    showToast: false,
    codeData: {}
  }
  input1: any = React.createRef();
  input2: any = React.createRef();
  input3: any = React.createRef();
  input4: any = React.createRef();

  componentDidMount() {
    const userHashedCode:any = this.props.history.location.state

    this.setState({
      codeData: userHashedCode
    })
  }
  

  onKeyboard = async (valueTarget: any) => {
    const {
      data: { code1, code2, code3, code4 }
    } = this.state
    let type = ''
    let validation: any = false
    let value = valueTarget.length > 1 ? valueTarget.substr(1) : valueTarget;
    
    if (!code1) {
      if (value === '') {
        value = null
        validation = false
      } else{
        validation = true
        type = 'code1'
        this.input2.current.focus();
      }
    } else if (code1 && !code2) {
      if (value === '') {
        value = null
        type = 'code1'
        this.input1.current.focus();
        validation = false
      } else {
        validation = true
        type = 'code2'
        this.input3.current.focus();
      }
    } else if (code1 && !code3) {
      if (value === '') {
        value = null
        type = 'code2'
        this.input1.current.focus();
        validation = false
      } else {
        validation = true
        type = 'code3'
        this.input4.current.focus();
      }
    } else if (code1 && code2 && code3 && !code4) {
      if (value === '') {
        value = null
        type = 'code3'
        this.input2.current.focus();
        validation = false
      } else {
        validation = true
        type = 'code4'
        this.input4.current.focus();
      }
    } else if (code1 && code2 && code3 && code4) {
      if (value === '') {
        value = null
        type = 'code4'
        this.input3.current.focus();
        validation = false
      }
    }


    await this.setState(
      produce(this.state, draft => {
        switch (type) {
          case 'code1':
            draft.data[type] = value
            draft.validation[type] = validation
            break
          case 'code2':
            draft.data[type] = value
            draft.validation[type] = validation
            break
          case 'code3':
            draft.data[type] = value
            draft.validation[type] = validation
            break
          case 'code4':
            draft.data[type] = value
            draft.validation[type] = validation
            break
          default:
            break;
        }

        let allAreTrue = true
        ;(Object.keys(draft.validation) as Array<keyof typeof draft.validation>).forEach(key => {
          const field = draft.validation[key]
          if (field === false) {
            allAreTrue = false
          }
        })
        draft.isFormValid = allAreTrue
      }),
    )
  }


  onButtonClick = async () => {
    const {
      data: { code1, code2, code3, code4 }, codeData
    } = this.state

    const codeSms = String(code1 + code2 + code3 + code4)
    const result:any = await validateCodeSms(codeSms,codeData.hashedCode, codeData.username)
    if(result && result.data.isValid){
      this.props.history.push(`/confirm-recovery-password`,{
        userName:codeData.username,
        token:result.data.token
      })
    } else {
      this.setState({showToast: true})
    }
  }

  resendCode = async () => {
    const { history } = this.props
    const userHashedCode:any = history.location.state
    const data ={
      type:userHashedCode.type,
      username:userHashedCode.username
    }
    const newCodeData = await recoveryPaswordSmsEmail(data)

    this.setState({
      codeData: newCodeData
    })
  }

  render() {
    const {
      data: { code1, code2, code3, code4 }, showToast, isFormValid
    } = this.state
    const { history } = this.props
    const userHashedCode:any = history.location.state
    const validationText = userHashedCode.type === 'phone' ? 'mensaje de texto' : 'correo electrónico'

    return (
      <IonPage className="confirm-code-sms" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonContent className="content-confirm-code" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <HeaderWithoutToolbar 
            icon={arrowBack}
            text="Recupera tu acceso"
            onClick={history.goBack}
          />
          <GuruHelper 
            label="Valida el código" 
            text={`Enviamos el código vía ${validationText}, ingrésalo aquí`}
          />
          <form>
            <div className="form">
              <div className="container-input-number">
                <div className={'code-valid'}>
                  <input 
                    className={`code-number-input ${code1 ? 'valid' : ''}`}
                    onChange={(e) => this.onKeyboard(e.target.value)}
                    value={code1 || ""}
                    minLength={1}
                    maxLength={1}
                    required={true}
                    type="number"
                    ref={this.input1}
                  />
                </div>
                <div className={'code-valid'}>
                  <input 
                    onChange={(e) => this.onKeyboard(e.target.value)}
                    className={`code-number-input ${code2 ? 'valid' : ''}`}
                    value={code2 || ""}
                    minLength={1}
                    maxLength={1}
                    required={true}
                    type="number"
                    ref={this.input2}
                  />
                </div>
                <div className={'code-valid'}>
                  <input 
                    onChange={(e) => this.onKeyboard(e.target.value)}
                    className={`code-number-input ${code3 ? 'valid' : ''}`}
                    value={code3 || ""}
                    minLength={1}
                    maxLength={1}
                    required={true}
                    type="number"
                    ref={this.input3}
                  />
                </div>
                <div className={'code-valid'}>
                  <input 
                    onChange={(e) => this.onKeyboard(e.target.value)}
                    className={`code-number-input ${code4 ? 'valid' : ''}`}
                    value={code4 || ""}
                    minLength={1}
                    maxLength={1}
                    required={true}
                    type="number"
                    ref={this.input4}
                  />
                </div>
              </div>
              <div className="container-label-resend-code">
                <label className="label-resend-code">
                  ¿No recibiste el código? <p onClick={this.resendCode}>Reenviar el código</p>
                </label>
              </div>
              <div className="container-btn-validate-code">
                <ButtonComponent
                  className="btn-primary"
                  text="Validar"
                  disabled={!isFormValid}
                  onClick={this.onButtonClick}
                />
              </div>
            </div>
            <div className="container-help" >
              <ContactSupport />
            </div>
          </form>
        </IonContent>
        <IonToast
          isOpen={showToast}
          mode="ios"
          cssClass="code-invalid"
          message="Código invalido"
          duration={500}
        />
      </IonPage>
    )
  }
}

export default track({ page: 'LoginConfirmSms' })(withRouter(ConfirmCodeSms))