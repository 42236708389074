import React, { useState, useEffect } from 'react'
import {

} from '@ionic/react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router'

//Models
import User from '../../models/User'
import Settings from '../../models/Settings'
import { ProductModel } from '../../models/ProductModel'
import Office from '../../models/Office'
import { CartModel } from '../../models/CartModel'
import { ProviderModel } from '../../models/ProviderModel'
import Category from '../../models/Category'
import { PromotionModel } from '../../models/PromotionModel'

//Componentes
import ModalWelcomeGuru from '../modal-welcome-guru/ModalWelcomeGuru'
import ModalRecoveryPassword from '../../pages/modal-recovery-password/ModalRecoveryPassword'
import ConfirmOrder from '../../pages/confirm-order/ConfirmOrder'
import ModalTermsConditions from '../terms-conditions/ModalTermsConditions'
import AlertModal from '../../components/modals/AlertModal'


interface IPathParams {}

interface RouteState {
}

type Props = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  offices: Office
  categories: Category
  promotion: PromotionModel
  productModel: ProductModel
  cartModel: CartModel
  providerModel: ProviderModel
  recoveryPassword?: any
  showModals:boolean
  titleWelcome?: string
  subtitleWelcome?: string
  showModalWelcome?: boolean
  fromSurvey?: boolean
  closeRedirectLink?: string
  closeLinkLocationState?: string
  userInfo?: any
}

const ModalsController: React.FC<Props> = props => {
  const [recoveryPassword, setRecoveryPassword] = useState<any>(false)
  const [modalTermsConditions, setModalTermsConditions] = useState<any>(false)
  const [welcomeGuru, setWelcomeGuru] = useState<any>(false)

  useEffect(() => {
    get()
  }, [props.userInfo])


  const get = async () => {
    if(props.showModals){
      showModalRecoveryPasswordLocal()
      showModalTermsandconditions()
      showModalWelcomeGuru()
    }
  }


  const showModalTermsandconditions = async () => {
     const termsandconditions = props.userInfo?.termsandconditions
 
     if (!termsandconditions) setModalTermsConditions(true)
   } 
 

  const showModalRecoveryPasswordLocal = () => {
    const recoveryPassword:any = props.recoveryPassword
    const showModalRecoveryPasswordLocal = localStorage.getItem("recoveryPassword")
    const showModalRecoveryPassword =  showModalRecoveryPasswordLocal ? JSON.parse(showModalRecoveryPasswordLocal) : null

    if(recoveryPassword?.recoveryPassword && showModalRecoveryPassword) setRecoveryPassword(true) 
  }

  const showModalTermsConditions = () => {
    return (
      <ModalTermsConditions {...props} />
    )
  }

  const modalRecoveryPassword = () => {
    return (
      <ModalRecoveryPassword 
      recoveryPassword={recoveryPassword}
      closeModalRecoveryPassword={closeModalRecoveryPassword}
      {...props} />
    )
  }

  const closeModalRecoveryPassword = () => {
    localStorage.setItem("recoveryPassword", JSON.stringify(false))
    setRecoveryPassword(false)
  }
 
  const showModalWelcomeGuru = () =>{
    const {showModalWelcome} = props

    const showModalshowModalWelcomeGuruLocal = localStorage.getItem("welcomeGuru")
    const showModalWelcomeGuru =  showModalshowModalWelcomeGuruLocal ? JSON.parse(showModalshowModalWelcomeGuruLocal) : null

    if(showModalWelcomeGuru || showModalWelcome) setWelcomeGuru(true)
  }

  const modalWelcomeGuru = () => {
    const {titleWelcome, subtitleWelcome, fromSurvey} = props
    return (
      <ModalWelcomeGuru 
        title={titleWelcome || ''}
        subtitle={subtitleWelcome || ''}
        openModalWelcome={welcomeGuru}
        closeModalWelcomeGuru={closeModalWelcomeGuru}
        from={fromSurvey || false}
        {...props} 
        closeRedirectLink={props.closeRedirectLink}
        closeLinkLocationState={props.closeLinkLocationState}
      />
    )
  }

  const closeModalWelcomeGuru = () => {
    localStorage.setItem("welcomeGuru", JSON.stringify(false))
    setWelcomeGuru(false)
  }

 
  return (
    <>
      {recoveryPassword && modalRecoveryPassword()}
      {modalTermsConditions && showModalTermsConditions()}
      {welcomeGuru && modalWelcomeGuru()}
    </>
  )
}

export default withRouter(ModalsController)
