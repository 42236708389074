import Axios from 'axios'
export const login = async (dni: string, password: string) => {
  try {
    const result = await Axios.post<object>(`${process.env.REACT_APP_BFF_NODE}/v1/users/login`, {
      username: dni,
      password: password
    })
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw error
  }
}

export const loginv2 = async (dni: string, password: string, lastToken?: any) => {
  let headers: any = {}
  if (lastToken) {
    const separator = `"`
    const cleanedToken = lastToken.split(separator)
    headers = {
      'Authorization': `Bearer ${cleanedToken[1]}`
    }
  }


  try {
    const result = await Axios.post<object>(`${process.env.REACT_APP_BFF_NODE}/v1/users/loginv2`, 
    {
      username: dni,
      password: password
    },
    {
      headers
    }
    )
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw error
  }
}

export const loginPassword = async (dni: string) => {
  try {
    const result = await Axios.post<object>(`${process.env.REACT_APP_BFF_NODE}/v2/login`, {
      username: dni
    })
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw error
  }
}

export const confirm = async (accessToken: string, form: any) => {
  try {
    const result = await Axios.put<object>(`${process.env.REACT_APP_BFF_NODE}/v1/users/confirm`, 
    {
      phone: form.phone, 
      email: form.email,
      password: form.password,
    }, {
        params: {
          access_token: accessToken
        }
      })
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw error
  }
}

export const update = async (form: any) => {
  try {
    const result = await Axios.put<object>(`${process.env.REACT_APP_BFF_NODE}/v1/users/update`, form)
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw error
  }
}

export const password = async (accessToken: string, password: string) => {
  try {
    const result = await Axios.put<object>(`${process.env.REACT_APP_BFF_NODE}/v1/users/password`, {
      password
    }, {
        params: {
          access_token: accessToken
        }
      })
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw error
  }
}

export const validateCaptcha = async  (token: string) => {
  try {
    const result = await Axios.post(`${process.env.REACT_APP_BFF_PUBLIC}/v2/users/recaptcha`, {token});
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw error
  }
}

export const userValidate = async  (user: string) => {
  try {
    const result = await Axios.get(`${process.env.REACT_APP_BFF_PUBLIC}/v1/passwords/recovery`,{
      params:{
          value:user,
          type:'username'
      }
    });
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw error
  }
}
