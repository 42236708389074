import { Container } from 'unstated-typescript'
import { find } from 'lodash'
import { fetchProviders, getHome, serchProductProvider, getOffersVendor, getProvidersRibbons } from '../clients/provider'
import { getFromCache } from '../utils/cache'
import { getLocalStorageObject } from '../utils/localstorageData'

export interface Provider {
  id: string,
  name: string,
  alias:string,
  days: any,
  weekday?: any,
  minimun?: number,
  deliveryDays: number[],
  deliveryInfo: any,
  paymentMethods?: any
  paymentForm?: string,
  logo: string,
  disponible: boolean
  banners: any 
  sameBussinestype: boolean
  linegrid:number
  paymentFormDefault?:string
  aditionalInfo?:string
  isActiveInComune:boolean
  qualification: any
}

type Providers = { [key: string]: Provider }

interface ProductState {
  providers: Providers
}

const storageName = '@providers'
export class ProviderModel extends Container<ProductState> {
  state: ProductState = {
    providers: {},
  }

  constructor () {
    super()

    const fromCache = getFromCache<Providers>(storageName)
    if (fromCache) {
      this.setState({ providers: fromCache })
    }
  }

  loadProviders = async () => {
    const providers = await fetchProviders()
    const map = providers.reduce<Providers>((o, p) => ({ ...o, [p.id]: p }), {})
    this.setState({ providers: map })
    localStorage.setItem(storageName, JSON.stringify(map))
    return map
  }

  isSingleProvider = (): boolean => {
    const singleProviderStorage = localStorage.getItem('@providers')
    const singleProviderObject = singleProviderStorage ? JSON.parse(singleProviderStorage) : null
    const dataVendors = Object.values(singleProviderObject).filter((item:any)=> item.isActiveInComune !== false)
    const isSingleProvider = dataVendors.length === 1
    return isSingleProvider
  }

  getById = (providerId: string, office?: any) => {
    const provider = this.state.providers[providerId]
    if (office) {
      const officeFound = find(provider.days, (off: any) => {
        return off && off.officeId && off.officeId._id === office._id
      })
      if (officeFound) provider.weekday = officeFound.days
    }
    return provider
  }

  getHome = async () => {
    let homeRes = await getHome()
    const home = homeRes || getLocalStorageObject("homeData")
    
    const data = home?.vendors
    const map = data?.reduce((o: any, p: any) => ({ ...o, [p.id]: p }), {}) || {}

    this.setState({ providers: map })
    localStorage.setItem(storageName, JSON.stringify(map))
  
    return {
      banners:home.banners,
      vendors:map,
      steps:home.steps
    }
  }

  getProvidersRibbons = async () => {
    try {
      const ribbons: any = await getProvidersRibbons()
      return ribbons?.data || []
    } catch (error) {
      console.error(error)
      return []
    }
  }

  serchProductProvider = async (providerId: string, query: string) => {
    const result = await serchProductProvider(providerId, query)
    return result
  }

  getOffersVendor = async (vendorId: string) => await getOffersVendor(vendorId)
}
