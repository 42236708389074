import { getLocalStorageObject } from "./localstorageData";

export const isModalPromotionVisualized = (promotionId: string): boolean => {
  let visitedPromotions = getLocalStorageObject('@visitedPromotions')

    if (!visitedPromotions) {
      localStorage.setItem('@visitedPromotions', JSON.stringify([promotionId]));
      return false
    }
    
    if (!visitedPromotions.includes(promotionId)) {
      localStorage.setItem('@visitedPromotions', JSON.stringify([...visitedPromotions, promotionId]))
      return false
    }
    
    return true
}