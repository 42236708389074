import React from 'react'

import { IonSlides, IonSlide } from '@ionic/react'
import { trackBannersActions } from '../firebase/firebaseTags'

import { counterEvent }  from '../../clients/eventCounter'
import { manageBannerClick } from '../../utils/deeplinks'

import './BannersProvider.scss'

interface IProps {
  slidesPerView?: number
  banners: any
  nameProvider: string
  history: any
}

interface IState {}
const bannerType = 'banner_vendor'  
export default class BannerProvider extends React.PureComponent<IProps, IState> {

  isOnePromo = this.props.banners?.length === 1

  options = {
    allowTouchMove: !this.isOnePromo,
    slidesPerView: this.isOnePromo ? 1.1 : 1.2,
    slidesOffsetBefore: 19,
    slidesOffsetAfter: 19,
    spaceBetween: 19,
    preloadImages: true,
    autoplay: this.isOnePromo ? false : {
      delay: 2500 + (Math.random() * 2000),
      disableOnInteraction: true,
    },
    on: {
      activeIndexChange() {
        const swiper: any = this
        let element = swiper.el
        
        if(element) {
          let position = element.getBoundingClientRect();
          if(position.top >= 0 && position.bottom <= window.innerHeight) {
            let providerName = ''
            let bannerId = ''
            let bannerName = ''
            const targetSlide = `slide-${swiper.activeIndex}`
            const bannerAction = 'ad_banner_impression'
            const slideElement = element.getElementsByClassName(targetSlide)[0]
            if(slideElement) {
              providerName = slideElement.getAttribute('data-vendor-name')
              bannerId = slideElement.getAttribute('data-banner-id')
              bannerName = slideElement.getAttribute('data-name-banner')
            }
            trackBannersActions(bannerAction, bannerType, providerName, bannerId, 'vendor', bannerName)
          }
        }
      },
    }
  }

  onBannerClick = async (banner: any) => {
    const { _id, name} = banner
    const { nameProvider, history } = this.props
    const bannerClick = 'ad_banner_click'
    const user = localStorage.getItem('@user') 
    const userId = user ? JSON.parse(user) :  null
    trackBannersActions(bannerClick, bannerType, nameProvider, _id, 'vendor', name)
    await counterEvent('banner-provider', banner.type, userId.id, banner._id, 'click')

    manageBannerClick(banner, history)
  }

  render() {
    const { banners, nameProvider } = this.props

    return (
      <div className="banners-provider-slider">
        <IonSlides pager={false} options={this.options} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {banners.map((banner:any, index: number)=> (
            <IonSlide 
              key={banner._id}
              data-vendor-name={nameProvider}
              data-banner-id={banner._id}
              data-name-banner={banner.name}
              className={`slide-${index} banners-provider-slide`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
                <img 
                  src={`${process.env.REACT_APP_BFF_IMAGE}${banner?.image?.url}`} 
                  alt="Banner Imagen" 
                  onClick={() => this.onBannerClick(banner)}  
                  className="promotion-img-provider"
                />
            </IonSlide>
          ))}
        </IonSlides>
      </div>
    )
  }
}
