import Axios from 'axios'

export interface Delivery {
  dispatchDate: any
  dispatchHour: any
  externalId: any
  officeId: any
  orderId: any
  orderNumber: any
  products: any
  providerId: any
  subtotal: any
  total: any
  value: any
}

export const postDelivery = async (order: Delivery): Promise<any> => {
  try {
    const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_NODE}/v1/deliveries`, order)
    return result
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const find = async (skip: any, limit: any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/deliveries`, {
      params: {
        filter: JSON.stringify({
          skip,
          limit
        })
      }
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const count = async () => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/deliveries/count`)
    return result.data.count
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}
