import { IonModal } from '@ionic/react'
import React from 'react'
import LoadingSvg from '../../../../svg/LoadingSvg'
import './index.scss'

interface Props {
  open: boolean
  title: string
  message: string
  action: (id?: string) => void
  closeModal: () => void
  loadingButton: boolean
  buttonActionName: string
}

function DeleteModal({ open, title, message, action, closeModal, loadingButton, buttonActionName }: Props) {
  return (
    <IonModal isOpen={open} id="delete-modal">
      <div className="wrapper">
        <h2>{title}</h2>
        <p>{message}</p>

        <div>
          <button onClick={() => action()}>
            {loadingButton ? <LoadingSvg width={22} height={22} color="#fff" background="#00B0FF" /> : buttonActionName}
          </button>
          <button onClick={closeModal}>Cancelar</button>
        </div>
      </div>
    </IonModal>
  )
}

export default DeleteModal
