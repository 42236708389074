import React from 'react'
import './index.scss'

function SchedulesFulfillment() {
  return (
    <div className="schedules-fulfillment">
      <p>
        <span>Compra</span> antes de las 14 hrs, <span>Recibe</span> en 24 hrs.
      </p>

      <p>
        <span>Compra</span> después de las 14 hrs, <span>Recibe</span> en 48 hrs.
      </p>
    </div>
  )
}

export default SchedulesFulfillment
