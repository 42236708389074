import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext'
import { CouponActivated } from '../../interfaces/coupons'

function useCheckAplicatedCoupon(coupons: CouponActivated[], providerId: string) {
  const { cartData } = useContext(AppContext)
  const [aplicatedCoupon, setAplicatedCoupon] = useState<boolean>(false)

  useEffect(() => {
    const action = (): boolean => {
      for (const coupon of coupons) {
        if (
          cartData.discounts.find(
            (discount) => discount.couponId === coupon.couponId && discount.providerId === providerId,
          )
        )
          return true
      }

      return false
    }

    setAplicatedCoupon(action())
  }, [cartData.discounts, coupons, providerId])

  return { aplicatedCoupon }
}

export default useCheckAplicatedCoupon
