import React, { useState, useEffect } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import track from 'react-tracking'

// Components
import { IonContent, IonPage } from '@ionic/react'
import HeaderWithoutToolbar from '../../components/header-without-toolbar/HeaderWithoutToolbar'
import GuruHelper from '../../components/guru-helper/GuruHelper'
import DropdownSelect from '../../components/select-dropdown/DropdownSelect'
import TextInput from '../../components/input-form/TextInput'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import GuruAcceptModal from '../../components/modal-action-accept/ModalActionAccept'

// Models
import User from '../../models/User'
import Office from '../../models/Office'

// Icons
import arrowBack from './../../assets/icons/arrow-back-blue.svg'

// Clients
import { getBusinessTypes, getComunesByDepartmentAndCountryCode, getComunesByProvincesAndCountryCode, getDepartmentsByCountryCode, getProvincesByDepartmentsAndCountryCode } from '../../clients/register'

// Utils
import { commercialNameByCountry, countriesWithProvinces, countryMx, countryPe, countryTerritorySelectText, territorialNames } from '../../utils/countriesTexts'
import { getLocalStorageObject } from '../../utils/localstorageData'

// Styles
import './BranchOffice.scss'

type territoryType = "region" | "province" | "commune"

type supportedCountry = "CL" | "PE" | "MX" | null

type IPathParams = {}

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  user: User
  offices: Office
}

const BranchOffice: React.FC<IProps> = props => {
  const [commercialName, setCommercialName] = useState<any>('')
  const [deparment, setDeparment] = useState<any>('')
  const [province, setProvince] = useState<any>('')
  const [address, setAddress] = useState<any>('')
  const [comune, setComune] = useState<any>('')
  const [departments, setDepartments] = useState<any[]>([])
  const [provinces, setProvinces] = useState<any[]>([])
  const [comunes, setComunes] = useState<any[]>([])
  const [country, setCountry] = useState<supportedCountry>(null)
  const [postalCode, setPostalCode] = useState<string>('');
  const [isValidRegister, setIsValidRegister] = useState<boolean>(false)
  const [businessTypes, setBusinessTypes] = useState<any[]>([])
  const [businessTypesOptions, setBusinessTypesOptions] = useState<any[]>([])
  const [selectedBusinessType, setSelectedBusinessType] = useState<any>(null)
  const { history, user, offices }: any= props
 
  useEffect(() => {
    if (history?.location?.state?.lateRegistration) fetchBusinessTypes();
  }, [])

  
  useEffect(() => {
    getCurrentCountry()
  }, [!departments]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBusinessTypes = async () => {
    const countryCode = getLocalStorageObject('countryName')?.countryCode;
    const businessTypes = await getBusinessTypes(countryCode);
    const businessTypesOptions: string[] = []
    
    const parsedBusinessTypes = businessTypes.map((business:any) =>  { 
      const { _id, name } = business
      return { value: name, label: name, id: _id }
    })


    businessTypes.forEach(({ name, visible }: any) => {
      if (!visible) return
      businessTypesOptions.push(name)
    })

    setBusinessTypes(parsedBusinessTypes);
    setBusinessTypesOptions(businessTypesOptions);
  }
  
  const handlerCreateOffice = async () => {
    setIsValidRegister(true)
  }

  const getCurrentCountry = async () => {
    const currentCountry = localStorage.getItem('countryName')
    if (currentCountry) { 
      setCountry(JSON.parse(currentCountry).countryCode)
      await getDepartments(JSON.parse(currentCountry).countryCode)
    }
  }

  const getDepartments = async (countryCode:string) => { 
    const department = await getDepartmentsByCountryCode(countryCode)
    if (department) setDepartments(department)
  }
  
  const getProvinces = async (countryCode: supportedCountry, deparment:string) => {
    const allProvinces = await getProvincesByDepartmentsAndCountryCode(deparment, countryCode as string)
    const tempProvincesName:any = []
    allProvinces?.map((province:any) => (tempProvincesName.push(province)))

    setProvinces(tempProvincesName)
  } 

  const getComunesByProvince = async (countryCode: supportedCountry, province:string) => {
    const allComunes = await getComunesByProvincesAndCountryCode(province, countryCode as string) 
    const tempComunesName:any = []
    allComunes?.map((comune:any) => (tempComunesName.push(comune.name)))

    setComunes(tempComunesName)
  } 

  const getComunesByDepartment = async (countryCode: supportedCountry, department:string) => {
    const allComunes = await getComunesByDepartmentAndCountryCode(department, countryCode as string) 
    const tempComunesName:any = []
    allComunes?.map((comune:any) => (tempComunesName.push(comune.name)))

    setComunes(tempComunesName)
  } 

  const onSelectOptionDeparment = (optionVal:any) => {
    
    setDeparment(optionVal)
    setComunes([])
    if (optionVal === "") return;

    if (countriesWithProvinces.includes(country || "")) {
      getProvinces(country, optionVal)
    } else {
      getComunesByDepartment(country, optionVal)
    }
  }

  const onSelectOptionProvince = (optionVal:any) => {
    setComunes([])
    setProvince(optionVal)
    if (optionVal !== "") getComunesByProvince(country, optionVal)
  }

  const createOfficeAndRedirect = async () => { 
    const isLateRegistration = history?.location?.state?.lateRegistration

    if (isLateRegistration) return createOfficeFromLoggedIn();
    createOfficeFromRegister();
  }

  const createOfficeFromLoggedIn = async () => {
    try {
      const accessToken = localStorage.getItem('@access_token');
      const userData = getLocalStorageObject('@user');
      const officeBusinessId: string = selectedBusinessType?.id || "6050d6bab9cfa9df19a22f95" // nunca debería usar el id, se deja por si acaso

      await offices.createOffice(`${userData?.name || "Sin nombre"}`, officeBusinessId, commercialName, address, comune, accessToken, deparment, province, postalCode)
      window.location.href = '/home';
    } catch (error) {
      console.error("Error----->", error)
    }
  }

  const createOfficeFromRegister = async () => {
    const authData = localStorage.getItem('registerSuccesfull')
    try {
      if (authData) {
        const parseAuthData = JSON.parse(authData)

        const officeBusinessId: string = history?.location?.state?.businessTypeId || "61c0b014e8a958aeecf573cc" // nunca debería usar el id, se deja por si acaso

        await user.authenticateWith(parseAuthData)
        await user.editCacheUser(parseAuthData.user)
        await offices.createOffice(
          `${parseAuthData.user.name || "Sin nombre"}`,
          officeBusinessId,
          commercialName,
          address, 
          comune, 
          parseAuthData.access_token, 
          deparment, 
          province, 
          postalCode)
        const dataUser =  {...parseAuthData,'welcomeGuru':true}
        
        history.push('/home', dataUser)
      }
    } catch (error) {
      console.error("Error----->", error)
    }
  }

  const onSelectOptionComune = (optionVal:any) => setComune(optionVal)
  
  const onChangeCommercialName = (event:any) => setCommercialName(event.target.value)

  const onChangeDirection = (event:any) => setAddress(event.target.value)

  const onChangePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const validateValue = value.substring(0, 5)
    setPostalCode(validateValue)
  }

  const countryTerritoryName = (territoryType: territoryType) => {
    const countryCode: supportedCountry = country
    if (!countryCode) return ""
    const countryTerritorialNames = territorialNames[countryCode]
    return countryTerritorialNames[territoryType]
  }

  const handleBusinessTypeSelect = (value: any) => {    
    const selectedBusinessType = businessTypes.find((businessType: any) => businessType?.value === value)
    setSelectedBusinessType(selectedBusinessType)
  }

  const signOut = async () => {
    await user.signOut()
    history.push('/')
    window.location.reload()
  }

  const validateDisabledSubmit = (): boolean => {
    const localizationValidations = !commercialName || !address 
    const territoriesValidations = !comune || !deparment || (country === "CL" ? false : !province) ;
    const postalCodeValidation = (country === countryMx && !validatePostalCode)
    const businessTypeValidation = history?.location?.state?.lateRegistration ? !selectedBusinessType : false

    return localizationValidations || postalCodeValidation || businessTypeValidation || territoriesValidations;
  }

  const validatePostalCode = postalCode.length === 5

  return (
    <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonContent className="container-add-office" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <HeaderWithoutToolbar 
          icon={arrowBack}
          text="Tu sucursal"
          onClick={signOut}
        />
        <GuruHelper 
          label="Agrega tu dirección"
          text="¡Último paso! Detalla la dirección de tu negocio para recibir pedidos"
        />

        {history?.location?.state?.lateRegistration
          ? <div className="container-address-items">
              <DropdownSelect 
                label="Tipo de Negocio"
                isDisabled={false}
                options={businessTypesOptions}
                firstOption={""}
                onSelect={handleBusinessTypeSelect}
                defaultSelected={businessTypesOptions[0]}
              />
            </div>
          : ''
        }

        <div className="container-address-items">
          <TextInput 
            onChange={onChangeCommercialName}
            placeholder="Ej: Abarrotes Lucy"
            label={commercialNameByCountry()}  
            isValid={!!commercialName.length}
            error={"El nombre comercial es obligatorio"}
            value={commercialName}
            type="text"
            toValidate={commercialName}
            autoComplete="off"
          />
        </div>
        
        <div className="container-address-items">
          <DropdownSelect 
            label={countryTerritoryName("region")}
            options={departments}
            firstOption={countryTerritorySelectText("region", country)}
            isDisabled={!departments?.length}
            onSelect={onSelectOptionDeparment}
          />
        </div>
        {country !== "CL" 
          ? <div className="container-address-items">
              <DropdownSelect 
                label={countryTerritoryName("province")}
                options={provinces}
                firstOption={countryTerritorySelectText("province", country)}
                isDisabled={!provinces?.length}
                onSelect={onSelectOptionProvince}
              />
            </div>
          : <></>
        }
        <div className="container-address-items">
          <DropdownSelect 
            label={countryTerritoryName("commune")}
            options={comunes}
            firstOption={countryTerritorySelectText("commune", country)}
            isDisabled={!comunes?.length}
            onSelect={onSelectOptionComune}
          />
        </div>
        {country === countryMx && 
          <div className="container-address-items">
            <TextInput 
              iconValid="icn_address"
              iconDefault="icn_address"
              onChange={onChangePostalCode}
              placeholder="Ej: 20997"
              label="Código Postal"  
              isValid={validatePostalCode}
              error={"Ingresa un código postal válido (5 dígitos)"}
              value={postalCode}
              type="number"
              toValidate={postalCode}
              autoComplete="off"
            />
          </div>
        }
        <div className="container-address-items">
          <TextInput 
            iconValid="icn_address"
            iconDefault="icn_address"
            onChange={onChangeDirection}
            placeholder="Ej: Mariano Angulo 123"
            label="Dirección"  
            isValid={!!address.length}
            error={"La dirección debe tener al menos un carácter"}
            value={address}
            type="text"
            toValidate={address}
            autoComplete="off"
          />
        </div>
        <div className="container-btn-add-direction">
          <ButtonComponent 
            text="Agregar"
            disabled={validateDisabledSubmit()}
            onClick={handlerCreateOffice}
            className="btn-primary"
          />
        </div>
      </IonContent>
      <GuruAcceptModal
        className="btn-primary"
        textButton="Hacer mi primer pedido"
        label="¡Estás listo!"
        secondLabel="Bienvenido a tu cuenta"
        onClickButton={createOfficeAndRedirect}
        disabled={false}
        isOpen={isValidRegister}
      />

      <style>
        {`
          .footer-menu-home {
          display: none !important; 
          }
        `}
      </style>
    </IonPage>
  )
}

export default track({ page: 'BranchOffice' })(withRouter(BranchOffice))