import { CountryInfo } from '../../interfaces/countryInfo'

export interface CountryInfoInitialState {
  countryInfo: CountryInfo
  handlerCountryInfo: (countryInfo: CountryInfo) => void
}

export const countryInfoInitialState: CountryInfoInitialState = {
  countryInfo: {
    city: '',
    countryCode: '',
    countryName: '',
  },
  handlerCountryInfo(countryInfo) {},
}
