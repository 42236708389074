import { Container } from 'unstated-typescript'
import { getSurveys, sendSurvey, additionalInfoSurvey, getSurveysKeys } from '../clients/survey'

interface SurveyState {}

export default class SurveyModel extends Container<SurveyState>{
  async getSurveys(countryCode?:string, key?:string, providerId?:string, group?:string, ){
    return await getSurveys(countryCode, key, providerId, group)
  }
  sendSurvey = async (surveyId:string, dataSurvey:Object) => await sendSurvey(surveyId, dataSurvey)
  additionalInfoSurvey = async (surveyId:string) => await additionalInfoSurvey(surveyId)
  getSurveysKeys = async () => await getSurveysKeys()
}