import { Container } from 'unstated-typescript'
import PouchDB from 'pouchdb'

interface SettingsState {
  first_run: boolean
  favoriteCategories: string[]
}

export default class Settings extends Container<SettingsState> {
  state = {
    first_run: true,
    favoriteCategories: ['Leches', 'Bebidas'],
  }
  db: PouchDB.Database = new PouchDB('settings', {
    auto_compaction: true,
    revs_limit: 1,
  })

  constructor() {
    super()
    this.bootstrap()
  }

  async bootstrap() {
    const info = await this.db.info()
    if (info.doc_count === 0) {
      await this.db.put({
        _id: 'settings',
        data: this.state,
      })
    } else {
      const settings = ((await this.db.get('settings')) as any).data as SettingsState
      await this.setState(settings)
    }
  }

  isFirstRun(): boolean {
    return this.state.first_run
  }

  favoriteCategories = (): string[] => this.state.favoriteCategories

  async setIsFirstRun(value: boolean) {
    await this.setState({
      first_run: value,
    })
    await this.save()
  }

  private save = async () => {
    const settings = (await this.db.get('settings')) as any
    settings.data = this.state

    await this.db.put(settings)
  }
}
