import React from 'react'
import User from '../../models/User'
import Settings from '../../models/Settings'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { ProductModel } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'
import { ProviderModel } from '../../models/ProviderModel'
import './Order.scss'



type IPathParams = {
  id: string
}

type IProps = RouteComponentProps<IPathParams> & {
  readonly user: User
  readonly settings: Settings
  readonly productModel: ProductModel
  readonly cartModel: CartModel
  readonly providerModel: ProviderModel
  orderUser:any
}

interface State {
  order: any
}

class OrderProduct extends React.PureComponent<IProps, State> {
  state: State = {
    order: {},
  }

  componentDidMount() {
    this.setState({
      order: this.props.orderUser
    })
  }


  renderInfoUser = (title: string, value: string) => {
    return (
      <div className='card-info-user'>
        <div className="title-field-user">{title}</div>
        <div className="value-field-user">{value}</div>
      </div>
    )
  }

  camelCaseMethodUsed = (method:string) => {
    const arr = method.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const capitalizeMethod = arr.join(" ");
    return capitalizeMethod
  }

  validateIfExistTotalPrice = (item:any) =>  item.infoPrice && item.infoPrice.totalPrice


  render() {
    const { orderUser } = this.props
    const { contact, store, paymentMethodTitle, country } = orderUser
    const { countryCellphoneCode } = country
    const { location } = store
    const {address, deparment, province, city} = location
    const formattedLocation =  `${address}, ${city}${province ? ', '+province+', ' : ''}${deparment ? deparment : ''}`
    

    return (
      <div className="card-order-product">
        <div className='card-user'>
          {paymentMethodTitle && this.renderInfoUser('Método de pago', this.camelCaseMethodUsed(paymentMethodTitle))}
          {formattedLocation && this.renderInfoUser('Dirección de entrega', formattedLocation)}
          {contact?.phone && this.renderInfoUser('Teléfono', `${countryCellphoneCode || ''} ${contact.phone}`)}
          {contact?.email && this.renderInfoUser('Email', contact.email)}
          {contact?.comment && this.renderInfoUser('Comentario', contact.comment)}
        </div>
      </div>
    )
  }
}

export default withRouter(OrderProduct)