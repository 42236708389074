import React from 'react'
import { IonContent, IonRouterOutlet, IonPage } from '@ionic/react'
import { BrowserRouter, Redirect, Route } from 'react-router-dom'
import {} from 'react-router'
import { AppLauncher } from '@capacitor/app-launcher'
import { App } from '@capacitor/app'

import { first } from 'lodash'
import User from '../../models/User'
import Office from '../../models/Office'
import Order from '../../models/Order'
import { Coupon } from '../../models/Coupon'
import Delivery from '../../models/Delivery'
import Category from '../../models/Category'
import Settings from '../../models/Settings'
import Pages from './../../pages'
import LoadingCover from '../../components/loading-cover/LoadingCover'
// Icons
import { CouponsActivatedModel } from '../../models/CouponsActivatedModel'
import { PromotionModel } from '../../models/PromotionModel'
import { ProductModel } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'
import { ProviderModel } from '../../models/ProviderModel'
import { validatorStorageForMenu } from '../reusableFunctions/CleanStorage'
import faqsModel from '../../models/Faqs'
import contactFormModel from '../../models/ContactForm'
import termsConditionModel from '../../models/TermsConditionModel'
import Notifications from '../../models/Notifications'
import SurveyModel from '../../models/Survey'
import { getOrderConfirmUser } from '../../clients/order'
import { Device } from '@capacitor/device'

// Update APP

import './Menu.scss'
import { HashtagModel } from '../../models/HashtagModel'
import PerformanceTimer from '../../models/PerformanceTimer'
import { callWithTimer, deleteTimer } from '../../utils/timers'
import AppContainer from '../../components/app-container/AppContainer'

interface IProps {
  user: User
  settings: Settings
  couponsActivatedModel: CouponsActivatedModel
  offices: Office
  categories: Category
  promotion: PromotionModel
  productModel: ProductModel
  cartModel: CartModel
  providerModel: ProviderModel
  orders: Order
  deliveries: Delivery
  coupons: Coupon
  showFaqs: faqsModel
  showContactDetails: contactFormModel
  showTermsConditions: termsConditionModel
  notification: Notifications
  surveyModel: SurveyModel
  hashtagModel: HashtagModel
}

interface IState {
  office: any
  offices: any
  loading: boolean
  appVersion: any
  nextUpdate: any
  info: any
  lock: boolean
  footerIsVisible: boolean
  lastRouteVisited: string
  userInfoMe?: any
  ordersConfirm?: any
  mounted: boolean
  redirectToLogout: boolean
}

export default class Menu extends React.PureComponent<IProps, IState> {
  state: IState = {
    office: {},
    offices: [],
    loading: true,
    appVersion: { update: false, url: null, required: false },
    nextUpdate: null,
    info: {},
    lock: false,
    footerIsVisible: true,
    lastRouteVisited: '',
    userInfoMe: null,
    ordersConfirm: [],
    mounted: false,
    redirectToLogout: false
  }

  routesThatDontAllowFooter: any[] = [
    '/cart',
    '/order-confirmation',
    '/guru-pro',
    '/categories',
    '/cart/1',
    '/cart/0',
    '/cart/2',
    '/cart/-1',
    '/register-optional',
    '/branch-office',
    '/notifications',
    '/terms-conditions',
  ]

  componentWillReceiveProps() {
    if (window.location.pathname !== this.state.lastRouteVisited) {
      this.setState({
        lastRouteVisited: window.location.pathname,
        footerIsVisible:
          this.routesThatDontAllowFooter.find((route) => window.location.pathname === route) !== undefined,
      })
    }
  }

  async componentDidMount() {
    const performanceTimer = PerformanceTimer.getInstance()
    performanceTimer.startPerformanceTimer()

    const { appVersion } = this.state
    const info = await Device.getInfo()
    const appInfoCapacitor = await App.getInfo().catch((err) => null)

    const nextUpdate = String(localStorage.getItem('@update'))
    deleteTimer('confirmationOrders')
    callWithTimer('confirmationOrders', 5, this.fetchOrdersToConfirm)

    const offices = await this.props.offices.get()

    const office = first(offices)
    this.props.offices.setOffice(office)
    this.update()

    const userInfo: any = await this.props.user.getUserMe()

    this.setState({
      loading: false,
      offices,
      userInfoMe: userInfo,
      lock: userInfo?.lock || false,
      office,
      mounted: true,
    })
    validatorStorageForMenu(info, nextUpdate, appVersion, this.state, appInfoCapacitor?.version || '')
  }

  fetchOrdersToConfirm = async () => {
    const ordersConfirm = await getOrderConfirmUser()
    if (ordersConfirm) this.setState({ ordersConfirm })
  }

  signOut = async () => {
    const { cartModel, user } = this.props
    await cartModel.clearCart()
    await user.signOut()
    window.location.replace('/')
  }

  goStore = async () => {
    const { appVersion } = this.state
    if (appVersion && appVersion.update && appVersion.url) {
      AppLauncher.openUrl({ url: appVersion.url })
    }
  }

  update() {
    const currentOffice = localStorage.getItem('currentOffice')
    setTimeout(() => {
      const office = currentOffice && currentOffice !== 'undefined' ? JSON.parse(currentOffice) : null
      this.setState({
        office,
      })

      if (currentOffice === 'undefined') {
        this.signOut()
      }
    }, 3000)
  }

  onRouteChange = (location: any) => {
    const path = location?.pathname
    const requests = [
      {
        routes: ['/home'],
        callback: () => callWithTimer('confirmationOrders', 5, this.fetchOrdersToConfirm),
      },
    ]

    requests.forEach(({ routes, callback }) => routes.includes(path) && callback())
  }

  render() {
    const { lock } = this.state

    return (
      <BrowserRouter>
        <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonContent id="content" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonRouterOutlet placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              {this.state.redirectToLogout && <Redirect to="/logout"/>}
              {this.state.loading ? (
                <LoadingCover isLoading={this.state.loading} />
              ) : (
                <AppContainer onRouteChange={this.onRouteChange}>
                  <Route
                    exact
                    path="/home"
                    render={(props) => (
                      <Pages.HomeView
                        {...this.props}
                        userInfoMe={this.state.userInfoMe}
                        ordersConfirm={this.state.ordersConfirm}
                        userOffices={this.state.offices}
                      />
                    )}
                  />
                  <Route
                    path="/home/:id"
                    render={(props) => (
                      <Pages.HomeView
                        {...this.props}
                        userInfoMe={this.state.userInfoMe}
                        ordersConfirm={this.state.ordersConfirm}
                        userOffices={this.state.offices}
                      />
                    )}
                  />
                  {!lock && (
                    <div>
                      <Route path="/profile" render={(props) => <Pages.ProfileView {...this.props} />} />
                      <Route path="/deliveries" render={(props) => <Pages.DeliveriesView {...this.props} />} />
                      <Route path="/orders" render={(props) => <Pages.OrdersView {...this.props} />} />
                      <Route path="/order" render={(props) => <Pages.OrderView {...this.props} />} />
                      <Route path="/order-help" render={(props) => <Pages.OrderHelp {...this.props} />} />
                      <Route path="/cart/:statePay?" render={(props) => <Pages.CartNew {...this.props} />} />
                      <Route
                        path="/search-products-providers"
                        render={(props) => <Pages.SearchProductsProvider {...this.props} />}
                      />
                      <Route path="/order-delete" render={(props) => <Pages.OrderDelete {...this.props} />} />
                      <Route path="/categories" render={(props) => <Pages.ListCategoriesView {...this.props} />} />
                      <Route path="/hashtag/:id" render={(props) => <Pages.HashtagView {...this.props} />} />
                      <Route path="/providers" render={(props) => <Pages.ProviderSelection {...this.props} />} />
                      <Route path="/results/:query" render={(props) => <Pages.ResultView {...this.props} />} />
                      <Route path="/home-providers" render={(props) => <Pages.HomeProviders {...this.props} />} />
                      <Route path="/home-brand" render={(props) => <Pages.HomeBrand {...this.props} />} />
                      <Route
                        path="/vendors/:id/hashtag/:hashtagId"
                        render={(props) => <Pages.HashtagProviderView {...this.props} />}
                      />
                      <Route path="/vendors/:id/offers" render={(props) => <Pages.VendorOffers {...this.props} />} />
                      <Route
                        path="/vendors/:id/top-products"
                        render={(props) => <Pages.VendorTopProducts {...this.props} />}
                      />
                      <Route
                        path="/vendors/:id/favorites"
                        render={(props) => <Pages.VendorFavoritesProducts {...this.props} />}
                      />
                      <Route
                        path="/vendors-products-offers"
                        render={(props) => <Pages.VendorsProductsOffers {...this.props} />}
                      />
                      <Route path="/plp/:id" render={(props) => <Pages.PlpView {...this.props} />} />
                      <Route
                        path="/plpCategories/:id"
                        render={(props) => <Pages.PlpViewCategories {...this.props} />}
                      />
                      <Route path="/faq" render={(props) => <Pages.FAQView {...this.props} />} />
                      <Route path="/coupons" render={(props) => <Pages.CouponsView {...this.props} />} />
                      <Route exact path="/" render={(props) => <Redirect to="/home" />} />
                      <Route path="/terms-conditions" render={(props) => <Pages.TermsConditions {...this.props} />} />
                      <Route
                        exact
                        path="/register-optional"
                        render={() => <Pages.RegisterOptional {...this.props} />}
                      />
                      <Route path="/logout" render={(props) => <Pages.Logout {...this.props} />} />
                      <Route path="/guru-pro" render={(props) => <Pages.GuruPro {...this.props} />} />
                      <Route path="/card/:statePay?" render={(props) => <Pages.Card {...this.props} />} />
                      <Route
                        path="/provider/:id/category/:id"
                        render={(props) => <Pages.ProviderCategory {...this.props} />}
                      />
                      <Route path="/tags-category" render={(props) => <Pages.TagsCategory {...this.props} />} />
                      <Route path="/category/:id" render={(props) => <Pages.AllCategorybyProvider {...this.props} />} />
                      <Route
                        path="/vendor/:id/product/:id"
                        render={(props) => <Pages.ProductModal {...this.props} />}
                      />
                      <Route path="/vendor/:id/brand/:id" render={(props) => <Pages.VendorBrand {...this.props} />} />
                      <Route path="/list-offers" render={(props) => <Pages.OfferList {...this.props} />} />
                      <Route
                        path="/vendor/:id/search"
                        render={(props) => <Pages.SearchProductsHomeProvider {...this.props} />}
                      />
                      <Route path="/notifications/:id?" render={(props) => <Pages.Notifications {...this.props} />} />
                      <Route path="/survey-nps" render={(props) => <Pages.SurveyNps {...this.props} />} />
                      <Route path="/branch-office" render={(props) => <Pages.BranchOffice {...this.props} />} />
                      <Route
                        path="/home-brands-detail/:id"
                        render={(props) => <Pages.HomeBrandDetail {...this.props} />}
                      />
                      <Route
                        exact
                        path="/office-localization"
                        render={() => <Pages.OfficeGeolocalization {...this.props} />}
                      />
                      <Route path="/cart2" component={Pages.Cart} />
                      <Route path="/suggested/:paymentMethod" component={Pages.Suggested} />
                      <Route path="/checkout" component={Pages.Checkout} />
                      <Route path="/payment-method" component={Pages.PaymentMethod} />
                      <Route path="/home-fulfillment" component={Pages.HomeFulfillment} />
                      <Route path="/brands-fulfillment" component={Pages.BrandsFulfillment} />
                    </div>
                  )}
                </AppContainer>
              )}
            </IonRouterOutlet>
          </IonContent>
          {this.state.footerIsVisible === false && !this.state.loading && (
            <Pages.Footer user={this.props.user} ordersConfirm={this.state.ordersConfirm} />
          )}
        </IonPage>
      </BrowserRouter>
    )
  }
}
