import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext'
import { ProviderCartData } from '../../interfaces/carts'
import { CouponActivated } from '../../interfaces/coupons'
import { removeCouponInCart } from '../../services/bff_mobile/v2/carts/removeCouponInCart'

function useHandleInitialValuesCouponActivated(couponActivated: CouponActivated, provider: ProviderCartData) {
  const { cartData, handlerCartData } = useContext(AppContext)
  const [disabledToggle, setDisabledToggle] = useState<boolean>(false)
  const [checkedToggle, setCheckedToggle] = useState<boolean>(false)

  const checkCouponInCart = (): boolean =>
    cartData.discounts.find(
      (discount) => discount.couponId === couponActivated.couponId && discount.providerId === provider._id,
    )
      ? true
      : false

  const checkDisabledCoupon = (): boolean => {
    const totalProductsPrice = provider.products.reduce((acc, product) => acc + product.totalPrice, 0)

    return totalProductsPrice < couponActivated.minimumAmount ? true : false
  }

  const removeCoupon = async (): Promise<void> => {
    await removeCouponInCart(cartData._id, couponActivated.couponId)

    handlerCartData({
      ...cartData,
      discounts: cartData.discounts.filter((discount) => discount.couponId !== couponActivated.couponId),
    })
  }

  useEffect(() => {
    const checkedCoupon = checkCouponInCart()
    const checkedDisabledCoupon = checkDisabledCoupon()

    if (checkedCoupon && checkedDisabledCoupon) removeCoupon()

    setCheckedToggle(checkedCoupon)
    setDisabledToggle(checkedDisabledCoupon)
  }, [cartData.discounts]) // eslint-disable-line react-hooks/exhaustive-deps

  return { checkedToggle, disabledToggle }
}

export default useHandleInitialValuesCouponActivated
