import React, { Fragment } from 'react'
import { IonPage, IonContent, withIonLifeCycle, IonHeader } from '@ionic/react'
import moment from 'moment'

import { groupBy , first} from 'lodash'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Order from '../../models/Order'
import User from '../../models/User'
import { CartModel } from '../../models/CartModel'
import { formatCurrency } from '../../utils/intl'
import { getLocalUserData } from '../../utils/localstorageData'

import { StaticContext } from 'react-router'
import { refreshNotifications } from '../../pages/reusableFunctions/Notifications'

import bell from '../../assets/icons/nav_notification.svg'
import agLogo from '../../assets/ag-logo.png'
import actMore from '../../assets/icons/act_more.svg'
import ToolBar  from '../../components/tool-bar/ToolBar'
import ConfirmOrder from '../../pages/confirm-order/ConfirmOrder'


import { ProviderModel } from '../../models/ProviderModel'
import { sendMetric, sendMetricWithSource, viewAllOrdersEvent, seeOrderDetailsEvent } from '../firebase/firebaseTags'
import Office from '../../models/Office'
import  Notifications   from '../../models/Notifications'
import { getCartId, getProductCart } from '../../clients/cartNew'

import cartToolbarIcon from '../../assets/icons/nav_cart.svg'
import orderInCompleteSrc from './../../assets/order_incomplete.png'
import { getSurveyByKey } from '../../utils/survey'
import GuruWhiteScreen from '../../components/guru-white-screen/GuruWhiteScreen'
import LoadingComponent from '../../components/loading-component/LoadingComponent'
import './Orders.scss'
import FeedbackNoScope from '../../components/feedback-no-scope/FeedbackNoScope'
import feedbackNoScopeImage from '../../assets/feedback-no-scope.png'
import { isOfficeSupported } from '../../utils/validateCurrentOffice'
import { navigateToCart } from '../../utils/navigation'

type IPathParams = {}

type IProps = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  readonly orders: Order
  readonly providerModel: ProviderModel
  readonly cartModel: CartModel
  readonly offices: Office
  notification: Notifications
  user: User
}

interface RouteState {
  orderId?:any
  targetType?:string
  targetId?:string
  key?:string
  vendorId?:string
}

interface State {
  ordersCategorized: any
  limit: any
  loading: boolean
  page: any
  office: any
  offices: any
  unreadNotificationsUser: number
  showConfirmOrder: any
  selectOrder: any
  cart: any
  loadingSeeMore: boolean
  ordersEmpty: boolean
  isOfficeSupportedValue: boolean
}
class Orders extends React.PureComponent<IProps, State> {
  state: State = {
    ordersCategorized: [],
    limit: 10,
    loading: true,
    page: 0,
    office: {},
    offices: [],
    unreadNotificationsUser: 0,
    showConfirmOrder: false,
    selectOrder: '',
    cart: 0,
    loadingSeeMore: false,
    ordersEmpty: false,
    isOfficeSupportedValue: true
  }

  async componentDidMount() {
    await this.loadOrdersPage()
  }

  checkOfficeSupport = () => {
    const isOfficeSupportedResult: boolean = isOfficeSupported()
    this.setState({isOfficeSupportedValue: isOfficeSupportedResult})
  }

  loadOrdersPage = async (changedOffice?: any) => {
    this.checkOfficeSupport()
    const { location: { state: locationState } } = this.props
    const inProgressOrdersRes = await this.props.orders.getByStatus(null, null, 'Pendiente,Planificado')
    const finishedOrdersRes = await this.props.orders.getByStatus(1, 10, 'Entregado,Rechazado,Anulado')

    const { orders: inProgressOrders } = inProgressOrdersRes
    const { orders: finishedOrders, nextPageExists } = finishedOrdersRes

    const orderId = locationState ? locationState.orderId : null
    let ordersCategorized: any[] = []

    if (inProgressOrdersRes && finishedOrdersRes) {
      if (!inProgressOrdersRes.orders?.length && !finishedOrdersRes.orders?.length) this.setState({ ordersEmpty: true })

      ordersCategorized = [
        {
          title: 'En preparación',
          page: 1,
          orders: inProgressOrders,
          renderSeeMore: false,
          id: 0,
          states: 'Pendiente,Planificado',
        },
        {
          title: 'Terminados',
          page: 1,
          orders: finishedOrders,
          renderSeeMore: nextPageExists,
          id: 1,
          states: 'Entregado,Rechazado,Anulado',
        },
      ]
      viewAllOrdersEvent(finishedOrders.length + inProgressOrders.length, '')
    } else {
      this.setState({ ordersEmpty: true })
    }

    const user = localStorage.getItem('@user') 
    const userId = user ? JSON.parse(user) :  null 

    if (userId.id) {
      const stateNotifications = await refreshNotifications(userId.id)
      this.setState({ unreadNotificationsUser: stateNotifications})
    }


    this.setState({
      ordersCategorized,
      loading: false,
    })

    const idCart = await getCartId()
    let productCart = await getProductCart(idCart.data._id)
    const cart = productCart?.cart?.products.filter((item:any) => !item.paid)
    this.setState({cart: cart.length})

    const offices = await this.props.offices.get()
    const currentOffice = localStorage.getItem('currentOffice')
    const office =  currentOffice ? JSON.parse(currentOffice) : first(offices)
    this.props.offices.setOffice(office)
    this.setState({
      offices,
      office: changedOffice ? changedOffice : office
    })
    if (orderId) {
      const orderMatch = [...finishedOrders, ...inProgressOrders].find((order:any) => order?._id === orderId)
      if(orderMatch) this.goDetailOrder(orderMatch)
    }
  }

  goToCart = () => {
    sendMetricWithSource('view_cart', 'orders')
    navigateToCart(this.props.history, this.props.location.state || this.props.history.location.state);
  }


  goDetailOrder = (order: any) => {
    const { _id, providerId, orderedAt } = order
    const providerName = !order?.provider ? 'Proveedor' : order.provider.alias    
    seeOrderDetailsEvent(_id, providerId, providerName, orderedAt);
    this.props.history.push('/order', order)
  }

  showConfirmOrder = () => {
    this.setState({
      showConfirmOrder: false,
      selectOrder: ''
    })
  }

  goToOrer = async (order: any) => {
    const orders = await this.props.orders.get(this.state.page, this.state.limit)
    const orderSelect = orders.find((item:any) => item._id === order._id)
    this.props.history.push('/order', orderSelect)
  }

  even = (element:any) => { 
    const surveyData = getSurveyByKey('nps-survey')
    
    return element.key === surveyData.value;
  }
  
  goToSurvey = (orderId:string, vendorId:string) => { 
    const { history } = this.props
    const surveyData = getSurveyByKey('nps-survey')

    history.push('/survey-nps', {
      targetType: 'order',
      targetId: orderId,
      key: surveyData.value,
      vendorId
    }) 
  }

  renderOrders = (orders: any[]) => {
    const surveyData = getSurveyByKey('nps-survey')

    return orders.map((order: any) => {
      const infoVendor = !order?.provider ? null : order.provider
      const aliasVendor = infoVendor?.alias ? infoVendor.alias : 'Proveedor' 
      const logoVendor = infoVendor?.logo ? `${process.env.REACT_APP_BFF_IMAGE}providers/${infoVendor?.logo}` : agLogo
      const border = order.isDeliverable ? 'is-home-deliverable-done': ''
 
      return (
        <Fragment key={order._id}>
          <div onClick={() => this.goDetailOrder(order)} className={`order ${order.state === 'Entregado' && surveyData && !order.surveysAnswered?.some(this.even) ? 'is-deliverable': border}`}>
            <div className='img-order-wrapper'>
              <img src={logoVendor} className='img-order' alt='logo'/>  
            </div>  
            <div className="provider">
              <div className="name-provider">{moment(new Date(order.orderedAt)).format('DD/MM/YYYY').toUpperCase()}
              {order.state === 'Entregado' && surveyData && !order.surveysAnswered?.some(this.even) &&
                <div className="rate-order">
                  Califica el pedido
                </div>
              }
              </div>
              <div className="name-provider">{this.sliceTextVendorName(aliasVendor)}</div>
              <div className="container-state">
                <div className="price-provider">{formatCurrency(order.finalPrice)}</div>
                <div className='state'>
                  <div className='cicle' style={{background:`${order.stateColor}`}}></div>
                  {order.state}
                </div>
              </div> 
            </div>
          </div>
        </Fragment>
      )
    })
  }
  
  private goToCategory = () => {
    sendMetricWithSource('view_categories', 'orders')
    this.props.history.push('/categories')
  }

  goToNotifications = () => {
    sendMetric('view_notifications')
    this.props.history.push('/notifications')
  }

  loadByComuneId = async (changedOffice: any) => {
    this.setState({ loading: true })
    await this.loadOrdersPage(changedOffice)
    this.checkOfficeSupport()
  }

  sliceTextVendorName = (vendorName:string) => {
    if(vendorName.length >= 16) return this.sliceText(vendorName)
    return vendorName
  }
  
  sliceText = (text: string, size: number = 16) => (text.slice(0, size) + '...')
  
  goHome = () => this.props.history.push('/home')

  updateOrders = async () => await this.loadOrdersPage()

  goToLogout = async () => { 
    const { user } = this.props
    const lastToken = localStorage.getItem('@access_token')

    await user.signOut()
    if (lastToken) localStorage.setItem('@last_token_access', lastToken)

    this.props.history.push('/')
    window.location.reload()
  }

  renderSeeMoreBtn = (renderSeeMore: boolean, loadingSeeMore: boolean, id: number, page: number, states: string) => {
    if (renderSeeMore && loadingSeeMore) return <LoadingComponent height={'60px'} />

    if (renderSeeMore) {
      return (
        <div className='see-more-container' onClick={() => { this.seeMoreOrders(id, page, states) }}>
          <h6 className='no-margin'>Ver más</h6>
          <img className='see-more-img' src={actMore} alt='' />
        </div>
      )
    }
  }

  seeMoreOrders = async (id: any, currentPage: number, states: string) => {
    this.setState({ loadingSeeMore: true })
    const nextOrdersRes = await this.props.orders.getByStatus(currentPage + 1, 10, states)
    this.setState({ loadingSeeMore: false })

    const { orders: nextOrders, nextPageExists } = nextOrdersRes
    const ordersCategorized = JSON.parse(JSON.stringify(this.state.ordersCategorized))
    const newOrders = []
    
    if(!nextOrders) return

    ordersCategorized.forEach((ordersCategory: any) => {
      if (ordersCategory.id === id) {
        ordersCategory.orders = [...ordersCategory.orders, ...nextOrders]
        ordersCategory.page = ordersCategory.page + 1
        ordersCategory.renderSeeMore = nextPageExists
      }
      newOrders.push(ordersCategory)
    })
    
    this.setState({ ordersCategorized })
  }

  render() {
    const { cartModel } = this.props
    const { ordersCategorized, loading, unreadNotificationsUser,
      cart, loadingSeeMore, ordersEmpty } = this.state
    const length = ordersCategorized?.length 

    const notificationsBadge = unreadNotificationsUser > 0 ? ' ' : 0

    const userData = getLocalUserData()
    let userName = userData?.name || userData?.role?.name
    const officesLocal = localStorage.getItem('@offices')
    const officesParsed = officesLocal ? JSON.parse(officesLocal) : []
    const officeLocal = localStorage.getItem('currentOffice')
    const officeParsed = officeLocal ? JSON.parse(officeLocal) : null

    return (
      <IonPage className="orders-page ds-content-page" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <>
            <p className="paragraph-3 categories-toolbar-text">Categorías</p>
            <ToolBar
              title={userName}
              office={officeParsed}
              offices={officesParsed}
              primaryButtons={[
                {
                  icon: bell,
                  key: 'notifications',
                  onClick: this.goToNotifications,
                  badge: notificationsBadge,
                },
                {
                  icon: cartToolbarIcon,
                  key: 'cart',
                  onClick: this.goToCart,
                  badge: cart,
                }
              ]}
              secondaryButtons={[
                {
                  key: 'category',
                  onClick: this.goToCategory,
                },
              ]}
              loadOnChangeOffice={this.loadByComuneId}
              officeModel={this.props.offices}
              cartModel={cartModel}
            />
          </>
        </IonHeader>
        <IonContent className="container-all-orders" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {!this.state.isOfficeSupportedValue ?
            <FeedbackNoScope 
              image={feedbackNoScopeImage} 
              text="Lamentamos informar que actualmente no contamos con servicios en tu zona." 
              callToAction="¡Pronto volveremos con novedades para ti y tu negocio!" 
            />
            :
            <>
              <div className="container-orders-title">
                <h1 className="title-payments-methods">Tus pedidos</h1>
              </div>
              { loading && <LoadingComponent/>}
              {!loading && !length && (
                <div className="container-white-screen">
                  <GuruWhiteScreen text="Acá podrás ver el estado de todos tus pedidos"/>
                </div>
              )}
              {!loading && length > 0 && (
                <div className="wrapper-orders">
                  
                  {ordersEmpty && 
                    <div className='orders-empty-wrapper'>
                      <GuruWhiteScreen text='Acá podrás ver el estado de todos tus pedidos' />
                    </div> 
                  }
                  {ordersCategorized?.map(({title, page, orders, renderSeeMore, id, states}: any, index: any) => (
                    orders?.length
                      ? <div className="list-orders" key={index}>
                          <div className="title">{title}</div>
                          <div className="list">
                            {this.renderOrders(orders)}
                          </div>
                          {this.renderSeeMoreBtn(renderSeeMore, loadingSeeMore, id, page, states)}
                        </div>
                      : <div key={index}></div>
                  ))}
                </div>
              )}
            </>
          }
        </IonContent>
      </IonPage>
    )
  }
}

export default withRouter(withIonLifeCycle(Orders))
