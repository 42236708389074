import { useState } from 'react'
import { CouponActivated } from '../../interfaces/coupons'

function CouponsActivatedProvider() {
  const [couponsActivated, setCouponsActivated] = useState<CouponActivated[]>([])

  const handlerCouponsActivated = (couponsActivated: CouponActivated[]) => setCouponsActivated(couponsActivated)

  return { couponsActivated, handlerCouponsActivated }
}

export default CouponsActivatedProvider
