import React from 'react'
import './BrandIconStyles.scss'

interface IBrandIcon {
  urlLogo: string;
  handleAction(): void;
}

const BrandIcon: React.FC<IBrandIcon> = ({urlLogo, handleAction}) => {
  return (
    <>
      <div
        className="brand-picture-icon"
        onClick={() => handleAction()}
      >
        <img 
          className="img-brand-icon"
          src={urlLogo}
          alt='Imagen de la marca'
        />
      </div>
    </>
  )
}

export default BrandIcon;
