import { CouponActivated } from '../../../../interfaces/coupons'
import { apiBffMobileV1Coupons } from './api'

interface Response {
  couponId: string
  couponInfo: {
    image: {
      url: string
    }
    minimum_amount: number
  }
  amount: string
  validVendors: string[]
}

export const getCouponsActivated = async (): Promise<CouponActivated[]> => {
  const response = await apiBffMobileV1Coupons.get('/activated')

  return response.data.map((data: Response) => {
    return {
      couponId: data.couponId,
      image: data.couponInfo.image.url,
      vendors: data.validVendors,
      amount: Number(data.amount),
      minimumAmount: data.couponInfo.minimum_amount,
    }
  })
}
