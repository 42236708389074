
import React, { useState, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router'

import User from '../../models/User'
import GlobalComponents from '../../components'

import { PromotionModel } from '../../models/PromotionModel'
import { ProductModel } from '../../models/ProductModel'
import Office from '../../models/Office'
import { CartModel } from '../../models/CartModel'
import { ProviderModel } from '../../models/ProviderModel'
import Category  from '../../models/Category'
import Settings from '../../models/Settings'
import { navigateToCart } from '../../utils/navigation'

interface IPathParams {} 

type Props = RouteComponentProps<IPathParams, StaticContext> & {
  filterBrand?:boolean
  singleProviderId:any
  history:any
  user: User
  settings: Settings
  offices: Office
  categories: Category
  promotion: PromotionModel
  productModel: ProductModel
  cartModel: CartModel
  providerModel: ProviderModel
  category?:any
  hideMore?: boolean
  isLoading?: any
  brandId?: any
  onlyOffers?: boolean
  validateIfIsHasProducts?: any
  from?: string
  showInBlueColor?: boolean
  categorySelectedInWordSlider?: string
  categoryPosition?: number
  filterCategoryName?: any
  nameProvider?: any
  fromSlider?: boolean
  placementName?: string
}

const  ProductSProductSliderAsynclider: React.FC<Props> = (props) => {
  const [productsCaregories, setProductsCaregories] = useState<any>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [placementNameLocal, setPlacementNameLocal] = useState<string>()

  useEffect(() => {
    if(!isFetching) get()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const get = async () => {
    const {singleProviderId, brandId, onlyOffers, isLoading, categories, from, categorySelectedInWordSlider, placementName} = props
    const page = 1
    const category = props.category ? props.category : undefined
    
    setPlacementNameLocal(placementName ?? 'not-defined')

    if (category) {
      let categoryId 
      if(from !== 'list-offers' && (!categorySelectedInWordSlider || categorySelectedInWordSlider === '')) {
        categoryId = category._id
      } else { 
        categoryId = categorySelectedInWordSlider
      }
      let response = await categories.getProducts(page, singleProviderId, brandId, categoryId, onlyOffers)
      setIsFetching(true)
      response = {...response, categoryId:category._id, nameCategory:category.name}
      
      if (response) {
        setProductsCaregories([{[category.name]: response}])
        if(isLoading) isLoading(false)
      }
    }
  }

const onMoreInfoClick = (categoryId:any, nameCategory:any) => {
  const singleProviderId = props.singleProviderId ? props.singleProviderId : undefined
  const { nameProvider } = props

  props.history.push(`/provider/${singleProviderId}/category/${categoryId}`, {
    id: categoryId,
    title: nameCategory,
    singleProvider:singleProviderId,
    categoryId:categoryId,
    nameProvider: nameProvider || ''
  })
}

  const nextPage = async (category:any) =>{
    const {singleProviderId, brandId, onlyOffers, categories} = props
    let categoryOnSlider:any = Object.values(category)[0]
    
    if(categoryOnSlider.page === categoryOnSlider.totalPage) return 
      categoryOnSlider.page +=1
      let page =  categoryOnSlider.page 
      
    let response = await categories.getProducts(page, singleProviderId, brandId, categoryOnSlider.categoryId, onlyOffers)
    categoryOnSlider.docs = categoryOnSlider.docs.concat(response.docs)
    let arr = productsCaregories.map((stateSubcategory:any) =>{
      return stateSubcategory
    })
    setProductsCaregories(arr)
  }


 const goToCart = () => navigateToCart(props.history, props.location.state || props.history.location.state);

  return (
    <div>
      {productsCaregories?.map((r: any) => {
        return Object.entries(r).filter(([, value]: any) => value?.docs?.length).map(([key, value]) => {
          const products: any = value 
          const data = [
            {
              name: key,
              products: products.docs,
              _id: products.categoryId,
              totalProducts: products.totalDocs
            },
          ]

          return (
            <div>
              {data.map(({name, products, _id, totalProducts} ) => {
                const {filterBrand, hideMore, showInBlueColor, from, categoryPosition, filterCategoryName, fromSlider} = props
                return (
                  <GlobalComponents.ProductSlider
                    key={name}
                    filterBrand = {filterBrand}
                    products={products}
                    info={{
                      title: name,
                      id: _id,
                      brandId: '',
                      hide_more: hideMore,
                      from,
                      showInBlueColor,
                      totalProducts
                    }}
                    offers={from === 'list-offers'}
                    onGoToCart={goToCart}
                    onMoreInfoClick={() => onMoreInfoClick(_id, name)}
                    nextPage={nextPage}
                    categoryPrimary={r}
                    {...props}
                    categoryPosition={categoryPosition}
                    filterCategoryName={filterCategoryName}
                    fromSlider={fromSlider || false}
                    placementName={placementNameLocal}
                  ></GlobalComponents.ProductSlider>
                )
              })}
            </div>
          )
        })
      })
    }
    
    </div>
  )
  
}

export default withRouter(ProductSProductSliderAsynclider)

