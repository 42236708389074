import React, { useState, useEffect, useCallback } from 'react'
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { StaticContext } from 'react-router'

//Components
import CardCartNew from '../cart-card/CardCartNew'
import ButtonComponent from '../../components/basic-button/ButtonComponent'

import { CartModel, CartProduct } from '../../models/CartModel'
import { ProviderModel } from '../../models/ProviderModel'
import { CouponsActivatedModel } from '../../models/CouponsActivatedModel'

import { getSuggestedProductsForCart } from '../../clients/product'

import { formatCurrency } from '../../utils/intl'

import {
  eventForCart,
  eventBeginCheckout,
  fbInitiateCheckoutEvent,
  checkoutEvent,
  eventWithName,
  confirmShoppingCartClickEvent,
} from '../firebase/firebaseTags'

import OrderConfirmation from '../order-confirmation/OrderConfirmation'

import User from '../../models/User'
import Settings from '../../models/Settings'
import Office from '../../models/Office'
import { ProductModel } from '../../models/ProductModel'
import SuggestedCheckout from '../../components/suggested-checkout-modal/SuggestedCheckout'
import { Cart } from '../../models/CartModel2'
import { validateIfIsInvitedUser } from '../../utils/invitedUser'

import './CartController.scss'
import ModalInvitedUser from '../../components/modals/modal-invited-user/ModalInvitedUser'
import { findShippingCost, verificarDescuento } from '../../utils/cart'

interface IPathParams {}
interface PastRouteinfo {
  route?: string
  paymentFormMethod?: string
  cartId?: string
}

interface RouteState {}

type Props = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  cartModel: CartModel
  providerModel: ProviderModel
  couponsActivatedModel: CouponsActivatedModel
  user: User
  settings: Settings
  offices: Office
  productModel: ProductModel
  pastRouteInfo?: PastRouteinfo
  paymentMethodCart: any
  productsGroupedByPaymentMethod: any
  removeProductFromCart: any
  cartId: string
  couponAddToCart: any[]
  totalDiscount: (total: number) => void
  couponsListActivated: any
  arrPaymentMethodProvider: any
  onProductMethodFormChangeHandler: any
  index: number
  productsIncart: Cart
  cartChangePrividerSelect: (providerId: string) => void
  providerSelectOpen: string
  cartAddCoupon: (idCoupon: any, idProvider: string) => void
  couponToCartRemove: (idCoupon: any, idProvider: string) => void
  bottomSheetShow: (isShowBottomSheet: boolean) => void
  updateCart: (checkLoadingProducts: boolean) => void
  arrPaymentMethodsByVendor: any[]
}

const CartControllerNew: React.FC<Props> = (props) => {
  const {
    paymentMethodCart,
    productsGroupedByPaymentMethod,
    cartId,
    couponsListActivated,
    arrPaymentMethodProvider,
    productsIncart,
    providerModel,
    cartChangePrividerSelect,
    couponAddToCart,
    providerSelectOpen,
    cartAddCoupon,
    couponToCartRemove,
    history,
    bottomSheetShow,
    arrPaymentMethodsByVendor,
    user,
  } = props
  const [modalOpen, setModalOpen] = useState<any>({ providerId: '', sku: '' })
  const [isOpenModalSuggested, setIsOpenModalSuggested] = useState<any>(false)
  const [totalToPayCard, setTotalToPayCard] = useState<number>(0)
  const [modalOrderConfirmation, setModalOrderConfirmation] = useState<boolean>(false)
  const [arrMethod, sertArrMethod] = useState<any>([])
  const [productsGroupedController, setProductsGrouped] = useState<any>([])
  const [paymentMethodCartSelected, setPaymentMethodCartSelected] = useState<any>('')
  const [showAlertIsUserInvited, setShowAlertIsUserInvited] = useState<boolean>(false)
  const [shippingCosts, setShippingCosts] = useState<any>({})
  const [vendorsShippingCost, setVendorsShippingCost] = useState({})
  const [totalShippingCost, setTotalShippingCost] = useState(0)
  const [shippingCostsApplied, setShippingCostsApplied] = useState({})

  useEffect(() => {
    const removeProduct = localStorage.getItem('@areRemovedProducts')
    const areRemovedProducts = removeProduct ? JSON.parse(removeProduct) : false

    const goBack = localStorage.getItem('@goBack')
    const goBackOrderConfirmation = goBack ? JSON.parse(goBack) : false

    const iniTialSuggestedVal: boolean =
      (props.pastRouteInfo?.cartId === cartId &&
        props.pastRouteInfo?.paymentFormMethod === paymentMethodCart.paymentMethod &&
        !areRemovedProducts &&
        !goBackOrderConfirmation) ||
      false
    setTimeout(() => setIsOpenModalSuggested(iniTialSuggestedVal), 500)
  }, [cartId, paymentMethodCart.paymentMethod, props.pastRouteInfo])

  useEffect(() => {
    const vendorInCart = productsGroupedByPaymentMethod.find(
      (item: any) => item.paymentMethod === paymentMethodCart.paymentMethod,
    )
    setProductsGrouped(vendorInCart.vendors)
    setShippingCosts(vendorInCart.shippingCosts)
  }, [paymentMethodCart.paymentMethod, productsGroupedByPaymentMethod, props.productsGroupedByPaymentMethod])

  const totalByVendor = useCallback(
    (idVendor: string) => {
      const total = paymentMethodCart.vendors[idVendor]
        .map((item: any) => {
          return item.infoPrice
        })
        .reduce((acc: any, current: any) => acc + current.totalPrice, 0)

      return total
    },
    [paymentMethodCart.vendors],
  )

  const getShippingCostPaymenthMethodAndVendors = useCallback(() => {
    const vendors = paymentMethodCart.vendors
    let listVendorsShippingCost: any = {}
    let sumShippingCost = 0
    let shippingCostsAppliedInVendor: any = {}
    for (const vendorId in vendors) {
      let totalPrice = totalByVendor(vendorId)
      if (couponAddToCart.length) {
        const discount = verificarDescuento(vendorId, couponAddToCart)
        totalPrice -= discount
      }
      const shippingCostVendor = findShippingCost(totalPrice, vendorId, shippingCosts)
      listVendorsShippingCost[vendorId] = shippingCostVendor ? shippingCostVendor.cost : null
      shippingCostsAppliedInVendor[vendorId] = shippingCostVendor
      sumShippingCost += shippingCostVendor ? shippingCostVendor.cost : 0
    }
    setVendorsShippingCost(listVendorsShippingCost)
    setTotalShippingCost(sumShippingCost)
    setShippingCostsApplied(shippingCostsAppliedInVendor)
  }, [couponAddToCart, paymentMethodCart.vendors, shippingCosts, totalByVendor])

  useEffect(() => {
    getShippingCostPaymenthMethodAndVendors()
  }, [getShippingCostPaymenthMethodAndVendors, shippingCosts])

  const titlePaymentMethod = () => {
    let name
    const validations = [
      { isValid: (value: string) => value === 'credito', name: 'Pago Crédito Proveedor' },
      { isValid: (value: string) => value === 'post-pago', name: 'Pago contra entrega' },
      { isValid: (value: string) => value === 'pre-pago', name: 'Pago anticipado' },
    ]

    paymentMethodCart &&
      validations.forEach((validation) => {
        if (validation.isValid(paymentMethodCart.paymentMethod)) {
          name = validation.name
        }
      })
    return name
  }

  const nameBtn = () => {
    if (paymentMethodCart && paymentMethodCart.paymentMethod === 'post-pago') {
      localStorage.setItem('totalPost', JSON.stringify(totalByPaymentMethod(paymentMethodCart)))
      return `Pedir Total: ${formatCurrency(totalByPaymentMethod(paymentMethodCart) + totalShippingCost)}`
    }
    if (paymentMethodCart && paymentMethodCart.paymentMethod === 'pre-pago') {
      localStorage.setItem('totalPre', JSON.stringify(totalByPaymentMethod(paymentMethodCart)))
      return `Pagar Total: ${formatCurrency(totalByPaymentMethod(paymentMethodCart) + totalShippingCost)}`
    }
    if (paymentMethodCart && paymentMethodCart.paymentMethod === 'credito') {
      localStorage.setItem('totalCredit', JSON.stringify(totalByPaymentMethod(paymentMethodCart)))
      return `Pedir Total: ${formatCurrency(totalByPaymentMethod(paymentMethodCart) + totalShippingCost)}`
    }
    return ''
  }

  const showModal = (providerId: string, sku: string, product: any) => {
    setModalOpen({ providerId, sku })
    eventForCart('edit_product_cart', product.name, product.id, product.brand, product.category)
  }

  const renderModal = (cartProduct: CartProduct, product: any) => {
    const { sku, providerId, quantity } = cartProduct
    const showModal = modalOpen != null && modalOpen.providerId === providerId && modalOpen.sku === sku
    const originLink = window.location.pathname.split('/')
    const originPath = originLink[1]

    if (typeof product === 'string') return undefined

    props.history.push(`/vendor/${product.providerId}/product/${product._id}`, {
      isOpen: showModal,
      initialQuantity: quantity,
      product: product,
      useInitialQuantity: true,
      fromSuggested: true,
      originPath,
      originLink,
    })
  }

  const handlerConfirmShoppingCartClickEvent = () => {
    const userId = JSON.parse(localStorage.getItem('@user')!).id
    const providerId = Object.keys(paymentMethodCart.vendors)[0]
    const paymentMethod = paymentMethodCart.paymentMethod
    const paymentType = paymentMethod === 'post-pago' ? 'Pago contra entrega' : 'pre-pago' ? 'Pago anticipado' : ''

    let providerTime: number = 0
    if (localStorage.getItem('confirmShoppingCartClickProviderTimeStart')) {
      const startTime = new Date(localStorage.getItem('confirmShoppingCartClickProviderTimeStart')!)
      const endTime = new Date()

      providerTime = (endTime.getTime() - startTime.getTime()) / 1000
    }

    confirmShoppingCartClickEvent(userId, providerId, paymentType, providerTime)
  }

  const onClickBtn = async (providersInCart: any, total: number) => {
    handlerConfirmShoppingCartClickEvent()

    const invitedUser = await validateIfIsInvitedUser()
    setShowAlertIsUserInvited(invitedUser)
    if (!invitedUser) {
      checkoutEvent(
        'begin_checkout',
        couponAddToCart,
        total,
        paymentMethodCart.vendors,
        paymentMethodCart.paymentMethod,
      )
      const productsPerProvider: any = await getSuggestedProductsForCart(providersInCart, cartId)

      if (Object.keys(productsPerProvider).length === 0) {
        payTheTotal(paymentMethodCart.paymentMethod)
      } else {
        // history.push({
        //   pathname: '/suggested',
        //   state: {
        //     providersToCheckout: providers,
        //     paymentFormMethod: paymentMethodCart.paymentMethod,
        //     idCart: cartId,
        //     productsGroupedByPaymentMethod,
        //     paymentMethodCart,
        //     couponAddToCart,
        //     arrPaymentMethodProvider,
        //     productsGroupedController,
        //     totalCart: totalByPaymentMethod(paymentMethodCart),
        //     totalShippingCost,
        //     vendorsShippingCost,
        //     appliedShippingCost: shippingCostsApplied,
        //   },
        // })
        setIsOpenModalSuggested(true)
      }
    }
  }

  const calculateTotalByVendorsMinimun = () => {
    let total: number = 0

    Object.keys(paymentMethodCart.vendors).forEach((vendorId) => {
      const totalVendor = totalByVendor(vendorId)
      const provider: any = providerModel.getById(vendorId)

      if (provider.minimun > totalVendor) total += totalVendor
    })
    return total
  }

  const totalByPaymentMethod = (paymentMethod: any) => {
    const total = Object.keys(paymentMethod.vendors)
      .map((vendorKey) => {
        return paymentMethodCart.vendors[vendorKey].reduce((acc: any, current: any) => {
          return current.infoPrice.totalPrice + acc
        }, 0)
      })
      .reduce((acc, current) => {
        return acc + current
      })
    return totalToPayCard > 0
      ? total - totalToPayCard - calculateTotalByVendorsMinimun()
      : total - calculateTotalByVendorsMinimun()
  }

  const removeProduct = (cartProduct: any, product: any) => {
    const { removeProductFromCart } = props

    removeProductFromCart(cartProduct, product)
    localStorage.setItem('@areRemovedProducts', JSON.stringify(true))
    setIsOpenModalSuggested(false)
  }

  const totalToPay = () => {
    const cartLocalStorage = localStorage.getItem('@cart')
    let cart: any = ''

    if (cartLocalStorage) cart = JSON.parse(cartLocalStorage)

    const currentMethodVendorsIds = Object.keys(paymentMethodCart.vendors)
    const total = cart.discounts
      .filter((discount: any) => currentMethodVendorsIds.includes(discount.providerId))
      .reduce((acc: any, current: any) => acc + current.realDiscount, 0)

    setTotalToPayCard(total)
    props.totalDiscount(total)
  }

  const handleSuggestedCallback = async (
    proceedWithPayment: boolean = false,
    total: number,
    shouldUpdateCart: boolean,
  ) => {
    const invitedUser = await validateIfIsInvitedUser()
    setShowAlertIsUserInvited(invitedUser)
    setIsOpenModalSuggested(false)

    if (!proceedWithPayment && shouldUpdateCart) props.updateCart(true)

    if (!invitedUser && proceedWithPayment) {
      const productsGrouped =
        productsGroupedByPaymentMethod.find((item: any) => item.paymentMethod === paymentMethodCart.paymentMethod)
          .vendors || {}
      checkoutEvent('view_checkout', couponAddToCart, total, productsGrouped, paymentMethodCart.paymentMethod)
      totalByPaymentMethod(paymentMethodCart)
      payTheTotal(paymentMethodCart.paymentMethod, shouldUpdateCart)
    }
  }

  const payTheTotal = async (method: string, shouldUpdateCart?: boolean) => {
    const arrMethod = arrPaymentMethodProvider.filter((item: any) => item.type === method)
    const vendorInCart = productsGroupedByPaymentMethod.find((item: any) => item.paymentMethod === method)
    const validateIfShowModalOrderConfirmation =
      arrMethod?.length && vendorInCart && Object.keys(vendorInCart.vendors).length > 0
    eventsFirebase()

    if (validateIfShowModalOrderConfirmation) {
      if (shouldUpdateCart) await props.updateCart(false)
      setModalOrderConfirmation(true) // history.push("/checkout")
      sertArrMethod(arrMethod)
      setPaymentMethodCartSelected(vendorInCart.paymentMethod)
    }
  }

  const eventsFirebase = () => {
    eventWithName('request_cart')

    const couponCode = couponAddToCart.length > 0 ? couponAddToCart[0].code : ''

    const productsIncartByMethodPay = productsIncart.products.filter(
      (product: any) => product.paymentForm === paymentMethodCart.paymentMethod,
    )
    const productsQuantity = productsIncartByMethodPay.reduce((acumulator: number, currentProduct: any) => {
      return acumulator + currentProduct.quantity
    }, 0)

    const productsGrouped =
      productsGroupedByPaymentMethod.find((item: any) => {
        return item.paymentMethod === paymentMethodCart.paymentMethod
      }).vendors || {}

    const skus: any[] = []
    const productsData: any[] = []
    const productsCart = productsIncart.products

    for (const providersId in productsGrouped) {
      for (const skuOfProducts in productsCart) {
        const product = productsCart[skuOfProducts]
        const partialProduct = {
          id: product.sku,
          name: product.name,
          price: product.price,
          quantity: product.quantity,
        }
        skus.push(product.sku)
        productsCart[skuOfProducts].providerId === providersId && productsData.push(partialProduct)
      }
    }

    fbInitiateCheckoutEvent('product', productsQuantity, total)
    eventBeginCheckout(couponCode, total, productsQuantity)
  }

  const onOpenChangeWayToPay = (event: string, providerId: any) => {
    props.onProductMethodFormChangeHandler(event, providerId)
    eventWithName('change_payment')
  }

  const closeModalOrder = () => setModalOrderConfirmation(false)

  const cartChange = (providerId: string) => cartChangePrividerSelect(providerId)

  const showBottomSheet = (isShowBottomSheet: any) => bottomSheetShow(isShowBottomSheet)

  let atLeastOneValid = false
  let invalidCartWithSomeMinimum: boolean = false
  let providers: string[] = []
  let total = 0

  return (
    <div className="container-card-controller">
      <h5 className="h5">{titlePaymentMethod()}</h5>
      {productsGroupedByPaymentMethod &&
        productsGroupedByPaymentMethod.length > 0 &&
        Object.keys(paymentMethodCart).length > 0 &&
        Object.keys(paymentMethodCart.vendors).map((idProvider: any) => {
          const provider = providerModel.getById(idProvider)
          const isMinimumFulfilled = !provider?.minimun || totalByVendor(idProvider) >= provider.minimun
          atLeastOneValid = atLeastOneValid || isMinimumFulfilled
          total += isMinimumFulfilled ? totalByVendor(idProvider) : 0
          const difference = provider?.minimun ? provider.minimun - totalByVendor(idProvider) : 0

          if (!invalidCartWithSomeMinimum && paymentMethodCart.paymentMethod === 'pre-pago')
            invalidCartWithSomeMinimum = difference > 0

          if (providers.indexOf(idProvider) === -1 && isMinimumFulfilled) providers.push(idProvider)

          const paymentMethodsByVendor: any = arrPaymentMethodsByVendor.find(
            (item: any) => item.idVendor === idProvider,
          )
          return (
            <div key={idProvider}>
              <CardCartNew
                products={idProvider}
                provider={provider}
                paymentMethodByVendors={paymentMethodCart}
                showModal={showModal}
                renderModal={renderModal}
                deleteProduct={removeProduct}
                productCart={productsIncart}
                isMinimumFulfilled={isMinimumFulfilled}
                couponAddCart={couponAddToCart}
                totalProvider={totalByVendor(idProvider)}
                totalToPay={totalToPay}
                couponsActivatedList={couponsListActivated}
                onOpenChangeWayToPay={onOpenChangeWayToPay}
                changeCart={cartChange}
                openProviderSelect={providerSelectOpen}
                addCouponCart={cartAddCoupon}
                removeCouponToCart={couponToCartRemove}
                isBottomSheetShow={showBottomSheet}
                paymentMethodsByVendor={paymentMethodsByVendor}
                {...props}
                vendorsShippingCost={vendorsShippingCost}
              />
            </div>
          )
        })}

      <div className="btn-cart-controller">
        <ButtonComponent
          className={`btn-primary`}
          disabled={!atLeastOneValid || invalidCartWithSomeMinimum}
          onClick={() => onClickBtn(providers, total)}
          text={nameBtn()}
        />
      </div>

      {modalOrderConfirmation && (
        <OrderConfirmation
          isOpenModal={modalOrderConfirmation}
          metodoPagoUser={arrMethod}
          productsGroupedMethod={productsGroupedController}
          paymentMethod={paymentMethodCartSelected}
          closeModalOrder={closeModalOrder}
          totalOrder={total}
          cuponsActiveCard={couponAddToCart}
          totalCart={totalByPaymentMethod(paymentMethodCart)}
          {...props}
          totalShippingCost={totalShippingCost}
          vendorsShippingCost={vendorsShippingCost}
          appliedShippingCost={shippingCostsApplied}
        />
      )}
      {isOpenModalSuggested && (
        <SuggestedCheckout
          isOpenModal={isOpenModalSuggested}
          closeModalSuggested={(proceedWithPayment: boolean, shouldUpdateCart: boolean) =>
            handleSuggestedCallback(proceedWithPayment, total, shouldUpdateCart)
          }
          providersToCheckout={providers}
          paymentFormMethod={paymentMethodCart.paymentMethod}
          idCart={cartId}
          {...props}
        />
      )}
      <ModalInvitedUser
        isOpen={showAlertIsUserInvited}
        history={history}
        user={user}
        closeModal={() => setShowAlertIsUserInvited(false)}
      />
    </div>
  )
}

export default withRouter(CartControllerNew)
