import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext'
import { ProductCartData } from '../../interfaces/carts'

function useCheckProductInCart(productId: string, providerId: string, paymentMethod: string) {
  const { cartData } = useContext(AppContext)
  const [foundProduct, setFoundProduct] = useState<ProductCartData | null>(null)

  useEffect(() => {
    setFoundProduct(null)
    cartData.data.forEach((data) => {
      if (data.paymentMethod === paymentMethod) {
        data.providers.forEach((provider) => {
          if (provider._id === providerId) {
            provider.products.forEach((product) => {
              if (product._id === productId) setFoundProduct(product)
            })
          }
        })
      }
    })
  }, [cartData]) // eslint-disable-line react-hooks/exhaustive-deps

  return { foundProduct }
}

export default useCheckProductInCart
