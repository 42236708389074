import { DiscountsCartData } from '../../../../interfaces/carts'
import { apiBffMobileV2Carts } from './api'

interface Response {
  products: {
    _id: string
    name: string
    sku: string
    paymentForm: string
    providerId: string
    quantity: number
    units: number
    packageType: string
    infoPrice: {
      totalPrice: number
      uom: string
    }
  }[]
  discounts: DiscountsCartData[]
}

export const getCartDataById = async (cartId: string): Promise<Response> => {
  const response = await apiBffMobileV2Carts.get(`/${cartId}`)

  return {
    products: response.data['cart']['products'],
    discounts: response.data['cart']['discounts'],
  }
}
