import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import track from 'react-tracking'
import { StaticContext } from 'react-router'

import { IonPage, IonContent, IonHeader, IonItem, IonSelect, IonSelectOption } from '@ionic/react'

import User from '../../models/User'
import Category from '../../models/Category'
import Settings from '../../models/Settings'
import { ProductModel, Product } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'

import GlobalComponents from '../../components'
import ToolBar from '../../components/tool-bar/ToolBar'

import { first } from 'lodash'

import cartToolbarIcon from '../../assets/icons/icon_carrito_grey.svg'

import { getLengthOfCart } from '../../utils/cart'
import './Plp.scss'
import { navigateToCart } from '../../utils/navigation'

interface IPathParams {
  id: string
  brandId: string
}

interface RouteState {
  id: string
  brandId: string
  singleProviderId?: string
  title: string
  goBack?: any
  filter?: boolean
  categories?: any
}

type IProps = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  categories: Category
  productModel: ProductModel
  tracking?: any
  cartModel: CartModel
}

interface State {
  id: string
  brandId: string
  title: string
  loading: any
  categories: any
}

class Plp extends React.PureComponent<IProps, State> {
  state: State = {
    id: '',
    brandId: '',
    loading: true,
    title: 'Categoría',
    categories: [],
  }

  goToCart = () => {
    navigateToCart(this.props.history, this.props.location.state || this.props.history.location.state);
  }

  goToDeliveries = () => {
    this.props.history.push('/deliveries')
  }

  goToOrders = () => {
    this.props.history.push('/orders')
  }

  goToHome = () => {
    this.props.history.replace('/')
  }

  goToCategories = () => {
    this.props.history.push('/categories')
  }

  goToProviders = () => {
    this.props.history.push('/providers')
  }

  onMoreInfoClick = (info: { id: string; title: string; brandId: string }) => {
    const singleProviderId =
      this.props.location.state && this.props.location.state.singleProviderId
        ? this.props.location.state.singleProviderId
        : undefined
    if (info.brandId && info.brandId !== undefined) {
      this.props.history.push(`/plp/${info.id}/${info.brandId}`, { ...info, singleProviderId })
    } else {
      this.props.history.push(`/plp/${info.id}`, { ...info, singleProviderId })
    }
  }

  async UNSAFE_componentWillReceiveProps(props: any) {
    const { id, title, brandId } = props.location.state
    if (id !== this.state.id) {
      await this.getCategory(id, title, brandId)
    }
  }

  async componentDidMount() {
    const { id, title, brandId } = this.props.location.state

    await this.getCategory(id, title, brandId)
  }

  async getCategory(id: string, title: string, brandId: any = null) {
    const singleProviderId = this.props.location.state ? this.props.location.state.singleProviderId : undefined
    const { categories } = this.props
    const categoriesResult = (await categories.getProductsById(id, brandId))
      .map(c => ({
        ...c,
        products: c.products ? c.products.filter(p => !singleProviderId || p.providerId === singleProviderId) : [],
      }))
      .filter(c => c.products.length > 0)


    this.setState({
      id,
      title,
      loading: false,
      categories: categoriesResult,
      brandId,
    })
  }

  filterSelect = () => {
    const { filter, categories, id } = this.props.location.state
    const category = filter && categories.find((category: any) => category._id === id)

    return (
      filter && (
        <IonItem className="container-filter" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonSelect placeholder="Filtrar por" interface="popover" onIonChange={this.onOpenChangeOffice} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {category.children.map((item: any) => {
              return (
                <div>
                  <IonSelectOption key={item.id} value={item} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    {item.name}
                  </IonSelectOption>
                  {item.children.length > 0 &&
                    item.children.map((children: any) => {
                      return <IonSelectOption value={children} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{children.name}</IonSelectOption>
                    })}
                </div>
              )
            })}
          </IonSelect>
        </IonItem>
      )
    )
  }

  onOpenChangeOffice = (event: any | undefined) => {
    const value = event && event.target && event.target.value

    this.props.history.push(`/plpCategories/${value._id}`, {
      title: value.name,
      id: value._id,
      brandId: '',
    })
  }

  render() {
    const { brandId } = this.props.location.state
    const { history } = this.props
    const { categories, loading } = this.state

    let products: any = null
    if (categories.length === 1) {
      const category: any = first(categories)

      products =
        category.products && category.products.length
          ? category.products.map((product: Product) => (
              <GlobalComponents.ProductCard
                key={product.sku}
                settings={this.props.settings}
                product={product}
                cartModel={this.props.cartModel}
                onGoToCart={this.goToCart}
                history={this.props.history}
              />
            ))
          : null
    }
  
    return (
      <IonPage className="plp-page" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <ToolBar
            title={this.props.location.state.title}
            secondaryButtons={[{ type: 'back', onClick: history.goBack }]}
            secondaryHeader={true}
            primaryButtons={[
              {
                icon: cartToolbarIcon,
                key: 'cart',
                onClick: this.goToCart,
                badge: getLengthOfCart()
              },
            ]}
          />
          {this.filterSelect()}
        </IonHeader>
        <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {/* <GlobalComponents.SearchBar {...this.props} categoryId={id} /> */}
          {/* {loading && <IonProgressBar type="indeterminate"></IonProgressBar>} */}
          {!loading &&
            (categories.length > 1 ? (
              categories.map((category: any) =>
                category.products && category.products.length ? (
                  <GlobalComponents.ProductPlp
                    key={category._id}
                    products={category.products}
                    info={{
                      title: category.name,
                      id: category._id,
                      brandId,
                    }}
                    onMoreInfoClick={this.onMoreInfoClick}
                    onGoToCart={this.goToCart}
                    historyProps={history}
                    fromSlider={false}
                    {...this.props}
                  ></GlobalComponents.ProductPlp>
                ) : null,
              )
            ) : (
              <div className="product-list-1">{products}</div>
            ))}
        </IonContent>
      </IonPage>
    )
  }
}

export default track({ page: 'Categoría' })(withRouter(Plp))
