import { apply } from "./functions"

type Nullable<T> = T | void

const countK = (str: string): number => {
    let count = 0
    for (const c of str) if (c === "K") count++
    return count
}

const zeroChar = "0".charCodeAt(0)

const getVerificationDigit = (cleanRut: string): string => {
    const sum =
        (cleanRut.charCodeAt(0) - zeroChar) * 3 +
        (cleanRut.charCodeAt(1) - zeroChar) * 2 +
        (cleanRut.charCodeAt(2) - zeroChar) * 7 +
        (cleanRut.charCodeAt(3) - zeroChar) * 6 +
        (cleanRut.charCodeAt(4) - zeroChar) * 5 +
        (cleanRut.charCodeAt(5) - zeroChar) * 4 +
        (cleanRut.charCodeAt(6) - zeroChar) * 3 +
        (cleanRut.charCodeAt(7) - zeroChar) * 2;
    const d = 11 - (sum % 11)
    if (d === 10) return "K"
    if (d === 11) return "0"
    return String.fromCharCode(zeroChar + d)
}

const isValidLength = (cleanRut: string): boolean => cleanRut.length === 8 || cleanRut.length === 9

const lastCharIsAK = (cleanRut: string): boolean => cleanRut[cleanRut.length - 1] === "K"

const hasAtMostOneKAndOnlyAtTheEnd = (cleanRut: string): boolean =>
    apply(countK(cleanRut), kCount => kCount === 0 || (kCount === 1 && lastCharIsAK(cleanRut)))

const formatRut = (cleanRut: string): string =>
    `${cleanRut[0] === "0" ? cleanRut[1] : cleanRut.substr(0, 2)}.${cleanRut.substr(2, 3)}.${cleanRut.substr(5, 3)}-${
        cleanRut[8]
    }`

export const validateRut = (rawRut: string): Nullable<string> => {
    const cleanRut = apply(rawRut.replace(/[^0-9Kk]/gi, ""), rut => (rut.length === 8 ? `0${rut}` : rut)).toUpperCase()
    if (!isValidLength(cleanRut) || !hasAtMostOneKAndOnlyAtTheEnd(cleanRut)) return undefined
    const leftPart = cleanRut.substr(0, cleanRut.length - 1)
    const realDigit = cleanRut.substr(cleanRut.length - 1, 1)
    const validDigit = getVerificationDigit(leftPart)
    return validDigit === realDigit ? formatRut(cleanRut) : undefined
}

export const validarRuc = (data:any) => {
    var ruc = data.replace(/[-.,[\]()\s]+/g,"")

    if (!(validateRucIf(ruc))) return undefined
            
    return data 
}
	
const validateRucIf = (ruc :any) => {
    if (ruc.length === 8) return true
    if (ruc.length === 11) return (ruc >= 1e10 && ruc < 11e9) || (ruc >= 15e9 && ruc < 18e9) || (ruc >= 2e10 && ruc < 21e9)
    return false
}

export const isRfcValid = (rfc: string) => {
    const regex = /^[a-z0-9]+$/i;
    const minLen = 12;
    const maxLen = 18;

    const isAlphanumeric = regex.test(rfc);
    const isLengthValid = rfc?.length >= minLen && rfc?.length <= maxLen;

    return isAlphanumeric && isLengthValid;
}
