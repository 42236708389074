import axios from 'axios'
import { REACT_APP_BFF_NODE } from '../../../../config'
import { getUserToken } from '../../../../utils/functions/getUserToken'
import { getOfficeId } from '../../../../utils/functions/parserCurrentOffice'

export const apiBffMobileV1Products = axios.create({
  baseURL: `${REACT_APP_BFF_NODE}/v1/products`,
  headers: {
    Authorization: `Bearer ${getUserToken()}`,
    officeid: getOfficeId(),
  },
})
