import React, { useEffect, useState, useCallback } from 'react';
import { IonModal, IonIcon } from '@ionic/react';
import { sendMetric } from '../firebase/firebaseTags';
import closeIcon from '../../assets/icons/nav_close_white_x.svg';
import User from '../../models/User';
import { ProductModel } from '../../models/ProductModel';
import { ProviderModel } from '../../models/ProviderModel';
import { manageBannerClick } from '../../utils/deeplinks';
import { registerInvasiveBannerEvent } from '../firebase/firebaseTags';
import { useGetSessionId } from '../../hooks/useGetSessionId';
import { PromotionModalClass } from '../../interfaces/promotionModal';
import './PromotionModal.scss';

interface PromotionModalProps {
  user: User;
  history: any;
  isOpenPromotion: boolean;
  promotionModal: any;
  closePromotionModal: (promotionModal: any) => void;
  productModel: ProductModel;
  providerModel: ProviderModel;
  validateDeeplinks?: () => void;
  placementName?: string
}

const PromotionModal: React.FC<PromotionModalProps> = ({
  history,
  validateDeeplinks,
  promotionModal,
  isOpenPromotion,
  closePromotionModal,
  placementName
}) => {

    const [promotion, setPromotion] = useState<PromotionModalClass | undefined>(undefined)
    const sessionId = useGetSessionId()

    useEffect(() => {
        if(promotionModal){
            const promotionInstance = PromotionModalClass.fromJson(promotionModal)
            setPromotion(promotionInstance)
        }
    }, [promotionModal])

  useEffect(() => {
    if (isOpenPromotion && promotion) {
        handleInvasiveBannerViewEvent()
    }
  }, [isOpenPromotion, promotion]);

  const handleInvasiveBannerViewEvent = () => {

    const now = Date.now();
    const nowDate: Date = new Date(now);
    const nowISO = nowDate.toISOString();    
    const userStringified = localStorage.getItem('@user')
    const user = JSON.parse(userStringified)
    let userId

    if(user && user.roleAlias === "guest"){
      userId = 'user_guest'
    } else {
      userId = user.id
    }

    const placement = placementName ?? "not-defined"

    registerInvasiveBannerEvent({
        eventName: 'home_invasive_modal_banner_view',
        bannerId: promotion?._id,
        sellerName: promotion?.name,
        userId: userId,
        userSessionCode: sessionId,
        placementName: placement,
        timestamp: now,
        datetimeISO: nowISO
    })
  }

  const onImageClick = useCallback(async () => {
    const now = Date.now();
    const nowDate: Date = new Date(now);
    const nowISO = nowDate.toISOString();    
    const userStringified = localStorage.getItem('@user')
    const user = JSON.parse(userStringified)
    let userId

    if(user && user.roleAlias === "guest"){
      userId = 'user_guest'
    } else {
      userId = user.id
    }

    const placement = placementName ?? "not-defined"

    registerInvasiveBannerEvent({
        eventName: 'home_invasive_modal_banner_click',
        bannerId: promotion._id,
        sellerName: promotion.name,
        userId: userId,
        userSessionCode: sessionId,
        placementName: placement,
        timestamp: now,
        datetimeISO: nowISO
    })

    await manageBannerClick(promotion, history, validateDeeplinks);

  }, [promotion, history, validateDeeplinks]);

  const handleCloseModal = useCallback(() => {
    const now = Date.now();
    const nowDate: Date = new Date(now);
    const nowISO = nowDate.toISOString();    
    const userStringified = localStorage.getItem('@user')
    const user = JSON.parse(userStringified)
    let userId

    if(user && user.roleAlias === "guest"){
      userId = 'user_guest'
    } else {
      userId = user.id
    }

    const placement = placementName ?? "not-defined"

    registerInvasiveBannerEvent({
        eventName: 'home_invasive_modal_banner_close',
        bannerId: promotion._id,
        sellerName: promotion.name,
        userId: userId,
        userSessionCode: sessionId,
        placementName: placement,
        timestamp: now,
        datetimeISO: nowISO
    })
    closePromotionModal(promotionModal);
  }, [closePromotionModal, promotion]);

  return (
    <IonModal 
      cssClass="promotion-modal-full-screen" 
      backdropDismiss={false} 
      isOpen={isOpenPromotion}
      {...{} as any}
    >
      <div className="content">
        <div className="header">
          <div className="close-banner">
            <IonIcon icon={closeIcon} onClick={handleCloseModal} {...{} as any} />
          </div>
        </div>
        <div 
          className="container-img" 
          style={{ backgroundImage: `url(${process.env.REACT_APP_BFF_IMAGE}${promotion?.image?.url})` }} 
          onClick={onImageClick}  
        />
      </div> 
    </IonModal>
  );
};

export default React.memo(PromotionModal); 