
import React from 'react'
import {
  IonIcon,
  IonContent, 
  IonButton, 
} from '@ionic/react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'

// Iconos
import logo from './../../assets/logo.png'
import { checkmarkCircle } from 'ionicons/icons'

import './OrderConfirmedModal.scss'

interface IPathParams {
}

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  onClick: () => void
}

class OrderConfirmedModal extends React.PureComponent<IProps> {

  render() {
  
    return (
      <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className='modal-orders-confirmed'>
          <div className="content">
              <div className="body-orders-confirmed">
                <div className='star' style={{ backgroundImage: `url(${logo})` }} ></div>
                <div className='container-info-productModal'>
                  <div className='info-modal' >Tu pedido fue realizado con éxito</div>
                </div>
                <div className="container-btn-next">
                   <IonButton 
                  className='next-button'
                  mode="ios"
                  onClick={() => this.props.history.push('/home')} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                    >
                    {'Seguir comprando'}
                  </IonButton>
                </div>
                <div className="container-btn-return">
                  <IonButton 
                  className='return-button'
                  mode="ios"
                  onClick={() => this.props.onClick()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                    >
                    {'Ver mis pedidos'}
                  </IonButton>
                </div>
               
              
              </div>
          </div>
        </div>
      </IonContent>
    )
  }
}


export default withRouter(OrderConfirmedModal)