import { useContext } from 'react';
import { SessionContext, SessionContextType } from '../hoc/UserSessionProvider/UserSessionProvider';

export const useGetSessionId = (): SessionContextType => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSessionId must be used within a UserSessionProvider');
  }
  return context;
};
