export const namePayTitle = (order: any) => {
  if (order) {
    const { paymentMethodsType, paymentForm } = order
    const orderPaymentMethod = paymentMethodsType ? paymentMethodsType : paymentForm || ''
    const paymentNames = [
      { key: "pre-pago", name: "Pago anticipado" },
      { key: "post-pago", name: "Pago contraentrega" },
      { key: "credito", name: "Pago crédito proveedor" },
    ]
    return paymentNames.find(({key}: any) => key === orderPaymentMethod)?.name || ""
  }
  return ''
}