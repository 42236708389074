import React, { FunctionComponent } from 'react'
import { IonIcon, IonChip, IonInput } from '@ionic/react'
import { add, remove } from 'ionicons/icons'
import './NumberUpDown.scss'

interface Props {
  min?: number
  max?: number
  value: number
  step?: number
  valueToShow: number
  onChange: (value: number) => void
  disabled?: boolean
}

const onIncrease = ({ max, min, value, step, onChange }: Props) => {
  const realStep = step || 1

  const realMax = max || Number.MAX_VALUE
  const realMin = min || -Number.MAX_VALUE
  
  
  let newValue = 1
  
  if(isNaN(value)) { 
    newValue = 1
  } else {
    newValue = value < realMin ? realMin : value + realStep
  }
  
  if (newValue <= realMax) onChange(newValue)
}

const onDecrease = ({ min, value, step, onChange }: Props) => {
  const realStep = step || 1
  const newValue = value - realStep
  onChange(newValue)
}

const getFixed = (n: number) => {
  const first2 = Math.floor(n * 100)
  if (first2 % 10 !== 0) return 2
  if (first2 % 100 !== 0) return 1
  return 0
}

const validateOnChangeNumber = ( min:number, max:number, onChange:any, value:number, fromInput: boolean) => {

  const validations = [
    { isValid: (value:any) => isNaN(value) , action: () => { onChange(min) } },
    { isValid: (value:number) => value < min , action: () => { onChange(min) } },
    { isValid: (value:number) => value > max , action: () => { onChange(max) } },
    { isValid: (value:number) => value >= min && value <= max , action: () => { onChange(value) } },
  ]

  validations.forEach(validation => {
    if(validation.isValid(value)) {
      validation.action()
    }
  })
}

let timeout:any = null

const onChangeProductQuantity = (e:any, { min, max, onChange }: Props ) => {
  const inputNumber = parseInt(e.target.value)

  clearTimeout(timeout)
  
  timeout = setTimeout(() => {
    if (!min || !max) return
    validateOnChangeNumber(min, max, onChange, inputNumber, true)
  }, 750)
}

const onKeyPressed = (e:any) => {
  if (e.charCode === 13 && document.activeElement instanceof HTMLElement) document.activeElement.blur();
}

const renderFieldAndButtons = (props: any) => {
  const { value, min, disabled } = props
  const realMin = min || -Number.MAX_VALUE

  const buttonsDisabled = disabled !== undefined ? disabled : false

  return (
    <>
      {value >= realMin &&
        <>
        <div className="inputs-background"></div>
        <IonChip 
          className="button btn-left"
          disabled={props.value === 0}
          onClick={() => onDecrease(props)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        >
          <IonIcon className="icon" icon={remove} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        </IonChip>
        <IonInput 
          className="value-cell"
          type="number"
          onIonInput={(e) => onChangeProductQuantity(e, props)}
          value={props.valueToShow.toFixed(getFixed(props.valueToShow))}
          onKeyPress={onKeyPressed} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        />
        </>
      }
      <IonChip 
        className={`button btn-right ${buttonsDisabled ? 'btn-add-disabled' : ''}`}
        disabled={buttonsDisabled || props.value === props.max}
        onClick={() => onIncrease(props)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      >
        <IonIcon className="icon" icon={add} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
      </IonChip>
    </>
  )
}

export const NumberUpDown: FunctionComponent<Props> = props => (
 
  <div className="number-updown-wrapper">
    <div className="number-updown">
      {renderFieldAndButtons(props)}
    </div>
  </div>
)
