import React, { useEffect, useState } from 'react'
import { IonSlide, IonSlides, IonButton } from '@ionic/react'
import { ifExistAndMayorToZero } from '../../utils/arrays';
import './WordsSlider.scss'
import { eventWithName } from '../../pages/firebase/firebaseTags';

interface Props {
  words:any[]
  getVendorsWithOffers?: any
  whenUpdateOffers?: any
  isLoading?: any
  isLoadingProducts?:boolean
  fireEvent?: string
  subcategoryId?: string;
}

const slideOpts = {
  autoHeigth: true,
  spaceBetween: 10,
  slidesPerView: 'auto',
  observer: true,
  observeParents: true
};

const WordsSlider: React.FC<Props> = props => {
  const { words, getVendorsWithOffers, whenUpdateOffers, isLoading, isLoadingProducts} = props
  const IsIfHasPropertyFrom = words[0]?.from ? {} : words[0]
  const [isSelected, setIsSelected] = useState<any>(IsIfHasPropertyFrom)
  const [isFetching, setIsFetching] = useState<boolean>(false)

  useEffect(() => {
    setIsSelected(IsIfHasPropertyFrom)
    if(isSelected) setIsFetching(true)
  }, [!isFetching]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isFetching) {
      setSubcategoryFromDeeplink()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!isFetching]);

  const setSubcategoryFromDeeplink = () => {
    if (props.subcategoryId) {
      setTimeout(() => {
        const word = props.words.find(item => item._id === props.subcategoryId);
        if (word) onClickWord(word);
      }, 500)
    }
  }

  const onClickWord = async (word:any) => {
    
    if (word.from && getVendorsWithOffers && whenUpdateOffers && isLoading) {
      whenUpdateOffers()
      isLoading(true)
      if(!compareIsIfEqualWord(word._id)) {
        setIsSelected(word)
        await getVendorsWithOffers(word._id, word.name)
      } else {
        setIsSelected({})
        await getVendorsWithOffers()
      }
    } else {
      if (isSelected.name !== word.name && props.fireEvent) {
        eventWithName(props.fireEvent, {
          value: word.name
        })
      }
      setIsSelected(word)
      const wordId = word._id.length > 14 ? word._id : words[1]?._id
      const section = document.querySelector(`#header-${wordId}`);
      section?.scrollIntoView({behavior:'smooth'});
    }
  }
  
  const slideWords = () => {
    return (
      words.map((word: any, index:number) => 
        {
          const STYLE_SLIDE = `${index === 0 ? 'isFirst' : ''} ${isSelected._id === word._id ? 'selected' : 'not-selected'} ${words.length - 1 === index ? 'last-word' : ''}`
          const IfISOffer = `${words[0].name === 'Ofertas' && isSelected._id === 'offers' ? 'tab-offer' : ''}`

          return (
            <IonSlide 
              key={index.toString() + word._id}
              className={`ion-slide-word ${STYLE_SLIDE} ${IfISOffer}`}
              onClick={() => onClickWord(word)}
              id={`slide-${word._id}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
              <IonButton
                id={`button-${word._id}`}
                disabled={isLoadingProducts}
                className={`word-slide ${STYLE_SLIDE} ${IfISOffer}`}
                fill="clear" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >
                {word.name}
              </IonButton>
            </IonSlide>
          )
        }
      )
    )
  }

  const compareIsIfEqualWord = (wordId:string) => isSelected._id === wordId

    return (
      <div className="container-slider">
        {isFetching && ifExistAndMayorToZero(words) && 
          <IonSlides className="ion-slides-container" options={slideOpts} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {slideWords()} 
          </IonSlides>
        }
      </div>
    )
  }

export default WordsSlider