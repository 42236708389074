import Axios from 'axios'
import { countryCl, countryPe, countryMx } from './countriesTexts'

export interface GeoInfo {
	countryName: string,
	currency: string,
	countryCode: string
}

const defaultGeoInfo: GeoInfo = {
	countryName: "Chile",
	currency: "CLP",
	countryCode: "CL"
}

const countryThatAllow: any[] = [countryPe]

export const getGeoInfo = async (): Promise<GeoInfo> => {
	try {
		const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/ipinfo`);
		if(!result) {
			localStorage.setItem('countryName', JSON.stringify(defaultGeoInfo))
			return defaultGeoInfo
		}
		let	data: GeoInfo= {
			countryName: result.data.country_name,
			currency:result.data.currency,
			countryCode:result.data.countryCode
		};

		const countrySelect = JSON.stringify(localStorage.getItem("countrySelect"))
		localStorage.setItem('countryName', JSON.parse(countrySelect))
			

		return data
	} catch (error) {
		console.error('Error: =>>>>>>>', error, error?.message)
		throw error
	}
};


export const currentApp = () => {
	return [
		{
			country:countryCl, 
			currencySymbol:'$',
			codeIos:'CLP',
			prefixNumber:'+56',
			countryName: 'Chile',
			countryCode:countryCl,
			currency:'CLP'
		},
		{
			country:countryPe, 
			currencySymbol:'S/',
			codeIos:'PEN',
			prefixNumber:'+51',
			countryName: 'Perú',
			countryCode:countryPe,
			currency: 'PEN'
		},
		{
			country: countryMx, 
			currencySymbol:'$',
			codeIos:'MXP',
			prefixNumber:'+52',
			countryName: 'México',
			countryCode: countryMx,
			currency: 'MXP'
		},
	]	
}

export const displayByCountry = () =>{
	const countryName = localStorage.getItem('countryName') 
	const country = countryName ? JSON.parse(countryName) :  ''
	return countryThatAllow.find(route => country.countryCode !== route) !== undefined
}

