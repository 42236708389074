import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext'
import { PaymentMethodCartData } from '../../interfaces/carts'
import { calculateProductsTotalPrice, calculateTotalDiscountsByProvider } from '../../utils/functions/calculatePrices'

function useCalculatePaymentMethodCardTotalPrice(paymentMethodData: PaymentMethodCartData) {
  const { cartData } = useContext(AppContext)
  const [totalPrice, setTotalPrice] = useState<number>(0)

  const calculateTotalPrice = (): number => {
    let total: number = 0

    paymentMethodData.providers.forEach((provider) => {
      const totalProductsPrice = calculateProductsTotalPrice(provider.products)

      const totalProviderDiscounts = calculateTotalDiscountsByProvider(cartData.discounts, provider._id)

      total += totalProductsPrice - totalProviderDiscounts
    })

    return total
  }

  useEffect(() => {
    setTotalPrice(calculateTotalPrice())
  }, [cartData]) // eslint-disable-line react-hooks/exhaustive-deps

  return { totalPrice }
}

export default useCalculatePaymentMethodCardTotalPrice
