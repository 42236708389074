import Axios from 'axios'
import { currentApp } from '../utils/currentCountry'

export const confirmSms = async (smsCode: any, haxSms: any, user: any) => {
  try {
    const result = await Axios.post<object>(`${process.env.REACT_APP_BFF_NODE}/v1/sms/verify`, {
      smsCode,
      haxSms,
      user
    })
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw error
  }
}

export const requestSms = async (number: any, username: any) => {
  const cleanUsername = username.replaceAll('.', '').replaceAll('-', '')
  try {
    const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_PUBLIC}/v2/sms`, {
      "type": "phone",
      "username": cleanUsername
    })
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw error
  }
}

export const recoveryPasword = async (recoveryData: any) => {
  try {
    const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_NODE}/v1/sms/recovery`, {
      recoveryData
    })
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw error
  }
}

export const recoveryConfirm = async (recoveryData: any) => {
  try {
    const result = await Axios.post<object>(`${process.env.REACT_APP_BFF_NODE}/v1/sms/recovery/verify`, {
      recoveryData
    })
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw error
  }
}

export const sendForm = async (user_contac:string, phone:string, email:string, emailInternal:any, comment:any, subject?:any, data?:any, rut?: any) => {
  const countryName = localStorage.getItem('countryName') 
	const country = countryName ? JSON.parse(countryName) :  ''
  
  const prefixNumber:any = currentApp().find(route => country.countryCode === route.country) 
  const rutRow = rut ? `<tr><td>RUT Comercial: ${rut}</td>` : ''

  const body = data ? 
      `<table  style="text-align: left">
        <tr><td>Nombre: ${user_contac}</td>
        <tr><td>Rut: ${data.rutUser}</td>
        <tr><td>Teléfono: ${prefixNumber.prefixNumber} ${phone}</td>
        <tr><td>Email: ${email}</td>
        <tr><td>Producto: ${data.product.name}</td>
        <tr><td>Sku: ${data.product.sku}</td>
        <tr><td>Dirección: ${data.direction.address}, ${data.direction.city} </td>
        <tr><td>Comentario: ${comment}</td>
      </table>` 
      : `<table  style="text-align: left">
          <tr><td>Nombre: ${user_contac}</td>
          ${rutRow}
          <tr><td>Teléfono: ${prefixNumber.prefixNumber} ${phone}</td>
          <tr><td>Email: ${email}</td>
          <tr><td>Comentario: ${comment}</td>
        </table>`
  try {
    const result = await Axios.post<object>(`${process.env.REACT_APP_BFF_NODE}/v1/email/sendFormEmail`, {
      "to":  [`${emailInternal}`],
      "subject":`${subject ? subject : "[Usuario Rebote] - Registro Incorrecto"}`,
      "body": body
    })
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw error
  }
}


export const recoveryPaswordSmsEmail = async (data: any) => {
  try {
    const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_PUBLIC}/v1/passwords/recovery`, data)
    return result
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const validateCodeSms = async (code:string, hashedCode:string, username:string  ) => {
  try {
    const result = await Axios.put<any>(`${process.env.REACT_APP_BFF_PUBLIC}/v1/passwords/verify`, 
      {
        code: code, 
        hashedCode: hashedCode, 
        username: username 
      }
    )
    return result
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const recoveryPaswordUser = async (newPassword: any, token:string) => {
  try {
    const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_PUBLIC}/v1/passwords/change`, 
      {
        newPassword: newPassword
      },
      {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    return result.data
  } catch (error) {
    console.log('Error: =>>>>>>>', error)
    throw error
  }
}

