import { Container } from 'unstated-typescript'
import { produce } from 'immer'
import { 
  find, 
  count, 
  orderDelete, 
  questions , 
  repeatProduct, 
  findOne, 
  findByStatus, 
  repeatOrder, 
  findHelpOptions, 
  createIncident, 
  guethShippingGuruProvider } from '../clients/order'
import { getFromCache } from '../utils/cache'
import { Incident } from './Incident'

interface OrderState {
  orders: any[]
  count: Number
}

const storageOrdersName = '@orders'
const PAYMENT_PENDING = 'Pendiente de Pago'
const ORDER_FAILED = 'Fallido'

export default class Order extends Container<OrderState> {
  state: OrderState = {
    orders: [],
    count: 0,
  }

  constructor() {
    super()
    const orders = getFromCache<any>(storageOrdersName)
    if (orders) {
      this.setState(
        produce(this.state, draft => {
          draft.orders = orders
        }),
      )
    }
  }

  async get(page: any, limit: any) {
    let orders = await find(limit * page, limit)
    orders = orders.filter((order:any) => (order.state !== PAYMENT_PENDING && order.state !== ORDER_FAILED))
    await this.setState(
      produce(this.state, draft => {
        draft.orders = orders as any[]
      }),
    )
    localStorage.setItem(storageOrdersName, JSON.stringify(orders))
    return orders as any[]
  }

  async getByStatus(page: any, limit: any, states: string) {
    let ordersRes = await findByStatus(page, limit, states)

    const orders = ordersRes.docs.filter((order:any) => (order.state !== PAYMENT_PENDING && order.state !== ORDER_FAILED))
    const nextPageExists = Boolean(ordersRes.nextPage)

    await this.setState(
      produce(this.state, draft => {
        draft.orders = orders as any[]
      }),
    )
    return { orders, nextPageExists }
  }

  async count() {
    const countResult = await count()
    await this.setState(
      produce(this.state, draft => {
        draft.count = countResult as Number
      }),
    )
    return countResult
  }

  async orderDelete(idOrder:string, comment?:string, question?:any) {
    const orders = await orderDelete(idOrder,comment, question)
    return orders
  }

  async questions(){
    const showQuestions = await questions()
    return showQuestions
  }

  async repeatProduct(orderNumber:string){
    const result = await repeatProduct(orderNumber)
    return result 
  }

  async findOne(id:string, withImages: boolean = false){
    const result = await findOne(id, withImages)
    return result 
  }

  async findHelpOptions(orderStatus: string) {
    const result = await findHelpOptions(orderStatus)
    return result 
  }

  async createIncident(incidentData: Incident) {
    const result = await createIncident(incidentData)
    return result 
  }

  async guethShippingGuruProvider(providers:string) {
    const result = await guethShippingGuruProvider(providers)
    return result 
  }


  repeatOrder = async (id:string) => await repeatOrder(id)
}
