import React from 'react'
import {
  IonTextarea,
} from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { produce } from 'immer'
import track from 'react-tracking'

// Clients 
import { sendForm } from '../../clients/validateSms'

// Models
import User from '../../models/User'

// Utils
import { newValidatePhone, validateEmail } from '../../utils/basetypes'
import { isPhoneValidByCountry, phoneLengthByCountry, phonePlaceholderByCountry } from '../../utils/countriesTexts'

// Components
import TextInput from '../../components/input-form/TextInput'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import AlertModal from '../../components/modals/AlertModal'

// Styles
import './AdaptativeLoginForm.scss'

type IPathParams = {}

type Props = RouteComponentProps<IPathParams> & {
  user: User
  message: string
  subject: string
  automaticMessage: boolean
  userRut?: string
  validDni?: boolean
  withDni?: boolean
  notRecoveryAccess?: boolean
}

interface FormAPP{
	username: string
	email: string
  phone: string
  messageLocal:string
}

interface State<T>{
  country: any
  data: { [name in keyof T]: T[name] }
	validation: { [name in keyof T]: boolean }
  isFormValid: boolean
  spinner: boolean
  showAlert: boolean
  focusOnTextarea: boolean
}

class AdaptativeLoginForm extends React.PureComponent<Props, State<FormAPP>> {
  state: State<FormAPP>={
    country:'',
    data: {
      username: '',
      email: '',
      phone: '',
      messageLocal:''
		},
    validation: {
      username: false,
      email: false,
      phone: false,
      messageLocal:false
		},
    isFormValid:false,
    spinner:false,
    showAlert:false,
    focusOnTextarea: false
  }

  async componentDidMount(){
    const countryName = localStorage.getItem('countryName') 
    const country = countryName ? JSON.parse(countryName) :  null 
    this.setState({country:country })
  }

	onChangeInput = (type: keyof FormAPP, value: FormAPP[keyof FormAPP]) => {
    const { automaticMessage } = this.props
    this.setState(
      produce(this.state, draft => {
        switch (type) {
          case 'username':
						draft.data[type] = value
						draft.validation[type] = value.length > 0
            break
          case 'phone':
            draft.data[type] = value
            draft.validation[type] = newValidatePhone(value)
            break
          case 'email':
            draft.data[type] = value
            draft.validation[type] = validateEmail(value)
            break
          case 'messageLocal':
            draft.data[type] = value
            draft.validation[type] = value.length > 0
            break
        }
        if(automaticMessage){
          draft.validation['messageLocal'] = true
        }

        let allAreTrue = true
          ; (Object.keys(draft.validation) as Array<keyof typeof draft.validation>).forEach(key => {
            const field = draft.validation[key]
            if (field === false) {
              allAreTrue = false
            }
          })
        draft.isFormValid = allAreTrue
      }),
    )
  }

  onChange = (type:string, event:any) => {
    this.setState({
      data: {
        ...this.state.data,
        [type]: event.target.value,
      }
    })
  }

	applyFor = async () => {
    const {
      data: { username, phone, email, messageLocal }
    } = this.state
    const { subject, message, automaticMessage, location, userRut }: any = this.props
    const rut: any = userRut || location?.state?.rut 

    this.setState({ spinner:true })
	  const emailInternal=  process.env.REACT_APP_CONTACT_FORM_EMAIL
    const messageUser = automaticMessage ? message : messageLocal
    const result: any = await sendForm(username, phone, email, emailInternal, messageUser, subject, null, rut)

    if(result && result.status === 200){
			localStorage.setItem('formLock', JSON.stringify(true))
			this.setState({
				isFormValid:false,
				spinner:false,
				showAlert:true,
        data: {
					username:'',
					phone:'',
          email:'',
          messageLocal:''
        },
        validation: {
					username: false,
					email: false,
          phone: false,
          messageLocal:false
				},
      })
    }
  }
  
  signOut = () => this.props.history.push('/login')

  validateSendHelp = () => {
    const { validDni, withDni } = this.props
    const {
			data: { phone, email, username },
    } = this.state
    const formWithDniValidator = !isPhoneValidByCountry(phone) || !validateEmail(email) || !username.length || !validDni
    const formWithoutDniValidator = !isPhoneValidByCountry(phone) || !validateEmail(email) || !username.length

    if(withDni) return formWithDniValidator

    return formWithoutDniValidator
  }

  render() {
    const {
			data: {  phone, email, username, messageLocal },
      showAlert, spinner, focusOnTextarea
    } = this.state
    const { automaticMessage, notRecoveryAccess } = this.props

    return (
      <div className='form-container'>
        <TextInput 
          iconDefault="nav_account" 
          iconValid="nav_account-blue"
          onChange={(event:any) => this.onChange('username', event)} 
          placeholder="Ej: María"
          label="Nombre"  
          isValid={!!username.length}
          error={"El nombre debe tener al menos un carácter"}
          value={username}
          type="text"
          toValidate={username}
          autoComplete="off"
        />
        <TextInput 
          iconDefault="icn_phone" 
          iconValid="icn_phone-blue"
          iconInvalid="icn_phone-red"
          onChange={(event:any) => this.onChange('phone', event)} 
          placeholder={phonePlaceholderByCountry()}
          label="Teléfono"  
          isValid={isPhoneValidByCountry(phone)}
          error={"Escribe tu número con el código de área"}
          value={phone}
          type="tel"
          toValidate={phone}
          maxLength={phoneLengthByCountry()}
          autoComplete="off"
        />
        <TextInput 
          iconDefault="icn_email" 
          iconValid="icn_email-blue"
          iconInvalid="icn_email-red"
          onChange={(event:any) => this.onChange('email', event)} 
          placeholder="Ej: maria@email.com"
          label="Correo"
          isValid={!!validateEmail(email)}
          error={"Tu email debe ser válido para recibir mensajes"}
          value={email}
          type="email"
          toValidate={email}
          autoComplete="off"
        />
        <div className="container-textarea-contact"> 
          {!automaticMessage &&
            <div className="container-textarea"> 
              <div className={`message ${focusOnTextarea ? 'in-focus' : ''}`}>Mensaje</div>
              <IonTextarea 
                className={`textarea-contact ${focusOnTextarea ? 'in-focus' : ''}`}
                value={messageLocal}
                onIonFocus={() => this.setState({ focusOnTextarea: true })}
                onIonBlur={() => this.setState({ focusOnTextarea: false })}
                onIonChange={e => this.onChangeInput('messageLocal', e.detail.value || '')}
                placeholder={"Deja tu duda aquí"} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              />
            </div>
          }
          
        </div>
        <div className='footer'>
          <ButtonComponent 
            className={`btn-primary ${spinner ? 'loading' : ''}`}
            text="Enviar"
            disabled={this.validateSendHelp()}
            onClick={this.applyFor}
          />
        </div>
        <AlertModal 
          label={'¡Envío exitoso!'}
          text={`Nos comunicaremos contigo a la brevedad para ayudarte${!notRecoveryAccess ? ' a recuperar tu acceso.' : '.'}`}
          buttonText={'Ok'}
          isOpen={showAlert}
          buttonAction={this.signOut}
          onDismiss={this.signOut}
        />
      </div>
    )
  }
}

export default track({ page: 'AdaptativeLoginForm' })(withRouter(AdaptativeLoginForm))

