import React, { Fragment, useContext, useState } from 'react'
import { IonIcon } from '@ionic/react'
import { closeOutline, trashOutline } from 'ionicons/icons'
import './index.scss'
import AppContext from '../../../../context/AppContext'
import DeleteModal from '../delete_modal'
import { deleteCart } from '../../../../services/bff_mobile/v2/carts/deleteCart'

function CartToolbar() {
  const { platform } = useContext(AppContext)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const { cartData, handlerCartData } = useContext(AppContext)
  const [loadingButton, setLoadingButton] = useState<boolean>(false)

  const handleDeleteCart = async () => {
    setLoadingButton(true)
    await deleteCart(cartData._id)
    handlerCartData({
      _id: '',
      data: [],
      discounts: [],
    })

    setLoadingButton(false)
    setOpenDeleteModal(false)
  }

  const openModal = (): void => setOpenDeleteModal(true)
  const closeModal = (): void => setOpenDeleteModal(false)

  return (
    <Fragment>
      <header className={`cart-header ${platform === 'ios' ? 'mobile' : 'web'}`}>
        <IonIcon icon={closeOutline} className="close-icon" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
        <h3>Carrito</h3>
        <IonIcon icon={trashOutline} size="large" onClick={openModal} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
      </header>

      <DeleteModal
        open={openDeleteModal}
        title="Vaciar carro"
        message="¿Estás seguro que quieres vaciar tu carrito?"
        action={handleDeleteCart}
        closeModal={closeModal}
        loadingButton={loadingButton}
        buttonActionName="Vaciar"
      />
    </Fragment>
  )
}

export default CartToolbar
