import React, { Fragment, useState, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router'

import { uniq } from 'lodash'

import {
  IonText, IonIcon, IonSkeletonText,IonImg, IonChip, IonButton, IonLabel, IonItem, IonSelect, IonSelectOption
} from '@ionic/react'
import { caretDown, caretUp } from 'ionicons/icons'

import Garbage from './../../assets/icons/icon_garbage.svg'
import Edit from './../../assets/icons/icon_edit.svg'

import { CartModel, CartProduct } from '../../models/CartModel'
import { ProviderModel } from '../../models/ProviderModel'
import { Product } from '../../models/ProductModel'
import {  updateCartClient } from '../../clients/cart'
import { formatCurrency } from '../../utils/intl'
import { groupBy } from '../../utils/arrays'

import { sendMetric } from '../firebase/firebaseTags'

import './CardCart.scss'

interface IPathParams {}

interface RouteState {
  eventSource?: any
  singleProviderId: any
  nameProvider: any
  minumun: any
  banners: any
}

type Props = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  readonly cartModel: CartModel
  readonly providerModel: ProviderModel
  providerId:string
  provider:any
  isMinimumFulfilled:boolean
  difference:any
  productsGroupedCard:any
  productsCard:any
  cuponsActiveCard:any
  couponReferalCard:any
  providerReferalCard:any
  subtotal:number
  totalToPay:any
  showModal:any
  renderModal:any
  removeProducts:any
  onOpenChangeWayToPay:any
  couponsController:any
  couponCountCard:number
  activeCouponReferal:any
  handleDesactivateReferal:any
  paymentMethod:string,
  cartLength: number
}

const CardCart: React.FC<Props> = props => {
  const [isInfoCard, setIsInfoCard] = useState<boolean>(false)

  useEffect(() => {
    if(props.cartLength === 1) {
      setIsInfoCard(true)
    } else {
      setIsInfoCard(false)
    }
   }, []) // eslint-disable-line react-hooks/exhaustive-deps
 
  const renderProduct = (cartProduct: CartProduct, product: Product, quantity:Number) => {
    const { providerId, sku } = cartProduct
    const { cartModel } = props
    const { filename, brand, name } = product
    const productsInCart = cartModel.getCart()
    const productsGrouped = groupBy(productsInCart, p => p.providerId)

    return (
      <div className="provider-product" key={sku}>
        {/* {renderModal(cartProduct)} */}
        <div className="quantity">{`${quantity}`}</div>
        <div> <IonImg className="product-image" src={filename} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></div>
      
        <div className="product-info">
          <IonText className="product-name" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{`${brand} · ${name.slice(0,20)}...`}</IonText>
          {/* <div>{quantity}</div> */}
          <IonText className="product-price" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              {Object.keys(productsGrouped).map(providerId => {
              return (
                productsGrouped[providerId].map((item:any)=>
                { 
                  return (  
                  <div  key={sku}>
                    {item.sku === product.sku  && 
                    formatCurrency(item.infoPrice.totalPrice)
                    }
                  </div> )
                  }
                )
                )})}
          </IonText>
        </div>
        <div className="product-operations">
          <IonChip outline className="product-operation" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonIcon className="icon" src={Edit} onClick={() => { onEditProduct(providerId, sku, product, cartProduct) } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /> 
          </IonChip>
          <IonChip outline className="product-operation" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonIcon
              className="icon"
              src={Garbage}
              onClick={async () => { onDeleteProduct(providerId, sku, product) } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            />
          </IonChip>
        </div>
      </div>
    )
  }

  const renderDiscount = (cartProvider: any, discountProvider: any, amount: any, data: any) => {
    if (cartProvider === discountProvider) {
      var frase = `#${data.code} descuento por ${formatCurrency(data.realDiscount)}`
      if (data.discountType === 'porcent') frase = ` #${data.code} descuento por ${data.amount}`
      
      if (data.applyDiscount) {
        return (
          <div className="coupon" key={discountProvider} align-content="center">
            {(data.couponType === 'referal' || data.couponType === 'choose-vendor') ?  (
              <div className="container-desactivate-referal">
                <div className="container-referal-text">
                  <div className="container-referal-title">
                    Cupón
                    {data.discountType === 'porcent' ? <span>{data.amount}%</span> : <span>{formatCurrency(data.amount)}</span>}
                  </div>
                  <div className="container-referal-numCoupon">#{data.code}</div>
                </div>
                <div className="container-referal-btn">
                  <IonButton 
                    onClick={() => handleDesactivateReferal(data.couponId)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Anular</IonButton>
                </div>
              </div>
            ) : (
              <IonChip
                  outline
                  color="success"
                  class="ion-color ion-color-success ios chip-outline ion-activatable hydrated" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >
                <IonIcon src="/static/media/confirmation_number-24px.c8ee5853.svg" role="img" class="ios hydrated" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><b> ACTIVO </b>  Cupón {frase}</IonLabel>
              </IonChip>
            )}
          </div>
        )
      } else {
        if (data.discountType === 'amount') {
          return (
            <div className="container-warning" key={discountProvider}>
              <IonChip
                outline
                color="warning"
                class="ion-color ion-color-warning ios chip-outline ion-activatable hydrated" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >
                <IonIcon src="/static/media/confirmation_number-24px.c8ee5853.svg" role="img" class="ios hydrated" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  Compra mínima {formatCurrency(data.minimumAmount)} para cupón de {' '}
                  {formatCurrency(data.amount)}
                </IonLabel>
              </IonChip>
            </div>
          )
        } else {
          return (
            <div className="container-warning" key={discountProvider}>
              <IonChip color="warning" class="ion-color ion-color-warning ios chip-outline ion-activatable hydrated" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonIcon src="/static/media/confirmation_number-24px.c8ee5853.svg" role="img" class="ios hydrated" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  Compra mínima {formatCurrency(data.minimumAmount)} para cupón de {data.amount}%
                </IonLabel>
              </IonChip>
            </div>
          )
        }
      }
    }
  }

  const handleDesactivateReferal = async (idCoupon:any) => {
    const cartLocalStorage = localStorage.getItem('@cart')
    let cart: any = ''
    if(cartLocalStorage){
      cart = JSON.parse(cartLocalStorage)
      const discounts = cart.discounts.filter((item:any) =>  item.couponId !== idCoupon)
      cart = {...cart, "discounts":discounts}
      localStorage.setItem('@cart', JSON.stringify(cart))
      await updateCartClient(cart)
    }
    props.handleDesactivateReferal(idCoupon)
  }

  const verificarDescuento = (proveedor: any, descuentos: any, subtotal: any) => {
    var totalToDiscount = 0

    var result = descuentos.filter((detail: any) => detail.provider === proveedor)

    if (result.length > 0) {
      totalToDiscount = result[0].realDiscount
      props.totalToPay(totalToDiscount, props.paymentMethod)
    }
  
    return totalToDiscount
  }

  const showInfoCard = () =>{
    setIsInfoCard(!isInfoCard)
  }

  const onCompleteProduct = (provider: any, providerId: any) => {
    const { alias, minimun, banners } = provider
    sendMetric('complete_cart')
    props.history.push(`/home-providers/${providerId}`, {
      singleProviderId: providerId,
      nameProvider: alias,
      minumun: minimun,
      banners,
    })
  }

  const onEditProduct = (providerId: any, sku: any, product: any, cartProduct: any) => {
    const { showModal, renderModal } = props
    showModal(providerId, sku, product)
    renderModal(cartProduct)
  }

  const onDeleteProduct = async (providerId: any, sku: any, product: any) => {
    const { cartModel, removeProducts, couponsController, location } = props
    const eventSource = location?.state?.eventSource || ''
    await cartModel.removeProduct(providerId, sku, product, eventSource)
    removeProducts()
    let cartId: string = cartModel.getId() as string
    couponsController(cartId)
  }

  const renderProviderTotal = () => {
    if(verificarDescuento(providerId, cuponsActiveCard, subtotal) > 0){
      return <IonText className="provider-total-value" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{formatCurrency(subtotal - verificarDescuento(providerId, cuponsActiveCard, subtotal))}</IonText>
    }
    return <IonText className="provider-total-value" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{formatCurrency(subtotal)}</IonText>
  }

  const {
    providerModel, providerId, provider, isMinimumFulfilled, difference, productsGroupedCard, productsCard,
     cuponsActiveCard, couponReferalCard, providerReferalCard, subtotal
  } = props
 
  let wayToPay = uniq(provider.paymentMethods.map((item:any) => item.type) )
  const methodsKeyValues: any = { 
    'pre-pago': 'Pago anticipado',
    'post-pago': 'Pago contra entrega',
    'credito': 'Pago crédito proveedor',
  }

  return (
    <div className="provider-list">
      <Fragment key={providerId}>
        <div className={isMinimumFulfilled ? 'provider-summary' : 'provider-summary invalid'}>
          <div className="provider-header-cart">
            <div className="container-provider-header">
              <div className="provider-title">
                {provider.alias}
              </div>
              {/* <div className="delivery-days">ENTREGA {provider && provider.deliveryInfo && provider.deliveryInfo.days} DÍAS</div> */}
              <div className="icon-provider">
              <IonIcon className="icon" icon={isInfoCard ? caretUp : caretDown} onClick={showInfoCard} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </div>
            </div>
          </div>
        {isInfoCard && 
        <div>
          <div className="provider-products">
            {productsGroupedCard[providerId].map((productInCart:any) => {
              const { sku } = productInCart
              const providerIdProducts = productsCard[providerId] || {}
              const product = providerIdProducts[sku]

              if (!product || typeof product === 'string') return <IonSkeletonText animated key={sku} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              return renderProduct(productInCart, product, productInCart.quantity)
            })}
                
          </div>

        {cuponsActiveCard?.length > 1 ? (
          <div>
            {cuponsActiveCard.map((data: any) => {
              return renderDiscount(providerId, data.provider, data.realDiscount, data)
            })}
          </div> ) :
        (
        providerReferalCard?.providers?.length > 0 &&  
        providerReferalCard.providers.includes(providerId) ? '' : 
        Object.entries(couponReferalCard).length > 0 && 
        <div className="containter-coupon-referal">
            <div className="container-referal-text">
              <div className="container-referal-title" >Cupón 
              {couponReferalCard.discount_type==='porcent' ? <span> - {couponReferalCard.amount}%</span>  :
                <span> - {formatCurrency(couponReferalCard.amount)}</span>
              }
              </div>
              <div className="container-referal-numCoupon" >#{couponReferalCard.code}</div>
            </div>
            <div className="container-referal-btn">
              <IonButton 
                            className="apply"
                            onClick={() => { props.activeCouponReferal(providerId, couponReferalCard) } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >Aplicar</IonButton>
            </div>
          </div>
        )}
        </div>}
        {providerModel.getById(providerId).minimun && difference > 0 && (
          <div className="container-minimun">
            <div className="text-minimun">
              <IonText placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {'Mínimo para entrega '}
                <IonText className="provider-minimum" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  {formatCurrency(providerModel.getById(providerId).minimun)}
                </IonText>
              </IonText>
            </div>
            <div className="provider-button">
              {!isMinimumFulfilled && difference > 0 && (
                <IonButton
                    shape="round"
                    onClick={() => { onCompleteProduct(provider, providerId) } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                >
                  Completar
                </IonButton>
              )}
            </div>
        </div>)}
        <div className="provider-footer">
          <div className="provider-total">
            <div className="provider-total-info">
              <IonText className="provider-total-title" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Total</IonText>
              {renderProviderTotal()}
            </div>
          </div>
          {isInfoCard && wayToPay && wayToPay.length > 1 &&
            <IonItem className="way-to-pay-card" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Forma de pago</IonLabel>
              <IonSelect 
                  className="select-way-to-pay"
                  mode="md"
                  interface="action-sheet"
                  cancelText="Cancelar"
                  value={''}
                  onIonChange={(e) => props.onOpenChangeWayToPay(e, providerId)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >
                <IonSelectOption value="pre-pago" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Pago anticipado</IonSelectOption>
                <IonSelectOption value="post-pago" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Pago contra entrega</IonSelectOption>
              </IonSelect>
            </IonItem>
          }
        </div>
        {isInfoCard && wayToPay?.length > 1 &&
          
          <IonItem className="way-to-pay-card" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Forma de pago</IonLabel>
            <IonSelect 
                className="select-way-to-pay"
                mode="md"
                interface="action-sheet"
                cancelText="Cancelar"
                onIonChange={(e) => props.onOpenChangeWayToPay(e, providerId)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
              {wayToPay.map((method: any, index: number) => {
                const currentMethod = methodsKeyValues[method]
                return <IonSelectOption key={index} value={method} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{currentMethod}</IonSelectOption>
              })}
            </IonSelect>
          </IonItem>
        }
      </div>
      </Fragment>
    </div>
  )
}

export default withRouter(CardCart)
