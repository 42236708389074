export const validateLocationState = (history: any, varsToCheck: any[]) => {
  let isValid = false
  const locationState: any = history?.location?.state

  if(locationState){
    isValid = varsToCheck.every((name:any) => locationState[name] !== null && locationState[name] !== undefined)
  }

  if(!isValid) history.push('/home')
  return isValid
}

export const areCartProductsLoading = () => {
  const loadingCartProductsLocal = localStorage.getItem("loadingCartProducts")
  const loadingCartProducts = loadingCartProductsLocal && loadingCartProductsLocal !== 'null' ? JSON.parse(loadingCartProductsLocal) : { adding: [], subtracting: [] }
  return Boolean(loadingCartProducts?.adding?.length) || Boolean(loadingCartProducts?.subtracting?.length)
}