import { useEffect, useState } from 'react'
import useGetShippingCostByProviderId from './useGetShippingCostByProviderId'

function useFindShippingCost(providerId: string, totalPrice: number) {
  const { shippingCosts } = useGetShippingCostByProviderId(providerId)
  const [shippingCost, setShippingCost] = useState<number | undefined>(0)

  const findShippingCost = () =>
    shippingCosts.find((shippingCost) => totalPrice > shippingCost.minAmount && totalPrice < shippingCost.maxAmount)
      ?.cost

  useEffect(() => {
    setShippingCost(findShippingCost())
  }, [totalPrice]) // eslint-disable-line react-hooks/exhaustive-deps

  return { shippingCost }
}

export default useFindShippingCost
