import { IonContent, IonPage } from '@ionic/react'
import React, { useState } from 'react'
import NavbarHomeProviders from '../../components/navbar-home-providers'
import './index.scss'
import InfoFulfillment from './components/info-fulfillment'
import SchedulesFulfillment from './components/schedules-fulfillment'
import BrandsHomeFulfillment from './components/brands-home-fulfillment'
import CategoriesHomeFulfillment from './components/categories-home-fulfillment'
import ProductsSlidesFulfillment from './components/products-slides-fulfillment'
import { ProductSlidesTypeEnums } from '../../enums/products'
import ModalDetailProduct from '../../components/modal-detail-product'

function HomeFulfillment() {
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <>
    <IonPage {...{} as any}>
      <IonContent {...{} as any}>
        <NavbarHomeProviders redirectTo="home" />

        <div className="home-fulfillment-content">
          <InfoFulfillment />

          <SchedulesFulfillment />

          <BrandsHomeFulfillment />

          <CategoriesHomeFulfillment />

          <ProductsSlidesFulfillment title="Favoritos" type={ProductSlidesTypeEnums.FAVORITOS} setShowModal={setShowModal}/>

          <ProductsSlidesFulfillment title="Ofertas" type={ProductSlidesTypeEnums.OFERTAS} setShowModal={setShowModal}/>

          <ProductsSlidesFulfillment title="Top Ventas" type={ProductSlidesTypeEnums.OTROS} setShowModal={setShowModal} />
        </div>
      </IonContent>
    </IonPage>

    <ModalDetailProduct showModal={showModal} setShowModal={setShowModal}/>
    </>
  )
}

export default HomeFulfillment
