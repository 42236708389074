import Axios from 'axios'

export const fetchDiscounts = async (cartId: string) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/coupons/${cartId}`)
    return result?.data.length ? result.data : []
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const find = async (skip: any, limit: any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/coupons`, {
      params: {
        filter: JSON.stringify({
          skip,
          limit,
        }),
      },
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    return null
  }
}

export const findUsed = async (skip: any, limit: any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/coupons/used`, {
      params: {
        filter: JSON.stringify({
          skip,
          limit,
        }),
      },
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    return null
  }
}

export const findExpired = async (skip: any, limit: any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/coupons/expired`, {
      params: {
        filter: JSON.stringify({
          skip,
          limit,
        }),
      },
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const findOne = async (id: any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/coupons/${id}`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const count = async () => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/coupons/count`)
    return result.data.count
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const activate = async (id: any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/coupons/activate/${id}`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const countReferal = async (codeCoupons: string, idUser: string) => {
  try {
    const result = await Axios.get<any>(
      `${process.env.REACT_APP_BFF_NODE}/v2/coupons/ValidateReferalCoupon/${codeCoupons}/${idUser}`,
    )
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const couponReferalCart = async (idUser: string, typeCoupon: string) => {
  try {
    const result = await Axios.get<any>(
      `${process.env.REACT_APP_BFF_NODE}/v1/coupons/findByUserIdWithoutVendor/${typeCoupon}`,
    )
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const activateCouponReferal = async (idcoupon: string, idUser: string, idVendor: string) => {
  try {
    const result = await Axios.get<any>(
      `${process.env.REACT_APP_BFF_NODE}/v1/coupons/updateReferal/${idcoupon}/${idUser}/${idVendor}`,
    )
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const desactivateCouponReferal = async (idcoupon: string, idUser: string) => {
  try {
    const result = await Axios.get<any>(
      `${process.env.REACT_APP_BFF_NODE}/v1/coupons/removeReferal/${idcoupon}/${idUser}`,
    )
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const removeCouponReferal = async (code: any, userId: any) => {
  try {
    const result = await Axios.delete<any>(
      `${process.env.REACT_APP_BFF_NODE}/v1/coupons/removeActivated/${code}/${userId}`,
    )
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const getALLCoupons = async () => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/coupons/activated`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const cartAddCoupon = async (cartId:string, providerId:string, cuponId: string) : Promise<any> => {
   try {
     const result = await Axios.put<any>(`${process.env.REACT_APP_BFF_NODE}/v2/carts/${cartId}/coupons/${cuponId}?providerId=${providerId}`)
     return result.data
   } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
     throw error
   }
 }
 
 
 export const deactivateCoupon = async (cartId:string, cuponId: string ) : Promise<any> => {
   try {
     const result = await Axios.delete<any>(`${process.env.REACT_APP_BFF_NODE}/v2/carts/${cartId}/coupons/${cuponId}`)
     return result.data
   } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
     throw error
   }
 }

  
 export const availableVendorsForCoupon = async (cuponId: string ) : Promise<any> => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/coupons/${cuponId}/vendors`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
  }
}

export const getCouponsActive = async () => {
  const response = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v2/coupons/activated`)
  return response.data
}
