import { Storage } from '@capacitor/storage';

export const validatorStorage = (info:any, nextUpdate: {}, appVersion: any, state:any, appVersionString: string) => {
  if (info.platform !== 'web' && appVersionString && nextUpdate) {
    if (appVersion.update && appVersion.url && (appVersion.required || !state.nextUpdate || state.nextUpdate.nextUpdate <= new Date().getTime())) {
      cleanNative()
      }
    } else {
    cleanLocal()
  }
}

export const validatorStorageForMenu = (info:any, nextUpdate: {}, appVersion: any, state:any, appVersionString: string) => {
  if (info.platform !== 'web' && appVersionString && nextUpdate) {
    if (appVersion.update && appVersion.url && (appVersion.required || !state.nextUpdate || state.nextUpdate.nextUpdate <= new Date().getTime())) {
      cleanNative()
    }
  }
}

export const cleanLocal = () =>{
  const cleanLocal = [
    '@access_token',
    '@user',
    '@device', 
    '@offices',
    'formLock',
    '@providers', 
    '@hashtags', 
    '@cart', 
    'currentOffice', 
    'countryName', 
    'recoveryPassword', 
    'welcomeGuru', 
    'countrySelect', 
    'variationsText', 
    'lastAccess', 
    'ordersConfirm',
    'currentNotifications', 
    'hasOpenedNotifications',
    'lastNotification',
    'eventsOrders',
    'currentCartId',
    'loadingCartProducts',
    'userEventsId',
    'pushNotifData',
    'lastUpdateHome',
    'homeData',
    '@promotions',
    'bannerPromotion',
    'officeRegisterSuccessful',
    '@visitedPromotions',
    'firstEntered',
    "marketingRegisterParams",
    'cartNavigation',
    'timers',
  ]

  for (let key in localStorage) {
      if (!cleanLocal.includes(key)) localStorage.removeItem(key)
  }
}

  export const cleanNative = async () =>  {
    const { keys } = await Storage.keys();

    const cleanNative = [
      '@access_token',
      '@user',
      '@device', 
      '@offices',
      'formLock',
      '@providers', 
      '@hashtags', 
      '@cart', 
      'currentOffice', 
      'countryName',
      'recoveryPassword', 
      'welcomeGuru', 
      'countrySelect', 
      'variationsText', 
      'lastAccess', 
      'ordersConfirm',
      'currentNotifications', 
      'hasOpenedNotifications',
      'lastNotification',
      'eventsOrders',
      'currentCartId',
      'loadingCartProducts',
      'userEventsId',
      'pushNotifData',
      'lastUpdateHome',
      'homeData',
      'bannersPromotion',
      '@promotions',
      'bannerPromotion',
      'officeRegisterSuccessful',
      '@visitedPromotions',
      'firstEntered',
      "marketingRegisterParams",
      'cartNavigation',
      'timers',
    ]

    for (let key in keys) {
      if (!cleanNative.includes(key)) await Storage.remove({ key });
    }
    
    for (let key in localStorage) {
      const localStorageClean = [
        '@access_token',
        '@user','@device',
        '@providers',
        'recoveryPassword', 
        'welcomeGuru', 
        'countrySelect', 
        'variationsText', 
        'ordersConfirm',
        'lastNotification',
        'eventsOrders',
        'currentCartId',
        'loadingCartProducts',
        'userEventsId',
        'pushNotifData',
        'userEventsId',
        'officeRegisterSuccessful',
        'firstEntered',
        "marketingRegisterParams",
        '@visitedPromotions',
        'cartNavigation',
        'timers',
      ]
        
      if(!localStorageClean.includes(key)) localStorage.removeItem(key);
    }
  }


  export const getQueryVariable = (variable:string) => {
    var query = window.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1])
      }
    }
    return null
  }
