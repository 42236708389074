import React from 'react';
import { eventWithName } from '../../pages/firebase/firebaseTags';
import './CouponsCountCard.scss'

interface CouponsCountCardProps {
  couponsQuantity: number
  history: any
}

const CouponsCountCard: React.FC<CouponsCountCardProps> = (props) => {
  const { couponsQuantity, history } = props

  const redirectToCoupons = () => {
    eventWithName('click_view_coupons')
    history.push("/coupons")
  }

  if(!couponsQuantity) return <></>

  return (
    <div className="coupons-count-card">
      <h6 className="coupons-count-text">¡Tienes {couponsQuantity} {couponsQuantity === 1 ? "cupón disponible!" : "cupones disponibles!"}</h6>
      <h6 className="coupons-link-text" onClick={redirectToCoupons}>Ver todos</h6>
    </div>
  )
};

export default CouponsCountCard;