import React, { useState, useEffect, createContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

const SESSION_DURATION = 45 * 60 * 1000; // 45 minutos en milisegundos

// Definimos el tipo para nuestro contexto
export type SessionContextType = string | null;

// Creamos el contexto con un valor inicial null
export const SessionContext = createContext<SessionContextType>(null);

export const UserSessionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [sessionId, setSessionId] = useState<string | null>(null);

  const createSession = (): void => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
    localStorage.setItem('userSessionCode', newSessionId);
    localStorage.setItem('sessionStart', Date.now().toString());
  };

  const checkSession = (): void => {
    const storedSessionId = localStorage.getItem('userSessionCode');
    const sessionStart = parseInt(localStorage.getItem('sessionStart') || '0', 10);
    const currentTime = Date.now();

    if (!storedSessionId || currentTime - sessionStart > SESSION_DURATION) {
      createSession();
    } else {
      setSessionId(storedSessionId);
    }
  };

  useEffect(() => {
    checkSession();

    const intervalId = setInterval(checkSession, 60000); // Verificar cada minuto

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <SessionContext.Provider value={sessionId}>
      {children}
    </SessionContext.Provider>
  );
};

export default UserSessionProvider;