import React from 'react'
import {
  IonContent,
  IonButton,
  IonImg,
  IonPage,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
  IonLoading,
  IonRippleEffect,
} from '@ionic/react'
import { checkmarkCircle } from 'ionicons/icons'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import UserModel from '../../models/User'
import SettingsModel from '../../models/Settings'
import logo from './../../assets/logo-top-blue.png'
import './PasswordConfirm.scss'
import { password } from '../../clients/login'

interface IPathParams { }

type Props = RouteComponentProps<IPathParams> & {
  user: UserModel
  settings: SettingsModel
}

interface State {
  newPassword: any []
  passwordConfirmation: any[]
  isFormValid: boolean
  loading: boolean
}

class PasswordConfirm extends React.PureComponent<Props, State> {
  state: State = {
    newPassword: ['', false],
    passwordConfirmation: ['', false],
    isFormValid: false,
    loading: false,
  }

  onButtonClick = async () => {
    this.setState({
      loading: true,
    })
    const { access_token }: any = this.props.history.location.state
    await password(access_token, this.state.newPassword[0])
    await this.props.user.authenticateWith(this.props.history.location.state)
    this.setState({
      loading: false,
    })
    window.location.href = '/'
  }

  updateFormValid = (callback?: () => void) => () => {
    const {
      newPassword: [, validNewPassword],
      passwordConfirmation: [, validPasswordConfirmation],
    } = this.state
    const isValid = validNewPassword && validPasswordConfirmation
    this.setState({ isFormValid: isValid }, callback)
  }

  onChangeNewPassword = (value: string, callback?: () => void): void => {
    this.setState({ newPassword: [value, value.length >= 4] }, this.updateFormValid(callback))
  }

  onChangePasswordConfirmation = (value: string, callback?: () => void): void => {
    this.setState(
      { passwordConfirmation: [value, value === this.state.newPassword[0]] },
      this.updateFormValid(callback),
    )
  }

  render() {
    const {
      newPassword: [newPasswordValue, newPasswordValid],
      passwordConfirmation: [passwordConfirmationValue, passwordConfirmationValid],
    } = this.state
    return (
      <IonPage className="confirm-page" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonLoading
            mode="md"
            cssClass="close-session"
            isOpen={this.state.loading}
            onDidDismiss={() => { }}
            message={'Guardando nueva contraseña....'}
            translucent={true}
          ></IonLoading>
          <IonImg src={logo} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          <div className="welcome-title">
            <div>Para finalizar</div>
            <div>Crea una nueva contraseña de acceso</div>
          </div>
          <form>
            <div className="form">
              <IonItem className={newPasswordValid ? 'item-valid' : ''} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonLabel position="floating" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Contraseña nueva</IonLabel>
                <IonInput
                  value={newPasswordValue}
                  onIonChange={e => this.onChangeNewPassword(e.detail.value || '')}
                  required={true}
                  autocomplete="off"
                  type="password" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                ></IonInput>
                <IonIcon size="small" icon={checkmarkCircle} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </IonItem>
              <IonItem className={passwordConfirmationValid ? 'item-valid' : ''} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonLabel position="floating" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Confirma contraseña</IonLabel>
                <IonInput
                  value={passwordConfirmationValue}
                  onIonChange={e => this.onChangePasswordConfirmation(e.detail.value || '')}
                  required={true}
                  autocomplete="off"
                  type="password" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                ></IonInput>
                <IonIcon size="small" icon={checkmarkCircle} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </IonItem>
              <br />
              <br />
              {this.state.isFormValid}
              <IonButton
                disabled={!this.state.isFormValid}
                strong={true}
                shape="round"
                onClick={this.onButtonClick}
                expand="block" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >
                <IonRippleEffect placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonRippleEffect>
                Finalizar
              </IonButton>
              <a href="/">
                <IonRippleEffect placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonRippleEffect>
                Volver
              </a>
            </div>
          </form>
        </IonContent>
      </IonPage>
    )
  }
}

export default withRouter(PasswordConfirm)
