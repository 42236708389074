import { addOrEditProductCart, deleteProductFromCart, getCartId, getProductCart } from "../clients/cartNew"

export const getLengthOfCart = () => {
  const cartLocalStorage = localStorage.getItem('@cart')
  let cart: any = ''
  
  if (cartLocalStorage) cart = JSON.parse(cartLocalStorage).products
  cart = cart && cart.filter((item:any) => !item.paid)
  return cart.length
}

export const addToCart = (cartId: any, productId: any, quantity: any) => (
  new Promise(async (resolve, reject) => {
    let atcRes:any = ''
    if(cartId !== ''){
      try {
        atcRes = await addOrEditProductCart(cartId, productId, quantity)
        const productCart:any = await getProductCart(cartId)

        checkLoadingCartProducts(productId, 'adding')

        productCart.cart && localStorage.setItem('@cart', JSON.stringify(productCart.cart))
        return resolve({ atcRes, cartLength: productCart.cart?.products?.length || null })
        
      } catch (error) {
        checkLoadingCartProducts(productId, 'adding')
        console.error("Error", error)
        return reject('')
        
      }
    }
    reject('')
  })
)

export const removeFromCart = (cartId: any, productId: any) => (
  new Promise(async (resolve, reject) => {
    let deleteRes:any = ''
    if(cartId !== ''){
      try {
        deleteRes = await deleteProductFromCart(cartId, productId)
        const productCart:any = await getProductCart(cartId)

        checkLoadingCartProducts(productId, 'subtracting')

        productCart.cart && localStorage.setItem('@cart', JSON.stringify(productCart.cart))
        return resolve({ deleteRes, cartLength: productCart.cart?.products?.length || null })

      } catch (error) {
        checkLoadingCartProducts(productId, 'subtracting')
        console.error("Error", error)
        return reject(null)
      }
    }

    resolve(deleteRes)
  })
)

export const getCartLength = () => {
  return new Promise(async (resolve, reject) => {
    try {
      localStorage.removeItem('loadingCartProducts')
      const idCart = await getCartId()
      let productCart = await getProductCart(idCart.data._id)
      const cart = productCart?.cart?.products.filter((item:any) => !item.paid)
      localStorage.setItem("currentCartId", idCart.data._id)
      localStorage.setItem("@cart", JSON.stringify(productCart.cart))
      resolve(cart?.length || null)
    } catch (err) {
      console.error(err)
      reject('')
    }
  })
}

export const getCurrentCartId = async () => {
  try {
    const idCart = await getCartId()
    if(Object.keys(idCart).length > 0) return idCart.data._id
  } catch (error) {
    console.error("Error", error)
    return null
  }
}

const checkLoadingCartProducts = (productId: any, action: string) => {
  const loadingCartProductsLocal = localStorage.getItem("loadingCartProducts")
  let loadingCartProducts = loadingCartProductsLocal ? JSON.parse(loadingCartProductsLocal) : { adding: [], subtracting: [] }

  if(!loadingCartProducts || loadingCartProducts === 'null') return localStorage.setItem('loadingCartProducts', JSON.stringify(loadingCartProducts))

  const checkedArray = action === 'adding' ? loadingCartProducts.adding : loadingCartProducts.subtracting
  const indexOfDelete = checkedArray.indexOf(productId)

  if (indexOfDelete > -1) {
    if(action === 'adding') loadingCartProducts?.adding?.splice(indexOfDelete, 1) 
    if(action === 'subtracting') loadingCartProducts?.subtracting?.splice(indexOfDelete, 1) 
  }

  localStorage.setItem('loadingCartProducts', JSON.stringify(loadingCartProducts))
}

export const findShippingCost = (total: number, vendorId: string, shippingCostProvider: any) => {
  if (shippingCostProvider && shippingCostProvider[vendorId]) {
    const costs: any = shippingCostProvider[vendorId]
    const sortedCosts = [...costs].sort(
      (a, b) => parseFloat(a.minAmount) - parseFloat(b.minAmount)
    );
    const shippingCost = sortedCosts.filter((item: any) => {
      return total >= item.minAmount && total <= item.maxAmount
    })
    if(shippingCost.length) {
      return shippingCost[0]
    }
    if(sortedCosts.length) {
      if (total < sortedCosts[0].minAmount) {
        return 0
      }
      return sortedCosts[sortedCosts.length - 1]
    }
  }
  return null
}

export const verificarDescuento = (vendorId: string, coupons: any[]) => {
  let totalToDiscount = 0
  const result = coupons.find((detail: any) => detail.providerId === vendorId)

  if (result) totalToDiscount = result.realDiscount
  
  return totalToDiscount
}

export const totalProducts = (products: any[]) => {
  const total = products.map(({infoPrice}: any) => infoPrice)
    .reduce((acc:any,  current:any ) =>  acc + current.totalPrice, 0)

  return total
}