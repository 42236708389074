import React from 'react'
import {
  IonContent,
  IonButton,
  IonImg,
  IonPage,
  IonItem,
  IonInput,
  IonIcon,
  IonButtons,
  IonSelect,
  IonSelectOption,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import track from 'react-tracking'
import UserModel from './../../models/User'
import SettingsModel from './../../models/Settings'

import './RegisterOptional.scss'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { typeOfBussiness, sendOptionalRegistration } from '../../clients/register'
//ICONS

import arrowBack from './../../assets/icons/arrow-back-red.svg'
import iconCamera from '../../assets/no-photo.png'
import { sendMetric } from '../firebase/firebaseTags'

defineCustomElements(window)

type IPathParams = {}

type Props = RouteComponentProps<IPathParams> & {
  user: UserModel
  settings: SettingsModel
  tracking?: any
}

interface State {
  btnApplyfor: boolean
  spinner: boolean
  photosPatent: any
  photosRutAhead: any
  photosRutRear: any
  businessType: any
  infoPhotosPatent: any
  infoPhotosRutAhead: any
  typeBussiness: any
  infoPhotosRutRear: any
  otherTypeOfBusiness: any
  photosBallot: any
  infoPhotosBallot: any
}

class RegisterOptional extends React.PureComponent<Props, State> {
  state: State = {
    btnApplyfor: false,
    spinner: false,
    photosPatent: '',
    photosRutAhead: '',
    photosRutRear: '',
    businessType: '',
    infoPhotosPatent: '',
    infoPhotosRutAhead: '',
    typeBussiness: '',
    infoPhotosRutRear: '',
    otherTypeOfBusiness: '',
    photosBallot: '',
    infoPhotosBallot: '',
  }

  componentDidMount = async () => {
    const result = await typeOfBussiness()
    this.setState({
      typeBussiness: result,
    })
  }

  onChangeInput = (event: any) => {
    this.setState({
      otherTypeOfBusiness: event,
    })
  }

  onUploadPhoto = async (type: string) => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Prompt,
      width: 600,
      height: 600,
    })

    if (type === 'patent') {
      this.setState({
        photosPatent: image && image.base64String,
        infoPhotosPatent: image,
      })
    } else if (type === 'rutAhead') {
      this.setState({
        photosRutAhead: image && image.base64String,
        infoPhotosRutAhead: image,
      })
    } else if (type === 'rutRear') {
      this.setState({
        photosRutRear: image && image.base64String,
        infoPhotosRutRear: image,
      })
    } else if (type === 'ballot') {
      this.setState({
        photosBallot: image && image.base64String,
        infoPhotosBallot: image,
      })
    }
  }

  onOpenChangeOffice = async (event?: any | undefined) => {
    const value = event && event.target && event.target.value

    this.setState({
      businessType: value,
    })
  }

  sendOptionalRegistration = async () => {
    const {
      businessType,
      infoPhotosPatent,
      infoPhotosRutAhead,
      infoPhotosRutRear,
      otherTypeOfBusiness,
      infoPhotosBallot,
    } = this.state

    let send = {}
    this.setState({
      spinner: true,
    })
    if (businessType !== '') {
      send = { ...send, bussinesType: businessType }
    }
    if (otherTypeOfBusiness !== '') {
      send = { ...send, bussinesTypeOther: otherTypeOfBusiness }
    }
    if (infoPhotosPatent !== '') {
      await this.uploadPhoto('photosPatent', infoPhotosPatent)
    }
    if (infoPhotosRutAhead !== '') {
      await this.uploadPhoto('photosRutAhead', infoPhotosRutAhead)
    }
    if (infoPhotosRutRear !== '') {
      await this.uploadPhoto('photosRutRear', infoPhotosRutRear)
    }
    if (infoPhotosBallot !== '') {
      await this.uploadPhoto('photosBallot', infoPhotosBallot)
    }

    if (Object.keys(send).length !== 0) {
      await sendOptionalRegistration(send)
      this.setState({
        spinner: false,
      })
    }
    this.props.history.replace('/home', this.props.history.location.state)
  }

  uploadPhoto = async (key: any, infoPhotosRutRear: any) => {
    let data: any = {}
    data[key] = {
      base64String: infoPhotosRutRear.base64String,
      format: infoPhotosRutRear.format,
    }
    sendMetric("optional_upload")
    await sendOptionalRegistration(data)
  }

  render() {
    const {
      photosPatent,
      photosRutAhead,
      photosRutRear,
      businessType,
      typeBussiness,
      otherTypeOfBusiness,
      photosBallot,
    } = this.state

    return (
      <IonPage className="register-optional" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <div className="wrapper">
            <div className="header">
              <IonButtons slot="secondary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonIcon
                  slot="icon-only"
                  onClick={() => {
                    this.props.history.push("/home", this.props.history.location.state)
                  } }
                  icon={arrowBack} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                />
              </IonButtons>
            </div>
          </div>  
         <div className='guru-pro-register'>Gurú Pro</div>
          <div className="welcome-title">
            <div>Te invitamos a ser un Gurú Pro</div>
            <div>completando tu información en el perfil</div>
          </div>
          <div className="container-photo-register">
            <div>
              <div className="tittle-register">Cédula Representante Legal</div>
              <div className="photos">
                <IonGrid placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonRow placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonCol placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                      <div className="container-img">
                        <div className="ahead-rear">Frontal</div>
                        <div>
                          {photosRutAhead ? (
                            <IonImg className="img-camera" src={`data:image/png;base64,${photosRutAhead}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonImg>
                          ) : (
                            <div className="not-image" style={{ backgroundImage: `url(${iconCamera})` }}></div>
                          )}
                        </div>
                        <div className="btn-upload-photos" onClick={() => this.onUploadPhoto('rutAhead')}>
                          Subir Foto
                        </div>
                      </div>
                    </IonCol>
                    <IonCol placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                      <div className="container-img">
                        <div className="ahead-rear">Posterior</div>
                        <div>
                          {photosRutRear ? (
                            <IonImg className="img-camera" src={`data:image/png;base64,${photosRutRear}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonImg>
                          ) : (
                            <div className="not-image" style={{ backgroundImage: `url(${iconCamera})` }}></div>
                          )}
                        </div>
                        <div className="btn-upload-photos" onClick={() => this.onUploadPhoto('rutRear')}>
                          Subir Foto
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          </div>
          <div className="container-photo-register">
            <div>
              <div className="tittle-register">Tipo de negocio</div>
              <IonSelect mode="md" placeholder="Filtrar por" interface="popover" onIonChange={this.onOpenChangeOffice} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {typeBussiness &&
                  typeBussiness.map((r: any) => {
                    return <IonSelectOption value={r._id} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{r.name}</IonSelectOption>
                  })}
              </IonSelect>
              {typeBussiness &&
                typeBussiness.length > 0 &&
                typeBussiness.map((r: any) => {
                  return (
                    r._id === businessType &&
                    r.name === 'Otro' && (
                      <IonItem placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <IonInput
                          value={otherTypeOfBusiness}
                          onIonChange={e => this.onChangeInput(e.detail.value)}
                          placeholder="ingresar otro tipo de negocio"
                          autocomplete="off"
                          maxlength={30}
                          required={true}
                          type="text"
                          autofocus={true} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                        ></IonInput>
                      </IonItem>
                    )
                  )
                })}
            </div>
          </div>
          <div className="container-photo-register">
            <div>
              <div className="tittle-register">Documentos del negocio</div>
              <div className="photos">
                <IonGrid placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonRow placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonCol placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                      <div className="container-img">
                        <div className="ahead-rear">Patente de alcoholes</div>
                        <div>
                          {photosPatent ? (
                            <IonImg className="img-camera" src={`data:image/png;base64,${photosPatent}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonImg>
                          ) : (
                            <div className="not-image" style={{ backgroundImage: `url(${iconCamera})` }}></div>
                          )}
                        </div>
                        <div className="btn-upload-photos" onClick={() => this.onUploadPhoto('patent')}>
                          Subir Foto
                        </div>
                      </div>
                    </IonCol>
                    <IonCol placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                      <div className="container-img">
                        <div className="ahead-rear">Boleta</div>
                        <div>
                          {photosBallot ? (
                            <IonImg className="img-camera" src={`data:image/png;base64,${photosBallot}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonImg>
                          ) : (
                            <div className="not-image" style={{ backgroundImage: `url(${iconCamera})` }}></div>
                          )}
                        </div>
                        <div className="btn-upload-photos" onClick={() => this.onUploadPhoto('ballot')}>
                          Subir Foto
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          </div>
            <IonButton
            strong={true}
            shape="round"
            onClick={this.sendOptionalRegistration}
            expand="block"
            className="continue" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
           {photosPatent !== '' || photosRutAhead !== '' || photosRutRear !== '' || businessType !== '' || 
            otherTypeOfBusiness !== '' || 
            photosBallot !== '' ? "CONTINUAR" : "OMITIR"
            }
          </IonButton>
        </IonContent>
      </IonPage>
    )
  }
}

export default track({ page: 'RegisterOptional' })(withRouter(RegisterOptional))
