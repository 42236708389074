export const callPromise = (funcPromise: (...args: any) => Promise<any>, params: any[], callback: Function)=> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const res = await funcPromise(...params);
      if (callback) callback(res);
      resolve();
      
    } catch (error) {
      console.error(error);
      reject();
    }
  })
}   