import React, { useState, useRef } from 'react';
import { IonSlides, IonSlide, IonIcon } from '@ionic/react';
import arrowBack from '../assets/icons/act_photo-left.svg';
import arrowNext from '../assets/icons/act_photo-right.svg';

import './SliderImages.scss'

const slideOpts = {
  speed: 400,
  slidesPerView: 'auto',
  preloadImages: false,
  observer: true,
  observeParents: true
};

interface Props {
  productMultiplesImages: any[]
  getCurrentSliderIndex: any
  onClickPhoto: any
}

export const SliderImages: React.FC<Props> = ({productMultiplesImages, getCurrentSliderIndex, onClickPhoto}) => {
  const mySlides = useRef<any>(null);
  const [sliderIndex, setSliderIndex] = useState(0)
  const [medias, ] = useState(productMultiplesImages)
  
  const onIconClick = async (direction: string) => {
    const swiper = await mySlides?.current?.getSwiper();
    if (direction === "next") swiper.slideNext();
    if (direction === "prev") swiper.slidePrev();
  }

  const onSliderChange = async () => {
    const swiper = await mySlides?.current?.getSwiper();
    setSliderIndex(swiper.activeIndex)
    getCurrentSliderIndex(swiper.activeIndex)
  }

  const spliceRouteImage = (imagePath: string) => {
    const splittedPath = imagePath.split('static/')
    const completePath = `${process.env.REACT_APP_BFF_IMAGE}${splittedPath[splittedPath.length -1]}`
    return completePath
  }

    return (
      <div className="slides-container">
        { sliderIndex !== 0 &&
          <IonIcon onClick={() => onIconClick('prev')} className="slider-arrow-action arrow-icon-left" icon={arrowBack} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon> 
        }
        <IonSlides onIonSlideTransitionStart={() => onSliderChange()} options={slideOpts} ref={mySlides} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          { medias.map((media, index) => (
            <IonSlide key={`slider-${index}`} id={`slider-image-${index}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <img onClick={() => onClickPhoto(sliderIndex)} className="main-picture" src={spliceRouteImage(media.url)} alt="Imagen del producto"/> 
            </IonSlide>
          ))}
        </IonSlides>
        { sliderIndex !== medias.length - 1 &&
          <IonIcon onClick={() => onIconClick('next')} className="slider-arrow-action arrow-icon-right" icon={arrowNext} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon> 
        }
      </div>
    )
};

export default SliderImages;