import { apiBffMobileV2Products } from './api'

interface Response {
  imageFullPath: string
  quantityMin: number
  quantityMax: number
  stock: number
  promotionalDynamicType: string
  promotionalDynamicDetail: number
}

export const getProductDataBySkuAndProviderId = async (sku: string, providerId: string): Promise<Response> => {
  const response = await apiBffMobileV2Products.get(`/${sku}/providers/${providerId}`)

  return {
    imageFullPath: response.data[0]['imageFullPath'],
    quantityMin: response.data[0]['quantityMin'],
    quantityMax: response.data[0]['quantityMax'],
    stock: response.data[0]['stock'],
    promotionalDynamicType: response.data[0]['promotionalDynamicType'],
    promotionalDynamicDetail:
      response.data[0]['promotionalDynamicDetail'].length > 0
        ? response.data[0]['promotionalDynamicDetail'][0]['stock']
          ? response.data[0]['promotionalDynamicDetail'][0]['stock']
          : null
        : null,
  }
}
