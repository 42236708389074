
import React from 'react'
import {
  IonIcon,
  IonModal,
  IonCol,
  IonSpinner,
  IonToast,
} from '@ionic/react'
import Lightbox from 'react-image-lightbox';
import parse from 'html-react-parser';
import { StaticContext } from 'react-router'
import { formatCurrency } from '../../utils/intl'
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { getSuggestedProductsForProduct, postAlertOfProduct, totalPrice } from '../../clients/product'

import { CartModel } from '../../models/CartModel'
import { Product } from '../../models/ProductModel'
import { getCartId } from '../../clients/cartNew'


import { NumberUpDown } from '../../components/number-updown/NumberUpDown'
import { carouselItem, eventAddToCart, fbAddToCartEvent, sendMetric, viewProductEvent } from '../../pages/firebase/firebaseTags'
import { validateLocationState } from '../../utils/locationStateValidations';
import { returnOptionsToPackage } from '../../utils/uomAndPackage'

import arrowDown from '../../assets/icons/act_open.svg'
import arrowUp from '../../assets/icons/act_close.svg'
import closeIcon from '../../assets/icons/nav_close.svg'

import ProductCard from '../../components/product-card/ProductCard'
import PromotionIcon from '../../components/product-promotion-icon/PromotionIcon';
import SliderImages  from '../../shared/SliderImages'
import ButtonComponent from '../../components/basic-button/ButtonComponent';
import { addToCart } from '../../utils/cart';
import { EventEmitter } from '../../utils/events';
import LoadingComponent from '../../components/loading-component/LoadingComponent';
import AlertModal from '../../components/modals/AlertModal';

import 'react-image-lightbox/style.css';
import './ProductModal.scss'
import { navigateToCart } from '../../utils/navigation';
interface IPathParams {
}

interface PastRouteinfo {
  route: string
  paymentFormMethod?: string
}

type Props = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
	cartModel: CartModel,
  renderAsModal?: boolean,
  closeModal?: () => any,
  closeOneByOne?: boolean
}

interface RouteState {
	isOpen:boolean,
	product:any
  initialQuantity:number
  useInitialQuantity?:boolean
  fromSuggested?: boolean
  pastRouteInfo?:  PastRouteinfo
  from?: string
  originPath?: any
  originLink?: any
}
interface State{
	isOpen: boolean,
	showToast: boolean,
	quantity: number
  total: any
	spinner: boolean
  provider:string
  selectLadder:any
  notAvailable:boolean
  isEnough:boolean
  suggestedProducts: any[] | null
  currentIndex: number
  openLightbox: boolean
  lightBoxIndex: number
  originPath: any
  toggle: boolean
  maxLength: number
  showAll: boolean
  mounted: boolean
  originLink: any
  idCart: string
  shouldRender?: any
  mainData?: any 
  disableBtn: boolean
}

const varsToCheck = ['product']

const mapProductFromSuggested = (product: any) => ({
  ...product, 
  provider: product.providerId,
  providerId: product.providerId._id,
  filename: product.imageFullPath ?? product.filename,
  vendorActiveInComune: product.providerId.active
})

const setUpQuantity = (props: any) => {
  const mainData = props.renderAsModal ? props : props.location.state

  if(mainData?.useInitialQuantity) return mainData.initialQuantity || 0

  return mainData?.product?.quantityMin - 1 
}

const validateMainData = (props: any) => {
  if (props.renderAsModal) {
    return Boolean(props.product)
  } else { 
    return validateLocationState(props.history, varsToCheck) 
  }
}


class ProductModal extends React.Component<Props,  State> {
	state: State = {
    isOpen: false,
    showToast: false,
    quantity: setUpQuantity(this.props) || 0,
		total: '',
		spinner: false,
    provider:'', 
    selectLadder:'', 
    notAvailable:false,
    isEnough:true,
    suggestedProducts: null,
    currentIndex: 1,
    openLightbox: false,
    lightBoxIndex: 0,
    originPath: '',
    toggle: false,
    maxLength: 140,
    showAll: false,
    mounted: true,
    originLink: [],
    idCart: '',
    shouldRender: validateMainData(this.props),
    mainData: this.props.renderAsModal ? this.props : this.props.location?.state,
    disableBtn: false
  }
  
	async componentDidMount() {
    const { mounted, shouldRender } = this.state
    if(!mounted) return 
    setTimeout(async () => {
      if(shouldRender) await this.loadInitialData()
    }, 500);
  }
  
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {
      return
    };
  }

  loadInitialData = async () => {
    const { mainData, quantity  } = this.state
    const { product, originPath, originLink }  = mainData

    this.setState({
			isOpen: true,
      originPath,
      originLink,
      selectLadder: quantity,
		})
    

    this.getCartId()

    await this.calculatingTotal(quantity)
		// TODO: Por el momento queda comentado carouselItem('view_content', product.id, product.name, product.category, product.brand, product.sku, product.providerName)
    const {   
      providerId,
      categoryId,
      category,
      categoryTree,
      _id: productId, 
      name: productName,
      showPrice: { price },
      discount: productDiscount
    } = product

    viewProductEvent(providerId, null, categoryId, category, categoryTree, productId, productName, price, productDiscount)

		const providerLocal = localStorage.getItem('@providers')
		let provider:any =''
		if(providerLocal) {
			provider = JSON.parse(providerLocal)
			provider = Object.values(provider).find((item:any) => item.id === product.providerId )
			this.setState({
				provider:provider.alias
			})
    }
    const cartId = await getCartId()

    let suggestedProducts = []

    try {
      suggestedProducts = (await getSuggestedProductsForProduct(product._id, cartId.data._id)).map((product :any) => 
        ({
          ...product,
          filename: product.imageFullPath ?? product.filename
        })
      )
      
      suggestedProducts.sort((a:any,b:any) => a.outOfStock === b.outOfStock ? 0 : a.outOfStock ? 1 : -1)

      this.setState({ suggestedProducts })
      
    } catch (error) {
        console.error("Error", error)
    }
    this.validateIfTheQmaxIsNotEnough()
  }
 
  getCartId = async() => {
    try {
      const idCart = await getCartId()
      if(Object.keys(idCart).length > 0) this.setState({idCart: idCart.data._id })
    } catch (error) {
      console.error("Error", error)
    }
  }

  private acceptModal = async (product: Product, quantity: number) => {
    this.setState({disableBtn: true})
    const { _id } = product
    const { history, closeModal } = this.props
    const { originLink, idCart, mainData } = this.state

    const { useInitialQuantity, initialQuantity, product: propsProduct } = mainData
    const previousQuantity = useInitialQuantity ? initialQuantity || 0 : propsProduct?.quantityMin - 1 
    const cartLocal = localStorage.getItem("@cart")
    const cart = cartLocal ? JSON.parse(cartLocal) : null
    const loadingCartProductsLocal = localStorage.getItem("loadingCartProducts")
    const loadingCartProducts = loadingCartProductsLocal ? JSON.parse(loadingCartProductsLocal) : { adding: [], subtracting: [] }
    
    let isInCart: any = null
    let isInLoadingAdd: any = null
    if (cart) isInCart = cart.products.some(({_id}: any) => _id === product._id)
    if (loadingCartProductsLocal) isInLoadingAdd = loadingCartProducts.adding.includes(product._id)

    let cartUpdateAction = 'none'
    if((!isInCart && !isInLoadingAdd) || previousQuantity === 0) cartUpdateAction = 'add'

    let addToCartObj: any = await addToCart(idCart, _id, quantity)
    const { atcRes } = addToCartObj

    EventEmitter.dispatch('UpdateCartCount', { cartUpdateAction, addedProductId: product._id, quantity })

    if(atcRes.message === 'Product added to the cart') {

      this.setShowToast(true)

      setTimeout(() => {
        this.dismissModal()
        if (mainData.renderAsModal && closeModal !== undefined) {
          closeModal()
        } else if (mainData?.from === 'fromDeeplink') {
          history.replace('/home')
        } else if(!mainData.fromSuggested || mainData.pastRouteInfo?.route === 'HOME_PROVIDER') {
          history.goBack()
        } else if(mainData.fromSuggested) {
          navigateToCart(
            history,
            this.props.location.state || this.props.history.location.state,
            { ...mainData, pastRouteInfo: mainData.pastRouteInfo } 
          );
        }
      }, 900)
      
    }

    const priceToEvents = (await totalPrice(product.id, quantity)).price
    const numberProductPrice = (typeof priceToEvents) === "string" ? parseInt(priceToEvents) : priceToEvents
    eventAddToCart(quantity, product, priceToEvents, product.providerName, originLink)
    fbAddToCartEvent(product.name, product.id, 'product', numberProductPrice)
  }

  dismissModal = () => {
    setTimeout(() => {
      this.setState({
        isOpen: false,
      })
    }, 10)
  }

	setShowToast(showToast: boolean) {
    this.setState({ showToast })
	}

	closeModal = () => {
    const { mainData } = this.state
    const { history, closeModal } = this.props
    sendMetric('close_view_product')
    this.dismissModal()

    if (mainData.renderAsModal && closeModal !== undefined) {
      closeModal()
    } else if (mainData?.from === 'fromDeeplink') { 
      history.replace('/home') 
    } else if (!mainData.fromSuggested || mainData.pastRouteInfo?.route === 'HOME_PROVIDER') { 
      history.goBack() 
    } else if (mainData.fromSuggested) {
      history.replace('/cart', { ...mainData, pastRouteInfo: mainData.pastRouteInfo })
    }
	}

	private setQuantity = async (quantity: number) => {
    this.setState({
      spinner: true,
      selectLadder:quantity,
      quantity
    })
    await this.calculatingTotal(quantity)
	}
	
	calculatingTotal = async (quantity: number) => {
    const { mainData } = this.state
		const { product } = mainData
    
    let filterQuantity: number = quantity
    if (product.stock && quantity > product.stock) {
      filterQuantity = product.stock
      this.setState({ quantity: product.stock })
    } else {
      this.setState({ quantity })
    }

    const result = product.outOfStock ? await totalPrice(product.id, 1) : await totalPrice(product.id, filterQuantity || 0)

    this.setState({
      total: result,
      spinner: false
    })
  }

  totalLaddersToMap = (ladder:any, selectLadder:any) => {
    const { mainData } = this.state
    const { product } = mainData
    const {  package: productPackage } = product
    const sortedLadder = [...ladder].sort((a:any, b:any) => a.quantity - b.quantity)

    return (
      sortedLadder.map((product: any, index: any) => {
        return (
          <div className="flex-container"
            key={index}
            onClick={(e:any) => this.setQuantityRadio(product.quantity)}
          >
            <div>
              <div 
                onClick={() => (e:any) => this.setSelected({e: { detail: product.quantity }})} 
                className={`checkbox ${product.quantity === selectLadder ? 'checked' : ''}`} 
              />
            </div>
            <div style={{ width: "100%"}}>
              <p className="paragraph-1 no-margin" style={{textAlign: "justify"}}>{`${product.quantity} ${this.findPairToShowValue(returnOptionsToPackage(), productPackage)} por ${formatCurrency(product.discount)}`}</p>
            </div>
          </div>
        )
      })
    )
  }
	
	private renderOldDiscountLadder = (product: any) => {
    const { total, selectLadder } = this.state
    const { discount } = product

    if (!discount || typeof discount === 'number') return undefined

    return (
      <div className="info-ladder">
        <p className="paragraph-1 ladder-title">Escalones</p>
        <div className="ladder"> 
          <div className="discount-ladder">
            {this.validateIfExistProductLadders(total) && this.totalLaddersToMap(total.ladder, selectLadder)}
          </div>
        </div>
      </div>
    )
  }

  private renderDiscountLadder = (product: any) => {
    const { total, selectLadder } = this.state
    const { promotionalDynamicDetail } = product
    const ladderRules = promotionalDynamicDetail.find(({name}:any) => name === "ladder")?.rules
    if(!ladderRules) return <></>

    return (
      <div className="info-ladder">
        <p className="paragraph-1 ladder-title">Escalones</p>
        <div className="ladder"> 
          <div className="discount-ladder">
            {this.validateIfExistProductLadders(total) && this.totalLaddersToMap(total.ladder, selectLadder)}
          </div>
        </div>
      </div>
      
    )
  }
  
  setSelected = (e:any) => this.setState({selectLadder:e.detail.value})
  
  modalNotAvailable = () =>{
    const { notAvailable } = this.state 

    return(
      <AlertModal 
        label="¡Entendido!"
        text="Te enviaremos una notificación cuando el producto esté de nuevo disponible."
        buttonText="Gracias"
        buttonAction={this.closeModalNoAvailable}
        isOpen={notAvailable}
        onDismiss={this.closeModalNoAvailable}
      />
      )
  }

  closeModalNoAvailable = () => this.setState({notAvailable:false})

  openModalNoAvailable = async () => { 
    const { mainData } = this.state
		const { product }  = mainData

    await postAlertOfProduct(product._id)
    this.setState({notAvailable:true})
  }

  setQuantityRadio = async (quantity: number) => {
    this.setState({
      spinner: true,
      selectLadder: quantity,
      quantity
    })
    await this.calculatingTotal(quantity)
  }
  
  validateIfTheQmaxIsNotEnough = () => {
    const { mainData } = this.state
    const { product } = mainData
    const { quantityMax, sku } = product

    const storedCart = localStorage.getItem("@cart") 
    
    if(storedCart) {
      let currentCart = JSON.parse(storedCart)
      const productsInCart = currentCart.products

      let filteredProducts = productsInCart.filter((productInfo:any) => {
        return productInfo.sku.length && productInfo.sku.includes(sku) && productInfo.quantity === quantityMax;
      });

      this.setState({
        isEnough: filteredProducts.length ? false : true
      })
    }
  }

  renderForMultipleProviders = () => {
    const { suggestedProducts, mainData } = this.state
    const { closeOneByOne } = mainData

    if (!suggestedProducts) {
      return (
        <div className="suggested-empty-container">
          <LoadingComponent height='80px' />
        </div>
      )
    }

    if (suggestedProducts?.length > 0) {
      return (
        <div className="product-slider-suggested">
          <div className="header">
            <h3 className="no-margin">Sugeridos</h3>
          </div>
          <div className="slider-body">
            <div>
              {
                suggestedProducts.map((product: any, index: number) => (
                  <div className="container-card" key={product.id + index.toString()} >
                    <div>
                      <ProductCard
                        key={product.id}
                        product={mapProductFromSuggested(product)}
                        cartModel={this.props.cartModel}
                        history={this.props.history}
                        pastRouteInfo={{route: 'HOME_PROVIDER'}}
                        onCloseCallback={this.closeModal}
                        renderShortcut={true}
                        closeOneByOne={closeOneByOne}
                      ></ProductCard>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      )
    } else {
      return <div style={{height: '80px'}}></div> 
    }
  }


  validateIfExistTotalPrices = (total:any) =>  total && total.unitPrice && total.totalDiscount > 0
	
  validateIfExistTotalUnitPrices = (totalUnits: any) => totalUnits && totalUnits.unitPrice && totalUnits.unitPriceDiscount > 0

  validateIfExistProductLadders = (product:any) => product && product.ladder && product.ladder.length > 0

  priceToRenderWithOldOrNew = (total:any, spinner:any) => {
    if(spinner) return <IonSpinner name="crescent" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /> 
    if (this.validateIfExistTotalPrices(total)) {
      return (
        <div className="promo-flex">
          <h4 className="old-prices no-margin">{formatCurrency(total.totalPrice + total.totalDiscount)}</h4>
          <h4 className="price-total no-margin">{formatCurrency(total.totalPrice)}</h4>
        </div>
      )
    } 
    return (
      <div>
        <h4 className="no-margin">{total && formatCurrency(total.totalPrice)}</h4>
      </div>
    )
  }

  getCurrentSliderIndex = (sliderIndex: number) => this.setState({ currentIndex: sliderIndex + 1 })

  productHasMultipleMedia = (medias:any, filename:any) => {
    if (medias && medias.length) {
      return (
        <SliderImages 
          onClickPhoto={(index: number) => this.onClickPhoto(index)} 
          productMultiplesImages={medias} 
          getCurrentSliderIndex={this.getCurrentSliderIndex}
        />
      )
    }

    return <img className="main-picture" onClick={() => this.onClickPhoto(0)} src={filename} alt="Imagen del producto"/>
  }

  onClickPhoto = (index:number) => this.setState({ openLightbox: true, lightBoxIndex: index })

  buildNewObjWithRouteImages = (medias: any) => {
    let allImagesRoute = []

    for(let i = 0; i < medias.length; i++) {
      const splittedPath = medias[i].url.split('static/')
      const completeRoute = `${process.env.REACT_APP_BFF_IMAGE}${splittedPath[splittedPath.length -1]}`
      allImagesRoute.push(completeRoute)
    }

    return allImagesRoute
  }

  lightBoxPhoto = () => {
    const { lightBoxIndex, mainData } = this.state
    const { product } = mainData
    const { medias, filename } = product

    const mediasFromNewObj = medias.length ? this.buildNewObjWithRouteImages(medias) : [filename]
    const nextModule = (lightBoxIndex + 1) % mediasFromNewObj.length
    const prevModule = (lightBoxIndex + mediasFromNewObj.length - 1) % mediasFromNewObj.length
    const nextSrc = medias.length > 1 ? mediasFromNewObj[nextModule] : undefined
    const prevSrc = medias.length > 1 ? mediasFromNewObj[prevModule] : undefined

    return (
      
      <Lightbox
        mainSrc={mediasFromNewObj[lightBoxIndex]}
        enableZoom={false}
        imagePadding= {60}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        onCloseRequest={() => this.setState({ openLightbox: false })}
        onMovePrevRequest={() =>
          this.setState({
            lightBoxIndex: prevModule
          })
        }
        onMoveNextRequest={() =>
          this.setState({
            lightBoxIndex: nextModule
          })
        }
      />
    )
  }

  ifIsTheLastInfo = (index: number, productPropertiesLength: number) => index === productPropertiesLength ? 'the-last-info' : ''

  showPrimaryProductInfo = () => {
		const { provider } = this.state

    const productProperties = [ {'title': 'Proveedor', 'description': provider} ]

    return (
      productProperties.map((property:any, index) => (
        <div className="info-product" key={`product-properties-${index}`}>
          <p className={`title-product-info paragraph-1 ${this.ifIsTheLastInfo(index, productProperties.length -1)}`}>{property.title}</p>
          <p className={`description-product-info paragraph-1 ${this.ifIsTheLastInfo(index, productProperties.length -1)}`}>{property.description}</p>
        </div>
      ))
    )
  }

  validateIsIfDescription = (title:string) => title !== 'Descripción'

  onPressSeeMore = (descriptionLarge:number) => {
		const { showAll } = this.state
    const lengthToSet = showAll ? 140 : descriptionLarge

    this.setState({maxLength: lengthToSet, showAll: !showAll})
  }

  partialDescription = (description:string) => {
		const { maxLength, showAll } = this.state

    return (
      <> 
        {description.slice(0, maxLength)}{showAll ? ' ' : '... '} 
        <button
          className="btn-read-more" 
          onClick={() => this.onPressSeeMore(description.length)}
        >  
          {showAll ? 'Leer menos' : 'Leer más'}
        </button>
      </>
    )
  }

  showPartialDescription = (title:string, description:string) => {
    if(!this.validateIsIfDescription(title)) {
      const htmlDescription = parse(description)
      if(typeof htmlDescription !== 'string') {
        return htmlDescription
      }
    }

    if(this.validateIsIfDescription(title) || description.length < 140) return description
    return this.partialDescription(description)
  }

  showExtraProductInfo = (manufacturer:string, description:string, quantityMin: number, quantityMax:number) => {
    const productProperties: any = 
      [
        {'title': 'Cantidad mínima', 'description': quantityMin},      
        {'title': 'Cantidad máxima', 'description': quantityMax},      
      ]
        
    const conditionalProductProperties: any = []

    if(description && description.length) conditionalProductProperties.unshift({'title': 'Descripción', 'description': description})
    if(manufacturer && manufacturer.length) conditionalProductProperties.push({'title': 'Fabricante', 'description': manufacturer})

    const allProductProperties = conditionalProductProperties.concat(productProperties)

    return (
      allProductProperties.map((property:any, index: number) => {
        const VALIDATION_DESCRIPTION = this.validateIsIfDescription(property.title)
        const textClass = VALIDATION_DESCRIPTION ? "" : "the-last-info"

        return (
          <div key={index} className={VALIDATION_DESCRIPTION ? "container-internal-info" : ""}>
            <p className={`paragraph-1 no-margin ${textClass}`}>{property.title}</p>
            <p className={`paragraph-2 product-description-text ${textClass}`}>
              {this.showPartialDescription(property.title, property.description)}
            </p>
          </div>
        )
      })
    )
  }

  showErrorInfo = (min:number, max:number, quantity:number) => {
    let errorInfo = ''
    if(max === quantity) errorInfo = `Cantidad máxima (${max})`
    if(isNaN(quantity)) errorInfo = `Cantidad mínima (${min})`

    return errorInfo
  }

  renderOfferInfo = () => {
    const { mainData } = this.state
    const { product } = mainData
    
    if (product) {
      const { promotionalDynamicType, promotionalDynamicDetail } = product
      
      if (promotionalDynamicType && promotionalDynamicDetail) {
        const { quantity } = this.state
        let promotionInfo = null

        const promotionTypes = [
          { type: 'xByI', value: this.getXbYIPromotionInfo(promotionalDynamicDetail, quantity)},
          { type: 'discountRate', value: this.getPercentagePromotionInfo(promotionalDynamicDetail)},
          { type: 'directOfferPrice', value: this.getDirectDiscountInfo(product)},
        ]

        promotionInfo = promotionTypes.find(({type}: any) => type === promotionalDynamicType)?.value

        if (promotionInfo) {
          return (
            <div className="promotion-info-section">
              <div className="promotion-top-section"></div>
              <div className="promotion-bottom-section"></div>
              <div className="promotion-info-wrapper">
                <h5 className="promotion-info-text">{promotionInfo}</h5>
              </div>
            </div>
          )
        }
      }
    }

    return <></>
  }

  getXbYIPromotionInfo = (promotionalDynamicDetail: any, quantity: any) => {
    let info = null
    const xByIRules = promotionalDynamicDetail.find(({name}:any) => name === "xByI")?.rules
    if(xByIRules && xByIRules.length) {
      const sortedRules = xByIRules?.sort((a:any, b:any) => b.quantity - a.quantity)
      const actualRule = sortedRules?.find((rule: any) => rule.quantity <= quantity) || sortedRules && sortedRules[sortedRules.length - 1]
      if(actualRule) info = `¡Por cada ${actualRule.quantity}, sólo pagas ${actualRule.quantity - actualRule.discount}!`
    }
    return info
  }

  getPercentagePromotionInfo = (promotionalDynamicDetail: any) => {
    let info = null
    const discountString = promotionalDynamicDetail.find(({name}:any) => name === "discountRate")?.value
    const numberDiscount = parseFloat(discountString)
    const isValid = !isNaN(numberDiscount)
    if(isValid) info = `¡Estás ahorrando un ${numberDiscount * 100}%!`
    return info
  }

  getDirectDiscountInfo = (product: any) => {
    let info = null
    const { price, offerPrice, package: productPackage } = product
    const packageTypes: any = {
      box: 'caja', 
      sbox: 'caja',
      xbox: 'caja',
      pack: 'pack',
      piece: 'pieza',
      bag: 'bolsa',
    }
    const packageText = packageTypes[productPackage] || "caja"
    
    let discount = 0
    if(price && offerPrice && price > offerPrice) discount = price - offerPrice
    if (discount && (price !== offerPrice)) info = `¡Ahorras ${formatCurrency(discount)} por ${packageText}!`
    return info
  }

  renderPromotionOptions = (product: any) => {
    const { promotionalDynamicType, promotionalDynamicDetail, ladder } = product

    if((!promotionalDynamicType || !promotionalDynamicDetail) && ladder) {
      return this.renderOldDiscountLadder(product)
    }

    const promotionsTypes = [
      { type: 'ladder', value: this.renderDiscountLadder(product) },
    ]

    const actualOptions = promotionsTypes.find(({type}: any) => promotionalDynamicType === type)?.value
    if(actualOptions) return actualOptions
    return <></>
  }

  isUnderMinQuantity = (quantity: any, ) => {
    const { mainData } = this.state
		const { product } = mainData
    const { quantityMin } = product
    return quantity < quantityMin
  }

  renderAddToCartButton = () => {
    const { quantity, mainData, disableBtn } = this.state
		const { product }  = mainData

    return (
      <div className="add-to-cart-btn-section">
        <ButtonComponent 
          className={`btn-primary add-to-cart-btn ${disableBtn ? 'loading' : ''}`}
          text={product && product.outOfStock ? "Me interesa" : "Agregar"}
          disabled={this.isAtcDisabled()}
          onClick={() => product && product.outOfStock ? this.openModalNoAvailable() : this.acceptModal(product,quantity)}
        />
      </div>
    )
  }

  isAtcDisabled = () => {
    const { quantity, isEnough, mainData, disableBtn } = this.state
		const { product, useInitialQuantity }  = mainData

    const activeValidation = !product.active
    const quantityValidation = !useInitialQuantity && isEnough === false
    const nanValidation = isNaN(quantity)
    const minQuantityValidation = this.isUnderMinQuantity(quantity)
    const stockValidation = !product.outOfStock

    return (activeValidation || disableBtn || quantityValidation || nanValidation || minQuantityValidation) && stockValidation
  }

  findPairToShowValue = (options:any, name: string) => options[name]

  capitalizeFirstWord = (text:string) => text[0].toUpperCase() + text.slice(1);

  render() {
    const { shouldRender } = this.state
    if(!shouldRender) return <Redirect to="/home"/>
    
		const { quantity, isOpen, total, spinner, notAvailable, currentIndex, openLightbox, toggle, mainData} = this.state
    const { product } = mainData
    const { name, brand, units, filename, quantityMin, quantityMax,  medias, manufacturer, description, UOM, package: productPackage, outOfStock, stock} = product

    const optionsToUOM = {
      'Litros':'litro',
      'litros':'litro',
      'Paquetes':'paquete',
      'paquetes':'paquete',
      'Packs':'pack',
      'packs':'pack',
      'Unidades':'unidad',
      'unidades':'unidad',
      'Displays':'display',
      'displays':'display',
      'Kilos':'kilo',
      'kilos':'kilo',
      'Mangas':'manga',
      'mangas':'manga',
      'Piezas':'pieza',
      'piezas':'pieza',
    }

    const optionsToPackage = {
      'caja': 'Cajas',
      'Caja': 'Cajas',
      'paquete': 'Paquetes', 
      'Paquete': 'Paquetes', 
      'paquetes': 'Paquetes', 
      'Paquetes': 'Paquetes', 
      'pack': 'Packs', 
      'Pack': 'Packs', 
      'packs': 'Packs',
      'Packs': 'Packs',
      'manga': 'Mangas', 
      'Manga': 'Mangas', 
      'display': 'Displays', 
      'Display': 'Displays', 
      'unidad': 'Unidades',
      'Unidad': 'Unidades'
    }

    const priceHeader = `Precio por ${this.findPairToShowValue(optionsToUOM,UOM)}`

    return (
      <IonModal cssClass="product-modal-container ds-content-page" isOpen={isOpen} backdropDismiss={false} >
				<div className="contaier-product">
					<div className="header"  >
            { medias.length > 1 ? 
              <div className="number-of-images"> 
                <h5 className="number-images-text no-margin">{`${currentIndex} / ${medias.length}`}</h5>
              </div>
              :
              <div></div>
            }

            {outOfStock && 
              <div className={`tag-out-of-stock ${medias.length <= 1 ? 'less-margin-right' : 'add-margin-right'}`}>
                Agotado
              </div>
            }

            { !outOfStock && (medias.length === 1 || medias.length === 0) && <div></div>}
            <IonIcon icon={closeIcon} onClick={this.closeModal} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </div>

					<div className="body">
            <div className="container-product-image">
              { openLightbox && this.lightBoxPhoto()}
              { this.productHasMultipleMedia(medias, filename) }
              <div className="promotion-icon-container">
                <div className="promotion-icon-wrapper">
                  <PromotionIcon product={product} selectedQuantity={quantity} iconHeight={'30px'} textSize={'23px'}/>
                </div>
              </div>
            </div>
            <div>
              <div className="product-title">
                <h4 className="no-margin">{name}</h4>
              </div>
							<p className="brand-product paragraph-2 no-margin">{`${brand}`}</p>
              {product && typeof product.vendorActiveInComune === 'boolean' && !product.vendorActiveInComune &&
              <div className="not-available">
                  <p className="paragraph-2 no-margin">No disponible en la comuna</p>
                </div>}
            </div>
          </div>
          {this.renderOfferInfo()}
          {this.showPrimaryProductInfo()}
					<div className="separator" />
          <div 
            className="info-product"
            onClick={() => this.setState({toggle: !toggle})} 
          >
						<div className="title-product-info see-more-info the-last-info">
              <p className="paragraph-1 no-margin">Saber más</p>
            </div>
						<div className="title-product-info the-last-info more-info-product">
              <IonIcon 
                className="arrow-icon"
                slot="end"
                icon={toggle ? arrowUp : arrowDown} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              />
            </div>

            {toggle && this.showExtraProductInfo(manufacturer, description, quantityMin, quantityMax)}
					</div>
					<div className="separator" />
          {!outOfStock && 
            <div className="info-unid">
              <div className="unid">
                <p className="paragraph-1 no-margin">{this.findPairToShowValue(optionsToPackage, productPackage)}</p>
              </div>
              <div className='product-quantity-container'>
                <NumberUpDown
                  value={quantity}
                  valueToShow={quantity}
                  min={quantityMin}
                  max={stock ? stock : quantityMax}
                  step={1}
                  onChange={this.setQuantity}
                  disabled={((stock && (quantity === stock)) ? true : false) || (quantity === quantityMax)}
                />
              </div>
            </div>
          }
          <div className="info-unid">
						<div className="unid">
              <p className="paragraph-1 no-margin">{this.capitalizeFirstWord(UOM)} por {productPackage}</p>
            </div>
						<div className="number-unid">
              <p className="paragraph-1 no-margin">{units} {this.capitalizeFirstWord(UOM)}</p>
            </div>
					</div>
          {this.renderPromotionOptions(product)}
					<div className="info-unid">
						<div className="unid">
              <p className="paragraph-1 no-margin">{priceHeader}</p>
            </div>
						<div className="number-unid">
							{this.validateIfExistTotalUnitPrices(total) ? (
								<IonCol placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
									<div className="old-price unid">
                    <p className="paragraph-1 no-margin gray-price">{formatCurrency(total.unitPrice || 0)}</p>
                  </div>
                  <div className="unid">
                    <p className="paragraph-1 no-margin">{formatCurrency(total.unitPriceDiscount || 0)}</p>
                  </div>
								</IonCol>
							) : (
								<IonCol placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <div className="unid">
                    <p className="paragraph-1 no-margin">{formatCurrency(total.unitPrice || 0)}</p>
                  </div>
                </IonCol>
							)}
						</div>
					</div>
					<div className="separator" />
					<div className={`info-unid-total`}>
						<div className="total">
              <h4 className={`no-margin ${outOfStock ? 'add-opacity-container' : ''}`}>Total</h4>
            </div>
            <div className={`price-total ${outOfStock ? 'add-opacity-container' : ''}`}>
              { this.priceToRenderWithOldOrNew(total, spinner)}
            </div>
					</div>

          {this.renderAddToCartButton()}
          {this.renderForMultipleProviders()}
          <IonToast
            isOpen={this.state.showToast}
            mode="ios"
            cssClass="addedToCart"
            message="Producto agregado al carro"
          />
          {notAvailable && this.modalNotAvailable()}
				</div>
      </IonModal>
    )
  }
}


export default withRouter(ProductModal)