import { apiBffMobileV2Carts } from './api'

interface Response {
  cartId: string
}

export const getCartId = async (): Promise<string> => {
  const response = await apiBffMobileV2Carts.get('/self')

  return response.data['data']['_id']
}
