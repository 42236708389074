import React from 'react'
import {
  IonIcon,
} from '@ionic/react'

import GoogleMapReact from 'google-map-react';

import { location } from 'ionicons/icons'
import './GoogleMap.scss'

interface GoogleMapsProps {
  coordinates: any
  zoom: number
}

const Marker = ({}:any) => <IonIcon className="icon-location" slot="icon-only" icon={location} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>

const GoogleMap: React.FC<GoogleMapsProps> = props => {
    
  const validateGoogleMapKey =  () => process &&   process.env &&  process.env.REACT_APP_GOOGLE_MAP_KEY
  const mapOptions = {
    fullscreenControl: false,
  };

  return (
      <div className="container-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key : `${validateGoogleMapKey()}`}}
          center={props.coordinates}
          defaultZoom={props.zoom} 
          options={mapOptions}
        >
        <Marker
          lat={props.coordinates.lat}
          lng={props.coordinates.lng}
        />
        </GoogleMapReact>
      </div>
    )
  
}

export default GoogleMap