import { useEffect, useState } from 'react'
import { ShippingCost } from '../../interfaces/shippingCosts'
import { getShippingCostByProviderId } from '../../services/bff_mobile/v1/shipping_cost/getShippingCostByProviderId'

function useGetShippingCostByProviderId(providerId: string) {
  const [shippingCosts, setShippingCosts] = useState<ShippingCost[]>([])

  useEffect(() => {
    const action = async () => {
      try {
        setShippingCosts(await getShippingCostByProviderId(providerId))
      } catch (error) {
        setShippingCosts([])
      }
    }

    action()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return { shippingCosts }
}

export default useGetShippingCostByProviderId
