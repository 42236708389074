import Axios from 'axios'

export type THomeBrand = {
  _id: string;
  name: string;
  brandSlug: string;
  position: number;
  urlLogo: string;
}

export const getHomeBrands = async (): Promise<THomeBrand[]> => {
  try {
    const result = await Axios.get<any[]>(`${process.env.REACT_APP_BFF_NODE}/v1/home/brands`)
    return homeBrandsMapper(result.data)
  } catch(error) {
    console.error(error);
    return [];
  }
}

export type TProvider = {
  _id: string;
  name: string;
  alias: string;
}

export const getBrandProviders = async (brandId: string): Promise<TProvider[]> => {
  try {
    const {data} = await Axios.get<TProvider[]>(`${process.env.REACT_APP_BFF_NODE}/v1/vendors/brand/${brandId}`)
    return data
  } catch(error) {
    console.error(error);
    return [];
  }
}

export const getBrandProviderProducts = async ({providerId, brandId, mainCategory, category, limit}: any): Promise<any[]> => {
  try {
    let params: any = {}
    if (limit) params['limit'] = limit
    if (mainCategory) params['mainCategory'] = mainCategory
    if (category) params['category'] = category
    const {data} = await Axios.get<any[]>(`${process.env.REACT_APP_BFF_NODE}/v1/products/provider/${providerId}/brand/${brandId}`,
      {
        params
      }
    )
    return data
  } catch(error) {
    console.error(error);
    return [];
  }
} 

const homeBrandsMapper = (homeBrandsResponse: any[]): THomeBrand[] => {
  return homeBrandsResponse.map((item: any) => {
    const {_id, name, logo} = item.brandId;
    const {position, brandSlug} = item;
    return {
      _id,
      name,
      urlLogo: `${process.env.REACT_APP_BFF_IMAGE}brands/${logo}`,
      position,
      brandSlug
    }
  })
}