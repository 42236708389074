import { IonChip, IonIcon, IonInput } from '@ionic/react'
import { addOutline, removeOutline } from 'ionicons/icons'
import React, { useContext } from 'react'
import AppContext from '../../context/AppContext'
import useHandleDisabledQuantityButton from '../../hooks/carts/useHandleDisabledQuantityButton'
import useCheckProductInCart from '../../hooks/suggested/useCheckProductInCart'
import { PaymentMethodCartData, ProductCartData } from '../../interfaces/carts'
import { ProductSuggestedData } from '../../interfaces/suggested'
import { deleteProductInCart } from '../../services/bff_mobile/v2/carts/deleteProductInCart'
import { updateProductPriceInCart } from '../../services/bff_mobile/v2/carts/updateProductPriceInCart'
import { shortString } from '../../utils/functions/shortString'
import { formatCurrency } from '../../utils/intl'
import './index.scss'
import PromoCost from '../../assets/icons/promo_cost.svg'
import DescImage from '../../assets/desc.svg'
import IconFulfillment from '../../assets/icn_full_filment.svg'

interface Props {
  product: ProductSuggestedData
  providerId: string
  paymentMethod: string
  setShowModal?: (show: boolean) => void
}

function NewProductCard({ product, paymentMethod, providerId, setShowModal }: Props) {
  const { foundProduct } = useCheckProductInCart(product.productId, providerId, paymentMethod)
  const { cartData, handlerCartData } = useContext(AppContext)
  const { disabledIncreaseButton } = useHandleDisabledQuantityButton({
    _id: product.productId,
    name: product.productName,
    image: product.imageFullPath,
    packageType: product.package,
    promotionalDynamicDetail: product.promotionalDynamicDetail!,
    promotionalDynamicType: product.promotionalDynamicType,
    quantityMax: product.quantityMax,
    quantityMin: product.quantityMin,
    quantity: foundProduct?.quantity!,
    stock: product.stock!,
    totalPrice: foundProduct?.totalPrice!,
    units: product.units,
    uom: product.uom,
  })

  const checkOfferPrice =
    product.offerPrice !== null &&
    product.offerPrice !== undefined &&
    typeof product.offerPrice === 'number' &&
    product.offerPrice > 0
      ? true
      : false

  const handlerQuantityProduct = async (type: string) => {
    let newQuantity: number = foundProduct ? foundProduct.quantity : 0

    if (type === 'increase') {
      if (foundProduct) newQuantity++
      else newQuantity = 1
    }

    if (type === 'decrease') {
      newQuantity--
    }

    let updatedCartData: PaymentMethodCartData[] = []

    if (newQuantity === 0) {
      await deleteProductInCart(cartData._id, product.productId)
      updatedCartData = cartData.data.map((data) => {
        if (data.paymentMethod !== paymentMethod) return data

        const updatedProviders = data.providers.map((provider) => {
          if (provider._id !== providerId) return provider

          const filteredProducts = provider.products.filter((p) => p._id !== product.productId)

          return {
            ...provider,
            products: filteredProducts,
          }
        })

        return {
          ...data,
          providers: updatedProviders,
        }
      })
    } else {
      const result = await updateProductPriceInCart(cartData._id, product.productId, newQuantity)

      const { quantity, totalPrice } = result

      updatedCartData = cartData.data.map((data) => {
        if (data.paymentMethod !== paymentMethod) return data

        const updatedProviders = data.providers.map((provider) => {
          if (provider._id !== providerId) return provider

          let updatedProducts: ProductCartData[] = []
          if (!foundProduct)
            updatedProducts = [
              ...provider.products,
              {
                _id: product.productId,
                image: product.imageFullPath,
                name: product.productName,
                promotionalDynamicDetail: product.promotionalDynamicDetail!,
                promotionalDynamicType: product.promotionalDynamicType,
                quantityMax: product.quantityMax,
                quantityMin: product.quantityMin,
                quantity,
                totalPrice,
                stock: product.stock!,
                packageType: product.package,
                units: product.units,
                uom: product.uom,
              },
            ]
          else
            updatedProducts = provider.products.map((p) => {
              if (p._id !== product.productId) return p

              return {
                ...p,
                quantity,
                totalPrice,
              }
            })

          return {
            ...provider,
            products: updatedProducts,
          }
        })

        return {
          ...data,
          providers: updatedProviders,
        }
      })
    }

    handlerCartData({
      ...cartData,
      data: updatedCartData,
    })
  }

  return (
    <div className="suggested-product-card">
      {checkOfferPrice === true && <img className="image-promo-cast" src={PromoCost} alt="" />}

      <img src={IconFulfillment} alt="" className="image-icon-ff" />

      <img src={DescImage} alt="" className={`image-desc ${checkOfferPrice ? "image-desc--with-offer-price" : "image-desc--without-offer-price"}`} />

      <div className="content-image" onClick={() => {
        if (setShowModal) setShowModal(true)
      }}>
        <img src={product.imageFullPath} alt={product.productName} />
      </div>

      <div className="content-info">
        <h6>{checkOfferPrice && formatCurrency(product.price)}</h6>

        <h5
          className={`content-info-title ${checkOfferPrice && 'content-info-title--oferta'} ${
            product.outOfStock === true && 'content-info-title--agotado'
          }`}
        >
          {checkOfferPrice ? formatCurrency(product.offerPrice) : formatCurrency(product.price)}
        </h5>
        <span className={`span-info span-info--margin ${product.outOfStock === true && 'span-info--agotado'}`}>
          {product.providerName}
        </span>
        <span className={`span-info ${product.outOfStock === true && 'span-info--agotado'}`}>
          {shortString(product.productName, 22)}
        </span>
      </div>

      {product.outOfStock === true ? (
        <h4 className="title-agotado">Agotado</h4>
      ) : (
        <div className="buttons">
          {foundProduct && (
            <>
              <div className="inputs-background"></div>

              <IonChip className="button btn-left" onClick={() => handlerQuantityProduct('decrease')} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonIcon className="icon" icon={removeOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
              </IonChip>

              <IonInput type="number" className="value-cell" value={foundProduct.quantity} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </>
          )}

          <IonChip
            className={`button btn-right button__${disabledIncreaseButton ? 'disabled' : 'active'}`}
            onClick={() => handlerQuantityProduct('increase')}
            disabled={disabledIncreaseButton} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
            <IonIcon className="icon" icon={addOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
          </IonChip>
        </div>
      )}
    </div>
  )
}

export default NewProductCard
