import React, { Dispatch, SetStateAction } from 'react'

// Libraries
import Autocomplete from 'react-google-autocomplete'

// Styles
import './LocationSearchInput.scss'
import { MapLocationCoordinates } from '../../models/MapLocationCoordinates'

interface TextInputProps {
  addressInput: any
  setAddressInput: Dispatch<SetStateAction<string | null>>
  setCoordinates: Dispatch<SetStateAction<MapLocationCoordinates>>
  findGeolocationSuccess: (position: any) => void
}

const LocationSearchInput: React.FC<TextInputProps> = ({
  setAddressInput,
  setCoordinates,
  findGeolocationSuccess,
  addressInput,
}) => {
  return (
    <Autocomplete
      apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
      options={{
        types: ['geocode', 'establishment'],
      }}
      defaultValue={addressInput}
      onPlaceSelected={async (place) => {
        if (place.formatted_address) setAddressInput(place.formatted_address)
        else setAddressInput('')

        const lat = place.geometry?.location?.lat()
        const lng = place.geometry?.location?.lng()

        if (lat && lng) {
          setCoordinates({
            lat: place.geometry?.location?.lat()!,
            lng: place.geometry?.location?.lng()!,
          })

          const position = {
            coords: {
              latitude: lat,
              longitude: lng,
            },
          }

          findGeolocationSuccess(position)
        }
      }}
      aria-placeholder="Ingresa tu dirección de despacho"
      className="location-search-input"
    />
  )
}

export default LocationSearchInput
