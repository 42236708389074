import React from 'react';
import { getLogo } from '../../utils/basetypes'
import './ProvidersCardsRenderer.scss'

interface ProvidersCardsRendererProps {
  providers: any
  source: string
  onProviderClick: (item:any, categoryId?:any, vendorId?:any, oneVendor?: boolean, vendorPosition?: any) => void
  providersRibbons?: any
}

enum SourceOptions {
  Home = "home",
  Category = "category",
}

const ProvidersCardsRenderer: React.FC<ProvidersCardsRendererProps> = (props) => {
  const { providers, source, onProviderClick, providersRibbons } = props

  const switchUseOfFunction = (item: any, index: number) => {
    switch (source) {
      case SourceOptions.Home:
        onProviderClick(item, null, null, false, index + 1) 
        break;
      case SourceOptions.Category:
        onProviderClick(item, index + 1)
        break;
      default:
        break;
    }
  }

  const checkProviderRibbon = (provider: any) => {
    if (!providersRibbons || !Object.keys(providersRibbons).length) return null
    const currentCommuneLocal = localStorage.getItem('currentOffice') 
    const currentCommune = currentCommuneLocal ? JSON.parse(currentCommuneLocal) : null
    const ribbon = providersRibbons[provider.id]?.ribbon
    const ribbonByComunes = providersRibbons[provider.id]?.ribbonByComunes

    let selectedRibbon = ribbon
    if (ribbonByComunes?.comunesIds.includes(currentCommune?.comuneId)) selectedRibbon = ribbonByComunes

    return selectedRibbon || null
  }

  const renderProvidersCards = (item: any, index: number) => {
    if(source === SourceOptions.Home && item.disponible !== true) return <></>

    const providerRibbon = checkProviderRibbon(item)

    if (item.name === "Gurú Despacho") return

    return (
      <div className="card-provider-wrapper" key={index}>
        <div
          className="card-provider-home"
          key={item.id}
          onClick={() => switchUseOfFunction(item, index)}
        >
          {providerRibbon
            ? <div className="ribbon-text-container" style={{ backgroundColor: providerRibbon.hexColor}}>
                <h6 className="ribbon-text">{providerRibbon.text}</h6>
              </div>
            : ""}
          <img
            className="img-provider-home"
            src={`${process.env.REACT_APP_BFF_IMAGE}providers/${getLogo(item.logo, 'x2')}`}
            alt="Logo"
          />
        </div>
      </div>
    )
  }

  if(!providers) return <></>
  
  return (
    <div className="providers-cards-container ds-content-page">
      {providers.map((item:any, index: number) => renderProvidersCards(item, index) )}
    </div>
  )
};

export default ProvidersCardsRenderer;