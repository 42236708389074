import Axios from 'axios'
import { countryCl } from '../utils/countriesTexts';

export const getTermsAndConditions = async () => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/documents/legals`);
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const getTermsAndConditionsLogin = async () => {
  try {
    const countryDataLocal = localStorage.getItem("countryName") || ""
    const countryData = JSON.parse(countryDataLocal)
    const countryCode = countryData?.countryCode || countryCl

    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_PUBLIC}/v1/documents/legals`, { 
      headers: { countryCode } 
    });


    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
  }
}