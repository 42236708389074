import { IonSlide, IonSlides } from '@ionic/react'
import React from 'react'
import BrandIcon from '../../../../components/brand-icon/BrandIcon'
import { REACT_APP_BFF_IMAGE } from '../../../../config'
import './index.scss'

interface Category {
  _id: string
  name: string
  logo: string
}

const categories: Category[] = [
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },

  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },

  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
]

function CategoriesHomeFulfillment() {
  return (
    <div className="categories-home-fulfillment">
      <h3>Categorías</h3>

      <div className="slides">
        <IonSlides pager={false} scrollbar={false} options={{ autoHeigth: true, slidesPerView: 'auto' }} {...{} as any}>
          {categories.map((category, index) => (
            <IonSlide key={index} className="slide-home-brand" {...{} as any}>
              <BrandIcon urlLogo={`${REACT_APP_BFF_IMAGE}categories/${category.logo}`} handleAction={() => {}} />
              <span>{category.name}</span>
            </IonSlide>
          ))}
        </IonSlides>
      </div>
    </div>
  )
}

export default CategoriesHomeFulfillment
