import Axios from 'axios'

export const getNotifications = async (userId:any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/users/${userId}/messages`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}


export const notificationRead = async (notificationId:any) => {
  try {
    const result = await Axios.patch<any>(`${process.env.REACT_APP_BFF_NODE}/v1/messages/${notificationId}/read`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const deleteNotification = async (notificationId:any) => {
  try {
    const result = await Axios.delete<any>(`${process.env.REACT_APP_BFF_NODE}/v1/messages/${notificationId}`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const unreadNotification = async (userId:any) =>{
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/users/${userId}/messages/unread`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}