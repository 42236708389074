import { apiBffMobileV2Carts } from './api'

interface Response {
  _id: string
  providerId: string
  quantity: number
  totalPrice: number
}

export const updateProductPriceInCart = async (
  cartId: string,
  productId: string,
  quantity: number,
): Promise<Response> => {
  const response = await apiBffMobileV2Carts.put(`/${cartId}/products/${productId}?quantity=${quantity}`)

  return {
    totalPrice: response.data['data']['infoPrice']['totalPrice'],
    _id: response.data['data']['_id'],
    providerId: response.data['data']['providerId']['_id'],
    quantity: response.data['data']['quantity'],
  }
}
