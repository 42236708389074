import React from 'react'
import { phonePlaceholderByCountry, isPhoneValidByCountry, phoneLengthByCountry } from '../../utils/countriesTexts'
import TextInput from '../input-form/TextInput'

interface Props {
  validateUser: any
}

interface State {
  valueInputText: any
  validationInput: boolean
  validationText: string | number
}

export default class PhoneUsernameField extends React.PureComponent<Props, State> {
  state: State = {
    valueInputText: "",
    validationInput: false,
    validationText: ""
  }

  onChangeInput = (event:any) => {
    const inputVal = event.target.value
    const validation = isPhoneValidByCountry(inputVal)

    this.setState({
      valueInputText: inputVal,
      validationInput: !!validation,
      validationText: inputVal
    })

    const localUser = { username: inputVal }
    this.props.validateUser(localUser, Boolean(validation))
  }

  renderPhoneField = () => {
    const { validationInput, valueInputText, validationText } = this.state

    return (
      <TextInput
        iconDefault="icn_phone"
        iconValid="icn_phone-blue"
        iconInvalid="icn_phone-red"
        onChange={this.onChangeInput}
        placeholder={phonePlaceholderByCountry()}
        label="Teléfono"  
        isValid={validationInput}
        error={"Escribe tu número con el código de área"}
        value={valueInputText}
        type="tel"
        toValidate={validationText}
        maxLength={phoneLengthByCountry()}
        autoComplete="off"
      />
    )
  }
  
  render() {
    return this.renderPhoneField()
  }
}


