import React, { useEffect, useState } from 'react'
import welcome from './../../assets/icons/almacenera-bienvenidos.svg'

import './LoadingCoverGuestUser.scss'

interface LoadingCoverGuestUserProps {
  isLoading?: boolean
  title: string
  route?: string
  phrase: any[]
}

const LoadingCoverUserGuest: React.FC<LoadingCoverGuestUserProps> = props => {
  const { isLoading, title, phrase: loadingPhrase, route } = props

  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [currentIndexPoints, setCurrentIndexPoints] = useState<number>(0)
  const loading = ['.', '..', '...']
  

  useEffect(() => {
      startTimedText()
      startTimedPoints()
  }, [currentIndex, currentIndexPoints])

  const startTimedText = () => {
    if (currentIndex < loadingPhrase.length) {
      setTimeout(() => {
        setCurrentIndex(currentIndex + 1) 
        if(currentIndex === 2) setCurrentIndex(0) 
      }, 5000)
    } 
  } 

  const startTimedPoints = () => {
    if (currentIndexPoints < loading.length) {
      setTimeout(() => {
        setCurrentIndexPoints(currentIndexPoints + 1) 
        if(currentIndexPoints === 2) setCurrentIndexPoints(0) 
      }, 1000)
    } 
  } 

  if (isLoading) {
    return (
      <div className="loading-container-guest-user">
        <div>
          <img src={welcome} alt='loading-gif' className='loading-gif' />
        </div>
        <div className="container-text-loading-user-guest">
          <h4 className="h4-label">{title}</h4>
          {route!== 'order' && <p className="p-loading-cover-guest-user">Encuentra ofertas especiales, promociones y cupones al <b>registrarte</b></p>}
          <p className="p-variable-text-cover-guest-user">{`${loadingPhrase[currentIndex]}${loading[currentIndexPoints]}`}</p>
        </div>

        <style>
          {`
            .footer-menu-home {
              display: none !important;
            }
          `}
        </style>
      </div>
    )
  }

  return <></>
}

export default LoadingCoverUserGuest
