import React from 'react';
import './ButtonIcon.scss';

interface ButtonIconProps {
  onPressBtn: any,
  icon: string,
  text: string
}

const ButtonIcon: React.FC<ButtonIconProps> = ({onPressBtn, icon, text}) => (
  <div className="container-btn-select">
    <button className="btn-primary btn-icon" onClick={onPressBtn}>
      <img src={icon} alt="Icono boton"/>
      {text}
    </button>
  </div>
);

export default ButtonIcon;