/* eslint-disable */
const phoneBlackList = [
  '012345',
  '123456',
  '234567',
  '345678',
  '456789',
  '567890',
  '000000',
  '111111',
  '222222',
  '333333',
  '444444',
  '555555',
  '666666',
  '777777',
  '888888',
  '999999',
]

export const validatePhone = (value: string) => {
  const regex = /^[0-9]{4}[\s]?[0-9]{4}$/i
  const numbersRegex = /^\d+$/
  const regexValidation = regex.test(value)
  const regexNumbersValidation = numbersRegex.test(value);

  if (!regexValidation || !regexNumbersValidation) return false
  const clearValue = value.replace(' ', '')

  return phoneBlackList.every(
    blackCase =>
      !clearValue.includes(blackCase) &&
      !clearValue.includes(
        blackCase
          .split('')
          .reverse()
          .join(''),
      ),
  )
}

export const newValidatePhone = (value: string, validationLen: number = 9) => {
  const regexByLength: any = {
    9: /^[0-9]{5}[\s]?[0-9]{4}$/i,
    10: /^\d{10}$/,
  }

  const numbersRegex = /^\d+$/
  const regexValidation = regexByLength[validationLen]?.test(value)
  const regexNumbersValidation = numbersRegex.test(value);
  if (!regexValidation || !regexNumbersValidation) return false
  const clearValue = value.replace(' ', '')

  return phoneBlackList.every(
    blackCase =>
      !clearValue.includes(blackCase) &&
      !clearValue.includes(
        blackCase
          .split('')
          .reverse()
          .join(''),
      ),
  )
}

export const validateEmail = (value: string) => {
  const re = /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}

export const  getLogo = (name:string, type ='') =>{
  const split= name.split('.');
  let imageName = split[0];

  if(type!==''){
    imageName = imageName.replace('_f','');
  }
  const sizeImage= type === '' ? '' : `_${type}`;
  return  `${imageName}${sizeImage}.${split[1]}`
}
