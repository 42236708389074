import React from 'react'

import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import CardNotification from './CardNotification'

//Models
import Notifications from '../../models/Notifications'
import Office from '../../models/Office'
import { CartModel } from '../../models/CartModel'
import { Coupon } from '../../models/Coupon'

//Eventos
import { clickNotificationEvent, pushNotifOpen } from '../firebase/firebaseTags'

//Images
import couponIcon from '../../assets/icons/icn_notif-cupon.svg'
import agLogo2 from '../../assets/ag-logo2.svg'

//Utils
import { dateToStringFormat } from '../../utils/dates'

//Deeplinks
import { getDeepLinkRouteData } from '../deeplinks/deeplinksRoutes'

import GuruWhiteScreen from '../../components/guru-white-screen/GuruWhiteScreen'

import './Notifications.scss'

interface IPathParams {}

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  notifications: any
  notification: Notifications
  coupons: Coupon
  offices: Office
  cartModel: CartModel
  emptyMessage: string
  openNotification: () => void
}

const CardNotifications: React.FC<IProps> = props => {
  const handlerCard = async (notification: any) => {
    const { _id, body, orderId, deeplink } = notification
    clickNotificationEvent(_id, body)
    pushNotifOpen(deeplink)
    await props.notification.notificationRead(_id)

    if (!orderId) {
      props.history.push(`/notifications/${_id}`)
      props.openNotification()
    } else {
      const deeplink = `${process.env.REACT_APP_DEEPLINKS}/order/${orderId}`
      const history = props.history
      const routeData = await getDeepLinkRouteData(deeplink, [])
      history.push(routeData.route, routeData.params)
    }
  }

  const cardNotifications = (notifications: any[]) => {
    const notifImgs = [
      { value: 'Campaña', img: agLogo2 },
      { value: 'Cupón', img: couponIcon },
      { value: 'Actualización', img: agLogo2 },
      { value: 'Noticia', img: agLogo2 },
    ]

    return notifications.map((notif: any, index: number) => {
      const { thumbnail, createdAt, subject, title, body, isOpened } = notif
      let imageObj = notifImgs.find(({ value }) => value === subject)
      let notifImage = imageObj ? imageObj.img : agLogo2
      if (thumbnail) notifImage = thumbnail
      const dateObj = new Date(createdAt)
      const notifDate = createdAt ? dateToStringFormat(dateObj) : ''

      return (
        <div key={index}>
          <CardNotification
            body={body}
            imgSrc={notifImage}
            isOpened={isOpened}
            notifDate={notifDate}
            subject={subject}
            title={validateText(title, 40)}
            thumbnail={thumbnail}
            handleClick={() => handlerCard(notif)}
          />
        </div>
      )
    })
  }

  const validateNotifications = (notifications: any) => Boolean(notifications?.length)

  const validateText = (body: any, limit: number) => (body.length > limit ? `${body.slice(0, limit)} ...` : body)

  const notifications = props.notifications || []

  return (
    <div className="page-card">
      {validateNotifications(notifications) && cardNotifications(notifications)}
      {!validateNotifications(notifications) && (
        <div className="container-not-notifications">
          <GuruWhiteScreen text={props.emptyMessage} />
        </div>
      )}
    </div>
  )
}

export default withRouter(CardNotifications)
