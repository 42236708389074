import React from 'react'
import { IonModal } from '@ionic/react'
import ButtonComponent from '../basic-button/ButtonComponent'
import iconAlert from './../../assets/icons/icn_alert.svg'
import './modals.scss'

interface AlertModalProps {
  label: string
  text: string
  buttonText: string
  buttonAction: any
  secondaryButtonText?: string
  secondaryButtonAction?: any
  isOpen: boolean
  onDismiss: any
  icon?: string
  hasError?: boolean
  buttonMargin?: string
}

const AlertModal: React.FC<AlertModalProps> = ({
    label,
    text, 
    buttonText, 
    buttonAction, 
    isOpen, 
    onDismiss, 
    icon, 
    hasError, 
    buttonMargin, 
    secondaryButtonText, 
    secondaryButtonAction,
  }) => {
  const textParts = text?.split('-icon-')

  return (
    <IonModal cssClass="alert-modal" backdropDismiss={buttonAction} isOpen={isOpen} onDidDismiss={onDismiss}>
      <div className="content-alert">
        <div className="content-alert-modal">
          {hasError && 
            <img src={iconAlert} alt="Icono alerta"/>
          }
          <h5 className={`container-title-modal ${hasError ? 'error-text' : ''}`}>{label}</h5>
        </div>
        <div className="container-information-modal">
          {textParts?.length > 1 ? (
            <p> {textParts[0]} <img src={icon} alt="Icono alerta"/> {textParts[1]}</p>
          ) : text}
        </div>
        <div 
          className={secondaryButtonText ? "container-btn-modal-two-buttons" :"container-btn-modal"}
          style={{ marginTop: buttonMargin ? buttonMargin : "0px" }}
        >
          {secondaryButtonText 
            ? <ButtonComponent 
                text={secondaryButtonText}
                className="btn-tertiary"
                onClick={secondaryButtonAction}
              />
            : ""
          }
          <ButtonComponent 
            text={buttonText}
            className="btn-tertiary"
            onClick={buttonAction}
          />
        </div>
      </div>
    </IonModal>
  )
};

export default AlertModal;
