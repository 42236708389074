import { useState } from 'react'
import { CountryInfo } from '../../interfaces/countryInfo'

function CountryInfoProvider() {
  const [countryInfo, setCountryInfo] = useState<CountryInfo>({
    city: '',
    countryCode: '',
    countryName: '',
  })

  const handlerCountryInfo = (countryInfo: CountryInfo) => setCountryInfo(countryInfo)

  return { countryInfo, handlerCountryInfo }
}

export default CountryInfoProvider
