import React from 'react';
import { signUpSupportEvent } from '../../pages/firebase/firebaseTags';
import './ContactSupport.scss'

interface ButtonProps {}

const ContactSupport: React.FC<ButtonProps> = () => (
  <p className="help">
    <b onClick={signUpSupportEvent}>
      ¿Necesitas ayuda? <a href="/contact">Contáctanos</a>
    </b>
  </p>
);

export default ContactSupport;