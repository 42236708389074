import { useEffect, useState } from 'react'
import { getPaymentMethodsByVendor } from '../../services/bff_mobile/v1/payment_methods/getPaymentMethodsByVendor'

function useGetPaymentMethodsByProviderId(providerId: string) {
  const [paymentMethods, setPaymentMethods] = useState<string[]>([])

  useEffect(() => {
    const action = async () => {
      try {
        setPaymentMethods(await getPaymentMethodsByVendor(providerId))
      } catch (error) {
        setPaymentMethods([])
      }
    }

    action()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return { paymentMethods }
}

export default useGetPaymentMethodsByProviderId
