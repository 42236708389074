import { IonButton, IonModal } from '@ionic/react'
import React from 'react'
import './modal-invited-user.scss'

interface ModalSendSuccess {
  isOpen: boolean
  cerrarModal(): void
}

const ModalSendSuccess: React.FC<ModalSendSuccess> = (props) => {
  const {isOpen, cerrarModal} = props

  return (
    <IonModal
      cssClass="modal-success-container"
      isOpen={isOpen}>
        <div className='modal-success-content'>
          <div className='success-title'>
            ¡Recibimos tus datos!
          </div>
          <div className='success-text'>
            Nos comunicaremos contigo a la brevedad 
            para ayudarte en lo que desees.
          </div>
          <div className='btn-ok-container'>
            <IonButton onClick={cerrarModal} className="send" mode='ios' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              Ok
            </IonButton>
          </div>
        </div>
    </IonModal>
  )
}

export default ModalSendSuccess;
