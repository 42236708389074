import React from 'react'

import './FeedbackNoScope.scss'

interface FeedbackNoScopeProps {
    image: string
    text: string
    callToAction: string
}

const FeedbackNoScope: React.FC<FeedbackNoScopeProps> = ({ image, text, callToAction }) => (
    <div className="feedback-no-scope-wrapper">
        <img src={image} alt="Feedback No Scope Image" />
        <span className="feedback-no-scope-text">{text}</span>
        <span className="feedback-no-scope-call-to-action">{callToAction}</span>
    </div>
)

export default FeedbackNoScope