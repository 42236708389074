import React from 'react'
import { IonModal, IonIcon, IonButton } from '@ionic/react'
import { close } from 'ionicons/icons'
import User from '../../models/User'
import welcome from './../../assets/icons/almacenera-bienvenidos.svg'

import './ModalWelcomeGuru.scss'

interface Props {
  user: User
  history: any
  openModalWelcome: boolean
  closeModalWelcomeGuru: any
  title: string
  subtitle: string
  from: boolean
  closeRedirectLink?: any
  closeLinkLocationState?: any
}

interface State {
  recoveryPassword: boolean
}

export default class ModalWelcomeGuru extends React.PureComponent<Props, State>{

closeModalWelcomeGuru = () => {
  const { history, closeRedirectLink, closeLinkLocationState} = this.props

  this.props.closeModalWelcomeGuru()
  if (closeRedirectLink && closeLinkLocationState) {
    if(closeLinkLocationState?.type === "Order") closeLinkLocationState.hideSurvey = true
    return history.push(closeRedirectLink, closeLinkLocationState)
  }
  if (closeRedirectLink) return history.push(closeRedirectLink)
  history.push('/orders')
}

render(){
  const { openModalWelcome, title, subtitle, from } = this.props

   return (
    <IonModal cssClass="welcome-guru-modal" backdropDismiss={false} isOpen={openModalWelcome}>
      <div className="content">
        <div className="body-welcome-guru">
          <img className="welcome" src={welcome} alt="Gracias por tu aporte"/>
        </div>
        <div className="container-info-productModal">
          <div className={`container-title-modal ${!from ? 'convert-to-upper' : ''}`}>{title}</div>
          <div className="info-modal">
            {subtitle}
          </div>
        </div>
        <div className='footer-btn-survey'>
          <IonButton 
             expand="block"
             shape="round"
             onClick={this.closeModalWelcomeGuru} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
            Volver
          </IonButton>
        </div>
      </div>
    </IonModal>
   );
 }
}
 