import React, { Fragment, ChangeEventHandler } from 'react'
import Settings from '../../models/Settings'
import { IonIcon } from '@ionic/react'
import close from './../../assets/icons/nav_close.svg'
import search from '../../assets/icons/act_search.svg'

import track from 'react-tracking'

import './SearchBar.scss'

import { Device } from '@capacitor/device';

type Props = {
  settings: Settings
  history?: any,
  onSearch?: any,
  categoryId?: any,
  searchText?: any,
  tracking?: any,
  isBackgroundTransparent?: boolean,
  onSearchCriteriaChange?: ChangeEventHandler<HTMLInputElement>
  routeProvider?: boolean
  routeHome?: boolean
}

type State = {
  searchText: string
  categoryId: string
  placeholder: string
  interval: any
  iconClose: boolean
  iconCloseClick: boolean
  icon: any
  lastSearchText: string
}

class SearchBar extends React.PureComponent<Props, State> {
  state = {
    searchText: '',
    categoryId: '',
    placeholder: 'Busca por proveedor, marca o producto',
    interval: null,
    iconClose: false,
    iconCloseClick: false, 
    icon: '',
    lastSearchText: ''
  }

  devicePlatform={}

  async componentDidMount() {
    const { routeProvider, routeHome, searchText } = this.props

    this.placeHolderChanger()
    this.devicePlatform= (await Device.getInfo()).platform

    if(this.routeSplit()[4]){
      this.setState({icon: close, searchText:this.routeSplit()[4]})
      this.onSearch()
    } 

    if(routeHome) this.setState({
      icon: close, 
      iconClose: true,
      lastSearchText: searchText
    })
    if(routeProvider) this.setState({icon: search })
    if(!routeProvider && !routeHome) this.setState({icon: search })
  }

  UNSAFE_componentWillReceiveProps(props: any) {
    const { iconCloseClick, searchText } = this.state
   
    if (props.searchText && props.searchText !== searchText && !iconCloseClick) {
      this.setState({
        searchText: props.searchText,       
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interval || 0)
  }

  onKeyDown = (e: any) => {
    if (e.charCode === 13 || e.keyCode === 13) {
      e.preventDefault()
      this.onSearch()
    }
  }

  onChange = (event: any) => {
    const { lastSearchText } = this.state

    this.setState({searchText: event.target.value})

    if(lastSearchText !== '' && lastSearchText !== event.target.value) this.setState({ icon:search, iconClose:false})
  }

  onSearch = () => {
    const { routeProvider, routeHome } = this.props
    const { searchText } = this.state

    if(routeProvider || routeHome) {
      this.setState({
        iconClose:true,
        icon: close, 
        lastSearchText: searchText
      })
    }

    if ((this.state.searchText && this.state.searchText.length > 2) || (this.props.searchText && this.props.searchText.length > 2)) {
      if (this.props.onSearch) {
        this.props.onSearch(this.state.searchText || this.props.searchText, this.props.categoryId)
      } else {
      this.props.history.push('/results/'+this.state.searchText || this.props.searchText, {
        searchText: this.state.searchText || this.props.searchText,
        categoryId: this.props.categoryId,
        time: Date.now()
      })
    }
   }
  }

  onClickIconClose = () => {
    this.setState({
      searchText:'',
      icon:search,
      iconClose:false,
      iconCloseClick:true,
    })
  }

  onSearchClick = () => { 
    const { iconClose } = this.state
    
    if(iconClose){
      this.onClickIconClose()
    } else {
      this.onSearch()
    }
  }


  placeHolderChanger = () => {
    const { routeProvider } = this.props
    
    let placeholders = [
      'Intenta buscar "Leches"',
      '¿Qué buscas hoy?',
      '¿Cómo te podemos ayudar?',
      'Intenta palabras como "Manjar 1kg"'
    ]

    if(routeProvider)  placeholders = ['Busca por proveedor, marca o producto']

    if (this.state.interval) {
      clearInterval(this.state.interval || 0)
    }
    const interval = setInterval(() => {
      this.setState({
        placeholder: placeholders[Math.floor(Math.random() * placeholders.length)]
      })
    }, 10000)
    this.setState({
      interval
    })
  }

  showIcon = () =>{
    const {routeProvider } = this.props
    const {iconClose, searchText } = this.state

    if(routeProvider && !iconClose) return search
    if(iconClose && searchText !== '' && routeProvider ) return close
    if(!routeProvider) return search
  }

  routeSplit = () =>  this.props.history.location.pathname.split("/")

  render() {
    const { icon } = this.state
    const { isBackgroundTransparent } = this.props
    const isIosAndResult = (this.props.history.location.pathname.indexOf('/results') !== -1 && this.devicePlatform==='ios')

    return (
      <Fragment>
        <div 

          className={`search-bar ${isBackgroundTransparent ? 'transparent-background' : ''} ${isIosAndResult ? 'no-padding-top' : ''}`}
        >
          <div>
            <input
              value={this.state.searchText}
              placeholder={this.state.placeholder}
              onKeyDown={this.onKeyDown}
              onChange={this.onChange}
            />
            <IonIcon className="search-bar-icon" icon={icon} onClick={this.onSearchClick} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default track({})(SearchBar)
