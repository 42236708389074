import React, { Fragment, useState, useEffect, useMemo, useCallback } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router'
import { uniq } from 'lodash'
import { IonText, IonIcon, IonImg, IonChip, IonLabel, IonSelect, IonSelectOption, IonToggle } from '@ionic/react'

import caretDown from '../../assets/icons/icn_arrow-closed.svg'
import caretOpen from '../../assets/icons/icn_arrow-open.svg'

import Garbage from './../../assets/icons/act_delete.svg'
import Edit from './../../assets/icons/act_edit.svg'
import { image } from 'ionicons/icons'

import { ProviderModel } from '../../models/ProviderModel'
import { Product } from '../../models/ProductModel'
import TermsConditionsCoupons from '../terms-conditions-coupons/TermsConditionsCoupons'

import { formatCurrency } from '../../utils/intl'
import { returnOptionsToUOM } from '../../utils/uomAndPackage'

import { applyCouponEvent, eventForCart, sendMetric } from '../firebase/firebaseTags'

import BottomSheet from 'bottom-sheet-react'

import './CardCart.scss'
import { verifyProviderReferal } from '../../clients/cart'

interface IPathParams {}

interface RouteState {}

type Props = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  providerModel: ProviderModel
  products: string
  provider: any
  paymentMethodByVendors: any
  showModal: (providerId: string, sku: string, product: any) => void
  renderModal: any
  deleteProduct: any
  productCart: any
  isMinimumFulfilled: boolean
  couponAddCart: any[]
  totalProvider: number
  totalToPay: (total: number) => void
  couponsActivatedList: any
  onOpenChangeWayToPay: any
  changeCart: (providerId: string) => void
  openProviderSelect: string
  addCouponCart: any
  removeCouponToCart: any
  isBottomSheetShow: (isShowBottomSheet: boolean) => void
  paymentMethodsByVendor: any
  vendorsShippingCost: any
}

const CardCartNew: React.FC<Props> = (props) => {
  const {
    paymentMethodByVendors,
    provider,
    productCart,
    isMinimumFulfilled,
    couponAddCart,
    totalProvider,
    couponsActivatedList,
    changeCart,
    openProviderSelect,
    addCouponCart,
    removeCouponToCart,
    isBottomSheetShow,
    paymentMethodsByVendor,
    products,
    vendorsShippingCost,
  } = props
  const [defautOpenCard, setDefautOpenCard] = useState<any>(null)
  const [bottomSheetShow, setBottomSheetShow] = useState(false)
  const [isOpenTermsConditionsCoupons, setIsOpenTermsConditionsCoupons] = useState(false)
  const [selectedCouponId, setSelectedCouponId] = useState(null)
  const [, setShowShowSelectedCouponId] = useState(null)
  const [lastAction, setLastAction] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isVendorInBlacklist, setIsVendorInBlacklist] = useState<boolean>(true)

  const removeCouponByAmount = useCallback(
    async (couponActivatedCart: any) => {
      if (couponActivatedCart?.providerId === provider?.id && couponActivatedCart?.minimumAmount >= totalProvider) {
        const idCoupon = {
          value: couponActivatedCart.couponId,
        }
        await removeCouponToCart(idCoupon, provider?.id)
      }
    },
    [provider, removeCouponToCart, totalProvider],
  )

  const get = useCallback(async () => {
    const oneVendor = uniq(productCart.products.map((item: any) => item.providerId))
    const cardSelect = oneVendor.length === 1
    setDefautOpenCard(cardSelect)
    const couponActivatedCart = productCart?.discounts.find((activated: any) => activated.providerId === provider.id)
    couponActivatedCart && setSelectedCouponId(couponActivatedCart.couponId)
    couponActivatedCart && removeCouponByAmount(couponActivatedCart)
  }, [productCart, provider.id, removeCouponByAmount])

  useEffect(() => {
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const allCoupons = useMemo(() => couponsActivatedList, [couponsActivatedList])

  const validateVendorsInBlacklist = useCallback(async () => {
    if (!couponsActivatedList.length) return
    let blockVendorsIds: any = []
    let validCouponsTypes = couponsActivatedList?.map((coupon: any) => coupon.couponType)
    let singleCouponsTypes = Array.from(new Set(validCouponsTypes))

    for (let i = 0; i < singleCouponsTypes.length; i++) {
      const couponType: any = singleCouponsTypes[i]
      const vendorsBlacklistInResponse = await vendosInBlacklist(couponType)
      const vendorsInBlacklist = vendorsBlacklistInResponse?.respuesta[0]?.providers || []

      blockVendorsIds = [...blockVendorsIds, ...vendorsInBlacklist]
    }

    setIsVendorInBlacklist(blockVendorsIds.includes(provider?.id))
  }, [couponsActivatedList, provider])

  useEffect(() => {
    validateVendorsInBlacklist()
  }, [allCoupons, validateVendorsInBlacklist])

  const findShippingCost = () => {
    if (vendorsShippingCost) {
      return vendorsShippingCost[products] || null
    }
    return null
  }

  const renderProducts = () => {
    return (
      provider &&
      paymentMethodByVendors.products[provider.id].map((product: any) =>
        paymentMethodByVendors.vendors[provider.id].map((productInCart: any) => {
          const uomProduct = findPairToShowValue(returnOptionsToUOM(), product?.UOM)
          const unitOfMeasure = uomProduct === 'unidad' ? 'unid.' : uomProduct

          return (
            productInCart &&
            product &&
            productInCart.sku === product.sku && (
              <div className="provider-product" key={product.sku}>
                <h1>{productInCart.quantity}</h1>
                {product.filename ? (
                  <div>
                    <IonImg className="product-image" src={product.filename} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  </div>
                ) : (
                  <div className="not-img">
                    <IonIcon className="product-image" icon={image} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  </div>
                )}
                <div className="product-info">
                  <p className="paragraph-3">{`${product.name.slice(0, 19)}...`}</p>
                  <p className="paragraph-3">{`${product.units} ${unitOfMeasure} por ${product.package}`}</p>
                  <div className="color-price" key={product.sku}>
                    {formatCurrency(productInCart.infoPrice.totalPrice)}
                  </div>
                </div>
                <div className="product-operations">
                  <IonChip outline className="product-operation" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon
                      className="icon"
                      src={Edit}
                      onClick={() => {
                        onEditProduct(provider?.id, product.sku, product, productInCart)
                      } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                    />
                  </IonChip>
                  <IonChip outline className="product-operation" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon
                      className="icon"
                      src={Garbage}
                      onClick={async () => {
                        onDeleteProduct(productInCart, product)
                      } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                    />
                  </IonChip>
                </div>
              </div>
            )
          )
        }),
      )
    )
  }

  const findPairToShowValue = (options: any, name: string) => options[name]

  const doesNotMeettheMinimum = () => {
    return (
      <div className="container-minimun">
        <div className="text-minimun paragraph-2">
          <IonText placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {'Mínimo para entrega '}
            <IonText className="provider-minimum" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{formatCurrency(provider?.minimun)}</IonText>
          </IonText>
        </div>
        <div className="provider-button">
          <div
            className="div-button"
            onClick={() => {
              onCompleteProduct(provider)
            }}
          >
            Completar
          </div>
        </div>
      </div>
    )
  }

  const onCompleteProduct = (provider: any) => {
    const { alias, minimun, banners, id } = provider

    sendMetric('complete_cart')
    props.history.push(`/home-providers/${id}`, {
      singleProviderId: id,
      nameProvider: alias,
      minumun: minimun,
      banners,
    })
  }

  const onEditProduct = (providerId: any, sku: any, product: Product, cartProduct: any) => {
    const { showModal, renderModal } = props

    const productEdit = { id: providerId }
    localStorage.setItem('onEditProduct', JSON.stringify(productEdit))

    showModal(providerId, sku, product)
    renderModal(cartProduct, product)
  }

  const onDeleteProduct = async (cartProduct: any, product: Product) => {
    const { deleteProduct } = props
    eventForCart('delete_product_cart', product.name, product.id, product.brand, product.category, 'cart')
    await deleteProduct(cartProduct, product)
  }

  const nameProvider = () => {
    return (
      <div
        className="provider-header-cart"
        onClick={() => {
          changeCardSelect(provider?.id)
        }}
      >
        <div className="container-provider-header">
          <div className="h6-text">{provider?.alias}</div>
          <div className="icon-provider">
            <IonIcon
              className="icon"
              icon={isCardSelect() ? caretOpen : caretDown}
              onClick={() => {
                changeCardSelect(provider?.id)
              } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            />
          </div>
        </div>
      </div>
    )
  }

  const changeCardSelect = (providerId: string) => {
    const providerSelect = providerId === openProviderSelect ? '' : providerId
    changeCart(providerSelect)
    setDefautOpenCard(null)
  }

  const totalProviderCart = () => {
    const shippingCost = findShippingCost()
    const cartLocalStorage = localStorage.getItem('@cart')
    let cart: any = ''

    if (cartLocalStorage) cart = JSON.parse(cartLocalStorage)

    return (
      <div className="provider-footer">
        {shippingCost !== null && (
          <div className="provider-total">
            <div className="provider-total-info">
              <h6>Costo de despacho</h6>
              <h6>{`${shippingCost > 0.1 ? formatCurrency(shippingCost) : '¡Gratis!'}`}</h6>
            </div>
          </div>
        )}
        <div className="provider-total">
          <div className="provider-total-info">
            <span>Total</span>
            {verificarDescuento(provider, cart.discounts) > 0 ? (
              <h6>{formatCurrency(totalProvider - verificarDescuento(provider, couponAddCart))}</h6>
            ) : (
              <h6>{formatCurrency(totalProvider)}</h6>
            )}
          </div>
        </div>
      </div>
    )
  }

  const verificarDescuento = (proveedor: any, coupon: any) => {
    let totalToDiscount = 0
    const result = coupon.filter((detail: any) => detail.providerId === proveedor.id)

    if (result.length > 0) totalToDiscount = result[0].realDiscount

    props.totalToPay(totalToDiscount)
    return totalToDiscount
  }

  const selectPaymentMethod = () => {
    const paymentMethodsText: any = {
      'pre-pago': 'anticipado',
      'post-pago': 'contra entrega',
      credito: 'crédito proveedor',
    }

    const placeholderSelect = `Pago ${paymentMethodsText[paymentMethodByVendors.paymentMethod]}`
    const uniqPaymentMethodsByVendor = uniq(paymentMethodsByVendor?.whitelist)

    return (
      <div className="way-to-pay-card">
        <IonSelect
          className="select-way-to-pay"
          mode="md"
          interface="action-sheet"
          cancelText="Cancelar"
          placeholder={placeholderSelect}
          onIonChange={(e) => {
            props.onOpenChangeWayToPay(e, provider?.id)
          } }
          interfaceOptions={{
            cssClass: 'paymenth-options-cart-container',
          }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        >
          {uniqPaymentMethodsByVendor.map((paymentMethod: any) => {
            const optionText = paymentMethodsText[paymentMethod]
            if (!optionText || paymentMethodByVendors.paymentMethod === paymentMethod) return ''
            return (
              <IonSelectOption value={paymentMethod} key={paymentMethod} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                Pago {optionText}
              </IonSelectOption>
            )
          })}
        </IonSelect>
      </div>
    )
  }

  const isCardSelect = () => {
    if (defautOpenCard !== null) return defautOpenCard

    let isInfoCard = openProviderSelect === provider?.id
    return isInfoCard
  }

  const vendosInBlacklist = async (couponType: string) => {
    const vendors = await verifyProviderReferal(couponType)
    return vendors
  }

  const renderCoupons = () => {
    const couponsActivatedCart = productCart?.discounts.find((activated: any) => activated.providerId === provider?.id)
    const validCoupons = couponsActivatedList.filter(
      (coupon: any) => coupon.validVendors && coupon.validVendors.length > 0,
    )
    const includeProvider =
      validCoupons.length > 0 && validCoupons.some((coupon: any) => coupon.validVendors.includes(provider.id))
    const hasValidCoupons = couponsActivatedList.length > validCoupons.length
    const couponsVendors = !includeProvider
      ? couponsActivatedList
          .filter((item: any) => item.validVendors.length === 0)
          .filter((item: any) => item.vendors.length === 0 || item.vendors.includes(provider?.id))
      : couponsActivatedList
    const couponText = availableCoupons?.length > 1 ? 'cupones disponibles' : 'cupón disponible'

    return (
      <div className="container-coupons">
        {couponsActivatedCart &&
        Object.keys(couponsActivatedCart).length > 0 &&
        couponsActivatedCart.providerId === provider?.id ? (
          <div className="coupons-activated">
            <div className="text-coupon">¡Descuento aplicado!</div>
            <div
              className="see-more"
              onClick={() => {
                showBottomSheet()
                setShowShowSelectedCouponId(provider?.id)
              }}
            >
              Ver Todos
            </div>
          </div>
        ) : (
          (hasValidCoupons || (validCoupons.length > 0 && includeProvider)) &&
          couponsVendors.length > 0 && (
            <div className="coupons-not-activated">
              <div className="text-coupon">
                Tienes {availableCoupons?.length} {couponText}
              </div>
              <div
                className="see-more"
                onClick={() => {
                  showBottomSheet()
                  setShowShowSelectedCouponId(provider?.id)
                }}
              >
                Ver Todos
              </div>
            </div>
          )
        )}
      </div>
    )
  }

  const renderBottomSheet = () => {
    return (
      <div className="container-bottom-sheet">
        {bottomSheetShow && (
          <BottomSheet isExpandable={bottomSheetShow} customHeight={200} onClose={() => showBottomSheet()}>
            <div className="info-bottom-sheet">
              <div className="btn-close" onClick={() => showBottomSheet()}></div>
              <div className="title-text">Cupones</div>
              <div className="sheet-text paragraph-2">
                Al aplicar cualquier cupón estás
                <div className="underlined" onClick={() => setIsOpenTermsConditionsCoupons(true)}>
                  aceptando los términos y condiciones.
                </div>
              </div>
              {availableCoupons.length > 0 && showListCoupons()}
            </div>
          </BottomSheet>
        )}
      </div>
    )
  }

  const showListCoupons = () => {
    const couponActivatedCart = productCart?.discounts.find((activated: any) => activated.providerId === provider?.id)

    return availableCoupons.map((item: any) => {
      let checked = item.couponId === couponActivatedCart?.couponId
      const minimumAmountCoupon = totalProvider >= item?.couponInfo.minimum_amount
      const isCouponDisabled = (selectedCouponId && !checked) || !minimumAmountCoupon

      return (
        <div className={`card-cupons ${isCouponDisabled ? 'disabled' : ''}`} key={item.couponId}>
          <div className="container-img-cupons">
            <IonImg
              className="image-cupons"
              src={`${process.env.REACT_APP_BFF_IMAGE}coupons/${item?.couponInfo?.image?.url}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            />
          </div>
          <div className="info">
            <h5 className="info-title">
              {item.discount_type === 'porcent'
                ? `${item?.couponInfo.amount}%`
                : formatCurrency(item?.couponInfo.amount)}{' '}
              {`de descuento`}
            </h5>
            <div className="paragraph-3">
              {`Compras sobre`} {formatCurrency(item?.couponInfo.minimum_amount)}
            </div>
          </div>
          <div className="container-toggle">
            <IonToggle
              checked={checked}
              value={item.couponId}
              mode="ios"
              disabled={!!isCouponDisabled}
              onIonChange={async (e) => {
                if (isLoading) return

                const existInCart = item.couponId === couponActivatedCart?.couponId

                if (!existInCart && e.detail.checked && lastAction !== 'add') {
                  applyCouponEvent(item?.code)
                  setIsLoading(true)
                  setLastAction('add')
                  setSelectedCouponId(item._id)
                  await addCouponCart(e.detail, provider?.id)
                  setIsLoading(false)
                }

                if (existInCart && !e.detail.checked && lastAction !== 'remove') {
                  setIsLoading(true)
                  setLastAction('remove')
                  setSelectedCouponId(null)
                  await removeCouponToCart(e.detail, provider?.id)
                  setIsLoading(false)
                }
              } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            />
            <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Aplicar</IonLabel>
          </div>
        </div>
      )
    })
  }

  const showBottomSheet = () => {
    setBottomSheetShow(!bottomSheetShow)
    isBottomSheetShow(!bottomSheetShow)
  }

  const closeTermsConditionsCoupons = () => setIsOpenTermsConditionsCoupons(false)

  let wayToPay = uniq(paymentMethodsByVendor?.whitelist.map((item: any) => item))

  const availableCoupons = useMemo(() => {
    const coupons = isVendorInBlacklist
      ? couponsActivatedList.filter(({ couponType }: any) => couponType === 'full-coupon')
      : couponsActivatedList

    const validCoupons = coupons.filter((coupon: any) => coupon.validVendors && coupon.validVendors.length > 0)
    const includeProvider =
      validCoupons.length > 0 ? validCoupons.filter((coupon: any) => coupon.validVendors.includes(provider?.id)) : []
    const couponsWithoutValidVendors = coupons.filter((item: any) => item.validVendors.length === 0)
    const couponsVendors =
      includeProvider.length === 0 ? couponsWithoutValidVendors : [...couponsWithoutValidVendors, ...includeProvider]
    return couponsVendors.filter((item: any) => item.vendors?.length === 0 || item.vendors.includes(provider?.id))
  }, [couponsActivatedList, isVendorInBlacklist, provider])

  return (
    <div className="provider-list ds-content-page" key={provider?.id}>
      <Fragment>
        <div className={isMinimumFulfilled ? 'provider-summary' : 'provider-summary invalid'}>
          {nameProvider()}
          {isCardSelect() && (
            <div className="container-info-card">
              {Object.keys(paymentMethodByVendors).length > 0 &&
                Object.keys(paymentMethodByVendors.products).length > 0 &&
                Object.keys(paymentMethodByVendors.vendors).length > 0 && (
                  <div className="provider-products">{renderProducts()}</div>
                )}
              {availableCoupons.length > 0 && renderCoupons()}
              {renderBottomSheet()}
            </div>
          )}
          {!isMinimumFulfilled && doesNotMeettheMinimum()}
          {isCardSelect() && wayToPay?.length > 1 && selectPaymentMethod()}
          {totalProviderCart()}
          {isOpenTermsConditionsCoupons && (
            <TermsConditionsCoupons
              isOpen={isOpenTermsConditionsCoupons}
              closeTermsModal={closeTermsConditionsCoupons}
            />
          )}
        </div>
      </Fragment>
    </div>
  )
}

export default withRouter(CardCartNew)
