import React from 'react'

interface IProps {
  icon?: string;
  alt?: string;
  style?: any
}

const PromotionImage: React.FC<IProps> = ({icon, style}) => {
  return (
    <img className="img-ladder" src={icon} alt={'icon-promotion'} style={style} />
  )
}

export default PromotionImage