import React from 'react'
import {RouteComponentProps } from 'react-router-dom'
import { validateRut } from '../../utils/rut'

import TextInput from '../../components/input-form/TextInput'


interface IPathParams {}

type Props = RouteComponentProps<IPathParams> & {
  validateUser: any
  informativeText?: string
}
interface State {
  valueInputText: any
  validationInput: boolean
  validationText: string | number
}

export default  class FieldRutChile extends React.PureComponent<Props, State> {
  state: State = {
    valueInputText: "",
    validationInput: false,
    validationText: ""
  }

  onChangeInput = (event:any) => {
    const inputVal = event.target.value;
    const validation = validateRut(inputVal) 
    this.setState({
      valueInputText: validation || inputVal,
      validationInput: !!validation,
      validationText: inputVal
    })

    if (validation) {
      const localUser = {'username': validation || inputVal}
      this.props.validateUser(localUser, true)
    } else {
      const localUser = {'username': validation || inputVal}
      this.props.validateUser(localUser, false)
    }
  }

  render() {
    const {
      valueInputText,
      validationInput,
      validationText
    } = this.state

    const {informativeText} = this.props
      
    return (
      <div className='form-container'>
        <TextInput 
          iconDefault="icn_id" 
          iconInvalid="icn_id-red"
          iconValid="icn_id-blue"
          onChange={this.onChangeInput} 
          placeholder="Ej: 201234567"
          label="RUT Comercial"
          isValid={validationInput}
          error={"Escribe tu RUT sin puntos ni guión"}
          value={valueInputText}
          toValidate={validationText}
          type="text"
          maxLength={12}
          autoComplete="off"
          informativeText={informativeText || ''}
        />
      </div>
    )
  }
}


