import Axios from 'axios'
import { PerformanceData } from '../models/PerformanceTimer';

export const sendPerfomance = async (performanceData: PerformanceData) => {
  try {
    const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_NODE}/v1/loadingTime`, performanceData);
    return result.data
  } catch (error) {
    console.error(error)
  }
}