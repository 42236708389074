import React, { ReactElement } from 'react'
import { IonIcon } from '@ionic/react'

// Models
import { MapLocationCoordinates } from '../../models/MapLocationCoordinates'

// Libraries
import GoogleMapReact from 'google-map-react'

// Clients

// Assets
import locationIcon from '../../assets/icons/icon_location-pin.svg'
import addressIcon from './../../assets/icons/icn_address.svg'

// Styles
import './MapLocationPicker.scss'

const Marker = () => <IonIcon className="map-marker" slot="icon-only" icon={locationIcon} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />

type FindMeButtonProps = {
  onClick: () => any
  disabled: boolean
}

const FindMeButton: React.FC<FindMeButtonProps> = ({ onClick, disabled }): ReactElement => (
  <button onClick={onClick} type="button" className={`find-me-button ${disabled ? 'find-me-button--disabled' : ''}`}>
    <img src={addressIcon} className="find-me-button__icon" alt="find-me-icon" /> <span>Encuéntrame</span>
  </button>
)

type MapLocationPickerProps = {
  coordinates: MapLocationCoordinates
  setCoordinates: (coordinates: MapLocationCoordinates) => any
  handleFindMe: () => any
  isFindMeDisabled: boolean
}

const MapLocationPicker: React.FC<MapLocationPickerProps> = ({
  coordinates,
  setCoordinates,
  handleFindMe,
  isFindMeDisabled,
}) => {
  const validateGoogleMapsKey = () => process?.env?.REACT_APP_GOOGLE_MAP_KEY || null

  const handleMapChange = (localization: any) => {
    const { center } = localization
    setCoordinates(center)
  }

  return (
    <div className="geolocalization-map">
      <div className="geolocalization-map__marker-container">
        <Marker />
      </div>

      <div className="geolocalization-map__find-btn-container">
        <FindMeButton onClick={handleFindMe} disabled={isFindMeDisabled} />
      </div>

      <GoogleMapReact
        onChange={handleMapChange}
        bootstrapURLKeys={{ key: `${validateGoogleMapsKey()}`, libraries: ['places'] }}
        center={coordinates}
        options={{ fullscreenControl: false, types: ['geocode', 'establishment'] }}
        defaultZoom={18}
        resetBoundsOnResize
      ></GoogleMapReact>
    </div>
  )
}

export default MapLocationPicker
