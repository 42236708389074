import React, { useEffect, useMemo, useState } from 'react'

// Libraries
import { IonContent, IonPage } from '@ionic/react'
import { isEmpty } from 'lodash'

// Clients
import {
  getBusinessTypes,
  getComunesByDepartmentAndCountryCode,
  getComunesByProvincesAndCountryCode,
  getDepartmentsByCountryCode,
  getProvincesByDepartmentsAndCountryCode
} from '../../../clients/register';
import { OfficeRegistration } from '../../../clients/officeRegistration';
import { requestSms } from '../../../clients/validateSms'

// Components
import HeaderWithoutToolbar from '../../../components/header-without-toolbar/HeaderWithoutToolbar'
import TextInput from '../../../components/input-form/TextInput'
import DropdownSelect from '../../../components/select-dropdown/DropdownSelect'
import Checkbox from '../../../components/checkbox/Checkbox'
import ButtonComponent from '../../../components/basic-button/ButtonComponent'
import GuruAcceptModal from '../../../components/modal-action-accept/ModalActionAccept'

// Models
import { LocationInfo } from '../../../models/LocationInfo'
import { BusinessType } from '../../../models/BusinessType'
import User from '../../../models/User'
import Office from '../../../models/Office'

// Hooks
import { useUserCountryCode } from '../../../hooks/useUserCountryCode'

// Assets
import arrowBackIcon from '../../../assets/icons/nav_left.svg'

// Utils
import {
  countriesWithPostalCode,
  countriesWithProvinces,
  countryCl,
  countryMx,
  countryPe,
  countryTerritoryName,
  countryTerritorySelectText,
  supportedCountry,
  territoryType
} from '../../../utils/countriesTexts';
import { cleanText } from '../../../utils/strings';
import { getLocalStorageObject } from '../../../utils/localstorageData';

// Styles
import './LocationConfirmationModal.scss'
import LoadingLayer from '../../../components/loading/LoadingLayer'

interface LocationConfirmationProps {
  isOpen: boolean
  locationInfo: LocationInfo
  onGoBack: () => void
  userModel: User
  officeModel: Office
  history: any
  editedOffice?: any
}

type confirmationFormField =
  | 'businessName'
  | 'businessType'
  | 'region'
  | 'province'
  | 'commune'
  | 'postalCode'
  | 'address'
  | 'additionalDeliveryInfo'

interface IConfirmationForm {
  businessName: string
  businessType: string
  region: string
  province: string
  commune: string
  postalCode: string
  address: string
  additionalDeliveryInfo?: string
  uploadedDocument?: string
}

const initialForm: IConfirmationForm = {
  businessName: '',
  businessType: '',
  region: '',
  province: '',
  commune: '',
  postalCode: '',
  address: '',
  additionalDeliveryInfo: '',
}

export const LocationConfirmationModal: React.FC<LocationConfirmationProps> = ({
  isOpen,
  locationInfo,
  onGoBack,
  userModel,
  officeModel,
  history,
}) => {
  /* 
    Reglas para el auto-completado del formulario
    (aplica en casos de que haya aceptado geolocalización o haya seleccionado una opción del select de maps)

    - En teoría, la región nunca faltará, por lo tanto se parte haciendo find de nombre por región.
      Si hace match, la rellena, si no, termina.

    - Al haber rellenado la región, pasará a hacer find de nombre por provincia (PE o MX) o por comuna (CL)
      Si hace match, la rellena, si no, termina.

    - En el caso de PE y MX, al haber llenado la provincia, pasará a hacer find de nombre por comuna.
      Si hace match, la rellena, si no, termina.
  */

  const userCountryCode = useUserCountryCode()
  const [form, setForm] = useState<IConfirmationForm>(initialForm)
  const [additionalInfoOpen, setAdditionalInfoOpen] = useState<boolean>(false)

  const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([])
  const [regionsOptions, setRegionsOptions] = useState<string[]>([])
  const [provincesOptions, setProvincesOptions] = useState<string[]>([])
  const [communesOptions, setCommunesOptions] = useState<string[]>([])
  const [isAutocompleting, setIsAutocompleting] = useState<boolean>(true)
  const [redirectData, setRedirectData] = useState<any>(null)
  const [registrationFinished, setRegistrationFinished] = useState<any>(null)
  const [registrationFinishedSms, setRegistrationFinishedSms] = useState<any>(null)
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

  useEffect(() => {
    fetchRegions()
    fetchBusinessTypes()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsAutocompleting(true)

    if (isOpen) {
      fillForm()
    } else {
      resetForm()
    }
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBusinessTypes = async () => {
    try {
      const businessTypes: BusinessType[] = await getBusinessTypes(userCountryCode)
      setBusinessTypes(businessTypes.filter(({ active, visible }: BusinessType) => active && visible))
    } catch (error) {
      console.error(error)
      setBusinessTypes([])
    }
  }

  const fetchRegions = async () => {
    try {
      const regions = await getDepartmentsByCountryCode(userCountryCode)
      setRegionsOptions(regions as string[])
      return regions
    } catch (error) {
      setRegionsOptions([])
      console.error(error)
      return []
    }
  }

  const fetchProvinces = async (region: string) => {
    try {
      const provinces = await getProvincesByDepartmentsAndCountryCode(region, userCountryCode)
      setProvincesOptions(provinces as string[])
      return provinces
    } catch (error) {
      setProvincesOptions([])
      console.error(error)
      return []
    }
  }

  const fetchCommunesByProvince = async (province: string) => {
    try {
      const communes = await getComunesByProvincesAndCountryCode(province, userCountryCode)
      const parsedCommunes = communes?.map(({ name }: any) => name)
      setCommunesOptions(parsedCommunes as string[])
      return parsedCommunes
    } catch (error) {
      setCommunesOptions([])
      console.error(error)
      return []
    }
  }

  const fetchCommunesByRegion = async (region: string) => {
    try {
      const communes = await getComunesByDepartmentAndCountryCode(region, userCountryCode)
      const parsedCommunes = communes?.map(({ name }: any) => name)
      setCommunesOptions(parsedCommunes as string[])
      return parsedCommunes
    } catch (error) {
      setCommunesOptions([])
      console.error(error)
      return []
    }
  }

  const resetForm = () => {
    setProvincesOptions([])
    setCommunesOptions([])
    setForm(initialForm)
  }

  const fillForm = async () => {
    if (isEmpty(locationInfo)) return
    const { address, postalCode, zones } = locationInfo

    const filledForm = { ...form, address }
    if (postalCode) filledForm.postalCode = postalCode

    if (zones) {
      const { region, province, commune } = zones

      const matchedRegion = territoryNameMatching(region || '', regionsOptions)
      let matchedProvince = null
      let matchedCommune = null

      if (matchedRegion) {
        const provinces = await fetchProvinces(matchedRegion)

        if (countriesWithProvinces.includes(userCountryCode) && province) {
          matchedProvince = territoryNameMatching(province || '', provinces)

          if (matchedProvince) {
            const communes = await fetchCommunesByProvince(matchedProvince)
            matchedCommune = territoryNameMatching(commune || '', communes)
          }
        }

        if (!countriesWithProvinces.includes(userCountryCode)) {
          const communes = await fetchCommunesByRegion(matchedRegion)
          matchedCommune = territoryNameMatching(commune || '', communes)
        }
      }

      filledForm.region = matchedRegion || ''
      filledForm.province = matchedProvince || ''
      filledForm.commune = matchedCommune || ''
    }

    if (businessTypes?.length === 1) filledForm.businessType = businessTypes[0].name

    setForm(filledForm)
    setIsAutocompleting(false)
  }

  const setFormValue = (fieldName: confirmationFormField, value: string) => {
    setForm({ ...form, [fieldName]: value })
  }

  const territoryNameMatching = (selectedName: string, territories: string[]) => {
    try {
      // Search direct match
      let foundRegion = null
      foundRegion = territories.find((option: string) => option === selectedName)
      if (foundRegion) return foundRegion

      // Search match by words
      const selectedRegionParts: string[] = cleanText(selectedName).split(' ')
      const coincidencesList: number[] = []

      territories.forEach((option: string) => {
        const optionParts: string[] = cleanText(option).split(' ')
        let wordsCoincidences: number = 0

        optionParts.forEach((part: string) => {
          if (selectedRegionParts.includes(part)) wordsCoincidences++
        })

        coincidencesList.push(wordsCoincidences)
      })

      const bestCoincidenceIndex: number = coincidencesList.reduce((indexMax, coincidence, currentIndex, array) => {
        return coincidence > array[indexMax] ? currentIndex : indexMax
      }, 0)

      if (coincidencesList[bestCoincidenceIndex] === 0) return ''

      return territories[bestCoincidenceIndex] || ''
    } catch (error) {
      console.error(error)
      return ''
    }
  }

  const onTerritorySelect = (name: territoryType, value: string) => {
    if (isAutocompleting) return

    let fieldsResetted: territoryType[] = []
    let fetchFunc: Function = () => {}
    const updatedForm = { ...form }

    if (name === 'region') {
      fieldsResetted = ['province', 'commune']
      fetchFunc = countriesWithProvinces.includes(userCountryCode) ? fetchProvinces : fetchCommunesByRegion
    }
    if (name === 'province') {
      fieldsResetted = ['commune']
      fetchFunc = fetchCommunesByProvince
    }

    fieldsResetted.forEach((fieldName: territoryType) => {
      if (fieldName === 'region') setProvincesOptions([])
      if (fieldName === 'province' || fieldName === 'region') setCommunesOptions([])
      updatedForm[fieldName] = ''
    })
    fetchFunc(value)

    setForm({ ...updatedForm, [name]: value })
  }

  const validateForm = (): boolean => {
    const { businessName, businessType, region, province, commune, postalCode, address, additionalDeliveryInfo } = form

    const validations: { [key in confirmationFormField]: boolean } = {
      businessName: Boolean(businessName),
      businessType: Boolean(businessType),
      region: Boolean(region),
      province: Boolean(province),
      commune: Boolean(commune),
      postalCode: Boolean(postalCode),
      address: address?.length ? true : false,
      additionalDeliveryInfo: additionalInfoOpen ? Boolean(additionalDeliveryInfo) : true,
    }

    // validaciones que debe usar cada país
    const requiredFieldsByCountry: { [key in string]: Array<confirmationFormField> } = {
      [countryCl]: ['businessName', 'businessType', 'region', 'commune', 'address', 'additionalDeliveryInfo'],
      [countryMx]: [
        'businessName',
        'businessType',
        'region',
        'province',
        'commune',
        'postalCode',
        'address',
        'additionalDeliveryInfo',
      ],
      [countryPe]: [
        'businessName',
        'businessType',
        'region',
        'province',
        'commune',
        'address',
        'additionalDeliveryInfo',
      ],
    }

    const isValid = requiredFieldsByCountry[userCountryCode || 'CL'].every(
      (field: confirmationFormField) => validations[field],
    )

    return isValid
  }

  const submitConfirmationForm = async () => {
    setLoadingSubmit(true)

    const {
      businessName,
      businessType,
      region,
      province,
      commune,
      postalCode,
      address,
      additionalDeliveryInfo,
    }: IConfirmationForm = form
    const { latitude, longitude }: LocationInfo = locationInfo
    const businessTypeId = businessTypes.find(({ name }: BusinessType) => name === businessType)?._id || ''

    const office: OfficeRegistration = {
      casaMatriz: true,
      name: '',
      businessName: businessName,
      bussinesTypeId: businessTypeId,
      location: {
        address: address,
        postalCode,
        longitude,
        latitude,
        city: commune,
        deparment: region,
        ...(province && { province }),
        ...(additionalInfoOpen && additionalDeliveryInfo && { additionalDeliveryInfo }),
      },
    }

    const isLateRegistration = history?.location?.state?.lateRegistration

    if (isLateRegistration) {
      await officeModel.createOfficeFromLoggedIn(office)
    } else {
      const registrationRes = await officeModel.createOfficeFromRegister(office, userModel)
      if (registrationRes) setRedirectData(registrationRes)
    }

    setLoadingSubmit(false)
    const authData = getLocalStorageObject("registerSuccesfull");
    if (authData.user.smsIsValid) {
      setRegistrationFinished(true)
    }
    else {
      setRegistrationFinishedSms(true)
    }
  }

  const authAndRedirect = async () => {
    if (redirectData) {
      await userModel.authUser()
      return history.push('/home', redirectData)
    }
    window.location.href = '/home'
  }

  const confirmationSMS = () => {
    const authData = getLocalStorageObject("registerSuccesfull");
    requestSms(authData.user.phone, authData.user.username).then(smsResult => {

      delete smsResult.MessageID
      smsResult.haxSms = smsResult.hashedCode
      const phone = {"phone": true}

      history.push('/confirm-sms',
        Object.assign(authData, smsResult, phone )
      )
    })
  }

  const shouldRender = (type: string) => {
    const validations: { [key: string]: Function } = {
      province: () => countriesWithProvinces.includes(userCountryCode) && !!provincesOptions?.length,
      commune: () => !!communesOptions?.length,
    }
    return validations[type]?.()
  }

  const territoriesCountryCode = userCountryCode as supportedCountry
  const businessTypesOptions = useMemo(() => businessTypes.map(({ name }: BusinessType) => name), [businessTypes])

  if (!isOpen) return <></>

  return (
    <IonPage className="location-confirmation" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <header>
        <HeaderWithoutToolbar icon={arrowBackIcon} iconSize={'20px'} text="Confirma tu dirección" onClick={onGoBack} />
      </header>
      <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <form className="location-confirmation__form">
          <div className="location-confirmation__field-container">
            <TextInput
              onChange={(e: any) => {
                setFormValue('businessName', e.target.value)
              }}
              placeholder="Ej: Abarrotes Lucy"
              label={'Nombre del negocio'}
              isValid={true}
              error={'El nombre comercial es obligatorio'}
              value={form.businessName}
              type="text"
              toValidate={form.businessName}
              autoComplete="off"
            />
          </div>

          <div className="location-confirmation__field-container">
            <DropdownSelect
              label="Tipo de Negocio"
              isDisabled={false}
              options={businessTypesOptions}
              firstOption={'Elige el tipo de negocio'}
              defaultSelected={form.businessType}
              onSelect={(value: any) => setFormValue('businessType', value)}
            />
          </div>

          <div className="location-confirmation__field-container">
            <DropdownSelect
              label={countryTerritoryName('region', territoriesCountryCode)}
              options={regionsOptions}
              firstOption={countryTerritorySelectText('region', userCountryCode as supportedCountry)}
              isDisabled={false}
              defaultSelected={form.region}
              onSelect={(value: string) => onTerritorySelect('region', value)}
            />
          </div>

          {shouldRender('province') && (
            <div className="location-confirmation__field-container">
              <DropdownSelect
                label={countryTerritoryName('province', territoriesCountryCode)}
                options={provincesOptions}
                firstOption={countryTerritorySelectText('province', userCountryCode as supportedCountry)}
                isDisabled={false}
                defaultSelected={form.province}
                onSelect={(value: string) => onTerritorySelect('province', value)}
              />
            </div>
          )}

          {shouldRender('commune') && (
            <div className="location-confirmation__field-container">
              <DropdownSelect
                label={countryTerritoryName('commune', territoriesCountryCode)}
                options={communesOptions}
                firstOption={countryTerritorySelectText('commune', userCountryCode as supportedCountry)}
                isDisabled={false}
                defaultSelected={form.commune}
                onSelect={(value: string) => onTerritorySelect('commune', value)}
              />
            </div>
          )}

          {countriesWithPostalCode.includes(userCountryCode) && (
            <div className="container-address-items">
              <TextInput
                onChange={(e: any) => setFormValue('postalCode', e.target.value)}
                placeholder="Ej: 20997"
                label="Código Postal"
                isValid={true}
                error={'Ingresa un código postal válido (5 dígitos)'}
                value={form.postalCode}
                type="number"
                toValidate={form.postalCode}
                autoComplete="off"
              />
            </div>
          )}

          <div className="location-confirmation__field-container">
            <TextInput
              onChange={(e: any) => setFormValue('address', e.target.value)}
              placeholder="Ingresa tu dirección de despacho"
              label={'Dirección de despacho'}
              isValid={true}
              error={'El nombre comercial es obligatorio'}
              value={form.address}
              type="text"
              toValidate={form.address}
              autoComplete="off"
              informativeText="*Verifica la numeración"
            />
          </div>

          <div className="location-confirmation__field-container location-confirmation__checkbox-field">
            <Checkbox label="Agregar referencia para despacho" onChange={setAdditionalInfoOpen} />
          </div>

          {additionalInfoOpen && (
            <div className="location-confirmation__field-container">
              <textarea
                className="location-confirmation__reference-info"
                rows={4}
                value={form.additionalDeliveryInfo}
                onChange={(e: any) => setFormValue('additionalDeliveryInfo', e.target.value)}
                placeholder="Entrada frente al colegio"
              />
            </div>
          )}

          <div className="location-confirmation__submit-btn-container">
            <ButtonComponent
              text="Confirmar"
              className="btn-primary"
              disabled={!validateForm()}
              onClick={submitConfirmationForm}
            />
          </div>
        </form>
      </IonContent>

      <GuruAcceptModal
        className="btn-primary"
        textButton="Verificar"
        label="¡Bienvenido a tu cuenta!"
        secondLabel="Por seguridad verificaremos tu número telefónico"
        onClickButton={confirmationSMS}
        disabled={false}
        isOpen={registrationFinishedSms}
      />
      <GuruAcceptModal
        className="btn-primary"
        textButton="Hacer mi primer pedido"
        label="¡Estás listo!"
        secondLabel="Bienvenido a tu cuenta"
        onClickButton={authAndRedirect}
        disabled={false}
        isOpen={registrationFinished}
      />

      {loadingSubmit && <LoadingLayer />}
    </IonPage>
  )
}
