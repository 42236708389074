import React, { ReactNode, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type Props = {
  children: ReactNode
  history: any
  onRouteChange: Function
}


const AppContainer: React.FC<RouteComponentProps & Props> = ({ children, history, onRouteChange }) => {
  useEffect(() => {
    history.listen(onRouteChange)
  }, [history, onRouteChange])

  return <>{ children }</>;
};

export default withRouter(AppContainer);