import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext'
import { CouponActivated } from '../../interfaces/coupons'

function useGetCouponsActivatedByProvider(providerId: string) {
  const { couponsActivated } = useContext(AppContext)
  const [coupons, setCoupons] = useState<CouponActivated[]>([])

  useEffect(() => {
    setCoupons(couponsActivated.filter((coupon) => coupon.vendors.includes(providerId)))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return { coupons }
}

export default useGetCouponsActivatedByProvider
