import AccessView from './access/Access'
import LoginView from './login/Login'
import RegisterUserView from './register-user/RegisterUser'
import LoginConfirmSmsView from './login-conf-sms/LoginConfirmSms'
import PasswordConfirmView from './password-confirm/PasswordConfirm'
import ContactView from './contact/Contact'
import MenuView from './menu/Menu'
import ProfileView from './profile/Profile'
import HomeView from './home/Home'
import HomeBrand from './homeBrand/HomeBrand'
import HomeProviders from './homeProviers/HomeProviders'
import DeliveriesView from './deliveries/Deliveries'
import OrdersView from './orders/Orders'
import OrderView from './order/Order'
import OrderHelp from './order-help/OrderHelp'
import OrderProduct from './order/OrderProduct'
import OrderInformation from './order/OrderInformation'
import OrderDelete from './order/OrderDelete'
import CategoriesView from './categories/Categories'
import ListCategoriesView from './list-categories/ListCategories'
import PlpView from './plp/Plp'
import ResultView from './results/Result'
import CartView from './cart/Cart'
import OrderConfirmationView from './order-confirmation/OrderConfirmation'
import FAQView from './faq/Faq'
import ProviderSelection from './provider-selection/ProviderSelection'
import CouponsView from './coupons/Coupons'
import RecoveryPassword from '../pages/recovery-password/RecoveryPassword'
import RecoveryPasswordConfirm from './recovery-password-confirm/RecoveryPasswordConfirm'
import TermsConditions from './terms-conditions/TermsConditions'
import PlpViewCategories from './plp/PlpCategories'
import Logout from './logout/Logout'
import Footer from './footer/Footer'
import RegisterOptional from './register-optional/RegisterOptional'
import GuruPro from './guru-pro/GuruPro'
import ProviderCategory from './provider-category/ProviderCategory'
import TagsCategory from './tags-category/TagsCategory'
import AllCategorybyProvider from './all-categories/AllCategorybyProvider'
import Card from './cards/Card'
import SearchProductsProvider from './search-products-providers/SearchProductsProvider'
import ProductModal from '../pages/product-modal/ProductModal'
import Notifications from '../pages/notifications/Notifications'
import ValidateUser from '../pages/validate-user/ValidateUser'
import HelpForm from '../pages/help-form/HelpForm'
import RecoverAccess from '../pages/recover-access/RecoverAccess'
import ConfirmCodeSms from '../pages/confirm-code-sms/ConfirmCodeSms'
import OpenNotifications from '../pages/notifications/OpenNotifications'
import BranchOffice from '../pages/branch-office/BranchOffice'
import NoCountryDetect from '../pages/no-country-detect/NoCountryDetect'
import VendorBrand from '../pages/vendor-brand/VendorBrand'
import VendorOffers from '../pages/vendor-offers/VendorOffers'
import OfferList from '../pages/offers-list/OffersList'
import SearchProductsHomeProvider from '../pages/search-products-home-provider/SearchProductsHomeProvider'
import CartNew from './cart/CartNew'
import SurveyNps from '../components/survey-nps/SurveyNps'
import UserInvited from '../pages/user-invited/UserInvited'
import VendorTopProducts from '../pages/vendor-top-products/VendorTopProducts'
import VendorFavoritesProducts from '../pages/vendor-favorites-products/VendorFavoritesProducts'
import HashtagView from '../pages/hashtag-view/HashtagView'
import HashtagProviderView from './hashtag-provider-view/HashtagProviderView'
import VendorsProductsOffers from './vendors-products-offers/VendorsProductsOffers'
import HomeBrandDetail from './home-brand-detail/HomeBrandDetail'
import OfficeGeolocalization from './office-geolocalization/OfficeGeolocalization'
import Cart from './cart2'
import Suggested from './suggested'
import Checkout from './checkout'
import PaymentMethod from './payment-method'
import HomeFulfillment from './home-fulfillment'
import BrandsFulfillment from './brands-fulfillment'

export default {
  AccessView,
  LoginView,
  Logout,
  RegisterUserView,
  LoginConfirmSmsView,
  PasswordConfirmView,
  ContactView,
  MenuView,
  HomeView,
  DeliveriesView,
  OrdersView,
  OrderView,
  OrderHelp,
  OrderProduct,
  OrderInformation,
  OrderDelete,
  ProfileView,
  CategoriesView,
  ListCategoriesView,
  PlpView,
  ResultView,
  CartView,
  OrderConfirmationView,
  FAQView,
  ProviderSelection,
  CouponsView,
  RecoveryPassword,
  RecoveryPasswordConfirm,
  HomeBrand,
  HomeProviders,
  TermsConditions,
  PlpViewCategories,
  RegisterOptional,
  GuruPro,
  Footer,
  ProviderCategory,
  TagsCategory,
  AllCategorybyProvider,
  Card,
  SearchProductsProvider,
  ProductModal,
  Notifications,
  ValidateUser,
  HelpForm,
  RecoverAccess,
  ConfirmCodeSms,
  OpenNotifications,
  BranchOffice,
  NoCountryDetect,
  VendorBrand,
  VendorOffers,
  SearchProductsHomeProvider,
  OfferList,
  UserInvited,
  VendorTopProducts,
  VendorFavoritesProducts,
  CartNew,
  SurveyNps,
  HashtagView,
  HashtagProviderView,
  VendorsProductsOffers,
  HomeBrandDetail,
  OfficeGeolocalization,
  Suggested,
  Checkout,
  Cart,
  PaymentMethod,
  HomeFulfillment,
  BrandsFulfillment,
}
