import Axios from 'axios'
import { Provider } from '../models/ProviderModel'

interface ProviderIn {
  _id: string
  name: string
  alias:string
  rut: string
  days: any
  minimun?: any
  deliveryInfo:any
  email: string
  paymentForm?: string,
  paymentTypes?: any,
  logo: string,
  disponible: boolean
  paymentMethods?:any
  banners:any
  linegrid:number
  paymentFormDefault?:string
  aditionalInfo?:string
  isActiveInComune: boolean
  sameBussinestype: boolean
  qualification: any
}

export const fetchProviders = async (): Promise<Provider[]> => {
  try {
    const result = await Axios.get<ProviderIn[]>(`${process.env.REACT_APP_BFF_NODE}/v1/vendors`)
    return result.data.map(({ 
      _id,
      alias,
      name,
      days, 
      minimun, 
      deliveryInfo, 
      paymentForm, 
      paymentMethods, 
      logo, 
      disponible, 
      banners, 
      linegrid, 
      paymentFormDefault, 
      aditionalInfo, 
      isActiveInComune,
      sameBussinestype, 
      qualification 
    }) => {
      const deliveryDays =
        Array.isArray(days) && days.length > 0 && days[0] != null && days[0].days != null && Array.isArray(days[0].days)
          ? days[0].days
          : []
      const provider: Provider = {
        id: _id,
        name,
        alias,
        days,
        minimun,
        deliveryDays,
        deliveryInfo,
        logo,
        disponible, 
        paymentMethods,   
        banners,
        linegrid, 
        paymentForm,
        paymentFormDefault,
        aditionalInfo,
        isActiveInComune,
        sameBussinestype,
        qualification
      }
      return provider
    })
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const getProviderById = async (providerId: string) => {
  try {
    const {data} = await Axios.get(`${process.env.REACT_APP_BFF_NODE}/v1/vendors/${providerId}`)
    return data
  } catch(error) {
    console.error(error);
  }
}

export const getHome = async () => {
  try {
    const result = await Axios.get<{banners:any, vendors:ProviderIn[], steps:number, config:any}>(`${process.env.REACT_APP_BFF_NODE}/v2/home`, {
      params: {
       
      },
    })
    const parseVendors = result.data.vendors.map(({ _id, 
      alias,
      name, 
      days, 
      minimun, 
      deliveryInfo,
      paymentForm, 
      paymentMethods, 
      logo, 
      disponible, 
      banners, 
      linegrid, 
      paymentFormDefault, 
      aditionalInfo, 
      isActiveInComune,
      sameBussinestype,
      qualification }) => {
      const deliveryDays =
        Array.isArray(days) && days.length > 0 && days[0] != null && days[0].days != null && Array.isArray(days[0].days)
          ? days[0].days
          : []
      const provider: Provider = {
        id: _id,
        name,
        alias,
        days,
        minimun,
        deliveryDays,
        deliveryInfo,
        logo,
        disponible, 
        paymentMethods,   
        banners,
        linegrid, 
        paymentForm,
        paymentFormDefault,
        aditionalInfo,
        isActiveInComune,
        sameBussinestype,
        qualification
      }
      return provider
    })
    return { 
      banners:result.data.banners,
      vendors:parseVendors,
      steps:result.data.config
    }
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    return null
  }
}

export const serchProductProvider  = async (providerId: string, query: string) => {
  try {
    const result = await Axios.get<object>(`${process.env.REACT_APP_BFF_NODE}/v2/vendors/${providerId}/search`, {
      params: {
        query,
      }}
    )
    return result
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
  }
}

export const getOffersVendor = async (vendorId: string) => {
  try {
    const result = await Axios.get<object>(`${process.env.REACT_APP_BFF_NODE}/v2/vendors/${vendorId}/offers?groupBy=category`)
    return result
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
  }
}

export const getOffersProductsByVendor = async (vendorId: string, categoryName: string, page: number) => {
  try {
    const result = await Axios.get<object>(`${process.env.REACT_APP_BFF_NODE}/v2/vendors/offersByVendor/${vendorId}?category=${categoryName}&page=${page}&limit=9`)
    return result
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
  }
}

export const getProvidersRibbons = async () => {
  try {
    const result = await Axios.get<object>(`${process.env.REACT_APP_BFF_NODE}/v1/vendors/ribbons`)
    return result
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
  }
}

export const getOutstandingProductsByVendor = async (vendorId: string, categoryName: string, page: number) => {
  try {
    const result = await Axios.get<object>(`${process.env.REACT_APP_BFF_NODE}/v1/vendors/outstandingProductsByVendorAndCategory/${vendorId}?category=${categoryName}&page=${page}&limit=9`)
    return result
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
  }
}

export const categoriesOfProductsByVendor = async (vendorId: string, type: string) => {
  try {
    const result = await Axios.get<object>(`${process.env.REACT_APP_BFF_NODE}/v1/vendors/categoriesOfProductsByVendor/${vendorId}?type=${type}`)
    return result
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
  }
}

export const categoriesOfFavoriteProducts = async (providerId: string) => {
  try {
    const result = await Axios.get<object>(`${process.env.REACT_APP_BFF_NODE}/v1/products/provider/${providerId}/favourites/categories`)
    return result
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
  }
}

export const productsFavoritesByCategory = async (providerId: string, categoryName: string, page = 1) => {
  try {
    const result = await Axios.get<object>(`${process.env.REACT_APP_BFF_NODE}/v1/products/provider/${providerId}/favourites/category/${categoryName}?page=${page}&limit=9`)
    return result
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
  }
}

export const getProviderShippingCost = async (providers: string) => {
  try {
    const {data} = await Axios.get(`${process.env.REACT_APP_BFF_NODE}/v1/shippingcost?idsProviders=${providers}`)
    return data
  } catch (error) {
    return null
  }
}
