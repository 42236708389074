import React from 'react'
import './ProviderCard.scss'

interface ProviderCardProps {
    index: number
    provider: any
    imageUrl: string
    providerRibbon: any
    onClick: () => void
}
export const ProviderCard: React.FC<ProviderCardProps> = ({
    index, 
    provider, 
    imageUrl,
    providerRibbon,
    onClick
}) => {
    return (
        <div className="card-provider-wrapper" key={index}>
        <div
          className="card-provider-home"
          key={provider.id}
          onClick={onClick}
        >
          {providerRibbon
            ? <div className="ribbon-text-container" style={{ backgroundColor: providerRibbon.hexColor}}>
                <h6 className="ribbon-text">{providerRibbon.text}</h6>
              </div>
            : ""}
          <img
            className="img-provider-home"
            src={imageUrl}
            alt="Logo"
          />
        </div>
      </div>
    )
}