import React from 'react'
import {
  IonContent,
  IonPage
} from '@ionic/react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import User from '../../models/User'
import Settings from '../../models/Settings'
import Pages from './../../pages'
import { CartModel } from '../../models/CartModel'
import Office from '../../models/Office'
import contactFormModel from '../../models/ContactForm'
import termsConditionModel from '../../models/TermsConditionModel'

interface IProps {
  user: User
  settings: Settings,
  cartModel: CartModel
  offices: Office,
  contactFormModel: contactFormModel
  showTermsConditions: termsConditionModel
}

interface IState {
  loading: {
    visible: boolean
  }
}

export default class Access extends React.PureComponent<IProps, IState> {
  state: IState = {
    loading: {
      visible: false,
    }
  }

  render() {
    return (
      <BrowserRouter>
        <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonContent id="content" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <Switch>
              <Route exact path="/" render={() => <Pages.LoginView {...this.props} />} />
              <Route exact path="/login" render={() => <Redirect to="/" />} />
              {/* <Route exact path="/register-user" render={() => <Pages.RegisterUserView {...this.props} />} /> */}
              <Route exact path="/register-optional" render={() => <Pages.RegisterOptional {...this.props} />} />
              <Route exact path="/validate-user" render={() => <Pages.ValidateUser {...this.props} />} />
              <Route exact path="/help-form" render={() => <Pages.HelpForm {...this.props} />} />
              <Route exact path="/recovery-password" render={() => <Pages.RecoveryPassword {...this.props} />} />
              <Route exact path="/confirm-recovery-password" render={() => <Pages.RecoveryPasswordConfirm {...this.props} />} />
              <Route exact path="/confirm-sms" render={() => <Pages.LoginConfirmSmsView {...this.props} />} />
              <Route exact path="/recover-access" render={() => <Pages.RecoverAccess {...this.props} />} />
              <Route exact path="/confirm-code-sms" render={() => <Pages.ConfirmCodeSms {...this.props} />} />
              <Route exact path="/contact" render={() => <Pages.ContactView {...this.props} />} />
              <Route exact path="/branch-office" render={() => <Pages.BranchOffice {...this.props} />} />
              <Route exact path="/logout" render={() => <Pages.Logout {...this.props} />} />
              <Route exact path="/no-country" render={() => <Pages.NoCountryDetect {...this.props} />} />
              <Route exact path="/terms-conditions" render={() => <Pages.TermsConditions {...this.props} />} />
              <Route exact path="/validate-comune" render={() => <Pages.UserInvited {...this.props} />} />
              <Route exact path="/office-localization" render={() => <Pages.OfficeGeolocalization {...this.props} />} />
            </Switch>
          </IonContent>
        </IonPage>
      </BrowserRouter>
    )
  }
}



