import Axios from 'axios'
import { Promotion } from '../models/PromotionModel';

export const find = async (type:any, providerId?:any ) => {
  try {
    const result = await Axios.get<Promotion[]>(`${process.env.REACT_APP_BFF_NODE}/v2/promotions`, {
      params: {
        type,
        providerId:providerId && providerId,
        isAll: type === 'Marketing'
      },
    })
    return result.data
  } catch (error) {
    return null
  }
}
