import React from 'react'
import './HelpOptionsModal.scss'
import navRight from '../../assets/icons/nav_right.svg'

interface Props {
  isOpen: boolean
  title: string,
  options: any[],
  setOpen: (isOpen: boolean) => any,
  onOptionClick: (option: any) => any,
}

const HelpOptionsModal: React.FC<Props> = ({ isOpen, title, options, setOpen, onOptionClick }) => {

  if (!isOpen) return <></>

  return (
    <div className={`ds-content-page help-modal-container ${isOpen ? "open-modal" : "closed-modal"}`}>
      <div className="help-modal-background" onClick={() => setOpen(false)}></div>
      <div className="help-modal-wrapper">
        <div className="help-modal">
          {title && <h5 className="help-modal-title">{title}</h5>}
          <div>
            {Boolean(options?.length) && options.map((option, i) => (
              <div className="help-option-container" key={i} onClick={() => onOptionClick(option)}>
                <p className="paragraph-1 no-margin help-option-title">{option.title}</p>
                <div className="help-arrow-wrapper">
                  <img src={navRight} alt="arror-right"/>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HelpOptionsModal