import React, { Fragment } from 'react'
import { IonPage, IonContent, IonIcon, IonItem, IonLabel} from '@ionic/react'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import { defaults, uniqBy } from 'lodash'
import { StaticContext } from 'react-router'

import { arrowDown, arrowForward } from 'ionicons/icons'
import ToolBar from '../../components/tool-bar/ToolBar'
import FeedbackNoScope from '../../components/feedback-no-scope/FeedbackNoScope'
import feedbackNoScopeImage from '../../assets/feedback-no-scope.png'

import Category from '../../models/Category'
import { CartModel } from '../../models/CartModel'


import './ListCategories.scss'

type IPathParams = {}
interface RouteState {
  categoryId?:any
  nameCategory?:any
  providers?:any
  fromDeeplink?:any
}

type IProps = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
	categories: Category
	cartModel: CartModel
}

interface State {
  categoriesList: any
  categoriesTree: any
  loadingCategories: boolean
}

class ListCategories extends React.PureComponent<IProps, State> {
  state: State = {
    categoriesList: [],
    categoriesTree: [],
    loadingCategories: true,
  }

  mounted:Boolean = false;
  async componentDidMount() {
    const { categories,  location: { state: locationState } } = this.props

    const categoriesList = await categories.get(500, 0)
    const categoryId = locationState ? locationState.categoryId : null
    if (categoryId) {
      const categoryMatch = categoriesList.find((category:any) => category._id === categoryId)
      if(categoryMatch) this.goToCategory(categoryMatch, true)
    }

    const categoriesTree = this.buildTree(categoriesList)

    this.setState({
      categoriesList,
      categoriesTree,
      loadingCategories: false,
    })
  }

  goToCategory = async (category: any, shouldReplace: boolean = false) => {
    const { categories } = this.props
    const providers = await categories.findCategoriesAllProviders(category._id)

    const index = providers.providers.findIndex((provider:any) => provider.id === process.env.REACT_APP_DESPACHO)
    if (index > 0) {
      const temp = providers.providers[0]
      providers.providers[0] = providers.providers[index]
      providers.providers[index] = temp
    }
    if (Object.keys(providers).length > 0 ) {

      this.props.history.push(`/category/${category._id}`, {
        nameCategory: category.name, 
        providers:providers,
        fromDeeplink: shouldReplace
      })
      }
  }

  buildTree = (categories: any) => {
    const nodes: any = {}
    return categories.filter(function (category: any) {
      if (!category.name) return false
      const id = category['_id']
      const categoryId = category['categoryId'] && category['categoryId']._id
      if (!categoryId && !category.open) {
        category.open = false
      }
      nodes[id] = defaults(category, nodes[id], { children: [] })
      categoryId && (nodes[categoryId] = nodes[categoryId] || { children: [] })['children'].push(category)
      if (nodes[categoryId] && nodes[categoryId].children) {
        nodes[categoryId].children = uniqBy(nodes[categoryId].children, '_id')
      }
      return !categoryId
    })
  }

  buildNode = (category: any, level: any) => {
    const categories = category.children
    return (
      <Fragment>
        {level === 1 && (
          <div key="all" className="category">
            <IonItem placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonLabel
                onClick={(e: any) => {
                  this.goToCategory(category)
                } }
                className={`text-item padding-left-${level}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >
                Ver todo ...
              </IonLabel>
            </IonItem>
          </div>
        )}
        {categories.map((cat: any) => {
          return (
            <div key={cat._id} className="category">
              <IonItem placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonLabel
                  onClick={(e: any) => {
                    this.goToCategory(cat)
                  } }
                  className={`text-item padding-left-${level}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                >
                  {cat.name}
                </IonLabel>
                {cat.children && cat.children.length > 0 && <IonIcon icon={arrowDown} slot="end" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>}
              </IonItem>
              <div className="children">
                {cat.children && cat.children.length > 0 && this.buildNode(cat, level + 1)}
              </div>
            </div>
          )
        })}
      </Fragment>
    )
  }

  isOpenIcon = (category: any) => {
    const onClick = (e: any) => {
      this.openNode(category)
    }
    return category.open ? (
      <IonIcon onClick={onClick} icon={arrowDown} slot="end" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
    ) : (
        <IonIcon onClick={onClick} icon={arrowForward} slot="end" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
      )
  }

  openNode = (category: any) => {
    const { categoriesList } = this.state

    this.setState({
      categoriesList: categoriesList.map((cat: any) => {
        if (cat._id === category._id) {
          cat.open = !cat.open
        }
        return cat
      }),
    })
  }

  goBack = () => {
    const { history, location: { state: locationState } } = this.props
    const categoryId = locationState?.categoryId || null
    if (categoryId) {
      history.replace('/home')
      return
    }
    history.replace('/home')
  }

  render() {
    const { categoriesList, loadingCategories } = this.state
    const categories = this.buildTree(categoriesList)

    return (
      <IonPage className="orders-page-div ds-content-page" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <ToolBar
          title="Categorías"
          secondaryHeader={true}
          secondaryButtons={[
            {
              type: 'close',
              onClick: this.goBack,
            },
          ]}
          textCenter={true}
        />
        <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
					<div className='categories-list'>
            {(!loadingCategories && !categories.length) && (
              <FeedbackNoScope
                image={feedbackNoScopeImage}
                text="Lamentamos informar que actualmente no contamos con servicios en tu zona."
                callToAction="¡Pronto volveremos con novedades para ti y tu negocio!" />
            )}
						{categories.map((category: any) => {
              return (
                category.main &&
                <div
                    key={category._id}
                    className="categories-card"
                    onClick={()=>  this.goToCategory(category)}
                >
                  <div className='logo' style={{ backgroundImage: `url(${process.env.REACT_APP_BFF_IMAGE}categories/${category.logo})`}} ></div>
                  <h6 className='name-categories' >{category.name}</h6>
                </div>
              )
            })}
          </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default withRouter(ListCategories)


