import { currentApp }  from '../utils/currentCountry'
import { getLocalStorageObject } from './localstorageData'


const formatters = [
  {
    countryCode: 'CLP',
    format: (value:any) => {
      return `$${Intl.NumberFormat('de-CL', { currency: 'CLP' }).format(value).replace(/,/g, '.').replace(/CLP/g, '$')}`
    }
  },
  {
    countryCode: 'PEN',
    format: (value:any) => {
      return `${Intl.NumberFormat('en-US', {style: 'currency', currency: 'PEN' }).format(value).replace(/PEN/g, 'S/')}`
    }
  },
  {
    countryCode: 'MXP',
    format: (value:any) => {
      return Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value)
    }
  },
]


export const formatCurrency = (price: any): string =>{
  if (typeof price === 'string') price = Number.parseFloat(price)

  const currentCountryData = getLocalStorageObject('countryName') || ''
  const appCountries: any = currentApp()?.length && currentApp()

  const infoCountry: any = appCountries.find((countryInfo:any ) => countryInfo.country === currentCountryData.countryCode)
  const matchFormatter = formatters.find(format => format.countryCode === infoCountry.codeIos)

  if (matchFormatter) return matchFormatter.format(price)
  
  return price
}


const weekDayToIndex: { [key: string]: number } = {
  domingo: 0,
  lunes: 1,
  martes: 2,
  miércoles: 3,
  jueves: 4,
  viernes: 5,
  sábado: 6,
}

const weekDays = [
  { short: 'dom', long: 'domingo' },
  { short: 'lun', long: 'lunes' },
  { short: 'mar', long: 'martes' },
  { short: 'mié', long: 'miércoles' },
  { short: 'jue', long: 'jueves' },
  { short: 'vie', long: 'viernes' },
  { short: 'sáb', long: 'sábado' },
]

const months = [
  { short: 'ene', long: 'enero' },
  { short: 'feb', long: 'febrero' },
  { short: 'mar', long: 'marzo' },
  { short: 'abr', long: 'abril' },
  { short: 'may', long: 'mayo' },
  { short: 'jun', long: 'junio' },
  { short: 'jul', long: 'julio' },
  { short: 'ago', long: 'agosto' },
  { short: 'sep', long: 'septiembre' },
  { short: 'oct', long: 'octubre' },
  { short: 'nov', long: 'noviembre' },
  { short: 'dic', long: 'diciembre' },
]

export const weekDay = (index: number | string, short: boolean = false): string =>
  typeof index === 'number'
    ? short
      ? weekDays[index].short
      : weekDays[index].long
    : weekDay(weekDayToIndex[index.toLowerCase()], short)

export const month = (index: number, short: boolean = false): string =>
  short ? months[index].short : months[index].long

export const idCentralMaorista = () => '5efcda0593f1fc0022248fdf'

export const idCentralMayorista = () => '5efcda0593f1fc0022248fdf'
