import React, { useState, useEffect } from 'react'
import {
  IonModal,
  IonImg,
} from '@ionic/react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
import { StaticContext } from 'react-router'

//Components
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import AlertModal from '../../components/modals/AlertModal'

//Img
import guruDelivery from '../../assets/guru-delivery.svg'

//Clients
import { orderConfirmUser, orderNotConfirmUser, orderUpdateNotConfirmUser, sendEmailNoConfirm, find }  from '../../clients/order'

//Events
import { confirmDeliveryEvent, viewDeliveryConfirmationEvent } from '../firebase/firebaseTags'

//Utils
import { formatCurrency } from '../../utils/intl'
import { getSurveyByKey } from '../../utils/survey'

import './ConfirmOrder.scss'
import { deleteTimer } from '../../utils/timers'

interface IPathParams {}

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  updateOrders?: any
  route: string
  selectOrder?: any
  showConfirmOrder?: any
  goToOrer?: any
  shouldRender?: any
  orders?: any
  ordersConfirm?: any
  onBackdropDismiss?: Function
}

const emailInternal = process.env.REACT_APP_CONTACT_FORM_EMAIL

const ConfirmOrder: React.FC<IProps> = props => {
  const [user] = useState<any>(JSON.parse(localStorage.getItem('@user') || ''))
  const [orders, setOrders] = useState<any>([])
  const [modalsOpen, setModalsOpen] = useState<any>('')
  const [showModalNotConfirm, seShowModalNotConfirm] = useState<boolean>(false)
  const [ordersEvents] = useState<any[]>([])
  const [page ] = useState<number>(0)
  const [limit ] = useState<number>(10)
  const [buttonsLoading, setButtonsLoading] = useState<boolean>(false)
  const shouldRender = props.shouldRender !== undefined ? props.shouldRender : true

  useEffect(() => {
    get()
  }, [props.ordersConfirm]) // eslint-disable-line react-hooks/exhaustive-deps

  const get = async () => {
    routeHome()
    routeOrders()
  }

  const routeHome = async () =>{
    const { route } = props

    if(route === 'home'){
      const orders = props.ordersConfirm
      const isHomeDeliverableDone = orders.filter((item:any) => !item.isHomeDeliverableDone)
      saveStatus(isHomeDeliverableDone)
    }
  }

  const routeOrders = async () =>{
    const { route, selectOrder } = props

    if(route === 'order'){
      saveStatus([selectOrder])
    }
  }

  const saveStatus = (orders:any) => {
    setOrders(orders)
    setModalsOpen(orders.length -1)
  }

  const confirmOrder = async (order: any) => {
    deleteTimer('confirmationOrders')
    setButtonsLoading(true)
    const { history } = props
    const { _id, provider, providerId, finalPrice, contact } = order
    const vendorId = provider?._id || providerId?._id
    const { comment } = contact
    confirmDeliveryEvent(vendorId, finalPrice, 'si', comment )
    await orderConfirmUser(_id)
    const updatedOrder = await props.orders.findOne(_id)
    let confirmedOrder = order
    if(updatedOrder) confirmedOrder = updatedOrder
    
    if(props.updateOrders) props.updateOrders()

    const orders = localStorage.getItem('ordersConfirm')
    const ordersConfirm = orders ? JSON.parse(orders) : ''
    if(ordersConfirm) localStorage.setItem("ordersConfirm",  JSON.stringify(ordersConfirm-1))
    
    const surveyData = getSurveyByKey('nps-survey')
    if(surveyData) {
      history.push('/survey-nps', {
        targetType: 'order',
        targetId: _id,
        key: surveyData.value,
        vendorId,
        closeRedirectLink: "/order",
        closeLinkLocationState: confirmedOrder
      })
    }
    closeModal()
  }

  const notConfirmOrder = async (order:any) => {
    deleteTimer('confirmationOrders')
    setButtonsLoading(true)
    const { _id, provider, finalPrice, providerId, contact } = order
    const vendorId = provider?._id || providerId?._id
    const { comment } = contact
    confirmDeliveryEvent(vendorId, finalPrice, 'no', comment)
    notConfirmAOrder(_id, order)
  }

  const notConfirmAOrder = async (id: string, order:any) =>{ 
    const { route, goToOrer, selectOrder } = props

    await orderNotConfirmUser(id)
    seShowModalNotConfirm(true)
    await orderUpdateNotConfirmUser(id)

    if(route === 'home') await sendEmailNoConfirm(user, order, emailInternal)
    
    if(route === 'order') {
      goToOrer && goToOrer(selectOrder)
      await sendEmailNoConfirm(user, selectOrder, emailInternal)
    }
  }

  const closeModal = () => {
    const { showConfirmOrder } = props
    setModalsOpen(modalsOpen -1)
    if(showConfirmOrder) showConfirmOrder()
  }

  const renderToModal = () => {
    if(!shouldRender) return <></>
    return (
      orders.map((order:any, index:number) =>{
        const date = (dateOrderPlanned(order.status)[dateOrderPlanned(order.status).length - 1]).estimatedDate ||
                     (dateOrderPlanned(order.status)[dateOrderPlanned(order.status).length - 1]).finishDate
        const isOpen = index === modalsOpen 
        if (isOpen && !ordersEvents.includes(order._id))  {
          ordersEvents.push(order._id)
          const vendorId = order.providerId._id
          viewDeliveryConfirmationEvent(vendorId)
        }
        const openModal = isOpen && showModalNotConfirm === false
        return(
          <IonModal
            isOpen={openModal}
            cssClass='modal-confirm-order ds-content-page'
            key={order._id}
            onDidDismiss={(e: any) => {
              closeModal()
              if (e?.detail?.role === 'backdrop') props.onBackdropDismiss?.();
            }}
          >
            <div className='modal-content'>
              <IonImg className={'modal-inf-image'} src={guruDelivery} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              <h4 className={'conf-modal-title'}>Orden {order.orderNumber}</h4>
              <div className='paragraph-1'>
                ¿Llegó tu pedido de {order?.provider?.alias || order?.providerId?.alias}?
                Planificado para el {moment.utc(new Date(date)).format('DD/MM/YYYY')}<br />
                {order?.totalAmount ? `por el monto total de ${formatCurrency(order.totalAmount)}` : ""}
              </div>
            </div>

            <div className='modal-footer'>
              <div className="modal-conf-btn-wrapper">
                <ButtonComponent 
                  className={`btn-primary conf-modal-btn`}
                  onClick={() => confirmOrder(order)}
                  text="Sí"
                />
              </div>  
              <div className="modal-conf-btn-wrapper">
                <ButtonComponent 
                  className={`btn-secondary conf-modal-btn`}
                  onClick={() => notConfirmOrder(order)}
                  text="No"
                />
              </div>
            </div>
          </IonModal>
        )
      })
    )
  }

  const modalNotConfirm = () => {
    return (
      <AlertModal 
        label={'Gracias'}
        text={"Lamentamos el retraso en el tiempo de entrega. Nos contactaremos con el proveedor."}
        buttonText={'Ok'}
        isOpen={showModalNotConfirm}
        buttonAction={() => closeModalNotConfirm()}
        onDismiss={() => closeModalNotConfirm()}
        buttonMargin="0px"
      />  
    )
  }
  
  const closeModalNotConfirm = async () => seShowModalNotConfirm(false)

  const dateOrderPlanned = (date:any) => date.filter((date:any) => date.name === "Planificado")

  return (
    <div className="ds-content-page confirmation-modal-container">
      {renderToModal()}
      {modalNotConfirm()}
    </div>
  )
}

export default withRouter(ConfirmOrder)
