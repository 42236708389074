import { CouponActivated } from '../../interfaces/coupons'

export interface CouponActivatedInitialState {
  couponsActivated: CouponActivated[]
  handlerCouponsActivated: (couponsActivated: CouponActivated[]) => void
}

export const couponsActivatedInitialState: CouponActivatedInitialState = {
  couponsActivated: [],
  handlerCouponsActivated: () => {},
}
