import { eventForCheckout, fbPurchaseEvent } from "../pages/firebase/firebaseTags"

export const ordersPaidEvents = () => {
  const eventsOrdersString = localStorage.getItem('eventsOrders')

  if (eventsOrdersString) {
    const eventsOrders = JSON.parse(eventsOrdersString)

    eventsOrders.forEach((eventOrderObj :any) => {
      const { eventName, totalValue, groupedProducts, activeCoupons, paymentMethod, comments, currentVendor, userOrdersQuantity } = eventOrderObj
      eventForCheckout(eventName, totalValue, groupedProducts, activeCoupons, paymentMethod, comments, currentVendor)
      if(!userOrdersQuantity) eventForCheckout(eventName, totalValue, groupedProducts, activeCoupons, paymentMethod, comments, currentVendor)

      fbPurchaseEvent(totalValue)
    })

    localStorage.removeItem('eventsOrders')
  }
}