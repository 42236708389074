import React, { useState } from 'react'
import { IonSlide, IonModal, IonIcon } from '@ionic/react'
import { close } from 'ionicons/icons'
import { Promotion } from '../../models/PromotionModel'
import { Product } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'
import { totalPrice } from '../../clients/product'
import GlobalComponents from './../../components'
import VisibilityTracker from '../../hoc/VisibilityTracker/VisibilityTracker'
import './PromotionCard.scss'

interface PromotionCardProps {
  promotion: Promotion
  cartModel: CartModel
  index: number
  typeBanners: string
  validateDeeplinks?: () => void
  onImpression: () => void
  onPromotionClick?: () => void
}

export const PromotionCard: React.FC<PromotionCardProps> = ({
  promotion,
  cartModel,
  index,
  typeBanners,
  onImpression = undefined,
  onPromotionClick = undefined,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [product, setProduct] = useState<Product | 'None yet'>('None yet')
  const [html, setHtml] = useState<string | null>(null)
  const [codeHtml, setCodeHtml] = useState<string | null>(null)
  const [user, setUser] = useState<string>('')
  const [providerName, setProviderName] = useState<string>(promotion.providerName || '')

  // useEffect(() => {
  //   const fetchProductAndSetup = async () => {
  //     if (promotion._id === 'none') return

  //     if (promotion.product) {
  //       setProduct(promotion.product)
  //     } else {
  //       const fetchedProduct = await productModel.getByProviderAndSku(promotion.providerId, promotion.sku)
  //       if (fetchedProduct) {
  //         setProduct({ ...fetchedProduct, discount: fetchedProduct.price - promotion.price })
  //       }
  //     }

  //     if (!promotion.providerName) {
  //       setProviderName(getProviderName(promotion.providerId))
  //     }

  //     const userFromStorage = localStorage.getItem('@user')
  //     const userId = userFromStorage ? JSON.parse(userFromStorage).id : null
  //     setUser(userId)
  //   }

  //   fetchProductAndSetup()
  // }, [promotion, productModel])

  // const getProviderName = (providerId: string) => {
  //   const providersLocal = localStorage.getItem('@providers')
  //   if (providersLocal) {
  //     const providers = JSON.parse(providersLocal)
  //     const providersValues: any = Object.values(providers)
  //     return providersValues.find(({ id }: any) => id === providerId)?.name || ''
  //   }
  //   return ''
  // }

  // const onPromotionClick = async () => {
  //   const bannerAction = 'banner_click'
  //   const { _id, providerId, name, deeplink } = promotion

  //   if (deeplink) {
  //     registerDeeplinkEvent(deeplink)
  //   }

  //   await trackBannersActions(bannerAction, typeBanners, providerName, _id, 'home', name)
  //   await counterEvent('panel-principal', promotion.type || '', user, _id, 'click')

  //   manageBannerClick(promotion, history, validateDeeplinks)
  // }

  const acceptModal = async (product: Product, quantity: number) => {
    const { sku, providerId, units, packageType } = product
    cartModel.setProduct({
      sku,
      providerId,
      price: (await totalPrice(product.id, quantity)).price,
      quantity,
      units,
      packageType,
      infoPrice: await totalPrice(product.id, quantity),
    })
    setShowToast(true)
    setIsOpen(false)
  }

  const dismissModal = () => {
    setTimeout(() => {
      setIsOpen(false)
    }, 1000)
  }

  const renderCodeHtml = () => {
    return (
      <IonModal cssClass="code-html-modal-dialog" backdropDismiss={false} isOpen={!!html} {...{} as any}>
        <div className="content">
          <div className="header">
            <IonIcon
              icon={close}
              onClick={() => {
                setHtml(null)
                setCodeHtml(null)
              }}
              {...{} as any}
            />
          </div>
          <div className="body">
            <div dangerouslySetInnerHTML={{ __html: codeHtml || '' }} />
          </div>
        </div>
      </IonModal>
    )
  }

  const renderModal = (product: Product) => {
    return (
      <GlobalComponents.ProductModal
        isOpen={isOpen}
        isNewProduct
        onAccept={acceptModal}
        onDismiss={dismissModal}
        product={product}
      />
    )
  }

  const promotionImage = (image: Promotion['image']) => {
    if (image && image.local) {
      return `/${image.local}/${image.url}`
    }
    const { url } = image || { url: '' }
    return `${process.env.REACT_APP_BFF_IMAGE}${url}`
  }

  // If functions are not defined, nothing happens...
  const onImpressionHandler = onImpression ?? (() => {})
  const onClickHandler = onPromotionClick ?? (() => {})

  return (
    <VisibilityTracker className="visibility-tracker-promotion-card" onVisible={onImpressionHandler} threshold={0.5}>
      <IonSlide
        key={promotion._id}
        data-banner-type={typeBanners}
        data-provider-name={providerName}
        data-provider-id={promotion._id}
        data-banner-name={promotion.name}
        className={`slide-${index} banner-slide-home banner-type-${`${typeBanners}`.toLowerCase()}`}
        {...{} as any}
      >
        {product !== 'None yet' && renderModal(product)}
        {html && renderCodeHtml()}
        <img
          src={promotionImage(promotion.image)}
          onClick={onClickHandler}
          alt=""
          className="promotion-img-home"
          style={{ borderRadius: '5px' }}
        />
      </IonSlide>
    </VisibilityTracker>
  )
}
