import React, { useRef, useEffect, useState } from 'react'

/**
 * Custom hook to track the visibility of an element using Intersection Observer API.
 *
 * @param onVisible - Function to call when the element becomes visible.
 * @param threshold - Percentage of the element's visibility required to trigger the onVisible callback (default is 0.5).
 * @returns A ref to be attached to the element to be observed.
 */

// Hook personalizado para manejar la visibilidad
export const useVisibilityTracker = (onVisible: () => void, threshold = 0.5) => {
  const ref = useRef<HTMLDivElement>(null)
  const [wasVisible, setWasVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !wasVisible) {
          onVisible()
          setWasVisible(true)
        }
      },
      { threshold },
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [onVisible, threshold, wasVisible])

  return ref
}
