import React from 'react'

import './LoadingLayer.scss'

interface Props {}

const LoadingLayer: React.FC<Props> = () => (
  <div className="loading-layer">
    <div className="loading-layer__animated-circle"/>
  </div>
);

export default LoadingLayer;
