import React, { useEffect } from 'react'
import { getLocalStorageObject } from '../../utils/localstorageData'
import { eventNotificationView } from '../firebase/firebaseTags'

interface Props {
  subject?: string
  title?: string
  body?: string
  notifDate?: string
  isOpened?: boolean
  imgSrc?: any
  thumbnail?: any
  subtitleCoupon?: string
  handleClick: () => void
}

function CardNotification({
  subject,
  body,
  title,
  notifDate,
  isOpened,
  imgSrc,
  thumbnail,
  handleClick,
  subtitleCoupon,
}: Props) {
  useEffect(() => {
    const { name } = getLocalStorageObject('@user')
    const timestamp = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`
    const { location } = getLocalStorageObject('currentOffice')

    eventNotificationView(subject!, name, timestamp, location?.city)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="page-card">
      <div className={`container-card-notification ${subtitleCoupon && 'container-coupon'}`} onClick={handleClick}>
        {(subject === 'Actualización' || subject === 'Noticia' || thumbnail || subject === 'Cupón devuelto') && (
          <div className="img-wrapper">
            <img src={imgSrc} alt="imagen-notificacion" style={{ width: '100%' }} />
          </div>
        )}
        <div className="info-wrapper">
          <div className="titulo-notif-card">{title}</div>
          {subject === 'Cupón devuelto' && <span className="code-subtitle">{subtitleCoupon}</span>}
          <div className={`${subtitleCoupon ? 'code' : 'texto'}`}>
            <span>{body}</span>
          </div>
          <div className="notif-card-footer">
            <div className="notif-card-date">
              <span>{notifDate}</span>
              {!isOpened && <div className="circle-is-open"></div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardNotification
