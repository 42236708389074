import { IonSlide, IonSlides } from '@ionic/react'
import React from 'react'
import BrandIcon from '../../../../components/brand-icon/BrandIcon'
import { REACT_APP_BFF_IMAGE } from '../../../../config'
import { useHistory } from 'react-router-dom'
import './index.scss'

interface Brand {
  _id: string
  name: string
  logo: string
}

const brands: Brand[] = [
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },

  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
]

function BrandsHomeFulfillment() {
  const history = useHistory()

  return (
    <div className="brands-home-fulfillment">
      <div className="slides">
        <IonSlides pager={false} scrollbar={false} options={{ autoHeigth: true, slidesPerView: 'auto' }} {...{} as any}>
          {brands.map((brand, index) => (
            <IonSlide key={index} className="slide-home-brand" {...{} as any}>
              <BrandIcon urlLogo={`${REACT_APP_BFF_IMAGE}brands/${brand.logo}`} handleAction={() => {}} />
            </IonSlide>
          ))}
        </IonSlides>
      </div>

      <span className="all-brands" onClick={() => history.push('/brands-fulfillment')}>
        Todas las marcas
      </span>
    </div>
  )
}

export default BrandsHomeFulfillment
