import { Container } from 'unstated-typescript'
import { produce } from 'immer'
import { find } from '../clients/promotions'
import { getFromCache } from '../utils/cache'
import { Product } from './ProductModel'

export interface Promotion {
  _id: string
  image: { url: string; width: number; height: number; local?: string }
  name: string
  price: number
  sku: string
  weight: number
  coupons?: boolean
  provider?: boolean
  html?: boolean
  codeHtml?: string
  publicity?: boolean
  providerId?: string
  type?: string
  deeplink?: string
  vendor?: any
  tag?: string
  providerName?: string
  product?: Product | null
}

interface PromotionState {
  promotions: Promotion[]
}

const storagePromotionsName = '@promotions'

export class PromotionModel extends Container<PromotionState> {
  state: PromotionState = {
    promotions: [],
  }

  constructor() {
    super()
    const promotions = getFromCache<Promotion[]>(storagePromotionsName)
    if (promotions) {
      this.setState(
        produce(this.state, (draft) => {
          draft.promotions = promotions
        }),
      )
    }
  }

  async get(bannerType: any, providerId?: any) {
    const promotions = await find(bannerType, providerId)
    if (promotions) {
      await this.setState(
        produce(this.state, (draft) => {
          draft.promotions = promotions
        }),
      )
      localStorage.setItem(storagePromotionsName, JSON.stringify(promotions))
      return promotions
    }
    return []
  }
}
