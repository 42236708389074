import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext'
import { CouponActivated } from '../../interfaces/coupons'

function useGetAvailableCoupons(coupons: CouponActivated[], providerId: string) {
  const { cartData } = useContext(AppContext)
  const [availabledCoupons, setAvailabledCoupons] = useState<number>(0)

  useEffect(() => {
    let cont: number = coupons.length

    coupons.forEach((coupon) => {
      cartData.discounts.find(
        (discount) => discount.couponId === coupon.couponId && discount.providerId === providerId,
      ) && cont--
    })

    setAvailabledCoupons(cont)
  }, [cartData.discounts, coupons, providerId])

  return { availabledCoupons }
}

export default useGetAvailableCoupons
