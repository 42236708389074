import { CartData } from '../../interfaces/carts'

export interface CartInitialState {
  cartData: CartData
  handlerCartData: (cartData: CartData) => void
}

export const cartInitialState: CartInitialState = {
  cartData: {
    _id: '',
    data: [],
    discounts: [],
  },
  handlerCartData(cartData) {},
}
