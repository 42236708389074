import Axios from 'axios'



export const getCartId = async (): Promise<any> => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v2/carts/self`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const addOrEditProductCart = async (cartId:string, productsId: string, quantity:number ) : Promise<any> => {
  try {
    const result = await Axios.put<any>(`${process.env.REACT_APP_BFF_NODE}/v2/carts/${cartId}/products/${productsId}?quantity=${quantity}`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}


export const getProductCart = async (cartId:string) : Promise<any> => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v2/carts/${cartId}`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const deletCart = async (cartId:string) : Promise<any> => {
  try {
    const result = await Axios.delete<any>(`${process.env.REACT_APP_BFF_NODE}/v2/carts/${cartId}`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}


export const deleteProductFromCart = async (cartId:string, productsId:string ) : Promise<any> => {
  try {
    const result = await Axios.delete<any>(`${process.env.REACT_APP_BFF_NODE}/v2/carts/${cartId}/products/${productsId}`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const changePaymentMethod = async (cartId:string, providerId:string, paymentMethod: string ) : Promise<any> => {
  try {
    const result = await Axios.put<any>(`${process.env.REACT_APP_BFF_NODE}/v2/carts/${cartId}/providers/${providerId}`,{
      "paymentForm": paymentMethod
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}
