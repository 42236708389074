import React, { useState, useEffect } from 'react'
import {
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonList
} from '@ionic/react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router'

//UTILS
import { formatCurrency } from '../../utils/intl'

//MODELS
import User from '../../models/User'
import { CartModel  } from '../../models/CartModel'
import Office from '../../models/Office'
import { ProviderModel } from '../../models/ProviderModel'

//IMG
import photoVisa from './../../assets/visa_x2.png'
import photoMasterCard from './../../assets/mastercard.png'
import photoBancoEstado from './../../assets/bancoestado_x3.png'
import photeOneClick from './../../assets/oneclick_x3.png'
import photeWebPay from './../../assets/webpay_x3.png'

//COMPONENTS

import './Payment.scss'



interface IPathParams {}

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  readonly user: User
  readonly offices: Office
  readonly cartModel: CartModel
  readonly providerModel: ProviderModel
  showPaymentTypeCards?: any[]
  selectedPayment: any
  oneClickCards?: any[]
  bancoEstadoCards?: any[]
  isWebModalCards: boolean
  selectPaymentRadio: any
  shippingCost: number
}


const PaymentCards: React.FC<IProps> = props => {
  const [selectedPayment, setSelectedPayment] = useState<string>('')
  const [promotions, setPromotions] = useState<any[]>([])
  const [prePayments, setPrePayments] = useState<any[]>([])
  const [, setCardOneClickModal] = useState<any[]>([])
  const [userInfo] = useState<any>(JSON.parse(localStorage.getItem('@user') || ''))
  const [totalCart] = useState<any>(JSON.parse(localStorage.getItem('totalPre') || ''))
  const [selectPaymentRadio, setSelectPaymentRadio] = useState<string>('')

  useEffect(() => {
      get()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const get = async () => {
    let prePayments:any
    let promotions:any

    if(!props.isWebModalCards){
      prePayments = props.showPaymentTypeCards && props.showPaymentTypeCards.filter((item:any) => (item.type === 'pre-pago' && !item.promotion ))
      promotions= props.showPaymentTypeCards && props.showPaymentTypeCards.filter((item:any) => item.promotion)
    } else {
      prePayments = props.showPaymentTypeCards && props.showPaymentTypeCards.filter((item:any) => !item.promotion && item.type === 'pre-pago' && item.name !== "Banco Estado")
      promotions= props.showPaymentTypeCards && props.showPaymentTypeCards.filter((item:any) => item.promotion && item.name !== "Banco Estado")
    }

    setPromotions(promotions)
    setPrePayments(prePayments)
    props.oneClickCards && setCardOneClickModal(props.oneClickCards)

    setSelectPaymentRadio(props.selectPaymentRadio)
    props.selectedPayment(props.selectPaymentRadio)
  }

  const setSelected = (event:any) =>{
    setSelectedPayment(event)
    props.selectedPayment(event)
    setSelectPaymentRadio('')
  }

  const toRenderPrePayment = (prePayment:any, index?: number) =>{
    return(
      <div className="container-payment-cards" key={index}>
        <IonList placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonRadioGroup 
            value={selectedPayment || selectPaymentRadio}
            onIonChange={e => setSelected(e.detail.value)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
              <div>
                <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{paymentMethodName(prePayment.name)}</IonLabel>
                <IonRadio mode="md" slot="start" value={valueToRenderPrePayment(prePayment.name)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </div>
            </IonRadioGroup>
        </IonList>
    </div>
    )
  }

  const paymentMethodName = (promotionName:string) => {
    const paymentNames = [
      { key: "Webpay", name: promotionName },
      { key: "IziPay", name: promotionName },
      { key: "OneClick", name: 'Agrega tu tarjeta con OneClick' },
      { key: "Banco Estado", name: 'Agrega tu cuenta Banco Estado' },
      { key: "EtPay", name: 'Transferencia Bancaria'},
    ]

    return paymentNames.find(({key}: any) => key === promotionName)?.name
  }

  const valueToRenderPrePayment = (value:string) => {
    const paymentValues = [
      { key: 'IziPay', name: 'iziPay' },
      { key: "Webpay", name: 'webPay' },
      { key: "OneClick", name: 'addCard' },
      { key: "Banco Estado", name: 'enrollBech' },
      { key: "EtPay", name: 'etPay' },
    ]
    return paymentValues.find(({key}: any) => key === value)?.name
  }

  const toRenderOneClick = (oneClick:any, promotion?:any, index?: number) => {
    return(
        <div key={index}>
          <div className="container-payment-cards" >
            <IonList placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonRadioGroup 
              value={selectedPayment || selectPaymentRadio}
              onIonChange={e => setSelected(e.detail.value)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >
                <div className="card-payment">
                  <img className="img-of-cards" src={oneClick.creditCardType === 'Visa' ? photoVisa : photoMasterCard} alt="cupones" />
                  <div className="cards">
                    <div className="container-info-card">
                      <div className="last-four-digits">**** **** **** {(oneClick.last4CardDigits).replaceAll('X', '')}</div> 
                      <div className="name-associate-with-card">{getUserShortName()}</div>
                    </div>
                    <div className="container-icon-trash">
                      <IonRadio mode="md" slot="start" value={"2-" + oneClick._id} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    </div>
                  </div>
                </div>
                </IonRadioGroup>
            </IonList>
           {validatePromotions() && promotion && infoPromotion(promotion.promotion)} 
        </div>
        
      </div>
    )
  }

  const toRenderBancoEstado = (bancoEstado:any, promotion?:any, index?: number) => {
    return(
      <div key={index}>
        <div className="container-payment-cards">
          <IonList placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonRadioGroup 
              value={selectedPayment || selectPaymentRadio}
              onIonChange={e => setSelected(e.detail.value)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
                <div className="card-payment">
                  <img className="img-of-cards" src={photoBancoEstado} alt="cupones" />
                  <div className="cards">
                    <div className="container-info-card">
                      <div className="last-four-digits">**** **** **** {bancoEstado.data.descStatusEnrolamiento.idCuenta}</div> 
                      <div className="name-associate-with-card">{bancoEstado.data.descStatusEnrolamiento.tipoCuenta}</div>
                    </div>
                    <div className="container-icon-trash">
                      <IonRadio mode="md" slot="start" value={"1-" + bancoEstado._id} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    </div>
                  </div>
                </div>
              </IonRadioGroup>
            </IonList>
           {validatePromotions() && promotion && infoPromotion(promotion.promotion)} 
        </div>
      </div>
     )
  }

  const borderColorSelectPaymentMethod = (promotion:any) => {
    const valueArray = selectedPayment.split("-")
    const valuesSlectPaymentRadio = selectPaymentRadio.split("-")

    if((selectedPayment === 'webPay' || selectPaymentRadio === 'webPay' ) && promotion.name === "Webpay") return true
    if((selectedPayment === 'enrollBech' || selectPaymentRadio === 'enrollBech') && promotion.name === "Banco Estado") return true
    if((selectedPayment === 'addCard' || selectPaymentRadio === 'addCard') && promotion.name === "OneClick") return true
    if(valueArray[1] === promotion._id || valuesSlectPaymentRadio[1] === promotion._id) return true
  }

  const toRenderWebpayPromo = (promotion:any, imgMethodName:any, index: number) => {
    return(
        <div key={promotion.name} >
          <div className="container-payment-cards" >
            <IonList placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonRadioGroup 
              value={selectedPayment || selectPaymentRadio}
              onIonChange={e => setSelected(e.detail.value)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >
                  <div className="card-payment">
                    <img className="img-of-cards" src={imgMethodName} alt="cupones" />
                    <div className="cards">
                      <div className="container-info-card">
                        <div className="card-name-oatment" >{paymentMethodName(promotion.name)}</div>
                      </div>
                      <div className="container-icon-trash">
                        <IonRadio mode="md" slot="start" value={valueToRenderPrePayment(promotion.name)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </div>
                    </div>
                  </div>
              </IonRadioGroup>
          </IonList>
           {validatePromotions() && promotion && infoPromotion(promotion.promotion)} 
        </div>
      </div>
    )
  }

  const getUserShortName = () => {
    const nameArray = userInfo.name.split(' ')
    return `${nameArray[0]} ${nameArray[1]}`
  }

  const infoPromotion = (promotion:any) => {
    const toPay = formatCurrency((totalCart - promotion.discountAmount) + props.shippingCost)
    return(
        <div className="container-info">
          <div className="info-promotion">
            <h5 className="save-money">Ahorra {formatCurrency(promotion.discountAmount)} </h5>
            <h5 className="total">Total: {toPay}</h5>
          </div>
          <div className="info-extra paragraph-3">
            {promotion.dispMsg}
          </div>
        </div>
      )
  }

  const promotionsRender = () => {
    return(
      promotions.map((promotion:any, index: number)=>{
        let cards:any = []
      
        if(promotion.name === 'OneClick'){
          if(props.oneClickCards && props.oneClickCards.length){
            cards = cards.concat(props.oneClickCards.map((card:any) => toRenderOneClick(card, promotion, index)))
          }
          cards = cards.concat([toRenderWebpayPromo(promotion, photeOneClick, index)]) 
        }

        if(promotion.name === 'Banco Estado'){
          if(props.bancoEstadoCards && props.bancoEstadoCards.length && !props.isWebModalCards){
            cards = cards.concat(props.bancoEstadoCards.map((card:any) => toRenderBancoEstado(card, promotion, index)))
          }
          cards = cards.concat([toRenderWebpayPromo(promotion, photoBancoEstado, index)]) 
        } 

        if(promotion.name === 'Webpay' || promotion.name === 'IziPay') cards = cards.concat([toRenderWebpayPromo(promotion, photeWebPay, index)]) 
       
        return cards
      })
    )
  }

  const prePaymentsRender = () => {
    return(
      prePayments.map((prePayment:any, index: number)=>{
        let cards:any = []
       
        if(prePayment.name === 'OneClick' ){
          if(props.oneClickCards && props.oneClickCards.length && !prePayment.promotion){
            cards = cards.concat(props.oneClickCards.map((card:any) => toRenderOneClick(card, null, index))) 
          } 
          cards = cards.concat([toRenderPrePayment(prePayment, index)]) 
        }

        if(prePayment.name === 'Banco Estado'){
          if(props.bancoEstadoCards && props.bancoEstadoCards.length && !prePayment.promotion && !props.isWebModalCards ){
            cards = cards.concat(props.bancoEstadoCards.map((card:any) => toRenderBancoEstado(card, null, index))) 
          } 
          cards = cards.concat([toRenderPrePayment(prePayment, index)]) 
        }

        if(prePayment.name !== 'Banco Estado' && prePayment.name !== 'OneClick') {
          cards = cards.concat([toRenderPrePayment(prePayment, index)])
        }

        return cards
      })
    )
  }

  const validatePromotions = () => promotions && promotions.length > 0

  const validatePrePayments = () => prePayments && prePayments.length > 0

  const textTitle = () => validatePromotions() ? "Cualquier otro método" : "Elige tu método de pago"

  const toPay = totalCart + props.shippingCost

  return (
    <div className="ds-content-page"> 
      {validatePromotions()  && <h5 className="title-save">Paga menos de {formatCurrency(toPay)} con </h5>}
      {promotionsRender()}
      {validatePrePayments() &&
        <div>
          <div className="container-other-payment-method"> 
          <h5>{textTitle()}</h5>
          <h5>{formatCurrency(toPay)}</h5>
        </div>
        </div>
      }
      {prePaymentsRender()}
    </div>
  )
}

export default withRouter(PaymentCards)
