import React, { useEffect, useState } from 'react'
import ErrorList from '../input-form/ErrorList'
import GuruIcon from 'guru-react-icons'
import Select from 'react-select';
import './SearchInput.scss'

interface SearchInputProps {
  iconValid?: string;
  iconInvalid?: string;
  iconDefault?: string;
  isValid?: boolean;
  placeholder?: string;
  label?: string;
  error?: string;
  onChange: any;
  value: any;
  toValidate?: string | number | undefined;
  maxLength?: number
  type: string
  autoComplete?: string
  required?: boolean
  options: any[]
  isSearchable?: boolean
  arrow?: boolean
  defaultValue?: any
  isDisabled?: boolean
}

const SearchInput: React.FC<SearchInputProps> = props => {
  const {
    isValid,
    label, 
    error, 
    onChange, 
    iconValid,
    iconDefault,
    iconInvalid,
    value, 
    toValidate,
    options,
    placeholder,
    isSearchable,
    arrow,
    defaultValue,
    isDisabled
  } = props

  const [selectedValue, setSelectedValue] = useState<any>('')
  
  useEffect(() => {
    if (defaultValue) return setSelectedValue(defaultValue)    
    if (value) return setSelectedValue(value)
  }, [])

  const inputValidation = (isValid:boolean | undefined, value:string | number |undefined, toValidate:string | number | undefined) => {
    if(toValidate === "") return 'valid'
    if(value && isValid) return 'valid'
    return 'invalid'
  }
  
  const inputIconValidation = (isValid:boolean | undefined, value:string | number | undefined, toValidate: string | number | undefined, arrow?: boolean) => {
    if(toValidate === "") return ""
    if(value && isValid && !arrow) return "icn_check-blue"
    if(arrow) return ""
    return "icn_alert"
  }
  
  const validationsGenerator = (iconDefault:string, iconValid:string, iconInvalid:string) => {
    return [
      {
        isValid: (value:string | number | undefined, isValid:boolean) => value && !isValid,
        value: iconInvalid
      },
      {
        isValid: (value:string | number | undefined, isValid:boolean) => value && isValid,
        value: iconValid
      },
      {
        isValid: (value:string | number | undefined, isValid:boolean) => !value && !isValid,
        value: iconValid
      }
    ]
  }
  
  const getIconValue = (toValidate:string | number | undefined, isValid:boolean | undefined, iconDefault:string | undefined, iconValid:string | undefined, iconInvalid:string | undefined) => {
  
    const validations = validationsGenerator(iconDefault || '', iconValid || '', iconInvalid || '') 
    for (let i = 0; i < validations.length; i++) {
      if (validations[i].isValid(toValidate, isValid || false)) {
        return validations[i].value
      }
    }
  }
  
  const onChangeValue = (selectedOption:any) => {
    setSelectedValue(selectedOption)
    onChange(selectedOption)
  }
 

  return (
    <div className="container-input-search-component">
      <div className="container-label-icon">
        {(iconDefault || iconInvalid || iconValid) &&
          <div className="icon-label"> 
            <GuruIcon 
              nameIcon={
                getIconValue(
                  toValidate,
                  isValid,
                  iconDefault, 
                  iconValid, 
                  iconInvalid
                )
              }
            />   
          </div>
        }
        <label className={`${inputValidation(isValid, value, toValidate)}`}>
          {label}
        </label>
      </div>
      <div className={`container-input-search ${!isValid ? 'error' : ''}`}>
        <Select 
          noOptionsMessage={() => 'Sin resultados'}
          onChange={onChangeValue}
          options={options}
          value={selectedValue}
          placeholder={placeholder}
          isSearchable={isSearchable}
          className={arrow ? 'arrow' : ''}
          isDisabled={isDisabled}
          defaultValue={defaultValue}
        />
        <div className="container-alert-icon">
          <GuruIcon nameIcon={`${inputIconValidation(isValid, value, toValidate, arrow)}`}/>   
        </div>
      </div>
      {toValidate !== "" && !isValid && error && <ErrorList rule={error} />}
    </div>
  )
}

export default SearchInput