import React, { useState, useEffect } from 'react'
import { IonPage, IonHeader, IonContent, IonSpinner, IonLabel, IonSegmentButton, IonSegment } from '@ionic/react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { first } from 'lodash'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperInterface } from 'swiper'

import 'swiper/swiper.min.css'
import '@ionic/react/css/ionic-swiper.css'

import Office from '../../models/Office'
import { CartModel } from '../../models/CartModel'
import Notifications from '../../models/Notifications'
import { Coupon } from '../../models/Coupon'

//Componente
import ToolBar from '../../components/tool-bar/ToolBar'
import CardNotifications from './CardNotifications'
import OpenNotifications from './OpenNotifications'

import './Notifications.scss'
import { validateIfIsInvitedUser } from '../../utils/invitedUser'

interface IPathParams {}

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  offices: Office
  coupons: Coupon
  cartModel: CartModel
  notification: Notifications
}

const NotificationsComponent: React.FC<IProps> = props => {
  const [, setOffice] = useState<any>({})
  const [, setOffices] = useState<any>({})
  const [loadingNotifications, setLoadingNotifications] = useState<boolean>(true)
  const [newNotifications, setNewNotifications] = useState<any[]>([])
  const [readNotifications, setReadNotifications] = useState<any[]>([])
  const [segmentValue, setSegmentValue] = useState<any>(
    localStorage.getItem('segmentValueNotifications') ? localStorage.getItem('segmentValueNotifications') : 'new',
  )
  const [newLabelStyle, setNewLabelStyle] = useState<any>({ color: '#1E3799' })
  const [readLabelStyle, setReadLabelStyle] = useState<any>({})
  const [newNotifClass, setNewNotifClass] = useState<any>('disappear')
  const [readNotifClass, setReadNotifClass] = useState<any>('disappear')
  const [openedNotification, setOpenedNotification] = useState<any>(false)
  const [isCheckedTabOne, setIsCheckedTabOne] = useState<any>(false)
  const [isCheckedTabOneNew, setIsCheckedTabOneNew] = useState<any>(false)
  const [isInvitedUser, setIsInvitedUser] = useState(false)
  const [swiper, setSwiper] = useState<SwiperInterface>()

  useEffect(() => {
    get()
    getTheCardsWithNotification()
    validateOpenedNotification()
    manageNotificationsAlert()
    checkIsInvitedUser()
    localStorage.removeItem('segmentValueNotifications')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const checkIsInvitedUser = async () => {
    const isInvitedUserResult = await validateIfIsInvitedUser()
    setIsInvitedUser(isInvitedUserResult)
  }

  const manageNotificationsAlert = () => localStorage.setItem('hasOpenedNotifications', 'true')

  const validateOpenedNotification = () => {
    const {
      match: { params },
    } = props
    const paramsId: any = params
    if (paramsId.id) setOpenedNotification(true)
  }

  const get = async (changedOffice?: any) => {
    const selectedStyle = { color: '#1E3799' }
    let newLabelStyle = {}
    let readLabelStyle = {}
    let newNotificationsClass = ''
    let readNotificationsClass = ''

    const offices = await props.offices.get()
    const currentOffice = localStorage.getItem('currentOffice')
    const office = currentOffice ? JSON.parse(currentOffice) : first(offices)
    props.offices.setOffice(office)
    setOffice(changedOffice ? changedOffice : office)
    setOffices(offices)
    if (segmentValue === 'new') {
      newLabelStyle = selectedStyle
      readNotificationsClass = 'disappear'
      setIsCheckedTabOneNew(true)
      setIsCheckedTabOne(false)
      setSegmentValue('new')
    }
    if (segmentValue === 'read') {
      readLabelStyle = selectedStyle
      newNotificationsClass = 'disappear'
      setIsCheckedTabOneNew(false)
      setIsCheckedTabOne(true)
      setSegmentValue('read')
    }

    setNewLabelStyle(newLabelStyle)
    setReadLabelStyle(readLabelStyle)
    setNewNotifClass(newNotificationsClass)
    setReadNotifClass(readNotificationsClass)
  }

  const getTheCardsWithNotification = async () => {
    setNewNotifClass('disappear')
    setReadNotifClass('disappear')

    const user = localStorage.getItem('@user')
    const userId = user ? JSON.parse(user) : ''

    const notificationsUser = await props.notification.getNotifications(userId.id)
    const { docs } = notificationsUser
    const readNotifications: any[] = []
    const nonReadNotifications: any[] = []

    docs.forEach((doc: any) => (doc.isOpened ? readNotifications.push(doc) : nonReadNotifications.push(doc)))

    if (segmentValue === 'new') setNewNotifClass('')
    if (segmentValue === 'read') setReadNotifClass('')
    setReadNotifications(readNotifications)
    setNewNotifications(nonReadNotifications)
    setLoadingNotifications(false)
  }

  const refreshNotifications = () => {
    localStorage.setItem('segmentValueNotifications', segmentValue)
    window.location.href = `/notifications`
  }

  const goToHome = () => props.history.push('/home')

  const onSelectNotification = () => setOpenedNotification(true)

  const onSegmentChange = (value: any) => {
    const selectedStyle = { color: '#1E3799' }
    let newLabelStyle = {}
    let readLabelStyle = {}
    let newNotificationsClass = ''
    let readNotificationsClass = ''

    if (value === 'new') {
      newLabelStyle = selectedStyle
      readNotificationsClass = 'disappear'
      setIsCheckedTabOneNew(true)
      setIsCheckedTabOne(false)
    } else if (value === 'read') {
      readLabelStyle = selectedStyle
      newNotificationsClass = 'disappear'
      setIsCheckedTabOne(true)
      setIsCheckedTabOneNew(false)
    }

    setNewLabelStyle(newLabelStyle)
    setReadLabelStyle(readLabelStyle)
    setNewNotifClass(newNotificationsClass)
    setReadNotifClass(readNotificationsClass)
    setSegmentValue(value)
  }

  const renderNotifications = () => {
    if (isInvitedUser) {
      return (
        <div className="notifications-container">
          <CardNotifications
            notifications={newNotifications}
            openNotification={() => {}}
            emptyMessage={'Debes tener cuenta para poder recibir notificaciones'}
            {...props}
          />
        </div>
      )
    }

    return (
      <>
        <Swiper
          onSlideChange={() => {
            setSegmentValue(segmentValue === 'new' ? 'read' : 'new')
          }}
          onSwiper={s => setSwiper(s)}
          initialSlide={segmentValue === 'new' ? 0 : 1}
        >
          <SwiperSlide>
            <div className={`notifications-container ${newNotifClass}`}>
              <CardNotifications
                notifications={newNotifications}
                openNotification={onSelectNotification}
                emptyMessage={'No tienes notificaciones nuevas\n¡Llegarán pronto!'}
                {...props}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`notifications-container ${readNotifClass}`}>
              <CardNotifications
                notifications={readNotifications}
                openNotification={onSelectNotification}
                emptyMessage={'No tienes notificaciones leídas'}
                {...props}
              />
            </div>
          </SwiperSlide>
        </Swiper>
        {loadingNotifications && (
          <div className="loading-section-notification">
            <p className="loading-notification">Cargando</p>
            <IonSpinner className="spinner-mid" name="crescent" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </div>
        )}
      </>
    )
  }

  const renderOpenedNotification = () => {
    return (
      <OpenNotifications
        refreshNotifications={refreshNotifications}
        showNotification={setOpenedNotification}
        {...props}
      />
    )
  }

  const goBack = async () => {
    refreshNotifications()
    props.history.push(`/notifications`)
  }

  return (
    <IonPage className="page-notifications" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <ToolBar
          title={openedNotification ? 'Volver' : 'Notificaciones'}
          secondaryHeader={true}
          secondaryButtons={[
            {
              type: openedNotification ? 'back' : 'close',
              onClick: openedNotification ? goBack : goToHome,
            },
          ]}
          boxShadow={true}
          textCenter={true}
          headerNotifications={true}
        />
      </IonHeader>
      {!openedNotification && !isInvitedUser && (
        <IonSegment
          mode="md"
          value={segmentValue}
          onIonChange={e => {
            onSegmentChange(e.detail.value)
            if (e.detail.value === 'new') swiper?.slideTo(0)
            if (e.detail.value === 'read') swiper?.slideTo(1)
          } }
          className="notifications-segment"
          swipeGesture={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        >
          <IonSegmentButton
            value="new"
            disabled={loadingNotifications}
            className="segment-btn"
            style={{ '--color-checked': '#1E3799' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
            <IonLabel className={isCheckedTabOneNew ? 'isChecked' : ''} style={newLabelStyle} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              Nuevas
            </IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            value="read"
            disabled={loadingNotifications}
            className="segment-btn"
            style={{ '--color-checked': '#1E3799' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
            <IonLabel className={isCheckedTabOne ? 'isChecked' : ''} style={readLabelStyle} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              Leídas
            </IonLabel>
          </IonSegmentButton>
        </IonSegment>
      )}
      <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{openedNotification ? renderOpenedNotification() : renderNotifications()}</IonContent>
      <style>
        {`
          .footer-menu-home {
            display: none !important;
          }
        `}
      </style>
    </IonPage>
  )
}

export default withRouter(NotificationsComponent)
